import React, { useCallback } from 'react';
import { motion } from "framer-motion";

import useUI from "@quidlo/common/hooks/useUI";

import agaPNG from './aga.png';
import radPNG from './rad.png';

import styles from './Egg.module.scss';

const
    Egg = () => {
        const { egg } = useUI();
        const renderedEgg = useCallback(() => {
            if (egg === 'rad') {
                const tmp = [];
                for (let i = 0; i <= 10; i += 1) {
                    const
                        width = 200 + (250 * Math.random()),
                        style = {
                            left: `${100 * Math.random()}%`,
                            top: `${100 * Math.random()}%`,
                            width: `${width}px`,
                            marginTop: `${-width / 2}px`,
                            marginLeft: `${-width / 2}px`,
                            animationDelay: `${0.5 * Math.random()}s`,
                        };
                    tmp.push(<img className={styles.radImg} src={radPNG} alt={egg} key={i} style={style} />);
                }
                return tmp;
            } else if (egg === 'aga') {
                return (
                    <motion.div
                        className={styles.agaImg}
                        animate={{
                            opacity: [0, 1, 1, 0],
                            y: [100, 0, 20, 0, 20, 0, -100],
                        }}
                        transition={{
                            opacity: {
                                ease: 'linear',
                                duration: 2,
                                times: [0, 0.1, 0.9, 1],
                            },
                            y: {
                                ease: 'easeInOut',
                                duration: 2,
                                times: [0, 0.15, 0.325, 0.5, 0.675, 0.85, 1]
                            }
                        }}
                    >
                        <img
                            src={agaPNG}
                            alt={egg}
                        />
                        <motion.div
                            className={styles.cloud}
                            animate={{
                                scale: [0, 1],
                            }}
                            transition={{
                                type: 'spring',
                                duration: 0.3,
                                delay: 0.6,
                            }}
                        >
                            I don&apos;t want to waste my time on this
                        </motion.div>
                    </motion.div>);
            }
            return null;
        }, [
            egg,
        ]);

        return (egg ? (<div className={styles.egg}>{renderedEgg()}</div>) : <div />);
    };

export default Egg;
