import React from 'react';
import { useIntl } from "react-intl";
import cx from 'classnames';
import { AnimatePresence, motion } from "framer-motion";
import PropTypes from 'prop-types';

import { Button, Icon } from '..';

import style from './Autocomplete.module.scss';

import messages from './Autocomplete.i18n';

const DEFAULT_OPTIONLIST_SIZE = 20,
    AutocompleteDropdown = ({
        inputValue,
        options,
        optionsLength,
        activeOption,
        noOptionsText,
        onSelect,
        onSelectAll,
        optionRef,
        optionsContainerRef,
        isFocused,
        allSelected,
        showAll,
        clickShowAll,
        selectOnly,
        setActiveOption
    }) => {
        const intl = useIntl();

        const optionsToRender =
                options.length > DEFAULT_OPTIONLIST_SIZE && showAll
                    ? options
                    : options.slice(0, DEFAULT_OPTIONLIST_SIZE);

        return (
            <AnimatePresence>
                {!!isFocused && (
                    <motion.div
                        className={style.optionsContainer}
                        ref={optionsContainerRef}
                        style={{
                            maxHeight: `${
                                (56 * optionsLength) + 28
                            }px`,
                        }}
                        initial={{ scale: 1, opacity: 0, y: -10 }}
                        animate={{ scale: 1, opacity: 1, y: 0 }}
                        exit={{ scale: 1, opacity: 0, y: -10 }}
                    >
                        {options.length > 0 && onSelectAll && !inputValue && (
                            <div
                                className={cx(
                                    style.checkbox,
                                    allSelected && style.checked,
                                    style.dropdown,
                                    style.selectAll,
                                    activeOption === 'all' && style.active,
                                )}
                                id="selectAll"
                                onClick={onSelectAll}
                                onMouseEnter={() => setActiveOption('all')}
                                ref={'all' === activeOption ? optionRef : null}
                            >
                                <div className={style.check}>
                                    <Icon icon="tick" color="blue" />
                                </div>
                                <label
                                    htmlFor="selectAll"
                                    className={style.labelElement}>
                                    All
                                </label>
                            </div>
                        )}
                        {options.length > 0 && (
                            <div>
                                {optionsToRender.map((opt, index) => (
                                    <div
                                        onMouseEnter={() => setActiveOption(index)}
                                        key={JSON.stringify(opt.value)}
                                        className={cx(
                                            style.checkbox,
                                            opt.selected && style.checked,
                                            style.dropdown,
                                            activeOption === index && style.active,
                                            selectOnly && style.only
                                        )}
                                        ref={index === activeOption ? optionRef : null}
                                        id={opt.value}
                                        onClick={() => {
                                            onSelect(opt.value);
                                        }}
                                    >
                                        <div >
                                            <div className={style.check}>
                                                <Icon icon="tick" color="blue" />
                                            </div>
                                            <label
                                                htmlFor={opt.value}
                                                className={
                                                    style.labelElement
                                                }>
                                                {opt.label}
                                            </label>
                                        </div>
                                        {selectOnly &&
                                        <div className={style.onlyButton}>
                                            <Button
                                                size={'superSmall'}
                                                text={intl.formatMessage(messages.only)}
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    selectOnly(opt.value)
                                                }}
                                                shadow
                                            />
                                        </div>
                                        }
                                    </div>
                                ))}
                                {!showAll && options.length > DEFAULT_OPTIONLIST_SIZE && (
                                    <div className={style.showAll}>
                                        <Button
                                            type="plain"
                                            text={intl.formatMessage(
                                                messages.showAll,
                                                {
                                                    length: options.length,
                                                },
                                            )}
                                            onClick={clickShowAll}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                        {options.length === 0 && (
                            <div
                                className={cx(
                                    style.dropdown,
                                    style.noOptionsContainer,
                                )}>
                                <span className={style.noOptions}>
                                    {noOptionsText}
                                </span>
                            </div>
                        )}
                    </motion.div>
                )}
            </AnimatePresence>
        );
    };

AutocompleteDropdown.propTypes = {
    inputValue: PropTypes.string,
    options: PropTypes.array.isRequired,
    optionsLength: PropTypes.number.isRequired,
    activeOption: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onSelect: PropTypes.func.isRequired,
    onSelectAll: PropTypes.func,
    optionRef: PropTypes.object.isRequired,
    optionsContainerRef: PropTypes.object.isRequired,
    noOptionsText: PropTypes.string.isRequired,
    isFocused: PropTypes.bool,
    allSelected: PropTypes.bool,
    showAll: PropTypes.bool.isRequired,
    clickShowAll: PropTypes.func.isRequired,
    selectOnly: PropTypes.func,
    setActiveOption: PropTypes.func.isRequired,
};

AutocompleteDropdown.defaultProps = {
    inputValue: undefined,
    onSelectAll: undefined,
    isFocused: false,
    allSelected: false,
    selectOnly: undefined
};

export default AutocompleteDropdown;
