import { defineMessages } from 'react-intl';

export default defineMessages({
    owner: {
        id: 'app.role.owner',
        defaultMessage: 'Owner',
    },
    admin: {
        id: 'app.role.admin',
        defaultMessage: 'Administrator',
    },
    employee: {
        id: 'app.role.employee',
        defaultMessage: 'Employee',
    },
    trialExpiredEmployee: {
        id: 'app.trialExpiredEmployee',
        defaultMessage: 'Your FREE TRIAL is about to end in {days} {dayForm}. Please ask the workpace’s owner to upgrade it.',
    },
    subscriptionExpiredEmployee: {
        id: 'app.subscriptionExpiredEmployee',
        defaultMessage: 'Your SUBSCRIPTION is about to end in {days} {dayForm}. Please ask the workpace’s owner to upgrade it.',
    },
    trialExpiredOwner: {
        id: 'app.trialExpiredOwner',
        defaultMessage: 'Your FREE TRIAL is about to end in {days} {dayForm}.',
    },
    subscriptionExpiredOwner: {
        id: 'app.subscriptionExpiredOwner',
        defaultMessage: 'Your SUBSCRIPTION is about to end in {days} {dayForm}.',
    },
    freeStarterPlanOwner: {
        id: 'app.freeStarterPlanOwner',
        defaultMessage: 'Your subscription plan expired. You are currently using a Free Starter Plan.',
    },
    upgrade: {
        id: 'app.upgrade',
        defaultMessage: 'Upgrade',
    },
    manageSubscription: {
        id: 'app.manageSubscription',
        defaultMessage: 'Manage subscription',
    },
    youHaveBeenInvited: {
        id: 'app.youHaveBeenInvited',
        defaultMessage: 'You have been invited to the {companyName} workspace!',
    },
    acceptInvitation: {
        id: 'app.acceptInvitation',
        defaultMessage: 'Accept invitation',
    },
    confirmYourEmailAddress: {
        id: 'app.confirmYourEmailAddress',
        defaultMessage: 'Please confirm your email address to continue Quidlo Timesheets.',
    },
    resendEmail: {
        id: 'app.resendEmail',
        defaultMessage: 'Resend email',
    },
    days: {
        id: 'app.days',
        defaultMessage: 'days',
    },
    day: {
        id: 'app.day',
        defaultMessage: 'day',
    },
    paymentIssue: {
        id: 'app.paymentIssue',
        defaultMessage: 'There was an issue with collecting payment for your subscription plan. Please verify your payment method in order not to lose access to your account.',
    },
});
