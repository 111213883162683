import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import useUI from "@quidlo/common/hooks/useUI";
import { Button, Space } from "@quidlo/ui";

import illustration from "../../../../assets/modals/workspace.png";
import ModalHOC, { ModalActions, ModalContent, ModalHeader } from "../../../../ui/Modal/Modal";

import style from "./YouHaveReached.module.scss";

import messages from "./YouHaveReached.i18n";

const YouHaveReached = () => {
    const
        intl = useIntl(),
        { modalClose } = useUI(),
        navigate = useNavigate(),
        upgrade = () => {
            modalClose();
            navigate('/paywall');
        };

    return (
        <>
            <ModalHeader>
                {intl.formatMessage(messages.title)}
            </ModalHeader>
            <ModalContent>
                <div className={style.description}>
                    {intl.formatMessage(messages.description1)}
                    <span>{` ${intl.formatMessage(messages.upTo10users)} `}</span>
                    {intl.formatMessage(messages.description2)}
                </div>
            </ModalContent>
            <ModalActions>
                <Space mr="m">
                    <Button color="white" text={intl.formatMessage(messages.cancel)} onClick={modalClose} />
                </Space>
                <Button color="blue" text={intl.formatMessage(messages.upgrade)} onClick={upgrade} />
            </ModalActions>
        </>
    );
};

export default ModalHOC(
    YouHaveReached,
    {
        id: 'youHaveReached',
        size: 'small',
        illustration,
    }
);

