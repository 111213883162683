const
    COLORS = {
        WHITE: '#ffffff',
        BLACK: '#141D27',
        BLUE: '#3C63E8',
        GREY: '#9BABAF',
        FLINT: '#779096',
        FLINT60: '#4E5966',
        ECRU: '#E3E3E3',
    },
    PREDEFINED_COLORS = [
        { color: '#354A5D', name: 'Rigorous Dark' },
        { color: '#004C82', name: 'Advantageous Cobalt' },
        { color: '#257AB3', name: 'Effective Azure' },
        { color: '#6740B4', name: 'Feasible Iris' },
        { color: '#995CB3', name: 'Rigorous Lilac' },
        { color: '#B24282', name: 'Productive Pink' },
        { color: '#932922', name: 'Easy Cherry' },
        { color: '#D93529', name: 'Rapid Red' },
        { color: '#947200', name: 'Feasible Mustard' },
        { color: '#438000', name: 'Lazy Green' },
        { color: '#007A61', name: 'Profitable Teal' },
        { color: '#006C7A', name: 'Rushing Ocean' },
    ],
    PROJECT_ARCHIVED_COLOR = '#9BABAF';

export default {
    COLORS,
    PREDEFINED_COLORS,
    PROJECT_ARCHIVED_COLOR
};