import React, { useEffect, useRef, useState } from 'react';
import cx from "classnames";
import PropTypes from 'prop-types';

import analytics from "@quidlo/common/utils/analytics";

import { Icon } from "../index";

import style from "./Input.module.scss";

const Input = ({
    label,
    placeholder,
    type,
    size,
    value,
    error,
    disabled,
    isRequired,
    onChange,
    onBlur,
    onFocus,
    onKeyDown,
    fieldId,
    cypressId,
    analyticsID
}) => {
    const
        [isPasswordVisible, setIsPasswordVisible] = useState(false),
        analyticsTimeout = useRef(null),
        focusHandler = () => {
            if (analyticsID) {
                analytics.logTimesheetsInputFocus(analyticsID)
            }
            if (onFocus) {
                onFocus();
            }
        },
        blurHandler = () => {
            if (onBlur) {
                onBlur({
                    value,
                });
            }
        },
        keyDownHandler = e => {
            if (onKeyDown) {
                onKeyDown(e);
            }
        },
        changeHandler = event => {
            const v = event.target.value;
            if (onChange) {
                onChange(v);
            }

            if (analyticsTimeout.current) {
                clearTimeout(analyticsTimeout.current);
            }

            analyticsTimeout.current = setTimeout(() => {
                if (analyticsID && v) {
                    analytics.logTimesheetsInputValue({ id: analyticsID, value: v })
                }
            }, 500);
        },
        togglePassword = () => {
            setIsPasswordVisible(
                prevIsPasswordVisible => !prevIsPasswordVisible,
            );
        };

    useEffect(() => {
        if (analyticsID && error) {
            analytics.logTimesheetsInputValidationError({ id: analyticsID, message: error })
        }
    }, [error]);

    return (
        <div
            className={cx(
                style.input,
                error && style.error,
                disabled && style.disabled,
                style[`size-${size}`],
            )}>
            <input
                type={isPasswordVisible ? 'text' : type}
                name={fieldId}
                autoComplete="off"
                value={value}
                onChange={changeHandler}
                onBlur={blurHandler}
                onFocus={focusHandler}
                onKeyDown={keyDownHandler}
                className={value && style.filled}
                data-cy={cypressId}
            />
            <span className={style.bar} />
            {label && (
                <label
                    className={placeholder && style.hasPlaceholder}
                    htmlFor={fieldId}>
                    {label}
                    {isRequired && <span>*</span>}
                </label>
            )}
            {placeholder ? <span className={style.hint}>{placeholder}</span> : null}
            {error && (
                <span className={style.errormessage}>
                    {error}
                </span>
            )}
            {type === 'password' && (
                <a className={style.showPassword} onClick={togglePassword}>
                    <Icon icon="eye" size="small" color="grey" />
                </a>
            )}
        </div>
    );
};

Input.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    error: PropTypes.string,
    isFocused: PropTypes.bool,
    isTouched: PropTypes.bool,
    isRequired: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.oneOf(['text', 'number', 'password', 'hour']),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    disabled: PropTypes.bool,
    fieldId: PropTypes.string,
    cypressId: PropTypes.string,
    analyticsID: PropTypes.string,
};

Input.defaultProps = {
    label: '',
    placeholder: '',
    type: 'text',
    size: 'medium',
    value: '',
    error: '',
    disabled: false,
    isFocused: false,
    isTouched: false,
    isRequired: false,
    onChange: undefined,
    onBlur: undefined,
    onFocus: undefined,
    onKeyDown: undefined,
    fieldId: undefined,
    cypressId: undefined,
    analyticsID: undefined
};

export default Input;
