import { defineMessages } from 'react-intl';

export default defineMessages({
    all: {
        id: 'approvalDetails.all',
        defaultMessage: 'All',
    },
    project: {
        id: 'approvalDetails.project',
        defaultMessage: 'Project',
    },
    logDate: {
        id: 'approvalDetails.logDate',
        defaultMessage: 'Log date',
    },
    person: {
        id: 'approvalDetails.person',
        defaultMessage: 'Person',
    },
    duration: {
        id: 'approvalDetails.duration',
        defaultMessage: 'Duration',
    },
    period: {
        id: 'approvalDetails.period',
        defaultMessage: 'Period',
    },
    tags: {
        id: 'approvalDetails.tags',
        defaultMessage: 'Tags',
    },
    description: {
        id: 'approvalDetails.description',
        defaultMessage: 'Description',
    },
    total: {
        id: 'approvalDetails.total',
        defaultMessage: 'Total:',
    },
    details: {
        id: 'approvalDetails.details',
        defaultMessage: 'Details',
    },
    requestFrom: {
        id: 'approvalDetails.requestFrom',
        defaultMessage: 'request from',
    },
    commentFromManager: {
        id: 'approvalDetails.commentFromManager',
        defaultMessage: 'Comment from manager',
    },
    approve: {
        id: 'approvalDetails.approve',
        defaultMessage: 'Approve',
    },
    reject: {
        id: 'approvalDetails.reject',
        defaultMessage: 'Reject',
    },
    withdrawApproval: {
        id: 'approvalDetails.withdrawApproval',
        defaultMessage: 'Withdraw approval',
    },
    tooltip: {
        id: 'approvalDetails.tooltip',
        defaultMessage: 'Your approval request has been withdrawn. You will need to amend your tasks and then create a new approval request.',
    },
    startStop: {
        id: 'approvalDetails.startStop',
        defaultMessage: 'Start - Stop',
    },
});
