import { FEATURE } from "@quidlo/common/constants/main";

export default [
    {
        title: 'Tracker',
        icon: 'timer',
        module: '/tracker',
        link: '/tracker',
        roles: {
            employee: true,
            manager: true,
            admin: true,
            owner: true
        }
    },
    {
        title: 'Reports',
        icon: 'report',
        module: '/reports',
        link: '/reports',
        roles: {
            employee: true,
            manager: true,
            admin: true,
            owner: true
        }
    },
    {
        title: 'Time Approvals',
        icon: 'approvals',
        module: '/approvals',
        link: '/approvals/list/my',
        roles: {
            employee: true,
            manager: true,
            admin: true,
            owner: true
        },
        feature: FEATURE.APPROVALS
    },
    {
        title: 'Projects',
        icon: 'project',
        module: '/projects',
        link: '/projects',
        roles: {
            manager: true,
            admin: true,
            owner: true
        }
    },
    {
        title: 'Users',
        icon: 'users',
        module: '/users',
        link: '/users',
        roles: {
            admin: true,
            owner: true
        }
    },
    {
        title: 'Settings',
        icon: 'settings',
        module: '/settings',
        link: '/settings/workspace',
        roles: {
            employee: true,
            manager: true,
            admin: true,
            owner: true
        }
    }
];
