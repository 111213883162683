import { defineMessages } from 'react-intl';

export default defineMessages({
    editProject: {
        id: 'projectsEdit.header',
        defaultMessage: 'Edit project'
    },
    projectEdited: {
        id: 'projectsEdit.projectEdited',
        defaultMessage: 'Project edited'
    },
    nameChangedToast: {
        id: 'projectsEdit.nameChangedToast',
        defaultMessage: 'Project name changed'
    },
    colorChangedToast: {
        id: 'projectsEdit.colorChangedToast',
        defaultMessage: 'Project color changed'
    },
    freezeDateChangedToast: {
        id: 'projectsEdit.freezeDateChangedToast',
        defaultMessage: 'Frozen date set'
    },
    tagAddedToast: {
        id: 'projectsEdit.tagAddedToast',
        defaultMessage: 'Project tag added'
    },
    tagRemovedToast: {
        id: 'projectsEdit.tagRemovedToast',
        defaultMessage: 'Project tag removed'
    },
    newTagAdded: {
        id: 'projectsEdit.newTagAdded',
        defaultMessage: 'New tag added'
    },
    tagRemoved: {
        id: 'projectsEdit.tagRemoved',
        defaultMessage: 'Tag removed'
    },
    couldntRemoveTag: {
        id: 'projectsEdit.couldntRemoveTag',
        defaultMessage: 'Sorry, couldn\'t remove tag'
    },
    projectTeam: {
        id: 'projectsEdit.projectTeam',
        defaultMessage: 'Project members'
    },
    managerRole: {
        id: 'projectsEdit.managerRole',
        defaultMessage: 'Manager'
    },
    memberRole: {
        id: 'projectsEdit.memberRole',
        defaultMessage: 'Member'
    },
    newUserAdded: {
        id: 'projectsEdit.newUserAdded',
        defaultMessage: 'New {role} added'
    },
    errorUserIsParticipatingAlready: {
        id: 'projectsEdit.errorUserIsParticipatingAlready',
        defaultMessage: 'User is already participating'
    },
    deleteUser: {
        id: 'projectsEdit.deleteUser',
        defaultMessage: 'Delete user'
    },
    toastUserRoleEdited: {
        id: 'projectsEdit.toastUserRoleEdited',
        defaultMessage: 'User role edited'
    },
    toastUserRemoved: {
        id: 'projectsEdit.toastUserRemoved',
        defaultMessage: 'User removed'
    },
    cancel: {
        id: 'projectsEdit.cancel',
        defaultMessage: 'Cancel'
    },
    remove: {
        id: 'projectsEdit.remove',
        defaultMessage: 'Remove'
    },
    invitationHasBeenSent: {
        id: 'users.toast.invitationHasBeenSent',
        defaultMessage: 'Invitation has been sent to {email}'
    },
    archiveProject: {
        id: 'projectsEdit.archiveProject',
        defaultMessage: 'Archive project'
    },
    activateProject: {
        id: 'projectsEdit.activateProject',
        defaultMessage: 'Activate project'
    },
    toastProjectArchived: {
        id: 'projectsEdit.toastProjectArchived',
        defaultMessage: 'Project archived'
    },
    toastProjectActivated: {
        id: 'projectsEdit.toastProjectActivated',
        defaultMessage: 'Project activated'
    },
    deleteProject: {
        id: 'projectsEdit.deleteProject',
        defaultMessage: 'Delete project'
    },
    addTeamMembers: {
        id: 'projectsEdit.addTeamMembers',
        defaultMessage: 'Add project members'
    },
});