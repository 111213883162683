import React from 'react';
import PropTypes from 'prop-types';

import emptyPng from './svg/empty.png';
import { Button, Space } from './..';

import style from './InfoCard.module.scss';

const InfoCard = ({ illustration, header, text, actions }) => (
    <div className={style.infoCardContainer}>
        <Space pv="m" className={style.infoCard}>
            {illustration && (
                <>
                    <div className={style.imageContainer}>{illustration}</div>
                    <div className={style.imageSpace} />
                </>
            )}
            {header &&
                <Space mb="xs" ph="m" className={style.header}>{header}</Space>
            }
            {text &&
                <Space mb="xs" ph="m" className={style.description}>{text}</Space>
            }
            {actions.length > 0 && (
                <Space mb="xs">
                    {actions.map(a => (
                        <Button
                            {...a}
                            key={a.text}
                            type="filled"
                            color="blue"
                            shadow
                        />
                    ))}

                </Space>
            )}
        </Space>
    </div>
);

InfoCard.propTypes = {
    header: PropTypes.string,
    text: PropTypes.string,
    actions: PropTypes.array,
    illustration: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
};

InfoCard.defaultProps = {
    header: 'No results',
    text: 'Try using different set of filters above',
    actions: [],
    illustration: <img src={emptyPng} alt="empty" />,
};

export default InfoCard;
