import { defineMessages } from 'react-intl';

export default defineMessages({
    total: {
        id: 'peopleview.total',
        defaultMessage: 'Total:'
    },
    project: {
        id: 'peopleview.project',
        defaultMessage: 'Project'
    },
    duration: {
        id: 'peopleview.duration',
        defaultMessage: 'Duration'
    },
    status: {
        id: 'peopleview.status',
        defaultMessage: 'Status'
    },
    summary: {
        id: 'peopleview.summary',
        defaultMessage: 'Summary'
    },
    totalTimeInAllProjects: {
        id: 'peopleview.totalTimeInAllProjects',
        defaultMessage: 'Total time in all projects:'
    },
});