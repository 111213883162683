import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import { AnimatePresence } from "framer-motion";
import PropTypes from 'prop-types';

import analytics from "@quidlo/common/utils/analytics";
import { Icon } from '@quidlo/ui';

import DateSelectGroup from './DateSelectGroup';
import Month from './Month';
import predefinedModes from "./predefinedModes";

import style from './Calendar.module.scss';

const weekdays = [
    {
        id: 'weekdays.monday.short',
        description: 'Monday - one letter',
        defaultMessage: 'M',
        key: 'monday'
    }, {
        id: 'weekdays.tuesday.short',
        description: 'Tuesday - one letter',
        defaultMessage: 'T',
        key: 'tuesday'
    }, {
        id: 'weekdays.wednesday.short',
        description: 'Wednesday - one letter',
        defaultMessage: 'W',
        key: 'wednesday'
    }, {
        id: 'weekdays.thursday.short',
        description: 'Thursday - one letter',
        defaultMessage: 'T',
        key: 'thursday'
    }, {
        id: 'weekdays.friday.short',
        description: 'Friday - one letter',
        defaultMessage: 'F',
        key: 'friday'
    }, {
        id: 'weekdays.saturday.short',
        description: 'saturday - one letter',
        defaultMessage: 'S',
        key: 'saturday'
    }, {
        id: 'weekdays.sunday.short',
        description: 'Sunday - one letter',
        defaultMessage: 'S',
        key: 'sunday'
    },
]

const
    keydownHandler = e => {
        if ((e.keyCode || e.which) >= 37 && (e.keyCode || e.which) <= 40) {
            e.preventDefault();
        }
    },
    isSameMonth = (dateA, dateB) => dateA.getMonth() === dateB.getMonth() && dateA.getFullYear() === dateB.getFullYear(),
    decrementMonth = (month, year) => (month !== 0 ? [month - 1, year] : [11, year - 1]),
    incrementMonth = (month, year) => (month !== 11 ? [month + 1, year] : [0, year + 1]),
    arraymove = (arr, fromIndex, toIndex) => {
        const element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
    },
    Calendar = ({
        date,
        onChange,
        dateBoundary,
        analyticsID
    }) => {
        const
            weekStart = 'monday',
            [state, setState] = useState({
                yearA: isSameMonth(date.gte, date.lte) ? decrementMonth(date.gte.getMonth(), date.gte.getFullYear())[1] : date.gte.getFullYear(),
                monthA: isSameMonth(date.gte, date.lte) ? decrementMonth(date.gte.getMonth(), date.gte.getFullYear())[0] : date.gte.getMonth(),
                yearB: date.lte.getFullYear(),
                monthB: date.lte.getMonth(),
                innerToggle: undefined
            }),
            checkBoundaryA = ({ currentMonth, currentYear }) => {
                if (new Date(currentYear, currentMonth, 1).getTime() >=
                    new Date(state.yearB, state.monthB, 1).getTime()) {
                    setState(prevState => ({
                        ...prevState,
                        monthB: Number(currentMonth + 1),
                        yearB: Number(currentYear),
                        monthA: currentMonth,
                        yearA: currentYear
                    }));
                } else {
                    setState(prevState => ({
                        ...prevState,
                        monthA: currentMonth,
                        yearA: currentYear
                    }));
                }
            },
            checkBoundaryB = ({ currentMonth, currentYear }) => {
                if (new Date(currentYear, currentMonth, 1).getTime() <=
                    new Date(state.yearA, state.monthA, 1).getTime()) {
                    setState(prevState => ({
                        ...prevState,
                        monthA: Number(currentMonth - 1),
                        yearA: Number(currentYear),
                        monthB: currentMonth,
                        yearB: currentYear
                    }));
                } else {
                    setState(prevState => ({
                        ...prevState,
                        monthB: currentMonth,
                        yearB: currentYear
                    }));
                }
            },
            setMonth = ({ currentMonth, currentYear }, side) => {
                if (side === 'A') {
                    checkBoundaryA({ currentMonth, currentYear });
                } else {
                    checkBoundaryB({ currentMonth, currentYear });
                }
            },
            setDate = newDate => {
                const newRange = { ...date };

                if (newDate < date.gte) {
                    setState(prevState => ({
                        ...prevState,
                        innerToggle: 'gte'
                    }));

                    newRange.gte = newDate;
                } else if (newDate > date.lte) {
                    setState(prevState => ({
                        ...prevState,
                        innerToggle: 'lte'
                    }));

                    newRange.lte = newDate;
                } else {
                    const { innerToggle } = state;

                    if (innerToggle) {
                        if (innerToggle === 'gte') {
                            setState(prevState => ({
                                ...prevState,
                                innerToggle: 'lte'
                            }));

                            newRange.lte = newDate;
                        } else {
                            setState(prevState => ({
                                ...prevState,
                                innerToggle: 'gte'
                            }));

                            newRange.gte = newDate;
                        }
                    } else {
                        const interval = {
                            gte: newDate - date.gte,
                            lte: date.lte - newDate
                        };

                        if (interval.gte <= interval.lte) {
                            setState(prevState => ({
                                ...prevState,
                                innerToggle: 'gte'
                            }));

                            // eslint-disable-next-line no-multi-assign,no-param-reassign
                            newRange.gte = date.gte = newDate;
                        } else {
                            setState(prevState => ({
                                ...prevState,
                                innerToggle: 'lte'
                            }));

                            // eslint-disable-next-line no-multi-assign,no-param-reassign
                            newRange.lte = date.lte = newDate;
                        }
                    }
                }
                analytics.logTimesheetsDatePickerSelect({
                    id: analyticsID,
                    date: newRange,
                    mode: 'custom'
                })
                onChange({ ...newRange, mode: 'custom' });
            },
            setMode = mode => {
                const value = mode.getValue({ weekStart })
                if (isSameMonth(value.gte, value.lte)) {
                    setMonth({ currentMonth: value.lte.getMonth(), currentYear: value.lte.getFullYear() }, 'B');
                } else {
                    setState(state => ({
                        ...state,
                        yearA: value.gte.getFullYear(),
                        monthA: value.gte.getMonth(),
                        yearB: value.lte.getFullYear(),
                        monthB: value.lte.getMonth(),
                    }))
                }
                analytics.logTimesheetsDatePickerSelect({
                    id: analyticsID,
                    date: value,
                    mode: mode.label
                })
                onChange(value);
            },
            nextMonth = side => {
                const requestedDate = incrementMonth(state[`month${side}`], state[`year${side}`]);
                if (side === 'A') {
                    checkBoundaryA({ currentMonth: requestedDate[0], currentYear: requestedDate[1] })
                } else {
                    checkBoundaryB({ currentMonth: requestedDate[0], currentYear: requestedDate[1] })
                }
            },
            prevMonth = side => {
                const requestedDate = decrementMonth(state[`month${side}`], state[`year${side}`]);
                if (side === 'A') {
                    checkBoundaryA({ currentMonth: requestedDate[0], currentYear: requestedDate[1] })
                } else {
                    checkBoundaryB({ currentMonth: requestedDate[0], currentYear: requestedDate[1] })
                }
            },
            keyupHandler = e => {
                if ((e.keyCode || e.which) === 37) {
                    prevMonth();
                } else if ((e.keyCode || e.which) === 39) {
                    nextMonth();
                }
            },
            activeMode = Object.values(predefinedModes).find(mode => {
                const { gte, lte } = mode.getValue({ weekStart });
                return gte.toDateString() === date.gte.toDateString() && lte.toDateString()===date.lte.toDateString()
            })?.name,
            props = {
                dateRange: date,
                onChange: setDate,
                weekStart,
                dateBoundary
            };

        useEffect(() => {
            document.addEventListener('keyup', keyupHandler);
            document.addEventListener('keydown', keydownHandler);

            return (() => {
                document.removeEventListener('keyup', keyupHandler);
                document.removeEventListener('keydown', keydownHandler);
            });
        }, []);

        if (weekStart === 'sunday') {
            arraymove(weekdays, 6, 0);
        }

        return (
            <div className={style.calendar}>
                <div className={style.month}>
                    <div className={style.header}>
                        <div>
                            <a onClick={() => prevMonth('A')} >
                                <Icon icon="caret-left"/>
                            </a>
                        </div>
                        <DateSelectGroup
                            value={new Date(state.yearA, state.monthA, 1)}
                            setDate={e => setMonth(e, 'A')}
                        />
                        <div>
                            <a onClick={() => nextMonth('A')} >
                                <Icon icon="caret-right" />
                            </a>
                        </div>
                    </div>
                    <div className={style.weeknames}>
                        {weekdays.map(day => (
                            <div key={day.key}>
                                <FormattedMessage {...day} />
                            </div>
                        ))}
                    </div>
                    <div className={style.days}>
                        <AnimatePresence initial={false}>
                            <Month {...props} month={state.monthA} year={state.yearA} />
                        </AnimatePresence>
                    </div>
                </div>
                <div className={style.month}>
                    <div className={style.header}>
                        <div>
                            <a onClick={() => prevMonth('B')}>
                                <Icon icon="caret-left"/>
                            </a>
                        </div>
                        <div className={style.name}>
                            <DateSelectGroup
                                value={new Date(state.yearB, state.monthB, 1)}
                                setDate={e => setMonth(e, 'B')}
                            />
                        </div>
                        <div>
                            <a onClick={() => nextMonth('B')}>
                                <Icon icon="caret-right"/>
                            </a>
                        </div>
                    </div>
                    <div className={style.weeknames}>
                        {weekdays.map(day => (
                            <div key={day.key}>
                                <FormattedMessage {...day} />
                            </div>
                        ))}
                    </div>
                    <div className={style.days}>
                        <AnimatePresence initial={false}>
                            <Month {...props} month={state.monthB} year={state.yearB} />
                        </AnimatePresence>
                    </div>
                </div>
                <div className={style.menu}>
                    {
                        Object.values(predefinedModes).map(mode => (
                            <a
                                key={mode.name}
                                className={cx([style.menuLink, activeMode === mode.name && style.active])}
                                onClick={() => setMode(mode)}
                            >
                                {mode.label}
                            </a>
                        ))
                    }
                    <a
                        className={cx([style.menuLink, !activeMode && style.active])}
                    >
                        Custom
                    </a>
                </div>
            </div>
        );
    };

Calendar.propTypes = {
    date: PropTypes.shape({
        gte: PropTypes.instanceOf(Date),
        lte: PropTypes.instanceOf(Date)
    }),
    onChange: PropTypes.func,
    dateBoundary: PropTypes.object,
    weekStart: PropTypes.oneOf(['monday', 'sunday']),
    analyticsID: PropTypes.string
};

Calendar.defaultProps = {
    date: {
        gte: new Date(),
        lte: new Date(),
    },
    onChange: undefined,
    dateBoundary: undefined,
    analyticsID: null,
};


export default Calendar;
