import fetch from '../utils/fetch';

export default {
    googleChatConnect: (workspaceCode, user, query) =>
        fetch.post(`/v1/${workspaceCode}/users/${user}/hangouts-user`, query),
    googleChatConnectRoom: (workspaceCode, user, query) =>
        fetch.post(`/v1/${workspaceCode}/users/${user}/hangouts-rooms`, query),
    googleChatGetStatus: (userId, workspaceCode) =>
        fetch.get(`/v1/${workspaceCode}/users/${userId}/hangouts-user/status`),
    slackConnect: (workspaceCode, user, query) =>
        fetch.post(`/v1/${workspaceCode}/users/${user}/slack-user`, query),
    slackGetConnection: (user, query) =>
        fetch.get(`/v1/users/${user}/slack-user`, { params: query }),
};
