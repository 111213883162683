import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'leaveWorkspace.title',
        defaultMessage: 'Leave',
    },
    subtitle: {
        id: 'leaveWorkspace.subtitle',
        defaultMessage: 'Are you sure you want to leave the workspace? By leaving, you will lose the access to the workspace as well.'
    },
    alt: {
        id: 'leaveWorkspace.alt',
        defaultMessage: 'An exit',
    },
    leave: {
        id: 'leaveWorkspace.leave',
        defaultMessage: 'Leave',
    },
    cancel: {
        id: 'leaveWorkspace.cancel',
        defaultMessage: 'Cancel',
    },
});
