import { defineMessages } from 'react-intl';

export default defineMessages({
    timeApprovals: {
        id: 'approvalCreate.timeApprovals',
        defaultMessage: 'New request',
    },
    requestTimeApproval: {
        id: 'approvalCreate.requestTimeApproval',
        defaultMessage: 'Request time approval',
    },
    projects: {
        id: 'approvalCreate.projects',
        defaultMessage: 'Projects',
    },
    apply: {
        id: 'approvalCreate.apply',
        defaultMessage: 'Apply',
    },
});