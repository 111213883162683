import { defineMessages } from 'react-intl';

export default defineMessages({
    yourAnswers: {
        id: 'pollModal.yourAnswers',
        defaultMessage: 'Your answers helps us to develop Quidlo Timesheets in harmony with our users needs.',
    },
    placeholder: {
        id: 'pollModal.placeholder',
        defaultMessage: 'Anything else to add?',
    },
    cancel: {
        id: 'pollModal.cancel',
        defaultMessage: 'Cancel',
    },
    send: {
        id: 'pollModal.send',
        defaultMessage: 'Send Feedback',
    },
});