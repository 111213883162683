import fetch from '../utils/fetch';

export default {
    getAPIKeys: (userId, workspaceCode) =>
        fetch.get(`/v1/${workspaceCode}/users/${userId}/api-keys`),
    postAPIKey: (userId, workspaceCode, key) =>
        fetch.post(`/v1/${workspaceCode}/users/${userId}/api-keys`, key),
    patchAPIKey: (userId, workspaceCode, name, delta) =>
        fetch.patch(`/v1/${workspaceCode}/users/${userId}/api-keys/${name}`, delta),
    deleteAPIKey: (userId, workspaceCode, name) =>
        fetch.delete(`/v1/${workspaceCode}/users/${userId}/api-keys/${name}`),
};
