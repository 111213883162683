import React from 'react';
import cx from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import PropTypes from 'prop-types';

import { ReactComponent as TickIcon } from "../Checkbox/svg/tick.svg";

import style from './Select.module.scss';

import messages from './Select.i18n';

const SelectOption = ({ label, value, onSelect, selected, isActive }) => (
    <div
        className={cx([
            style.checkbox,
            selected && style.checked,
            isActive && style.active
        ])}
    >
        <div className={style.check} onClick={onSelect}>
            <TickIcon />
        </div>
        <input
            onChange={onSelect}
            checked={selected}
            id={value}
            name={value}
            type="checkbox"
            value={selected}
        />
        <label htmlFor={value} className={style.label}>
            {label}
        </label>
    </div>
)

const SelectDropdown = ({
    options,
    active,
    onSelect,
    onSelectAll,
    optionsContainerRef,
    optionsContHeight,
    activeOption,
    intl,
}) => (
    <AnimatePresence>
        {!!active && (<motion.div
            className={style.optionsContainer}
            ref={optionsContainerRef}
            style={{ maxHeight: optionsContHeight }}
            initial={{ scale: 1, opacity: 0, y: -10 }}
            animate={{ scale: 1, opacity: 1, y: 0 }}
            exit={{ scale: 1, opacity: 0, y: -10 }}
        >
            {options.length > 0 && onSelectAll && (
                <SelectOption
                    key='all'
                    value={0}
                    label={intl.formatMessage(messages.all)}
                    selected={false}
                    onSelect={() => onSelect(0)}
                    isActive={activeOption === 'all'}
                />
            )}
            {options.length > 0 && options.map((o, index) => (
                <SelectOption
                    key={o.value}
                    onSelect={() => onSelect(o.value)}
                    isActive={activeOption === index}
                    {...o}
                />
            ))}
            {options.length === 0 && (
                <div className={style.noOptionsContainer}>
                    <span className={style.noOptions}>
                        {intl.formatMessage(messages.noOptions)}
                    </span>
                </div>
            )}
        </motion.div>)}
    </AnimatePresence>
);

SelectDropdown.propTypes = {
    onSelect: PropTypes.func.isRequired,
    onSelectAll: PropTypes.func,
    options: PropTypes.array.isRequired,
    active: PropTypes.bool,
    multiselect: PropTypes.bool,
    optionsContainerRef: PropTypes.object.isRequired,
    optionsContHeight: PropTypes.number.isRequired,
    activeOption: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    intl: PropTypes.object.isRequired,
};

SelectDropdown.defaultProps = {
    active: false,
    onSelectAll: undefined,
    multiselect: undefined,
};

export default SelectDropdown;
