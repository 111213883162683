import React from 'react';
import { useIsFetching } from '@tanstack/react-query'
import cx from 'classnames';

import style from './Spinner.module.scss';

const Spinner = () => {
    const isFetching = useIsFetching();
    return (
        <div className={cx(style.spinner, !isFetching && style.hidden)}>
            <div />
            <div />
            <div />
        </div>
    );
}

export default Spinner;