import fetch from '../utils/fetch';

export default {
    request: email => fetch.post('/v1/password-recovery-process', { email }),
    set: (email, token, newPassword) =>
        fetch.post(`/v1/password-recovery-process/${email}/reset-password-action`, {
            token,
            newPassword,
        }),
};
