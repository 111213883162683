import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'deleteUserDialog.title',
        defaultMessage: 'Delete user*',
    },
    subtitle1: {
        id: 'deleteUserDialog.subtitle1',
        defaultMessage: 'Are you sure you want to permanently delete',
    },
    subtitle2: {
        id: 'deleteUserDialog.subtitle2',
        defaultMessage: '? This action cannot be reversed.',
    },
    disclaimer: {
        id: 'deleteUserDialog.disclaimer',
        defaultMessage: '*The user will lose the access to this workspace, but their tracked data will remain. This action can’t be reversed. ',
    },
    alt: {
        id: 'deleteUserDialog.alt',
        defaultMessage: 'A trashbin',
    },
    delete: {
        id: 'deleteUserDialog.delete',
        defaultMessage: 'Delete',
    },
    cancel: {
        id: 'deleteUserDialog.cancel',
        defaultMessage: 'Cancel',
    }
});