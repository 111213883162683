import React from 'react';
import { useQueryClient } from "@tanstack/react-query";
import { AnimatePresence, motion } from "framer-motion";

import useAuthentication from "@quidlo/common/hooks/useAuthentication";
import useAutoTracker from "@quidlo/common/hooks/useAutoTracker";
import useWorkspace from "@quidlo/common/hooks/useWorkspace";
import { durationFormatter } from '@quidlo/common/utils/duration';
import { Button, ProgressCircle } from '@quidlo/ui';

import style from './Autotracker.module.scss';

const Autotracker = () => {
    const { task, stop } = useAutoTracker();
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    const queryClient = useQueryClient();

    const stopAutotrackingTask = () => {
        stop();
        setTimeout(() => {
            queryClient.invalidateQueries([
                { workspace: workspace.code, user: user.id },
                'reports'
            ])
            queryClient.invalidateQueries([
                { workspace: workspace.code, user: user.id },
                'tracker'
            ]);
        }, 500)
    };

    return (
        <AnimatePresence>
            {!!task && (
                <motion.div
                    className={style.autotracker}
                    initial={{ borderColor: "#fff" }}
                    animate={{ borderColor: "#F0F0F0" }}
                    exit={{ borderColor: "#fff" }}
                    transition={{ delay: 0.5 }}
                >
                    <motion.div
                        className={style.label}
                        initial={{ opacity: 0, y: 5 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 5 }}
                        transition={{ delay: 0.8 }}
                    >
                        {task.title && (
                            <div className={style.title}>
                                {task.title.length > 35
                                    ? `${task.title.substring(0, 35)}...`
                                    : task.title}
                            </div>
                        )}
                        <div className={style.duration}>{durationFormatter(task.duration)}</div>
                    </motion.div>
                    <motion.div
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                        exit={{ scale: 0 }}
                        transition={{ type: "spring", stiffness: 100, delay: 0 }}
                    >
                        <ProgressCircle>
                            <Button
                                onClick={stopAutotrackingTask}
                                icon="stop"
                                size="medium"
                                iconSize="small"
                                color="white"
                                iconColor="blue"
                                type="bordered"
                                shadow
                            />
                        </ProgressCircle>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
}

export default Autotracker;
