import fetch from '../utils/fetch';

export default {
    get: () => fetch.get('/v1/session', { headers: { Silent: true } }),
    delete: () => fetch.get('/v1/delete'),
    postCredentials: (email, password, timezone) =>
        fetch.post('/v1/session/creation-with-email-password', {
            email,
            password,
            timezone,
        }),
    postGoogle: (googleIdToken, timezone) =>
        fetch.post('/v1/session/creation-with-google', { googleIdToken, timezone }),
    postApple: (authorizationCode, firstName, lastName) =>
        fetch.post('/v1/session/creation-with-apple', {
            authorization_code: authorizationCode,
            first_name: firstName,
            last_name: lastName,
        }),
    deleteAccount: userId => fetch.delete(`/v1/users/${userId}`),
};
