import CustomPNG from './assets/custom-card.png';
import ProPNG from './assets/pro-card.png';
import StarterPNG from './assets/starter-card.png';

const cardOptions = {
    starter: {
        image: StarterPNG,
        alt: 'starterAlt',
        title: 'starterTitle',
        price: 'starterPrice',
        subtitle: 'starterSubtitle',
        perk1: 'starterPerk1',
        perk2: 'starterPerk2',
        best: false,
        button: 'starterButton'
    },
    pro: {
        image: ProPNG,
        alt: 'proAlt',
        title: 'proTitle',
        price: 'proPrice',
        subtitle: 'proSubtitle',
        perk1: 'proPerk1',
        perk2: 'proPerk2',
        billedMonthly: 'billedMonthly',
        billedAnnually: 'billedAnnually',
        best: true,
        button: 'proButton'
    },
    custom: {
        image: CustomPNG,
        alt: 'customAlt',
        title: 'customTitle',
        price: 'customPrice',
        subtitle: 'customSubtitle',
        perk1: 'customPerk1',
        best: false,
        button: 'customButton'
    }
};

export default cardOptions;