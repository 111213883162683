import { useContext } from 'react';

import { UIContext } from '../providers/UIProvider';

const useUI = () => {
    const {
        toasts,
        toastPush,
        toastPop,
        isSpinner,
        spinnerPush,
        spinnerPop,
        modal,
        modalOpen,
        modalClose,
        isMenu,
        setIsMenu,
        isTopbar,
        setIsTopbar,
        egg,
        displayEgg,
        isMobile,
    } = useContext(UIContext);

    return {
        toasts,
        toastPush,
        toastPop,
        isSpinner,
        spinnerPush,
        spinnerPop,
        modal,
        modalOpen,
        modalClose,
        isMenu,
        setIsMenu,
        isTopbar,
        setIsTopbar,
        egg,
        displayEgg,
        isMobile,
    };
};

export default useUI;
