import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import style from './ListCell.module.scss';

const
    ListCell = ({
        header,
        footer,
        width,
        align,
        last,
        children,
        onClick
    }) => (
        <div
            className={cx(
                style.listCell,
                (header && style.header),
                (footer && style.footer),
                (last && style.last),
                style[`align-${align}`],
                (onClick && style.clickable)
            )}
            style={{ flex: !width ? 1 : 0, maxWidth: width, minWidth: width }}
            onClick={onClick}
            onKeyPress={undefined}
        >
            <div className={style.children}>
                {children}
            </div>
        </div>
    );

ListCell.propTypes = {
    header: PropTypes.bool,
    footer: PropTypes.bool,
    last: PropTypes.bool,
    width: PropTypes.string,
    align: PropTypes.oneOf(['left', 'center', 'right']),
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    onClick: PropTypes.func,
};

ListCell.defaultProps = {
    header: false,
    footer: false,
    last: false,
    width: undefined,
    align: 'left',
    children: undefined,
    onClick: undefined
};

export default ListCell;