import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'cannotLeaveWorkspace.title',
        defaultMessage: 'Cannot leave workspace',
    },
    subtitle: {
        id: 'cannotLeaveWorkspace.subtitle',
        defaultMessage: 'You are not able to leave the workspace unless you first transfer the workspace ownership to another user.',
    },
    alt: {
        id: 'cannotLeaveWorkspace.alt',
        defaultMessage: 'A message notification',
    },
    manageUsers: {
        id: 'cannotLeaveWorkspace.manageUsers',
        defaultMessage: 'Manage users',
    },
    cancel: {
        id: 'cannotLeaveWorkspace.cancel',
        defaultMessage: 'Cancel',
    },
});
