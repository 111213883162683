import fetch from '../utils/fetch';

export default {
    start: (workspaceCode, userId, { date, projectId, tagIds, title, timeFrom }) =>
        fetch.post(`/v1/${workspaceCode}/users/${userId}/tasks/start-tracking-action`, { date, projectId, tagIds, title, userId, timeFrom }),
    stop: (workspaceCode, userId, { date, projectId, tagIds, title, timeTo }) =>
        fetch.post(`/v1/${workspaceCode}/users/${userId}/tasks/stop-tracking-action`, { date, projectId, tagIds, title, userId, timeTo }),
    restore: (workspaceCode, userId) =>
        fetch.get(`/v1/${workspaceCode}/users/${userId}/tasks/ongoing-task`),
};
