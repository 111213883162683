import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'qs';

import ChatbotsService from "@quidlo/common/api/ChatbotsService";
import IntegrationsService from "@quidlo/common/api/IntegrationsService";
import ProjectsService from "@quidlo/common/api/ProjectsService";
import useAuthentication from "@quidlo/common/hooks/useAuthentication";
import useForm from "@quidlo/common/hooks/useForm2";
import useWorkspace from "@quidlo/common/hooks/useWorkspace";
import validators from "@quidlo/common/utils/validators";
import { Autocomplete, Button, Label } from "@quidlo/ui";

import { Container } from "../../../ui";
import ProjectPill from "../../../ui/ProjectPill/ProjectPill";
import ViewHOC from '../../../ui/View/View';

import { ReactComponent as ChatbotIcon } from './svg/report-big.svg';
import NoPermissionToConfigure from './NoPermissionToConfigure';
import NoProjects from './NoProjects';

import style from './ConfigureGoogleChatContainer.module.scss';

import messages from './ConfigureGoogleChatContainer.i18n';

const FORM = {
    projects: {
        value: [],
        validators: [validators.isRequired()]
    },
    intents: {
        value: [],
        validators: [validators.isRequired()]
    }
}

const ConfigureBotContainer = () => {
    const
        intl = useIntl(),
        location = useLocation(),
        navigate = useNavigate(),
        { user } = useAuthentication(),
        { workspace } = useWorkspace(),
        [form] = useForm(FORM),
        [state, setState] = useState(
            {
                options: {
                    projects: [],
                    intents: []
                },
                accountConnected: true,
                hasSpaceId: true,
                isReady: false,
                hasPermissionToConfigure: false
            }),
        getProjects = () => new Promise((resolve, reject) => {
            ProjectsService.getAdministrableAll(user.id, workspace.code).then(({ data }) => {
                setState(prevState => ({
                    ...prevState,
                    options: {
                        ...prevState.options,
                        projects: data.map(p => ({
                            label :<ProjectPill key={p.id} {...p} />,
                            labelText: p.name,
                            value: p.id
                        }))
                    }
                }));
                form.projects.onChange(data.map(p => p.id));
                resolve();
            }).catch(reject);
        }),
        getIntents = () => new Promise((resolve, reject) => {
            ChatbotsService.getIntents().then(({ data }) => {
                setState(prevState => ({
                    ...prevState,
                    options: {
                        ...prevState.options,
                        intents: data.map(t => ({
                            label: t.name,
                            labelText: t.name,
                            value: t.id
                        }))
                    }
                }));
                form.intents.onChange(data.map(p => p.id));
                resolve();
            }).catch(reject);
        }),
        connectRoom = () => {
            IntegrationsService.googleChatConnectRoom(workspace.code, user.id, {
                hangoutsUserId: state.userId,
                spaceId: state.spaceId,
                spaceName: state.spaceName,
                intentIds: form.intents.value,
                projectIds: form.projects.value,
                redirectUrl: state.redirectUrl,
                token: state.token
            }).then(({ data }) => {
                if (data && data.redirectUrl) {
                    window.location.replace(data.redirectUrl);
                }
            });
        };

    useEffect(() => {
        const { search } = location;

        if (!search) {
            navigate('/integrations/chatbots/list');
            return;
        }

        const
            parsedSearch = search.substring(1).replace('+', '%2b');
        const {
            userId, spaceId, spaceName, redirectUrl, token
        } = qs.parse(parsedSearch);

        setState({
            userId,
            spaceId,
            spaceName,
            redirectUrl,
            token
        });

        Promise.all([
            getProjects(),
            getIntents()
        ])
            .then(() => {
                setState(prevState => ({
                    ...prevState,
                    hasPermissionToConfigure: true,
                    isReady: true
                }));
            });
    }, []);

    if (!state.isReady) {
        return null;
    }

    if (!state.hasPermissionToConfigure) {
        return (
            <NoPermissionToConfigure />
        );
    }

    if (state.options.projects.length === 0) {
        const props = {
            intl,
            onClick: () => navigate('/projects'),
            text: intl.formatMessage(messages.alertMsg)
        };

        return (
            <NoProjects {...props} />
        );
    }

    return (
        (
            <div className={style.card}>
                <Container>
                    <div className={style.infoCardContainer}>
                        <div className={style.headerContainer}>
                            <div className={style.image}>
                                <ChatbotIcon />
                            </div>
                            <div className={style.header}>
                                <FormattedMessage {...messages.header} />
                            </div>
                        </div>
                        <div className={style.infoCard}>
                            <div className={style.header}>
                                <FormattedMessage values={{ roomName: state.spaceName }} {...messages.infocardHeader} />
                            </div>
                            <div className={style.selectContainer}>
                                <div className={style.select}>
                                    <div className={style.selectHeader}>
                                        <Label icon="project" text={intl.formatMessage(messages.projects)} size="small" />
                                    </div>
                                    <Autocomplete
                                        fieldId="projects"
                                        {...form.projects}
                                        options={state.options.projects}
                                        allselect
                                        multiselect
                                    />
                                </div>
                                <div className={style.select}>
                                    <div className={style.selectHeader}>
                                        <Label icon="edit" text={intl.formatMessage(messages.intents)} size="small" />
                                    </div>
                                    <Autocomplete
                                        fieldId="intents"
                                        {...form.intents}
                                        options={state.options.intents}
                                        allselect
                                        multiselect
                                    />
                                </div>
                            </div>
                            <div className={style.buttonContainer}>
                                <Button
                                    type="filled"
                                    color="blue"
                                    text="Save"
                                    onClick={connectRoom}
                                    shadow
                                />
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        )
    );
};

export default ViewHOC(
    ConfigureBotContainer,
    {
        title: "Google Chat Congifuration",
        menu: true,
        topbar: true,
        auth: {},
        subscription: true
    }
);