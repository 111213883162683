import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Button } from '@quidlo/ui';

import { Container } from '../../ui';
import ViewHOC from '../../ui/View/View';

import { ReactComponent as SVG404 } from './svg/404.svg';

import style from './Error404.module.scss';

import messages from './Error404.i18n';

const
    Error404 = () => {
        const navigate = useNavigate();
        const intl = useIntl();
        return (
            <div className={style.error}>
                <Container>
                    <div className={style.center}>
                        <div className={style.number}>
                            <SVG404 />
                        </div>
                        <div className={style.title}>
                            {intl.formatMessage(messages.sorryPage)}
                        </div>
                        <div className={style.subtitle}>
                            {intl.formatMessage(messages.checkRecent)}
                        </div>
                        <div className={style.button}>
                            <Button color="blue" shadow text={intl.formatMessage(messages.goTimer)} onClick={() => {
                                navigate('/tracker');
                            }} />
                        </div>
                    </div>
                </Container>
            </div>
        );
    };

export default ViewHOC(Error404, {
    title: '404',
    menu: true,
    topbar: true
});
