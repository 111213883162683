import { useQuery } from '@tanstack/react-query'

import ProjectsService from "@quidlo/common/api/ProjectsService";
import TasksService from "@quidlo/common/api/TasksService";
import UsersService from "@quidlo/common/api/UsersService";
import ProjectsServiceV2 from "@quidlo/common/apiV2/ProjectsService";
import useAuthentication from "@quidlo/common/hooks/useAuthentication";
import useWorkspace from "@quidlo/common/hooks/useWorkspace";
import { dateToString } from "@quidlo/common/utils/dateHelpers";

export const getTrackerSummaryQuery = ({ startDate, endDate, selection }) => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    return useQuery(
        [
            { workspace: workspace.code, user: user.id },
            'tracker', 'summary', { startDate, endDate, selection }
        ],
        async () => {
            const data = (await TasksService.getSummaryByDays(workspace.code, user.id, { startDate: dateToString(startDate), endDate: dateToString(endDate), selection }))?.data;
            const worklog = {};
            for (let i = 0; i < data.length; i += 1) {
                worklog[data[i].day.substring(0, 10)] = data[i].durationSummaryMins;
            }
            return worklog;
        },
        {
            // enabled: !!workspace?.code && !!user?.id,
            initialData: {},
            keepPreviousData: true
        })
};

export const getTrackerDataQuery = ({ date, selection }) => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    return useQuery(
        [
            { workspace: workspace.code, user: user.id },
            'tracker', 'data', { date, selection }
        ],
        async () => {
            const data = (await TasksService.getDaySummaryByProjects(workspace.code, user.id, { date, selection }))?.data;
            return ({
                projects: data?.projects,
                selection,
                date
            })
        },
        {
            // enabled: !!workspace?.code && !!user?.id,
            initialData: {},
            keepPreviousData: true
        })
};

export const getTrackerFiltersUsersQuery = () => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    return useQuery(
        [
            { workspace: workspace.code, user: user.id },
            'tracker', 'filters', 'users'
        ],
        async () =>  (await UsersService.getMyProjectsParticipants(workspace.code, user.id))?.data,
        {
            initialData: [],
            keepPreviousData: true
        })
};

export const getTrackerFiltersProjectsQuery = () => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    return useQuery(
        [
            { workspace: workspace.code, user: user.id },
            'tracker', 'filters', 'projects'
        ],
        async () =>  (await ProjectsService.getAll(workspace.code, user.id))?.data,
        {
            // enabled: !!workspace?.code && !!user?.id,
            initialData: [],
            keepPreviousData: true
        })
};

export const getTrackerFiltersProjectsByUserQuery = userId => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    return useQuery(
        [
            { workspace: workspace.code, user: user.id },
            'tracker', 'filters', 'projects-by-user', userId
        ],
        async () =>  (await ProjectsServiceV2.searchProjects(workspace.code, {
            participant_ids: [userId],
            archived: false,
        }))?.data?.data,
        {
            // enabled: !!workspace?.code && !!user?.id && !!userId,
            enabled: !!userId,
            initialData: [],
            keepPreviousData: true
        })
};

export const getTrackerFiltersTagsQuery = projectId => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    return useQuery(
        [
            { workspace: workspace?.code, user: user?.id },
            'tracker', 'filters', 'tags', { projectId }
        ],
        async () =>  (await ProjectsService.getParticipatedProjectTags(workspace.code, user.id, projectId))?.data,
        {
            // enabled: !!workspace?.code && !!user?.id && !!projectId,
            enabled: !!projectId,
            initialData: [],
        })
};

