import { useContext } from 'react';
import { useIntl } from "react-intl";

import { messagesToasts } from '@quidlo/timesheets/src/messages';

import AutotrackerService from "../api/AutotrackerService";
import { AutoTrackerContext } from "../providers/AutoTrackerProvider";
import analytics from "../utils/analytics";
import { dateToString } from "../utils/dateHelpers";
import { timeFormatter } from "../utils/time";

import useAuthentication from "./useAuthentication";
import useUI from "./useUI";
import useWorkspace from "./useWorkspace";

import messages from "./useAutoTracker.i18n";

const useAutoTracker = () => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    const { toastPush } = useUI();
    const intl = useIntl();

    const {
        task,
        setTask,
        restoreTask,
    } = useContext(AutoTrackerContext);

    const start = async ({ date, projectId, tagIds, title }) => {
        try {
            await AutotrackerService.start(workspace.code, user.id, { date, projectId, tagIds, title, timeFrom: timeFormatter(new Date()) });
            analytics.logTimesheetsAutotrackerStart();
            toastPush({
                text: intl.formatMessage(messages.taskStarted)
            });
            restoreTask();
        } catch (e) {
            toastPush({
                text: e?.response?.data?.message || intl.formatMessage(messagesToasts.unexpectedError),
                color: 'red'
            });
        }
    }

    const stop = async () => {
        try {
            const now = new Date();
            const { data } = await AutotrackerService.stop(workspace.code, user.id, {
                ...task,
                date: dateToString(now),
                timeTo: timeFormatter(now),
            } );
            analytics.logTimesheetsAutotrackerStop(data.durationMins);
            setTask(null);
        } catch (e) {
            toastPush({
                text: e.response.data.message,
                color: 'red',
            });
        }
    }

    return {
        task,
        start,
        stop,
    };
};

export default useAutoTracker;
