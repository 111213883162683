import React from 'react';
import { useIntl } from 'react-intl';

import useUI from "@quidlo/common/hooks/useUI";
import useWorkspace from "@quidlo/common/hooks/useWorkspace";
import { Button, Space } from '@quidlo/ui';

import illustration from "../../../assets/modals/workspace.png";
import ModalHOC, { ModalActions, ModalContent, ModalHeader } from "../../../ui/Modal/Modal";

import messages from './LeaveWorkspace.i18n';

const LeaveWorkspace = () => {
    const intl = useIntl();
    const { modalClose } = useUI();
    const { workspace, leaveWorkspace } = useWorkspace();

    const leave = async () => {
        await leaveWorkspace(workspace.code);

        modalClose();
    };

    return (
        <>
            <ModalHeader>
                {intl.formatMessage(messages.title)}
            </ModalHeader>
            <ModalContent>
                {intl.formatMessage(messages.subtitle)}
            </ModalContent>
            <ModalActions>
                <Space mr="m">
                    <Button
                        onClick={modalClose}
                        text={intl.formatMessage(messages.cancel)}
                        color="white"
                    />
                </Space>
                <Button
                    onClick={leave}
                    text={intl.formatMessage(messages.leave)}
                    shadow
                    color="blue"
                />
            </ModalActions>
        </>
    );
};

export default ModalHOC(
    LeaveWorkspace,
    {
        id: 'leaveWorkspace',
        size: 'small',
        illustration,
    }
);