import React, { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { WORKSPACE_ROLE, WORKSPACE_ROLES } from '@quidlo/common/constants/main';
import useAuthentication from "@quidlo/common/hooks/useAuthentication";
import useUI from "@quidlo/common/hooks/useUI";
import useWorkspace from "@quidlo/common/hooks/useWorkspace";
import { Avatar, Button, Counter, Header, Search, Select, Space, Tooltip } from "@quidlo/ui";

import { messagesToasts } from "../../messages";
import {
    editUserMutation,
    getUsersListAllInifiniteQuery,
    getUsersTotalQuery, resendInvitationMutation,
} from "../../queries/users.queries";
import { Container, InfiniteLoader, List, ListActions, ListCell } from "../../ui";
import ProjectPill from "../../ui/ProjectPill/ProjectPill";
import ViewHOC from '../../ui/View/View';
import DeleteUser from "../modals/DeleteUser";
import LeaveWorkspace from "../modals/LeaveWorkspace";

import ChangeOwner from "./modals/ChangeOwner";
import InviteUsers from "./modals/InviteUsers";
import YouHaveReached from "./modals/YouHaveReached";

import style from "./Users.module.scss";

import messages from "./Users.i18n";

const Users = () => {
    const
        intl = useIntl(),
        { modalOpen, toastPush } = useUI(),
        { user } = useAuthentication(),
        { workspace } = useWorkspace(),
        [searchPhrase, setSearchPhrase] = useState(''),
        { data, isFetchingNextPage, hasNextPage, fetchNextPage } = getUsersListAllInifiniteQuery({ 'suggestions-for': searchPhrase }),
        { data: total } = getUsersTotalQuery(),
        { mutate: resendInvitation } = resendInvitationMutation(),
        { mutate: editUser } = editUserMutation(),
        deleteUser = (userId, name) => {
            if (userId === user.id) {
                modalOpen('leaveWorkspace');
            } else {
                modalOpen('deleteUser', { userId, name });
            }
        },
        inviteUsers = () => {
            modalOpen('inviteUsers', {});
        },
        giveOwnership = ({ userId, organizationRole }) => {
            modalOpen('changeRoleConfirm', { userId, organizationRole });
        },
        editUserRole = (userId, role) => {
            editUser({
                userId,
                delta: {
                    organizationRole: role
                }
            })
        };

    const users = useMemo(() => data?.pages ? data.pages.filter(f => !!f).flat(1) : [], [data]);

    return (
        <>
            <Container>
                <Space mb="l">
                    <Header
                        title={intl.formatMessage(messages.users, { total })}
                        icon="users"
                        size="large"
                        underline
                        rightChildren={(
                            <div className={style.headerRight}>
                                <div className={style.search}>
                                    <Search placeholder={intl.formatMessage(messages.searchPlaceholder)} onSearch={setSearchPhrase} />
                                </div>
                                <Button
                                    text={intl.formatMessage(messages.inviteUsers)}
                                    color="blue"
                                    shadow
                                    onClick={inviteUsers}
                                />
                            </div>
                        )}
                    />
                </Space>
                <Space>
                    <List
                        header={(
                            <>
                                <ListCell align="center" header>
                                    <FormattedMessage {...messages.name} />
                                </ListCell>
                                <ListCell align="center" header>
                                    <FormattedMessage {...messages.email} />
                                </ListCell>
                                <ListCell align="center" header>
                                    <FormattedMessage {...messages.projects} />
                                </ListCell>
                                <ListCell width="180px" align="center" header>
                                    <FormattedMessage {...messages.role} />
                                </ListCell>
                            </>
                        )}
                        data={users}
                        renderRow={({
                            id,
                            firstName,
                            lastName,
                            avatarUrl,
                            email,
                            projects,
                            organizationRole,
                            status
                        }, state) => ((
                            <>
                                <ListCell>
                                    {(firstName && lastName && status === 'approved') && (
                                        <div className={style.credentialsAndAvatar}>
                                            <Avatar firstName={firstName} lastName={lastName} picture={avatarUrl} />
                                            <span className={style.credentials}>{firstName} {lastName}</span>
                                        </div>
                                    )}
                                    {(firstName && lastName && status !== 'approved') && (
                                        <div className={style.credentialsAndAvatar}>
                                            <Avatar firstName={firstName} lastName={lastName} picture={avatarUrl} />
                                            <div className={style.credentials}>
                                                <div>{firstName} {lastName}</div>
                                                <div>({intl.formatMessage(messages.pending)})</div>
                                            </div>
                                        </div>
                                    )}
                                    {(!firstName && !lastName && status !== 'approved') && (
                                        <div className={style.pending}>
                                            <FormattedMessage {...messages.pending} />
                                        </div>
                                    )}
                                </ListCell>
                                <ListCell>
                                    <span>{email}</span>
                                </ListCell>
                                <ListCell>
                                    {projects.length > 0 ?
                                        <div className={style.cell}>
                                            {projects.slice(0, 1).map(project => (
                                                <div key={project.id}>
                                                    <ProjectPill {...project} />
                                                </div>
                                            ))}
                                            {projects.length > 1 && (
                                                <Tooltip text={projects.slice(1, projects.length).map(p => p.name).join(', ')}>
                                                    <Counter number={projects.length - 1} />
                                                </Tooltip>
                                            )}
                                        </div>
                                        : <FormattedMessage {...messages.noProjects} />
                                    }
                                </ListCell>
                                <ListCell width="180px" last>
                                    {
                                        state === 'edit' ?
                                            <div className={style.selectContainer}>
                                                <Select
                                                    options={WORKSPACE_ROLES.map(role => ({
                                                        label: intl.formatMessage(messages[role]),
                                                        labelText: intl.formatMessage(messages[role]),
                                                        value: role
                                                    }))}
                                                    value={organizationRole}
                                                    onChange={value => {
                                                        if (organizationRole !== value) {
                                                            if (value === 'owner') {
                                                                if (workspace?.organizationRole === WORKSPACE_ROLE.OWNER) {
                                                                    giveOwnership({
                                                                        userId: id,
                                                                        organizationRole: value
                                                                    })
                                                                } else {
                                                                    toastPush({
                                                                        text: intl.formatMessage(messagesToasts.noAccessToChangeOwnerRole),
                                                                        color: 'red'
                                                                    });
                                                                }
                                                            } else {
                                                                editUserRole(id, value);
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div> :
                                            <FormattedMessage {...messages[organizationRole]} />
                                    }
                                </ListCell>
                                <ListActions
                                    actions={status !== 'approved' ? [
                                        {
                                            title: intl.formatMessage(messages.deleteUser),
                                            icon: 'trash',
                                            onClick: () => { deleteUser(id, firstName ? `${firstName} ${lastName}` : email); }
                                        }, {
                                            title: intl.formatMessage(messages.resendInvitation),
                                            icon: 'resend',
                                            onClick: () => { resendInvitation({ userId: id, email: email }); }
                                        }] : [
                                        {
                                            title: intl.formatMessage(messages.deleteUser),
                                            icon: 'trash',
                                            onClick: () => { deleteUser(id, firstName ? `${firstName} ${lastName}` : email); }
                                        }
                                    ]}
                                />
                            </>
                        ))}
                        editable
                    />
                </Space>
            </Container>
            <InfiniteLoader
                action={fetchNextPage}
                isMore={hasNextPage && !isFetchingNextPage}
            />
            <InviteUsers />
            <YouHaveReached />
            <LeaveWorkspace />
            <DeleteUser />
            <ChangeOwner />
        </>
    );
};

export default ViewHOC(
    Users,
    {
        title: 'Users',
        menu: true,
        topbar: true,
        auth: {
            roles: ['admin', 'owner']
        },
        subscription: true
    }
);

