import { defineMessages } from 'react-intl';

export default defineMessages({
    settings: {
        id: 'settings.settings',
        defaultMessage: 'Settings',
    },
    features: {
        id: 'settings.features',
        defaultMessage: 'Features',
    },
    workspace: {
        id: 'settings.workspace',
        defaultMessage: 'Workspace',
    },
    subscription: {
        id: 'settings.subscription',
        defaultMessage: 'Subscription',
    },
});