import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import SharedReportsService from "@quidlo/common/api/SharedReportsService";
import useAuthentication from "@quidlo/common/hooks/useAuthentication";
import useForm from "@quidlo/common/hooks/useForm2";
import useUI from "@quidlo/common/hooks/useUI";
import useWorkspace from "@quidlo/common/hooks/useWorkspace";
import analytics from "@quidlo/common/utils/analytics";
import validators from "@quidlo/common/utils/validators";
import { Button, Input, InputCollection, Space } from "@quidlo/ui";

import illustration from "../../../../assets/modals/share.png";
import { messagesToasts } from "../../../../messages";
import { Textarea } from "../../../../ui";
import ModalHOC, { ModalActions, ModalContent, ModalHeader }  from "../../../../ui/Modal";

import styles from './SendReport.module.scss';

import messages from './SendReport.i18n';

const SendReport = () => {
    const
        intl = useIntl(),
        { user } = useAuthentication(),
        { workspace } = useWorkspace(),
        { modalClose, modalOpen, toastPush } = useUI(),
        [form,{ isFormValid, cleanForm }] = useForm({
            recipients: {
                value: [],
                validators: [validators.isRequired(), validators.isEmail()]
            },
            description: {
                value: intl.formatMessage(messages.descriptionDefault)
            },
            title: {
                value: intl.formatMessage(messages.titleDefault, {
                    workspace: workspace.name
                }),
                validators: [
                    validators.isRequired(),
                    validators.minLength(5, intl.formatMessage(messages.titleMin, { min: 5 })),
                    validators.maxLength(200, intl.formatMessage(messages.titleMax, { max: 200 }))
                ]
            }
        }),
        close = () => {
            cleanForm();
            modalClose();
        },
        sendReport = async () => {
            const filters = JSON.parse(window.atob(location.hash.substr(1)));
            analytics.logTimesheetsReportsCreateSend({
                emails: form.recipients.value.length,
                days: ((new Date(filters.date.lte)) - (new Date(filters.date.gte))) / ((1000*60*60*24)),
                people: filters.users.length,
                projects: filters.projects.length,
                statuses: filters.statuses.length,
                tags: filters.tags.length,
                period: filters.period,
                type: filters.type
            });
            SharedReportsService.post(
                workspace.code,
                user.id,
                filters,
                form.description.value,
                form.title.value,
                null,
                form.recipients.value.map(email => email.toLowerCase()),
            ).then(() => {
                modalOpen('superRadek', {});
                toastPush({
                    text: intl.formatMessage(messagesToasts.reportSent)
                });
                setTimeout(close, 2500);
            });
        };

    return (
        <>
            <ModalHeader>
                <FormattedMessage {...messages.sendReport} />
            </ModalHeader>
            <ModalContent>
                <Space mb="l">
                    <Input
                        {...form.title}
                        fieldId="send-report-title"
                        placeholder={intl.formatMessage(messages.title)}
                        label={intl.formatMessage(messages.title)}
                        isRequired
                    />
                </Space>
                <Space mb="l">
                    <Textarea
                        {...form.description}
                        fieldId="send-report-description"
                        placeholder={intl.formatMessage(messages.description)}
                        label={intl.formatMessage(messages.description)}
                    />
                </Space>
                <Space mb="l">
                    <InputCollection
                        {...form.recipients}
                        label={intl.formatMessage(messages.enterEmail)}
                        size="small"
                    />
                </Space>
                <div className={styles.copyMail}>
                    <FormattedMessage
                        {...messages.copyOfThisMail}
                    />
                </div>
            </ModalContent>
            <ModalActions>
                <Space mr="m">
                    <Button
                        color="white"
                        text="Cancel"
                        onClick={close}
                    />
                </Space>
                <Button
                    color="blue"
                    text="Send"
                    onClick={sendReport}
                    disabled={!isFormValid}
                    shadow
                />
            </ModalActions>
        </>
    );
};

export default ModalHOC(
    SendReport,
    {
        id: 'sendReport',
        size: 'medium',
        illustration,
    }
);