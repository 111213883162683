import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Icon from '@quidlo/ui/src/Icon';

import style from './MenuItem.module.scss';

const MenuItem = ({ title, icon, link, active }) => {
    const [hovered, setHovered] = useState(false),
        hover = val => {
            setHovered(val);
        };

    return (
        <Link
            to={link}
            className={cx(style.menuItem, active && style.active)}
            onMouseEnter={() => {
                hover(true);
            }}
            onMouseLeave={() => {
                hover(false);
            }}>
            <div className={style.icon}>
                <Icon
                    icon={icon}
                    color={active || hovered ? 'blue' : 'grey'}
                />
            </div>
            <div className={style.title}>{title}</div>
        </Link>
    );
};

MenuItem.propTypes = {
    /** Title string of the element. */
    title: PropTypes.string.isRequired,
    /** Icon string of the element. */
    icon: PropTypes.string,
    /** Url for element. */
    link: PropTypes.string.isRequired,
    /** Is element choosen. */
    active: PropTypes.bool,
};

MenuItem.defaultProps = {
    icon: 'default',
    active: false,
};

export default MenuItem;
