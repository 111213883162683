import React, { useMemo, useState } from 'react';
import { useLocation } from "react-router-dom";
import cx from 'classnames';
import PropTypes from 'prop-types';

import { PROJECT_ROLE,WORKSPACE_ROLE } from "@quidlo/common/constants/main";
import useAuthentication from "@quidlo/common/hooks/useAuthentication";
import useWorkspace from "@quidlo/common/hooks/useWorkspace";
import useWorkspaceFeatures from "@quidlo/common/hooks/useWorkspaceFeatures";

import packageJson from '../../../package.json';

import { ReactComponent as LogoSVG } from './svg/logo.svg';
import MenuItem from './MenuItem';

import style from './Menu.module.scss';

const Menu = ({ disabled, hidden, items }) => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    const { features } = useWorkspaceFeatures();
    const location = useLocation();
    const [showVersion, setShowVersion] = useState(false);

    const list = useMemo(() => {
        if (!workspace) return [];

        const
            role = ((workspace.projectsRole === PROJECT_ROLE.MANAGER && workspace?.organizationRole === WORKSPACE_ROLE.EMPLOYEE) ? PROJECT_ROLE.MANAGER : workspace.organizationRole);

        return items
            .filter(m => m.roles[role])
            .filter(m => (m.workspaces ? m.workspaces.includes(workspace.code) : true))
            .filter(m => (m.feature ? features[m.feature] : true))
            .map(m => ({
                ...m,
                active: location.pathname.includes(m.module) || (location.pathname === '/' && m.module === '/tracker')
            }));
    }, [user, workspace, features, location.pathname]);

    if (!user || !workspace) return null;

    return (
        <div
            className={cx(
                style.menu,
                hidden && style.hidden,
                disabled && style.disabled,
            )}>
            <div>
                <div className={style.logo}>
                    <LogoSVG />
                </div>
                {list.map(i => <MenuItem key={i.title} {...i} />)}
            </div>
            <div className={style.version} onDoubleClick={() => setShowVersion(s => !s)}>
                {showVersion ? process.env.REACT_APP_INSTANCE_NAME : 'Quidlo'}
                <div className={style.timesheets}>{showVersion ? packageJson.version : 'Timesheets'}</div>
            </div>
        </div>
    );
};

Menu.propTypes = {
    disabled: PropTypes.bool,
    hidden: PropTypes.bool,
    items: PropTypes.array.isRequired,
};

Menu.defaultProps = {
    disabled: false,
    hidden: false,
};

export default Menu;
