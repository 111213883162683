import React from 'react';
import { useIntl } from "react-intl";

import { Header, Space } from '@quidlo/ui';

import { Container } from '../../ui';
import ViewHOC from '../../ui/View/View';

import DeleteAccount from "./DeleteAccount";
import Notifications from './Notifications';
import Personal from './Personal';

import messages from './Profile.i18n';

const Profile = () => {
    const intl = useIntl();

    return (
        <Container>
            <Space mb="l">
                <Header
                    title={intl.formatMessage(messages.profile)}
                    icon="users"
                    size="large"
                    underline
                />
            </Space>
            <Space mb="l">
                <Personal />
            </Space>
            <Space mb="l">
                <Notifications />
            </Space>
            <Space>
                <DeleteAccount />
            </Space>
        </Container>
    );
}


export default ViewHOC(
    Profile,
    {
        title: 'Profile',
        menu: true,
        topbar: true,
        auth: {
            roles: ['employee', 'manager', 'admin', 'owner']
        },
    }
);
