import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Icon } from '@quidlo/ui';

import style from './ListDocked.module.scss';

/** Component of List */
const
    List = ({
        rightChildren,
        title,
        color,
        icon,
        children
    }) => (
        <div className={cx(style.listDocked)}>
            {title &&
                <div className={cx(style.title)}>
                    <div className={style.left} style={{ color }}>
                        {title}
                        {icon && (
                            <div className={style.icon}>
                                <Icon icon={icon} color={color} size="medium" />
                            </div>
                        )}
                    </div>
                    <div className={style.right}>
                        {rightChildren}
                    </div>
                    {color &&
                        <div className={style.label} style={{ backgroundColor: color }} />
                    }
                </div>
            }
            <div className={cx(style.content)}>
                {children}
            </div>
        </div>
    );

List.propTypes = {
    /** List title */
    title: PropTypes.string,
    /** Color of list title */
    color: PropTypes.string,
    /** Icon of list title */
    icon: PropTypes.string,
    /** Children nodes of list body */
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    /** Children nodes of header right side */
    rightChildren: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])
};

List.defaultProps = {
    title: '',
    color: undefined,
    icon: undefined,
    rightChildren: undefined
};

export default List;