import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Icon } from '..';

import style from './Label.module.scss';

const Label = ({ icon, text, size, disabled }) => (
    <div
        className={cx(
            style.label,
            style[`size-${size}`],
            disabled && style.disabled,
        )}>
        {icon && (
            <Icon
                icon={icon}
                size={size}
                color={size === 'medium' && !disabled ? 'flint' : 'grey'}
            />
        )}
        <span>{text}</span>
    </div>
);

Label.propTypes = {
    icon: PropTypes.string,
    text: PropTypes.string.isRequired,
    size: PropTypes.oneOf(['small', 'medium']),
    disabled: PropTypes.bool,
};

Label.defaultProps = {
    icon: null,
    size: 'medium',
    disabled: false,
};

export default Label;
