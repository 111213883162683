import React from 'react';
import { useNavigate } from 'react-router-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Icon, Space } from '..';

import HeaderTab from "./HeaderTab";

import style from './Header.module.scss';

const Header = ({ icon, title, size, underline, leftChildren, rightChildren, tabs, goBack, goBackURL }) => {
    const navigate = useNavigate();

    return     (
        <div
            className={cx([
                style.header,
                style[`size-${size}`],
                underline && style.underline
            ])}
        >
            <div className={style.left}>
                {goBack && (
                    <div className={style.back} onClick={() => goBackURL ? navigate(goBackURL) : navigate(-1)}>
                        <Icon size="large" icon={'caret-left'} color="flint-60" />
                    </div>
                )}
                {icon && (
                    <Space mr="m" className={style.icon}>
                        <Icon icon={icon} color="flint-60" size={size} />
                    </Space>
                )}
                <Space mr="s">
                    <div className={style.titleWrapper}>
                        <span className={style.title}>{title}</span>
                    </div>
                </Space>
                {leftChildren}
                {tabs.length > 0 && (
                    <Space className={style.tabs} ml="s" mr="s" direction="row">
                        {
                            tabs.map(t => <HeaderTab key={t.label} {...t} />)
                        }
                    </Space>
                )}
            </div>
            <div className={style.right}>
                {rightChildren}
            </div>
        </div>
    );
}

Header.propTypes = {
    icon: PropTypes.string,
    title: PropTypes.string,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    underline: PropTypes.bool,
    tabs: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        to: PropTypes.string
    })),
    leftChildren: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    rightChildren: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    goBack: PropTypes.bool,
    goBackURL: PropTypes.string
};

Header.defaultProps = {
    icon: undefined,
    title: undefined,
    size: 'medium',
    tabs: [],
    underline: false,
    rightChildren: undefined,
    leftChildren: undefined,
    goBack: false,
    goBackURL: ''
};

export default Header;
