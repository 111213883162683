import React, { useEffect, useRef, useState } from 'react';
import Dropzone from "react-dropzone";
import { FormattedMessage, useIntl } from 'react-intl';
import cx from "classnames";

import TIMEZONES from '@quidlo/common/constants/timezones';
import useAuthentication from "@quidlo/common/hooks/useAuthentication";
import useForm from "@quidlo/common/hooks/useForm2";
import useUI from "@quidlo/common/hooks/useUI";
import validators from "@quidlo/common/utils/validators";
import { Button, Card, Icon, Input, Select } from "@quidlo/ui";

import { editSettingsMutation,getSettingsQuery } from "../../../queries/settings.queries";

import ChangePassword from "./modals/ChangePassword";

import style from "./Personal.module.scss";

import messages from './Personal.i18n';

const avatarValidationRules = {
        MAX_SIZE: 800000,
        MIN_SIZE: 10,
        TYPES: ['image/jpg', 'image/jpeg', 'image/png']
    },
    Personal = () => {
        const
            dropzoneRef = useRef(),
            intl = useIntl(),
            { toastPush, modalOpen } = useUI(),
            { user, patchUser, patchUserAvatar } = useAuthentication(),
            { data } = getSettingsQuery(),
            { mutate } = editSettingsMutation(),
            [form, { isFormValid, isFormDirty, touchForm }] = useForm({
                firstName: {
                    value: user.firstName,
                    validators: [
                        validators.isRequired(),
                        validators.isName(),
                        validators.minLength(3, intl.formatMessage(messages.name)),
                    ]
                },
                lastName: {
                    value: user.lastName,
                    validators: [
                        validators.isRequired(),
                        validators.isName(),
                        validators.minLength(3, intl.formatMessage(messages.surname)),
                    ]
                },
                email: {
                    value: user.email,
                    validators: [validators.isRequired()]
                },
                timezone: {
                    value: Intl.DateTimeFormat().resolvedOptions().timezone || undefined,
                    validators: [validators.isRequired()]
                },
                avatar: {
                    value: { preview: user.avatarUrl }
                }
            }),
            [isAvatarHovering, setIsAvatarHovering] = useState(false),
            handleMouseHover = value => {
                setIsAvatarHovering(value);
            },
            openChangePassword = () => {
                modalOpen('changePassword', {});
            },
            saveForm = () => {
                if (!isFormValid) return;

                patchUser(
                    {
                        firstName: form.firstName.value,
                        lastName: form.lastName.value
                    }
                );

                if (form.timezone.value !== data.timezone) {
                    mutate({ timezone: form.timezone.value });
                }
                if (form.avatar.value.preview !== user.avatarUrl) {
                    patchUserAvatar(form.avatar.value);
                }
            },
            fileUpload = ([file], [rejected]) => {
                if (rejected) {
                    if (rejected.size > avatarValidationRules.MAX_SIZE) {
                        toastPush({
                            text: intl.formatMessage(messages.maxSizeError),
                            color: 'red'
                        });
                    } else if (rejected.size < avatarValidationRules.MIN_SIZE) {
                        toastPush({
                            text: intl.formatMessage(messages.minSizeError),
                            color: 'red'
                        });
                    } else if (!avatarValidationRules.TYPES.find(type => rejected.type === type)) {
                        toastPush({
                            text: intl.formatMessage(messages.wrongTypeError, { types: avatarValidationRules.TYPES.join(', ') }),
                            color: 'red'
                        });
                    } else {
                        toastPush({
                            text: intl.formatMessage(messages.unexpectedError),
                            color: 'red'
                        });
                    }
                } else {
                    form.avatar.onChange(file)
                }
            };

        useEffect(() => {
            if (data) {
                form.timezone.setValue(data.timezone);
            }
        }, [data])

        return (
            <>
                <Card title={intl.formatMessage(messages.personalInfo)}>
                    <div className={style.block}>
                        <div className={style.row}>
                            <div className={style.upload}>
                                <div className={cx(style.cell, style.dropzone)}>
                                    <div className={style.dropzoneContainer}>
                                        <Dropzone
                                            className={style.avatar}
                                            activeClassName={style.active}
                                            ref={dropzoneRef}
                                            onDrop={fileUpload}
                                            style={{
                                                background: `url(${form.avatar.value.preview})`,
                                            }}
                                            accept={avatarValidationRules.TYPES}
                                            multiple={false}
                                            maxSize={avatarValidationRules.MAX_SIZE}
                                            minSize={avatarValidationRules.MIN_SIZE}
                                            onMouseEnter={() => {
                                                handleMouseHover(true);
                                            }}
                                            onMouseLeave={() => {
                                                handleMouseHover(false);
                                            }}
                                        />
                                        <div className={cx(style.openDropzone, isAvatarHovering && style.active)}>
                                            <div className={style.openDropzoneContainer}>
                                                <div className={style.icon}>
                                                    <Icon icon="camera" color="blue" />
                                                </div>
                                                <Button
                                                    type="bordered"
                                                    color="white"
                                                    onClick={() => {
                                                        dropzoneRef.open();
                                                    }}
                                                    text="Edit Photo"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={cx(style.cell, style.avatarText, isAvatarHovering && style.active)}>
                                    <p className={style.small}>
                                        <FormattedMessage {...messages.minSize} />
                                    </p>
                                </div>
                            </div>
                            <div className={cx(style.block, style.textFields)}>
                                <div className={style.row}>
                                    <div className={style.cell}>
                                        <Input
                                            {...form.firstName}
                                            type="text"
                                            label={intl.formatMessage(messages.firstName)}
                                            isRequired
                                        />
                                    </div>
                                    <div className={style.cell}>
                                        <Input
                                            {...form.lastName}
                                            type="text"
                                            label={intl.formatMessage(messages.lastName)}
                                            isRequired
                                        />
                                    </div>
                                    <div className={style.cell}>
                                        <Input
                                            {...form.email}
                                            type="text"
                                            label={intl.formatMessage(messages.email)}
                                            disabled
                                            isRequired
                                        />
                                    </div>
                                </div>
                                <div className={style.row}>
                                    <div className={style.timezone}>
                                        <Select
                                            {...form.timezone}
                                            options={TIMEZONES.map(t => ({
                                                label: `(GMT${t.offset}) - ${t.label}`,
                                                labelText: `(GMT${t.offset}) - ${t.label}`,
                                                value: t.name
                                            }))}
                                            label={intl.formatMessage(messages.timeZone)}
                                            isRequired
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={style.row}>
                            <div className={cx(style.cell, style.right, style.btnContainer)}>
                                {user.hasPassword && (
                                    <Button
                                        type="bordered"
                                        color="white"
                                        onClick={openChangePassword}
                                        text={intl.formatMessage(messages.changePassword)}
                                    />
                                )}
                                <div className={style.saveBtn}>
                                    <Button
                                        onClick={saveForm}
                                        onDisabledClick={touchForm}
                                        disabled={!isFormValid || !isFormDirty}
                                        text={intl.formatMessage(messages.save)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
                <ChangePassword />
            </>
        );
    };

export default Personal;

