import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import SessionService from '@quidlo/common/api/SessionService';
import useAuthentication from "@quidlo/common/hooks/useAuthentication";
import useForm from "@quidlo/common/hooks/useForm2";
import useUI from "@quidlo/common/hooks/useUI";
import { Button, Input, Space } from '@quidlo/ui';

import illustration from "../../../../../assets/modals/trash.png";
import { messagesToasts } from "../../../../../messages";
import ModalHOC, { ModalActions, ModalContent, ModalHeader } from "../../../../../ui/Modal/Modal";

import style from './DeleteAccount.module.scss';

import messages from './DeleteAccount.i18n';

const DeleteAccount = () => {
    const intl = useIntl();
    const { modal, modalClose, toastPush } = useUI();
    const { user, signOut } = useAuthentication();
    const [form, { isFormValid, touchForm }] = useForm(useMemo(() => ({
        confirm: {
            value: '',
            validators: [val => (val !== 'DELETE' ? intl.formatMessage(messages.invalidValue) : '')]
        }
    }), []));
    const deleteAccount = async () => {
        if (form.confirm.value === 'DELETE') {
            SessionService.deleteAccount(user.id).then(() => {
                toastPush({
                    text: intl.formatMessage(messagesToasts.accountDeleted)
                })
                signOut();
            })
        }
    };

    return (
        <>
            <ModalHeader>
                {intl.formatMessage(messages.title)}
            </ModalHeader>
            <ModalContent>
                <div className={style.subtitle}>
                    {intl.formatMessage(messages.subtitle)}
                    {
                        modal?.data?.deleteWorkspace && (
                            <div>{intl.formatMessage(messages.deleteWorkspace)}</div>
                        )
                    }
                    <div>{intl.formatMessage(messages.typeInBelow)}</div>
                    <div className={style.textToType}>{intl.formatMessage(messages.deleteAccount)}</div>
                </div>
                <Input
                    {...form.confirm}
                    label={intl.formatMessage(messages.typeInDeleteAccount)}
                />
            </ModalContent>
            <ModalActions>
                <Space mr="m">
                    <Button color="white" text={intl.formatMessage(messages.cancel)} onClick={modalClose} />
                </Space>
                <Button
                    color="blue"
                    text={intl.formatMessage(messages.delete)}
                    onClick={deleteAccount}
                    disabled={!isFormValid}
                    onDisabledClick={touchForm}
                />
            </ModalActions>
        </>
    );
};

export default ModalHOC(
    DeleteAccount,
    {
        id: 'deleteAccount',
        size: 'small',
        illustration,
    }
);