import React from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from "react-router-dom";

import useUI from "@quidlo/common/hooks/useUI";
import useWorkspace from "@quidlo/common/hooks/useWorkspace";
import { Button, Tooltip } from '@quidlo/ui';

import messages from './ReportCreate.i18n';

const ShareReport = () => {
    const location = useLocation();
    const { modalOpen } = useUI();
    const intl = useIntl();
    const { workspace } = useWorkspace();

    const share = async () => {
        const filters = JSON.parse(window.atob(location.hash.substr(1)));
        if (filters.projects.length > 1) {
            modalOpen('shareMany', {}, () => {
                modalOpen('sendReport', {
                    ...filters,
                    weekStart: workspace.weekStart,
                });
            });
        } else {
            modalOpen('sendReport', {
                ...filters,
                weekStart: workspace.weekStart,
            });
        }
    }

    return (
        <Tooltip
            text={intl.formatMessage(
                messages.sendReport,
            )}
            position="bottom">
            <Button
                icon="mail"
                iconColor="blue"
                iconSize="medium"
                size="medium"
                color="white"
                type="bordered"
                onClick={share}
                disabled={!location.hash}
            />
        </Tooltip>
    )
};

export default ShareReport
