import { defineMessages } from 'react-intl';

export default defineMessages({
    user: {
        id: 'connectGoogleChat.user',
        defaultMessage: 'Dear user'
    },
    message: {
        id: 'connectGoogleChat.message',
        defaultMessage: 'Your account will be connected with Google Chat'
    },
    ok: {
        id: 'connectGoogleChat.ok',
        defaultMessage: 'Ok'
    }
});