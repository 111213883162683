import fetch from '../utils/fetch';

export default {
    post: (userId, workspace) =>
        fetch.post(`/v1/users/${userId}/administered-organizations`, workspace),
    patch: (userId, workspaceCode, delta) =>
        fetch.patch(
            `/v1/users/${userId}/administered-organizations/${workspaceCode}`,
            delta,
        ),
    delete: (userId, workspaceCode) =>
        fetch.delete(`/v1/users/${userId}/administered-organizations/${workspaceCode}`),
    leave: (userId, workspaceCode) =>
        fetch.delete(`/v1/users/${userId}/administered-organizations/${workspaceCode}/leave`),
    postAvatar: (userId, workspaceCode, formData) =>
        fetch.post(
            `/v1/users/${userId}/administered-organizations/${workspaceCode}/logo-image`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            },
        ),
    getOwner: (workspaceCode, userId) =>
        fetch.get(`/v1/users/${userId}/administered-organizations/${workspaceCode}/owner`)
};
