import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../Icon'

import style from './Select.module.scss';

import messages from './SelectView.i18n';

const SelectView = ({
    label,
    values,
    error,
    isNarrow,
    isAll,
    isMulti,
    isTouched,
    isRequired,
    intl,
}) => {
    let text;

    if (isMulti) {
        if (isAll) {
            if (values.length === 0) {
                text = '';
            } else {
                text = intl.formatMessage(messages.all);
            }
        } else if (values.length === 1) {
            text = values[0].label;
        } else if (values.length > 1) {
            text = intl.formatMessage(messages.selectedNumber, {
                number: values.length,
            });
        } else if (isTouched && values.length === 0) {
            text = intl.formatMessage(messages.none);
        } else if (!isTouched && values.length === 0) {
            text = '';
        }
    } else {
        text = values && values.label ? values.label : '';
    }

    return (
        <div
            className={cx(
                style.selectedView,
                error && style.error,
                isNarrow && style.narrow,
            )}>
            {label && (
                <div className={cx(style.label, text && style.hide)}>
                    {label}
                    {isRequired && <span>*</span>}
                </div>
            )}
            <div className={style.value}>
                <div className={style.content}>{text}</div>
            </div>
            <Icon icon="caret-down" size="small" color="flint" />
            {error && (
                <div className={style.errormessage}>{error}</div>
            )}
        </div>
    );
};

SelectView.propTypes = {
    label: PropTypes.string,
    error: PropTypes.string,
    values: PropTypes.any,
    isAll: PropTypes.bool,
    isMulti: PropTypes.bool,
    isNarrow: PropTypes.bool,
    isTouched: PropTypes.bool,
    intl: PropTypes.object.isRequired,
    isRequired: PropTypes.bool,
};

SelectView.defaultProps = {
    label: '',
    error: '',
    values: undefined,
    isAll: false,
    isMulti: false,
    isNarrow: false,
    isTouched: false,
    isRequired: false,
};

export default SelectView;
