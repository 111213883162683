import { defineMessages } from 'react-intl';

export default defineMessages({
    unexpectedError: {
        id: 'toast.global.unexpectedError',
        defaultMessage: 'Unexpected error'
    },
    accessDenied: {
        id: 'toast.global.accessDenied',
        defaultMessage: 'Access denied'
    },
    uploadFailed: {
        id: 'toast.global.uploadFailed',
        defaultMessage: 'Upload failed'
    },
    taskStarted: {
        id: 'toast.autotracker.taskStarted',
        defaultMessage: 'Task started'
    },
    answerSent: {
        id: 'toast.poll.answerSent',
        defaultMessage: 'Answer sent'
    },
    settingsSaved: {
        id: 'toast.profile.settingsSaved',
        defaultMessage: 'Settings saved'
    },
    apiKeyCreated: {
        id: 'toast.profile.apiKeyCreated',
        defaultMessage: 'API key created'
    },
    apiKeyEdited: {
        id: 'toast.profile.apiKeyEdited',
        defaultMessage: 'API key edited'
    },
    apiKeyDeleted: {
        id: 'toast.profile.apiKeyDeleted',
        defaultMessage: 'API key removed'
    },
    approvalsSwitchedOff: {
        id: 'toast.approval.approvalsSwitchedOff',
        defaultMessage: 'Approvals have been switched off by the workspace admin'
    },
    approvalRequestSent: {
        id: 'toast.approval.approvalRequestSent',
        defaultMessage: 'Approval request sent'
    },
    approvalWithdrawn: {
        id: 'toast.approval.approvalWithdrawn',
        defaultMessage: 'Time approval withdrawn'
    },
    accountDeleted: {
        id: 'toast.profile.accountDeleted',
        defaultMessage: 'Account deleted'
    },
    projectCreated: {
        id: 'toast.projects.projectCreated',
        defaultMessage: 'Project created'
    },
    projectEdited: {
        id: 'toast.projects.projectEdited',
        defaultMessage: 'Project edited'
    },
    projectArchived: {
        id: 'toast.projects.projectArchived',
        defaultMessage: 'Project archived'
    },
    projectActivated: {
        id: 'toast.projects.projectActivated',
        defaultMessage: 'Project activated'
    },
    projectDeleted: {
        id: 'toast.projects.projectDeleted',
        defaultMessage: 'Project deleted'
    },
    requestApproved: {
        id: 'toast.approval.requestApproved',
        defaultMessage: 'Request approved'
    },
    requestRejected: {
        id: 'toast.approval.requestRejected',
        defaultMessage: 'Request rejected'
    },
    subscriptionPlanChangedToStarter: {
        id: 'toast.subscription.subscriptionPlanChangedToStarter',
        defaultMessage: 'Your subscription plan has been changed to STARTER'
    },
    tagAddedToProject: {
        id: 'toast.projects.tagAddedToProject',
        defaultMessage: 'Tag added to project'
    },
    tagDeletedFromProject: {
        id: 'toast.projects.tagDeletedFromProject',
        defaultMessage: 'Tag deleted from project'
    },
    userAddedToProject: {
        id: 'toast.projects.userAddedToProject',
        defaultMessage: 'User added to project'
    },
    userDeletedFromProject: {
        id: 'toast.projects.userDeletedFromProject',
        defaultMessage: 'User deleted from project'
    },
    userProjectRoleChanged: {
        id: 'toast.projects.userProjectRoleChanged',
        defaultMessage: 'User project role changed'
    },
    reportSent: {
        id: 'toast.reports.reportSent',
        defaultMessage: 'Report sent'
    },
    taxInfoSaved: {
        id: 'toast.workspace.taxInfoSaved',
        defaultMessage: 'Tax information saved'
    },
    taxInfoEdited: {
        id: 'toast.workspace.taxInfoEdited',
        defaultMessage: 'Tax information edited'
    },
    taskCreated: {
        id: 'toast.tracker.taskCreated',
        defaultMessage: 'Task created'
    },
    taskEdited: {
        id: 'toast.tracker.taskEdited',
        defaultMessage: 'Task edited'
    },
    taskDeleted: {
        id: 'toast.tracker.taskDeleted',
        defaultMessage: 'Task deleted'
    },
    usersAdded: {
        id: 'toast.users.usersAdded',
        defaultMessage: 'Users invited to workspace'
    },
    userAdded: {
        id: 'toast.users.userAdded',
        defaultMessage: 'User invited to workspace'
    },
    userEdited: {
        id: 'toast.users.userEdited',
        defaultMessage: 'User edited'
    },
    userRemoved: {
        id: 'toast.users.userRemoved',
        defaultMessage: 'User removed from workspace'
    },
    userChangedRole: {
        id: 'toast.users.userChangedRole',
        defaultMessage: 'User workspace role changed'
    },
    invitationHasBeenSent: {
        id: 'toast.users.invitationHasBeenSent',
        defaultMessage: 'Invitation has been sent to {email}'
    },
    activationEmailResent: {
        id: 'toast.users.activationEmailResent',
        defaultMessage: 'Activation email has been resent!'
    },
    noAccessToChangeOwnerRole: {
        id: 'toast.users.noAccessToChangeOwnerRole',
        defaultMessage: 'You can\'t grant owner role'
    },
    workspaceDeleted: {
        id: 'toast.workspace.workspaceDeleted',
        defaultMessage: 'workspaceDeleted'
    },
    workspaceTagAdded: {
        id: 'toast.workspace.workspaceTagAdded',
        defaultMessage: 'Tag added'
    },
    workspaceTagDeleted: {
        id: 'toast.workspace.workspaceTagDeleted',
        defaultMessage: 'Tag deleted'
    }
});
