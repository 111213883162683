import React, { useCallback, useEffect, useState } from 'react';
import cx from "classnames";
import PropTypes from 'prop-types';

import { Portal } from "../index";

import style from "./Drawer.module.scss";

const Drawer = ({ name, visibilityPosition, children }) => {
    const [visible, setVisible] = useState(false),
        [active, setActive] = useState(false),
        scrollHandler = useCallback(() => {
            if (window.scrollY > visibilityPosition) {
                setVisible(true);
            } else if (window.scrollY <= visibilityPosition) {
                setVisible(false);
                setActive(false);
            }
        }),
        toggleActive = () => {
            setActive(prevActive => !prevActive);
        };

    useEffect(() => {
        document.addEventListener('scroll', scrollHandler);
        () => {
            document.removeEventListener('scroll', scrollHandler);
        };
    }, []);

    return <Portal>
        <div
            className={cx(
                style.drawer,
                !visible && style.invisible,
                active && visible && style.active,
            )}>
            <div className={style.content}>
                <div className={style.container}>{children}</div>
            </div>
            <div className={style.buttonContainer}>
                <a
                    className={style.button}
                    onClick={toggleActive}
                >
                    <span>{name}</span>
                    <svg width="10" height="10" viewBox="0 0 10 10">
                        <path d="M5 8.57L.002 1.43h9.996z" />
                    </svg>
                </a>
            </div>
        </div>
    </Portal>;
};

Drawer.propTypes = {
    name: PropTypes.string,
    visibilityPosition: PropTypes.number,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

Drawer.defaultProps = {
    name: 'Drawer',
    visibilityPosition: 100,
};

export default Drawer;
