import React, { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import useWorkspace from "@quidlo/common/hooks/useWorkspace";
import { durationFormatter } from '@quidlo/common/utils/duration';
import { Avatar, Counter, Pill, Space, Tooltip } from '@quidlo/ui';

import { ApprovalStatus, ListCell, ListDocked } from '../../../../ui';
import { periodToDate, periodToString } from '../../../../utils/periodString';
import { filterStatuses } from '../utils';

import style from './TimeView.module.scss';

import messages from './TimeView.i18n';

const TimeView = ({
    data,
    isStatus,
    openReport
}) => {
    const intl = useIntl();
    const { workspace } = useWorkspace();
    const weekStart = workspace?.weekStart || 'monday';
    return (
        <div>
            <Space mv="m">
                <div className={style.totalTimeWrapper}>
                    <Pill color="white" text={`${intl.formatMessage(messages.totalTimeInAllProjects)} ${durationFormatter(data.durationSummaryMins || 0)}`} size="small" />
                </div>
            </Space>
            {data.projects.map(project => (
                <Space mb="m" key={project.id}>
                    <ListDocked
                        color={project.color}
                        title={project.name}
                        icon={project.isBillable ? 'dollar' : ''}
                        rightChildren={
                            <Pill
                                text={`${intl.formatMessage(messages.total)} ${durationFormatter(project.durationSummaryMins || 0)}`}
                                size="small"
                            />
                        }
                        headerDock={(
                            <Fragment>
                                {isStatus &&
                                    <ListCell width="200px" align="center" header>
                                        <FormattedMessage {...messages.status} />
                                    </ListCell>
                                }
                                <ListCell width="95px" align="center" header>
                                    <FormattedMessage {...messages.person} />
                                </ListCell>
                                <ListCell align="center" header>
                                    <FormattedMessage {...messages.duration} />
                                </ListCell>
                            </Fragment>
                        )}
                        header={(
                            <Fragment>
                                {
                                    project.users[0].periods.map(({ periodCode }) => (
                                        <ListCell key={periodCode} align="center">
                                            {periodToString(periodCode, intl, false, weekStart)}
                                        </ListCell>
                                    ))
                                }
                            </Fragment>
                        )}
                        footerDock={(
                            <Fragment>
                                <ListCell width="95px" align="center" footer>
                                    <FormattedMessage {...messages.summary} />
                                </ListCell>
                                {isStatus && <ListCell width="200px" align="center" />}
                                <ListCell align="center" footer>
                                    {durationFormatter(project.durationSummaryMins || 0)}
                                </ListCell>
                            </Fragment>
                        )}
                        footer={(
                            <Fragment>
                                {
                                    project.periods.map(({ periodCode, durationSummaryMins }) => {
                                        const periodDates = periodToDate(periodCode);
                                        return (
                                            <ListCell key={periodCode} align="center" footer onClick={() => openReport({
                                                gte: periodDates[0],
                                                lte: periodDates[1]
                                            }, [project.id], undefined, undefined, 'tasks', undefined, undefined)}>
                                                {durationFormatter(durationSummaryMins || 0)}
                                            </ListCell>
                                        );
                                    })
                                }
                            </Fragment>
                        )}
                        data={project.users}
                        renderRowDock={({
                            id,
                            firstName,
                            lastName,
                            avatarUrl,
                            durationSummaryMins,
                        }) => {
                            const statuses = filterStatuses(project.users.reduce((acc,p) => p?.approval_statuses?.length ? [...acc, ...p.approval_statuses] : acc, []));
                            return (
                                <Fragment>
                                    {isStatus &&
                                        <ListCell width="200px" align="center">
                                            <Space direction="row" style={{ justifyContent: "center" }}>
                                                <ApprovalStatus status={statuses[0]} />
                                                {statuses.length > 1 &&
                                                    <Space ml={"xxs"}>
                                                        <Tooltip text={statuses.slice(1).join(', ')}>
                                                            <Counter number={statuses.length - 1} />
                                                        </Tooltip>
                                                    </Space>
                                                }
                                            </Space>
                                        </ListCell>
                                    }
                                    <ListCell width="95px" align="center"
                                        onClick={() => openReport(undefined, [project.id], undefined, [id], 'tasks', undefined, undefined)}>
                                        <Tooltip text={`${firstName} ${lastName}`}>
                                            <Avatar firstName={firstName} lastName={lastName} picture={avatarUrl} />
                                        </Tooltip>
                                    </ListCell>
                                    <ListCell align="center">
                                        {durationFormatter(durationSummaryMins || 0)}
                                    </ListCell>
                                </Fragment>
                            )}}
                        renderRow={({
                            id,
                            periods
                        }) => (
                            <Fragment>
                                {
                                    periods.map(({ periodCode, durationSummaryMins }) => {
                                        if (durationSummaryMins > 0) {
                                            return (
                                                <ListCell key={periodCode} align="center"
                                                    onClick={() => openReport(undefined, [project.id], undefined, [id], 'tasks', undefined, undefined)}>
                                                    {durationFormatter(durationSummaryMins || 0)}
                                                </ListCell>
                                            );
                                        }

                                        return (
                                            <ListCell key={periodCode} align="center" />
                                        );
                                    })
                                }
                            </Fragment>
                        )}
                        cellsAmount={project.periods.length}
                    />
                </Space>
            ))}
        </div>
    );
}

TimeView.propTypes = {
    data: PropTypes.object,
    openReport: PropTypes.func.isRequired,
    isStatus: PropTypes.bool,
};

TimeView.defaultProps = {
    data: {},
    isStatus: false,
};

export default TimeView;
