import React, { useEffect, useState } from 'react';
import { useIntl } from "react-intl";
import { Link, useLocation } from 'react-router-dom';
import cx from 'classnames';

import UsersService from "@quidlo/common/api/UsersService";
import { PROJECT_ROLE, SUBSCRIPTION_STATUS,WORKSPACE_ROLE } from "@quidlo/common/constants/main";
import useAuthentication from "@quidlo/common/hooks/useAuthentication";
import useInvitations from "@quidlo/common/hooks/useInvitations";
import useUI from "@quidlo/common/hooks/useUI";
import useWorkspace from "@quidlo/common/hooks/useWorkspace";
import { Icon } from "@quidlo/ui";

import { messagesErrors,messagesToasts } from "../../../messages";
import { getWorkspaceSubscriptionQuery } from "../../../queries/workspace.queries";
import { differenceInDays } from "../../../utils/dateHelpers";

import style from './Snackbar.module.scss';

import messages from "./Snackbar.i18n";

const Snackbar = () => {
    const [snackbar, setSnackbar] = useState(null);
    const intl = useIntl();
    const location = useLocation();
    const { toastPush, isTopbar } = useUI();
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    const { data: subscription } = getWorkspaceSubscriptionQuery();
    const { invitations, acceptInvitation } = useInvitations();
    useEffect(() => {
        if (!user || !workspace || !subscription) {
            setSnackbar(null);
            return;
        }

        const isAdminOrManager = [WORKSPACE_ROLE.ADMIN, WORKSPACE_ROLE.OWNER].find(role => role === workspace?.organizationRole) || workspace?.projectsRole === PROJECT_ROLE.MANAGER;
        const isOwner = [WORKSPACE_ROLE.OWNER].includes(workspace?.organizationRole);

        if (subscription.status === SUBSCRIPTION_STATUS.PAST_DUE && isAdminOrManager) {
            setSnackbar({
                text: intl.formatMessage(messages.paymentIssue)
            });
            return;
        }
        else if (subscription.status === SUBSCRIPTION_STATUS.TRIAL_EXPIRING && !['/paywall'].includes(location.pathname)) {
            const days = differenceInDays(new Date(subscription?.validTo))
            setSnackbar({
                text: intl.formatMessage(isOwner ? messages.trialExpiredOwner : messages.trialExpiredEmployee, {
                    days,
                    dayForm: intl.formatMessage(days === 1 ? messages.day : messages.days)
                }),
                CTAtext: isOwner ? intl.formatMessage(messages.upgrade) : '',
                href: '/paywall',
                showCloseButton: true
            });
            return;
        }
        else if (subscription.status === SUBSCRIPTION_STATUS.PRO_EXPIRING && !subscription.isActive) {
            const days = differenceInDays(new Date(subscription?.validTo))

            setSnackbar({
                text: intl.formatMessage(isOwner ? messages.subscriptionExpiredOwner : messages.subscriptionExpiredEmployee, {
                    days,
                    dayForm: intl.formatMessage(days === 1 ? messages.day : messages.days)
                }),
                CTAtext: isOwner ? intl.formatMessage(messages.manageSubscription) : '',
                href: '/paywall',
                showCloseButton: true
            });
            return;
        }
        else if (invitations?.length) {
            setSnackbar({
                text: intl.formatMessage(messages.youHaveBeenInvited, { companyName: invitations[0]?.organization?.name }),
                CTAtext: intl.formatMessage(messages.acceptInvitation),
                action: () => acceptInvitation(invitations[0]?.organization?.code),
                color: 'green',
                showCloseButton: true
            });
            return;
        }
        else if (!user.isActive) {
            setSnackbar({
                text: intl.formatMessage(messages.confirmYourEmailAddress),
                CTAtext: intl.formatMessage(messages.resendEmail),
                action: async () => {
                    try {
                        await UsersService.resendActivationEmail(user.email);
                        toastPush({
                            text: intl.formatMessage(messagesToasts.activationEmailResent)
                        })
                    } catch (e) {
                        console.log(e);
                        toastPush({
                            text: intl.formatMessage(messagesErrors.unexpected)
                        })
                    }
                },
                color: 'green',
                showCloseButton: false
            });
            return;
        }
        setSnackbar(null)
    }, [workspace, invitations, user, subscription])

    if (!snackbar || !isTopbar) return <div />
    return (
        <div className={style.snackbarWrapper}>
            <div className={style.spacer} />
            <div className={cx(style.contentWrapper, style[`color-${snackbar.color}`])}>
                <div className={style.text}>
                    {snackbar.text}
                    {snackbar.href && snackbar.CTAtext ?
                        <Link to={snackbar.href}>{snackbar.CTAtext}</Link>
                        :
                        <button onClick={() => {
                            snackbar.action();
                            setSnackbar(false);
                        }}
                        >
                            {snackbar.CTAtext}
                        </button>
                    }
                </div>
                {snackbar.showCloseButton ?
                    <button className={style.closeButton} onClick={() => setSnackbar(false)}>
                        <Icon icon="close" />
                    </button>
                    :
                    null
                }
            </div>
        </div>
    );
};

export default Snackbar;
