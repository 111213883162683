import React from 'react';
import PropTypes from 'prop-types';

import feedbackPNG from './assets/feedback.png';

import style from './FeedbackButton.module.scss';

const FeedbackButton = ({ feedbackHandler }) => (
    <button className={style.feedback} onClick={feedbackHandler}>
        <img src={feedbackPNG} alt="icon of a man" />
    </button>
);

FeedbackButton.propTypes = {
    feedbackHandler: PropTypes.func,
};

FeedbackButton.defaultProps = {
    feedbackHandler: () => {
        const
            iframe = document.getElementById('jsd-widget'),
            innerDoc = iframe.contentDocument || iframe.contentWindow.document,
            button = innerDoc.getElementById('help-button');

        if (button) {
            button.click();
        }
    }
};

export default FeedbackButton;
