import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import UsersService from "@quidlo/common/api/UsersService";
import { WORKSPACE_ROLE, WORKSPACE_STATUS } from "@quidlo/common/constants/main";
import useAuthentication from "@quidlo/common/hooks/useAuthentication";
import useUI from "@quidlo/common/hooks/useUI";
import { Button, Card } from "@quidlo/ui";

import CannotDeleteAccountModal from "./modals/CannotDeleteAccount";
import DeleteAccountModal from "./modals/DeleteAccount";

import style from "./DeleteAccount.module.scss";

import messages from './DeleteAccount.i18n';

const DeleteAccount = () => {
    const intl = useIntl();
    const { user } = useAuthentication();
    const { modalOpen } = useUI();

    const deleteAccount = async () => {
        const isUserOwner = !!user.organizations.find(o => o.organizationRole === 'owner');

        if (!isUserOwner) {
            modalOpen('deleteAccount')
            return;
        }

        const owneredWorkspaces = user.organizations.filter(w => w?.organizationRole === WORKSPACE_ROLE.OWNER && w.status === WORKSPACE_STATUS.APPROVED)

        const usersInWorkspaces = await Promise.all(owneredWorkspaces.map(async w => (await UsersService.getAll(w.code))?.data?.length));

        const indexOfWorkspaceWithUsers = usersInWorkspaces.findIndex(u => u > 1);

        if (indexOfWorkspaceWithUsers > -1) {
            modalOpen('cannotDeleteAccount', {
                workspaceCodeToRedirect: owneredWorkspaces[indexOfWorkspaceWithUsers]?.code
            })
        } else {
            modalOpen('deleteAccount', { deleteWorkspace: true })
        }
    }

    return (
        <Card
            title={intl.formatMessage(messages.accountActions)}
        >
            <div className={style.root}>
                <div className={style.left}>
                    <FormattedMessage{...messages.byDeleting} />
                </div>
                <div className={style.right}>
                    <Button
                        text={intl.formatMessage(messages.deleteAccount)}
                        shadow
                        color="white"
                        onClick={deleteAccount}
                    />
                </div>
            </div>
            <DeleteAccountModal />
            <CannotDeleteAccountModal />
        </Card>
    )
};

export default DeleteAccount;

