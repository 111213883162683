import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import ChatbotsService from "@quidlo/common/api/ChatbotsService";
import IntegrationsService from "@quidlo/common/api/IntegrationsService";
import useAuthentication from "@quidlo/common/hooks/useAuthentication";
import useUI from "@quidlo/common/hooks/useUI";
import useWorkspace from "@quidlo/common/hooks/useWorkspace";

export const getGoogleChatStatusQuery = () => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    return useQuery(
        [
            { workspace: workspace.code, user: user.id },
            'chatbots', 'google-chat', 'status'
        ],
        async () => (await IntegrationsService.googleChatGetStatus(user.id, workspace.code))?.data,
        {
            // enabled: !!workspace?.code && !!user?.id,
            initialData: {
                accountConnected: false,
                hasSpaceId: false
            }
        }
    )
};

export const getSlackTeamsQuery = () => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    return useQuery(
        [
            { workspace: workspace.code, user: user.id },
            'chatbots', 'slack', 'teams'
        ],
        async () => (await ChatbotsService.getSlackTeams(user.id, workspace.code))?.data.map(team => ({
            ...team,
            id: team.slackUserId
        })),
        {
            // enabled: !!workspace?.code && !!user?.id,
            initialData: []
        }
    )
};

export const deleteSlackTeamMutation = slackUserId => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    const queryClient = useQueryClient();
    const { toastPush } = useUI();
    return useMutation(async key => (await ChatbotsService.deleteSlackTeam(user.id, workspace.code, slackUserId, key))?.data, {
        onSuccess: () => {
            queryClient.invalidateQueries([
                { workspace: workspace.code, user: user.id },
                'chatbots', 'slack', 'teams'
            ])
        },
        onError: e => {
            toastPush({
                text: e?.response?.data?.message,
                color: 'red'
            });
        }
    })
}

export const getRoomQuery = roomId => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    return useQuery(
        [
            { workspace: workspace.code, user: user.id },
            'chatbots', 'rooms', 'room', roomId
        ],
        async () => (await ChatbotsService.getRoom(user.id, workspace.code, roomId))?.data,
        {
            // enabled: !!workspace?.code && !!user?.id,
        }
    )
};

export const getRoomsQuery = () => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    return useQuery(
        [
            { workspace: workspace.code, user: user.id },
            'chatbots', 'rooms', 'list'
        ],
        async () => (await ChatbotsService.getRooms(user.id, workspace.code))?.data,
        {
            // enabled: !!workspace?.code && !!user?.id,
            initialData: []
        }
    )
};

export const editRoomMutation = roomId => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    const queryClient = useQueryClient();
    const { toastPush } = useUI();
    return useMutation(async key => (await ChatbotsService.editRoom(user.id, workspace.code, roomId, key))?.data, {
        onSuccess: () => {
            queryClient.invalidateQueries([
                { workspace: workspace.code, user: user.id },
                'chatbots', 'rooms', 'list'
            ])
            queryClient.invalidateQueries([
                { workspace: workspace.code, user: user.id },
                'chatbots', 'rooms', 'room', roomId
            ])
        },
        onError: e => {
            toastPush({
                text: e?.response?.data?.message,
                color: 'red'
            });
        }
    })
}

export const deleteRoomMutation = roomId => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    const queryClient = useQueryClient();
    const { toastPush } = useUI();
    return useMutation(async key => await (ChatbotsService.deleteRoom(user.id, workspace.code, roomId, key))?.data, {
        onSuccess: () => {
            queryClient.invalidateQueries([
                { workspace: workspace.code, user: user.id },
                'chatbots', 'rooms', 'list'
            ])
        },
        onError: e => {
            toastPush({
                text: e?.response?.data?.message,
                color: 'red'
            });
        }
    })
}

export const getIntentsQuery = () => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    return useQuery(
        [
            { workspace: workspace.code, user: user.id },
            'chatbots', 'intents', 'list'
        ],
        async () => (await ChatbotsService.getIntents())?.data,
        {
            // enabled: !!workspace?.code && !!user?.id,
        }
    )
};
