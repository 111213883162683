import { defineMessages } from 'react-intl';

export default defineMessages({
    taskStarted: {
        id: 'useAutoTracker.taskStarted',
        defaultMessage: 'Task started',
    },
    projectIsFrozen: {
        id: 'useAutoTracker.projectIsFrozen',
        defaultMessage: 'Project is frozen on that date',
    },
    descriptionRequired: {
        id: 'useAutoTracker.descriptionRequired',
        defaultMessage: 'Task description is required. Please change Required Fields in settings.',
    }
});
