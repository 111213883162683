import { defineMessages } from 'react-intl';

export default defineMessages({
    enterEmail: {
        id: 'reportCreate.enterEmail',
        defaultMessage: 'At least one recipient'
    },
    copyOfThisMail: {
        id: 'reportCreate.copyOfThisMail',
        defaultMessage: 'A copy of this report will be sent to you as well.'
    },
    sendReport: {
        id: 'reportCreate.sendReport',
        defaultMessage: 'Send report'
    },
    title: {
        id: 'reportCreate.title',
        defaultMessage: 'Report title'
    },
    description: {
        id: 'reportCreate.description',
        defaultMessage: 'Additional notes'
    },
    titleMax: {
        id: 'reportCreate.titleMax',
        defaultMessage: 'Title should be at most {max} characters long'
    },
    titleMin: {
        id: 'reportCreate.titleMin',
        defaultMessage: 'Title should be at least {min} characters long'
    },
    descriptionMax: {
        id: 'reportCreate.descriptionMax',
        defaultMessage: 'Description should be at most {max} characters long'
    },
    descriptionMin: {
        id: 'reportCreate.descriptionMin',
        defaultMessage: 'Description should be at least {min} characters long'
    },
    titleDefault: {
        id: 'reportCreate.titleDefault',
        defaultMessage: 'Your hourly report from {workspace}'
    },
    descriptionDefault: {
        id: 'reportCreate.descriptionDefault',
        defaultMessage: 'This is my report'
    }
});