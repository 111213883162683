import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import useUI from "@quidlo/common/hooks/useUI";
import useWorkspace from "@quidlo/common/hooks/useWorkspace";

import {
    deleteRoomMutation,
    deleteSlackTeamMutation,
    getGoogleChatStatusQuery,
    getRoomsQuery,
    getSlackTeamsQuery,
} from '../../../queries/chatbots.queries';
import ViewHOC from '../../../ui/View/View';

import ChatbotList from './ChatbotList';

import messages from './ChatbotList.i18n';

const ChatbotListContainer = () => {
    const
        location = useLocation(),
        intl = useIntl(),
        navigate = useNavigate(),
        { toastPush } = useUI(),
        { data: googleChatAccountConnected } = getGoogleChatStatusQuery(),
        { data: slackTeams } = getSlackTeamsQuery(),
        { data: googleChatRooms } = getRoomsQuery(),
        { mutate: deleteRoom } = deleteRoomMutation(),
        { mutate: deleteSlackTeam } = deleteSlackTeamMutation(),
        { workspace: { organizationRole, projectsRole } } = useWorkspace(),
        handleDelete = id => {
            deleteRoom(id);
        },
        handleEdit = roomId => {
            navigate(`/integrations/chatbots/${roomId}`);
        },
        handleSlackTeamDelete = slackUserId => {
            deleteSlackTeam(slackUserId)
        },
        hasPermissionToSeeRooms = () => {
            return ['admin', 'owner'].filter(role => role === organizationRole).length > 0 || projectsRole === 'manager';
        },
        props = {
            slackAccountConnected: slackTeams.length > 0,
            hasPermissionToSeeRooms: hasPermissionToSeeRooms(),
            googleChatAccountConnected: googleChatAccountConnected.accountConnected,
            googleChatRooms,
            slackTeams,
            handleDelete,
            handleEdit,
            handleSlackTeamDelete,
        };
    useEffect(() => {
        const queryParamsString = location.search.substring(1), // remove the "?" at the start
            searchParams = new URLSearchParams(queryParamsString),
            error = searchParams.get('error');
        if (error) {
            switch (error) {
                case 'access_denied':
                    toastPush({
                        text: intl.formatMessage(messages.accessDenied),
                        color: 'red'
                    });
                    break;
                case 'internal_error':
                    toastPush({
                        text: intl.formatMessage(messages.internalError),
                        color: 'red'
                    });
                    break;
                case 'bot_already_installed':
                    toastPush({
                        text: intl.formatMessage(messages.botAlreadyInstalled),
                        color: 'red'
                    });
                    break;
                case 'empty_code':
                    toastPush({
                        text: intl.formatMessage(messages.emptyCode),
                        color: 'red'
                    });
                    break;
                default:
                    toastPush({
                        text: intl.formatMessage(messages.defaultSlackError),
                        color: 'red'
                    });
                    break;
            }
        }
    }, []);

    return (
        <ChatbotList {...props} />
    );
};

export default ViewHOC((ChatbotListContainer),
    {
        title: 'Chatbots List',
        menu: true,
        topbar: true,
        auth: {
            roles: ['employee', 'manager', 'admin', 'owner']
        },
        subscription: true
    }
);