import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import cx from 'classnames';
import PropTypes from 'prop-types';

import SelectDropdown from './SelectDropdown';
import SelectView from './SelectView';

import style from './Select.module.scss';

const Select = ({
    label,
    value,
    error,
    onChange,
    onBlur,
    onFocus,
    isTouched,
    isRequired,
    options,
    multiselect,
    allselect,
    isNarrow,
    disabled,
}) => {
    const
        intl = useIntl(),
        [isFocused, setIsFocused] = useState(false),
        [activeOption, setActiveOption] = useState(null),
        [optionsContHeight, setOptionsContHeight] = useState(0),
        rootRef = useRef(null),
        selectFieldRef = useRef(null),
        optionsContainerRef = useRef(null),
        selectAllHandler = () => {
            onChange(options.map(o => o.value));
        },
        open = useCallback(() => {
            onFocus();
            setIsFocused(true);
            setActiveOption(allselect ? 'all' : 0);
        }, []),
        close = useCallback(() => {
            setActiveOption(null);
            onBlur();
            setIsFocused(false);
            if (rootRef.current) {
                rootRef.current.blur();
            }
        }, []),
        selectHandler = val => {
            let newVal;
            if (multiselect) {
                if (value.find(v => v === val)) {
                    newVal = value.filter(v => v !== val);
                } else {
                    newVal = [...value, val];
                }
            } else {
                newVal = val;
                close();
            }

            if (newVal !== value) {
                onChange(newVal);
            }
        },
        keydownHandler = useCallback(event => {
            if (event.keyCode === 40) {
                setActiveOption(val => {
                    if (val === 'all') {
                        return 0;
                    } else if (options.length > val + 1) {
                        return val + 1;
                    }
                    return val;
                });
            }

            if (event.keyCode === 38) {
                setActiveOption(val => {
                    if (val === 0 && allselect) {
                        return 'all';
                    } else if (val > 0) {
                        return val - 1;
                    }
                    return val;
                });
            }

            if (event.keyCode === 13) {
                setActiveOption(val => {
                    if (val === 'all') {
                        selectAllHandler();
                    } else if (options[val]?.value) {
                        selectHandler(options[val].value);
                    }
                    return val;
                });
            }

            if (event.keyCode === 9) {
                close();
            }
        }),
        props = {
            options: options.map(o => ({
                ...o,
                selected: multiselect
                    ? !!value.find(v => v === o.value)
                    : value === o.value,
            })),
            active: isFocused,
            onSelect: selectHandler,
            onSelectAll:
                allselect && multiselect ? selectAllHandler : undefined,
            multiselect,
            optionsContainerRef,
            optionsContHeight,
            activeOption,
            intl,
        },
        propsSelectedView = {
            label,
            error,
            values: multiselect
                ? options.filter(o => value.includes(o.value))
                : options.find(o => value === o.value),
            isAll: multiselect ? options.length === value.length : false,
            isMulti: multiselect,
            isNarrow,
            isTouched,
            intl,
        };

    useEffect(() => {
        if (isFocused) {
            if (selectFieldRef.current) {
                const { bottom } =
                        selectFieldRef.current.getBoundingClientRect(),
                    optsContHeight =
                        document.body.clientHeight - (bottom + window.scrollY) <
                        300
                            ? document.body.clientHeight -
                            (bottom + window.scrollY) -
                            10
                            : 300;

                setOptionsContHeight(optsContHeight);
            }
            setTimeout(() => {
                document.removeEventListener('click', close);
                document.removeEventListener('keydown', keydownHandler);
                document.addEventListener('click', close);
                document.addEventListener('keydown', keydownHandler);
            }, 300);
        } else {
            document.removeEventListener('click', close);
            document.removeEventListener('keydown', keydownHandler);
        }
        return () => {
            document.removeEventListener('click', close);
            document.removeEventListener('keydown', keydownHandler);
        }
    }, [isFocused]);

    return (
        <div className={cx(style.select, disabled && style.disabled)} tabIndex="0" onFocus={open} onBlur={close} ref={rootRef}>
            <div
                className={cx(style.selectField, isFocused && style.active)}
                ref={selectFieldRef}>
                <SelectView {...propsSelectedView} />
            </div>
            <div
                className={style.optionsList}
            >
                <SelectDropdown {...props} />
            </div>
        </div>
    );
};

Select.propTypes = {
    label: PropTypes.string,
    value: PropTypes.any,
    error: PropTypes.node,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    isTouched: PropTypes.bool,
    isRequired: PropTypes.bool,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.any.isRequired,
            labelText: PropTypes.any,
            value: PropTypes.any.isRequired,
        }),
    ),
    multiselect: PropTypes.bool,
    allselect: PropTypes.bool,
    isNarrow: PropTypes.bool,
    disabled: PropTypes.bool,
    onBlur: PropTypes.func,
};

Select.defaultProps = {
    label: '',
    value: undefined,
    error: undefined,
    options: [],
    multiselect: false,
    allselect: false,
    isTouched: false,
    isRequired: false,
    isBlur: false,
    errorHidden: false,
    onChange: () => {},
    onBlur: () => {},
    onFocus: () => {},
    disabled: false,
};

export default Select;
