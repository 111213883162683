import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import style from './ListFooter.module.scss';

/** Component of header row for List */
const
    ListFooter = ({
        children,
        docked,
        width
    }) => {
        const childrenProps = React.Children.map(children, child =>
            React.cloneElement(child, { header: true }));

        return (
            <div className={cx(style.listFooter, docked && style.docked)} style={{ width }}>
                {childrenProps}
            </div>
        );
    };

ListFooter.propTypes = {
    /** Children nodes */
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    /** Is header docked */
    docked: PropTypes.bool,
    /** Width */
    width: PropTypes.string,
};

ListFooter.defaultProps = {
    docked: false,
    width: '100%'
};

export default ListFooter;