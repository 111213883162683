import { defineMessages } from 'react-intl';

export default defineMessages({
    user: {
        id: 'configureGoogleChat.user',
        defaultMessage: 'Dear user'
    },
    message: {
        id: 'configureGoogleChat.message',
        defaultMessage: 'You have no projects yet. To use a bot you should create at least one project.'
    },
    ok: {
        id: 'configureGoogleChat.ok',
        defaultMessage: 'Go to projects'
    }
});