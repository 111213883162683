import { defineMessages } from 'react-intl';

export default defineMessages({
    duration: {
        id: 'autoTrackerForm.duration',
        defaultMessage: 'Duration'
    },
    whatHaveBeenDone: {
        id: 'autoTrackerForm.whatHaveBeenDone',
        defaultMessage: 'Task description'
    },
    tags: {
        id: 'autoTrackerForm.tags',
        defaultMessage: 'Tags',
    },
    project: {
        id: 'autoTrackerForm.project',
        defaultMessage: 'Project name',
    },
    user: {
        id: 'autoTrackerForm.user',
        defaultMessage: 'People',
    },
    userIsReq: {
        id: 'autoTrackerForm.userIsReq',
        defaultMessage: 'Please select a person',
    },
    projectIsReq: {
        id: 'autoTrackerForm.projectIsReq',
        defaultMessage: 'Please select a project',
    },
    wrongTimeFormat: {
        id: 'autoTrackerForm.wrongTimeFormat',
        defaultMessage: 'Incorrect time format',
    },
    descriptionMin: {
        id: 'autoTrackerForm.descriptionMax',
        defaultMessage: 'Description should be at least {min} characters long',
    },
    descriptionMax: {
        id: 'autoTrackerForm.descriptionMax',
        defaultMessage: 'Description should be at most {max} characters long',
    },
    pleaseSelect: {
        id: 'autoTrackerForm.pleaseSelect',
        defaultMessage: 'Please select a project first',
    }
});