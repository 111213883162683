import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import analytics from "./analytics";
import localDB from "./localDB";

export default Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_APIKEY,
    appVersion: window.version,
    releaseStage: process.env.REACT_APP_INSTANCE_NAME,
    enabledReleaseStages: ['production', 'stage', 'dev'],
    plugins: [new BugsnagPluginReact()],
    onError: event => {
        event.app.version = window.version;
        const user = localDB.LSget('user');
        if (user) {
            const { id, email, name } = JSON.parse(user);
            event.setUser(id, email, name)
        }
        if (event?.originalError?.name === 'AxiosError') {
            const error = event?.originalError
            event.addMetadata('api request', {
                url: error.config.url,
                method: error.config.method,
                headers: error.config.headers,
                data: JSON.parse(error?.config?.data) || null,
            })
            event.addMetadata('api response', error.response)
        }

        event.errors[0].stacktrace = event.errors[0].stacktrace.map(function (frame) {
            frame.file = frame.file.replace(/chrome-extension:/g, 'chrome_extension:')
            frame.file = frame.file.replace(/moz-extension:/g, 'moz_extension:')
            frame.file = frame.file.replace(/safari-extension:/g, 'safari_extension:')
            frame.file = frame.file.replace(/safari-web-extension:/g, 'safari_web_extension:')
            return frame
        })
        analytics.logError(event?.originalError?.name)
    },
    redactedKeys: [
        'Authorization',
        'password',
        'newPassword',
        'email',
        'googleIdToken',
        'token'
    ]
});