import { useQuery } from '@tanstack/react-query'

import ReportsService from "@quidlo/common/api/ReportsService";
import SharedReportsService from "@quidlo/common/api/SharedReportsService";
import useAuthentication from "@quidlo/common/hooks/useAuthentication";
import useWorkspace from "@quidlo/common/hooks/useWorkspace";

export const getReportsData = hash => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    const filters = hash ? JSON.parse(window.atob(hash.substr(1))) : {};
    return useQuery(
        [
            { workspace: workspace.code, user: user.id },
            'reports', 'report', filters
        ],
        async () => {
            const tagsOptions = (await ReportsService.getTagsByProjects(workspace.code, user.id, filters.projects))?.data
            const usersOptions = (await ReportsService.getUsersByProjects(workspace.code, user.id, filters.projects))?.data
            const report = (await ReportsService.get(workspace.code, user.id, {
                ...filters,
                tags: filters.tags.filter(t => t !== "g-0").length < tagsOptions.length ? filters.tags : [],
                users: filters.users.length < usersOptions.length ? filters.users : [],
            }))?.data;
            return {
                filters,
                report,
            };
        },
        {
            initialData: {},
            keepPreviousData: true,
            // enabled: !!workspace?.code && !!user?.id && !!hash,
            enabled: !!hash,
            refetchOnWindowFocus: false
        }
    );
};

export const getReportsFiltersProjects = () => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    return useQuery(
        [
            { workspace: workspace.code, user: user.id },
            'reports', 'filters', 'projects'
        ],
        async () => {
            return (await ReportsService.getProjects(workspace.code, user.id))?.data
        },
        {
            // enabled: !!workspace?.code && !!user?.id,
            initialData: []
        }
    );
};

export const getReportsFiltersTagsByProjects = projectIds => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    return useQuery(
        [
            { workspace: workspace.code, user: user.id },
            'reports', 'filters', 'tags', { projectIds }
        ],
        async () => {
            const tags = (await ReportsService.getTagsByProjects(workspace.code, user.id, projectIds))?.data;
            return ([
                {
                    id: 'g-0',
                    name: 'untagged'
                },
                ...tags,
            ])
        },
        {
            // enabled: !!workspace?.code && !!user?.id && !!projectIds,
            enabled: !!projectIds,
            initialData: []
        }
    );
};

export const getReportsFiltersUsersByProjects = projectIds => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    return useQuery(
        [
            { workspace: workspace.code, user: user.id },
            'reports', 'filters', 'users', { projectIds }
        ],
        async () => (await ReportsService.getUsersByProjects(workspace.code, user.id, projectIds))?.data,
        {
            // enabled: !!workspace?.code && !!user?.id && !!projectIds,
            enabled: !!projectIds,
            initialData: []
        }
    );
};

export const getSharedReportOptions = code => {
    return useQuery(
        ['reports', 'shared', code, 'options'],
        async () => (await SharedReportsService.get(code))?.data,
        {
            initialData: null,
            enabled: !!code
        }
    );
};

export const getSharedReportData = ({ code, hash }) => {
    const filters = hash ? JSON.parse(window.atob(hash.substr(1))) : {};
    return useQuery(
        ['reports', 'shared', code, 'data', filters],
        async () => {
            return {
                filters,
                report: (await SharedReportsService.getData(code, filters))?.data,
            };
        },
        {
            initialData: null,
            enabled: !!hash && !!code
        }
    );
};