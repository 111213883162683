import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import useForm from "@quidlo/common/hooks/useForm2";
import validators from "@quidlo/common/utils/validators";
import { Button, Card, Input, Label, Space, Switch, Tooltip } from '@quidlo/ui';

import { editProjectMutation, getProjectsProjectQuery } from "../../../../queries/projects.queries";
import { InputColor, InputDate } from '../../../../ui';
import { dateToString } from "../../../../utils/dateHelpers";

import style from './BasicParams.module.scss';

import messages from './BasicParams.i18n';

const FORM = {
    color: {
        value: ''
    },
    name: {
        value: '',
        validators: [validators.isRequired(), validators.minLength(3), validators.maxLength(40)]
    },
    freezeDate: {
        value: null
    },
    isBillable: {
        value: false
    }
}

const BasicParams = ({ projectId }) => {
    const intl = useIntl();
    const { data: project } = getProjectsProjectQuery(projectId);
    const { mutate: editProject } = editProjectMutation();
    const [form, { isFormValid, isFormDirty, touchForm }] = useForm(FORM);

    useEffect(() => {
        if (project) {
            form.color.setValue(project.color);
            form.name.setValue(project?.name);
            form.freezeDate.setValue((project?.freezeDate && new Date(project?.freezeDate) > new Date('1980-01-01')) ? new Date(project.freezeDate) : null);
            form.isBillable.setValue(project.isBillable);
        }
    }, [project])


    const save = () => {
        if (!isFormValid || !isFormDirty) return;
        editProject({ id: projectId, delta: {
            name: form.name.value,
            freezeDate: form.freezeDate.value ? dateToString(form.freezeDate.value) : '1970-01-01',
            color: form.color.value,
            isBillable: form.isBillable.value,
        } })
    };

    return (
        <Card>
            <div className={style.wrapper} style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                <div className={style.inputs} style={{ display: "flex", flexDirection: "row" }}>
                    <Space pv="s" pr='m'>
                        <div className={style.project}>
                            <Label icon="project" text={intl.formatMessage(messages.project)} size="small" />
                            <Space mt="xxs">
                                <div className={style.inputContainer}>
                                    <div className={style.input}>
                                        <Input
                                            {...form.name}
                                            placeholder={intl.formatMessage(messages.typeProjectsName)}
                                            name="project-edit-name"
                                            disabled={project.isArchived}
                                            isRequired
                                        />
                                    </div>
                                </div>
                            </Space>
                        </div>
                    </Space>
                    <Space ml="s" pv="s" pr='m'>
                        <Label icon="color" text={intl.formatMessage(messages.projectColor)} size="small" />
                        <Space mt="xxs">
                            <div className={style.inputContainer}>
                                <div className={style.input}>
                                    <InputColor
                                        {...form.color}
                                        disabled={project.isArchived}
                                    />
                                </div>
                            </div>
                        </Space>
                    </Space>
                    <Space ml="s" pv="s" pr='m'>
                        <Label icon="dollar" text={intl.formatMessage(messages.billable)} size="small" />
                        <Space mt="xxs">
                            <div className={style.inputContainer}>
                                <div className={style.input}>
                                    <Switch
                                        {...form.isBillable}
                                        options={[
                                            {
                                                value: false,
                                                label: ''
                                            },
                                            {
                                                value: true,
                                                label: ''
                                            }
                                        ]}
                                        size="small"
                                        disabled={project.isArchived}
                                        fieldId="billable-switch"
                                    />
                                </div>
                            </div>
                        </Space>
                    </Space>
                    <Space ml="s" pv="s" pr='m'>
                        <div className={style.freeze}>
                            <Label text={intl.formatMessage(messages.frozeProject)} size="small" />
                            <Space mt="xxs">
                                <div className={style.inputContainer}>
                                    <div className={style.input}>
                                        <div className={style.switchFreeze}>
                                            <Tooltip text={intl.formatMessage(messages.frozeProjectTooltip)}>
                                                <Switch
                                                    value={!!form.freezeDate.value}
                                                    onChange={value => {
                                                        form.freezeDate.onChange(value ? new Date() : null)
                                                    }}
                                                    options={[
                                                        {
                                                            value: false,
                                                            label: ''
                                                        },
                                                        {
                                                            value: true,
                                                            label: ''
                                                        }
                                                    ]}
                                                    size="small"
                                                    disabled={project.isArchived}
                                                    fieldId="freeze-switch"
                                                />
                                            </Tooltip>
                                        </div>
                                        <InputDate
                                            {...form.freezeDate}
                                            name="project-edit-freeze-date"
                                            disabled={project.isArchived}
                                        />
                                    </div>
                                </div>
                            </Space>
                        </div>
                    </Space>
                </div>
                <Space ml="s" pv="s">
                    <div className={style.btnSave}>
                        <Button
                            type="bordered"
                            color="blue"
                            shadow
                            text={intl.formatMessage(messages.save)}
                            onClick={save}
                            onDisabledClick={touchForm}
                            disabled={project.isArchived || !isFormValid || !isFormDirty}
                        />
                    </div>
                </Space>
            </div>
        </Card>
    )
};

BasicParams.propTypes = {
    projectId: PropTypes.string.isRequired,
};

export default BasicParams;
