import { defineMessages } from 'react-intl';

export default defineMessages({
    project: {
        id: 'basicParams.project',
        defaultMessage: 'Project\'s name'
    },
    typeProjectsName: {
        id: 'basicParams.typeProjectsName',
        defaultMessage: 'Type project’s name'
    },
    frozeProject: {
        id: 'basicParams.frozeProject',
        defaultMessage: 'Lock the project till'
    },
    frozeProjectTooltip: {
        id: 'basicParams.frozeProjectTooltip',
        defaultMessage: 'If enabled, no changes can be made to the project\'s entries till the specified date.'
    },
    projectColor: {
        id: 'basicParams.projectColor',
        defaultMessage: "Project's color"
    },
    billable: {
        id: 'basicParams.billable',
        defaultMessage: 'Billable'
    },
    setAsPublic: {
        id: 'basicParams.setAsPublic',
        defaultMessage: 'Set the project as public'
    },
    save: {
        id: 'basicParams.save',
        defaultMessage: 'Save'
    },
});