import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from "react-router-dom";
import cx from "classnames";

import { FEATURE } from "@quidlo/common/constants/main";
import useForm from '@quidlo/common/hooks/useForm2';
import useUI from '@quidlo/common/hooks/useUI';
import useWorkspace from '@quidlo/common/hooks/useWorkspace';
import analytics from "@quidlo/common/utils/analytics";
import { dateToString } from '@quidlo/common/utils/dateHelpers';
import { durationFormatter } from '@quidlo/common/utils/duration';
import validators from "@quidlo/common/utils/validators";
import { Autocomplete, Button, Header, Space, Spinner } from '@quidlo/ui';

import useSuggestions from '../../../hooks/useSuggestions';
import { getPotentialApprovalsQuery } from '../../../queries/approvals.queries';
import { getProjectsListQuery } from '../../../queries/projects.queries';
import { Container, DateRangePicker, Suggestions } from '../../../ui';
import ProjectPill from "../../../ui/ProjectPill/ProjectPill";
import ViewHOC from '../../../ui/View/View';
import { setWeekday } from '../../../utils/dateHelpers';
import CreateRequestModal from '../modals/CreateRequestModal';

import PotentialApprovalsList from './PotentialApprovalsList/PotentialApprovalsList';

import style from './ApprovalCreate.module.scss';

import messages from './ApprovalCreate.i18n';

const ApprovalCreate = () => {
    const
        intl = useIntl(),
        { modalOpen } = useUI(),
        { workspace } = useWorkspace(),
        navigate = useNavigate(),
        [form,{ isFormValid, isFormDirty, touchForm }] = useForm({
            date: {
                value: {
                    gte: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
                    lte: setWeekday(new Date(), 6, workspace?.weekStart),
                }
            },
            projects: {
                value: [],
                validators: [validators.isRequired()]
            }
        }),
        [filters, setFilters] = useState({
            date: {
                gte: dateToString(new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)),
                lte: dateToString(setWeekday(new Date(), 6, workspace?.weekStart)),
            },
            projects: [],
        }),
        { data, isFetched, isFetching } = getPotentialApprovalsQuery(filters),
        { data: projectsOptions } = getProjectsListQuery(),
        [chosenProjects, setChosenProjects] = useState([]),
        totalTimeInAllProjects = useMemo(() => data.projects.length ? durationFormatter(data.projects.reduce( (acc, obj) => acc + Number(obj.duration_mins), 0)) : '0', [data, chosenProjects]),
        {
            projects: projectsSuggestions,
            feedSuggestions
        } = useSuggestions(),
        totalTimeOfChosenTasks = useMemo(() => {
            let total = 0;
            data.projects.map(pro => {
                if(chosenProjects.includes(pro.project.id)) {
                    total = total + pro.duration_mins
                }
            })
            return total
        }, [chosenProjects, data]),
        projectsToApprove = useMemo(() => data.projects.filter(proj => chosenProjects.includes(proj.project.id)), [data, chosenProjects]),
        apply = (
            date= {
                gte: dateToString(form.date.value.gte),
                lte: dateToString(form.date.value.lte)
            },
            projects = form.projects.value,
        ) => {
            navigate(`/approvals/create#${window.btoa(JSON.stringify({ date, projects }))}`);
            feedSuggestions('projects', form.projects.value)
        };

    useEffect(() => {
        form.projects.setValue(projectsOptions.map(p => p.id));
        setFilters(filters => ({
            ...filters,
            projects: projectsOptions.map(p => p.id)
        }))
    }, [projectsOptions])

    useEffect(() => {
        if (location.hash) {
            const newFilters = JSON.parse(window.atob(location.hash.substr(1).replaceAll('%22','"')));
            setFilters(newFilters);
            form.projects.onChange(newFilters.projects)
            form.date.setValue({
                gte: new Date(newFilters.date.gte),
                lte: new Date(newFilters.date.lte)
            });
            analytics.logTimesheetsFilters({
                screenName: "Approvals - Create Approval",
                date: {
                    gte: new Date(newFilters.date.gte),
                    lte: new Date(newFilters.date.lte)
                },
                projectsNo: newFilters.projects.length
            })
        }
    }, [location.hash]);

    return (
        <Container>
            <Header
                title={intl.formatMessage(messages.timeApprovals)}
                rightChildren={
                    <Button
                        color={'blue'}
                        onClick={() => modalOpen('createApprovalRequest', { projectsToApprove, totalTimeOfChosenTasks, date: form.date.value }, () => navigate('/approvals/list/my'))}
                        size={'small'}
                        disabled={!chosenProjects.length}
                        text={intl.formatMessage(messages.requestTimeApproval)}
                    />
                }
                size="large"
                goBack
                underline
            />
            <Space mt="m" direction="row">
                <DateRangePicker
                    date={form.date.value}
                    onChange={form.date.onChange}
                    analyticsID="Approvals - Create Approval"
                />
            </Space>
            <Space mt="m" p="s" direction="row" className={style.filters}>
                <div className={style.select}>
                    <Autocomplete
                        {...form.projects}
                        label={intl.formatMessage(messages.projects)}
                        multiselect
                        allselect
                        options={projectsOptions.map(p => ({
                            label: <ProjectPill {...p} />,
                            labelText: p.name,
                            value: p.id,
                        }))}
                        size="large"
                        onlyselect
                        analyticsID="Approvals - Create Approval - Projects"
                        isRequired
                    />
                    <div className={cx([style.suggestions, (form.projects.error) && style.hidden])}>
                        <Suggestions
                            options={projectsOptions.map(o => ({
                                label: o.name,
                                value: o.id
                            }))}
                            suggestions={projectsSuggestions}
                            onClick={e => form.projects.onChange([e])}
                            maxNumber={3}
                            analyticsID="Approvals - Create Approval - Projects"
                        />
                    </div>
                </div>
                <Button
                    size={'small'}
                    color={'white'}
                    onClick={() => apply()}
                    onDisabledClick={touchForm}
                    disabled={!isFormDirty || !isFormValid}
                    text={intl.formatMessage(messages.apply)}
                    shadow
                />
            </Space>
            <Space mt="m">
                {
                    (!isFetched && isFetching) ? (
                        <Space p="l">
                            <Spinner active />
                        </Space>
                    ) : (
                        <PotentialApprovalsList
                            chosenProjects={chosenProjects}
                            setChosenProjects={setChosenProjects}
                            data={data}
                            totalTimeInAllProjects={totalTimeInAllProjects}
                            totalTimeOfChosenTasks={totalTimeOfChosenTasks}
                            dateRange={form.date.value}
                        />
                    )
                }
            </Space>
            <CreateRequestModal />
        </Container>
    );
};

export default ViewHOC((ApprovalCreate),
    {
        title: 'Approvals - Create Approval',
        menu: true,
        topbar: true,
        auth: {},
        subscription: true,
        feature: FEATURE.APPROVALS
    }
);

