import React from 'react';
import PropTypes from 'prop-types';

import DateSelect from './DateSelect';

import style from './DateSelect.module.scss';

const DateSelectGroup = ({ value, setDate }) => {
    return (
        <div className={style.selectGroup}>
            <DateSelect
                type="month"
                value={value.getMonth()}
                year={value.getFullYear()}
                setDate={v => setDate({
                    currentMonth: v,
                    currentYear: value.getFullYear()
                })}
            />
            <DateSelect
                type="year"
                value={value.getFullYear()}
                setDate={v => setDate({
                    currentMonth: value.getMonth(),
                    currentYear: v
                })}
            />
        </div>
    );
}

DateSelectGroup.propTypes = {
    value: PropTypes.instanceOf(Date),
    setDate: PropTypes.func,
};

DateSelectGroup.defaultProps = {
    value: Date.now(),
    setDate: undefined,
};

export default DateSelectGroup;