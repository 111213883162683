import React from 'react';
import cx from 'classnames';
import { AnimatePresence } from "framer-motion";
import PropTypes from 'prop-types';

import Toast from './Toast';

import style from './Toaster.module.scss';

const Toaster = ({ toasts }) => (
    <div className={cx(style.toaster, toasts.length && style.visible)}>
        <AnimatePresence>
            {toasts.slice(0,1).map(t => (
                <Toast key={t.id} {...t} />
            ))}
        </AnimatePresence>
    </div>
);

Toaster.propTypes = {
    toasts: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            text: PropTypes.string,
            color: PropTypes.oneOf(['green', 'red', 'yellow', 'blue']),
        }),
    ).isRequired,
};

export default Toaster;
