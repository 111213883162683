import { defineMessages } from 'react-intl';

export default defineMessages({
    total: {
        id: 'tasksview.total',
        defaultMessage: 'Total:'
    },
    logDate: {
        id: 'tasksview.date',
        defaultMessage: 'Date'
    },
    person: {
        id: 'tasksview.person',
        defaultMessage: 'Person'
    },
    description: {
        id: 'tasksview.description',
        defaultMessage: 'Description'
    },
    deleteTask: {
        id: 'taskview.deleteTask',
        defaultMessage: 'Delete task'
    },
    editTask: {
        id: 'taskview.editTask',
        defaultMessage: 'Edit task'
    },
    duration: {
        id: 'tasksview.duration',
        defaultMessage: 'Duration'
    },
    tasks: {
        id: 'tasksview.tasks',
        defaultMessage: 'Tasks'
    },
    project: {
        id: 'tasksview.project',
        defaultMessage: 'Project'
    },
    status: {
        id: 'tasksview.status',
        defaultMessage: 'Status'
    },
    tags: {
        id: 'tasksview.tags',
        defaultMessage: 'Tags'
    },
    actions: {
        id: 'tasksview.actions',
        defaultMessage: 'Actions'
    },
    wrongTimeFormat: {
        id: 'tasksview.wrongTimeFormat',
        defaultMessage: 'Incorrect time format',
    },
    descriptionMin: {
        id: 'tasksview.descriptionMin',
        defaultMessage: 'Description should be at least {min} characters long',
    },
    descriptionMax: {
        id: 'tasksview.descriptionMax',
        defaultMessage: 'Description should be at most {max} characters long',
    },
    totalTimeInAllProjects: {
        id: 'tasksview.totalTimeInAllProjects',
        defaultMessage: 'Total time in all projects:'
    },
    startStop: {
        id: 'tasksview.startStop',
        defaultMessage: 'Start - Stop',
    },
    date: {
        id: 'tasksview.date',
        defaultMessage: 'Date',
    },
});