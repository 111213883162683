import React from 'react';
import PropTypes from 'prop-types';

import Bugsnag from '@quidlo/common/utils/bugsnag';

import Error500 from '../Errors/Error500';

const BugsnagErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)
const ErrorBoundary  = ({ children }) => (
    <BugsnagErrorBoundary FallbackComponent={Error500}>
        {children}
    </BugsnagErrorBoundary>
);

ErrorBoundary.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

export default ErrorBoundary;
