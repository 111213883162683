import React, { Component } from 'react';
import equal from 'deep-equal';
import PropTypes from 'prop-types';

import { addDays, setWeekday } from '../../utils/dateHelpers';

import DatePickerContext from './DatePickerContext';

const
    keydownHandler = e => {
        if ((e.keyCode || e.which) >= 37 && (e.keyCode || e.which) <= 40) {
            e.preventDefault();
        }
    };

class DatePickerContextContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            year: this.props.date.getFullYear(),
            month: this.props.date.getMonth(),
            innerToggle: undefined,
            direction: 'center',
        };

        this.nextMonth = this.nextMonth.bind(this);
        this.prevMonth = this.prevMonth.bind(this);
        this.keyupHandler = this.keyupHandler.bind(this);
        this.changeHandler = this.changeHandler.bind(this);

        document.addEventListener('keyup', this.keyupHandler);
        document.addEventListener('keydown', keydownHandler);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !equal(nextState, this.state) || !equal(nextProps, this.props);
    }

    componentWillUnmount() {
        document.removeEventListener('keyup', this.keyupHandler);
        document.removeEventListener('keydown', keydownHandler);
    }

    changeHandler(date) {
        const {
            type,
            onChange,
            dateRange,
            weekStart
        } = this.props;

        if (type === 'single') {
            onChange({ date });
        } else if (type === 'week') {
            if (date >= dateRange.gte && date <= dateRange.lte) {
                onChange({ date });
            } else {
                const range = {
                    gte: setWeekday(date, 0, weekStart),
                    lte: setWeekday(date, 6, weekStart)
                };

                onChange({ date, range });
            }
        } else if (type === 'range') {
            const newRange = { ...dateRange };

            if (date < dateRange.gte) {
                this.setState({
                    innerToggle: 'gte'
                });

                newRange.gte = date;
            } else if (date > dateRange.lte) {
                this.setState({
                    innerToggle: 'lte'
                });

                newRange.lte = date;
            } else {
                const { innerToggle } = this.state;

                if (innerToggle) {
                    if (innerToggle === 'gte') {
                        this.setState({
                            innerToggle: 'lte'
                        });

                        newRange.lte = date;
                    } else {
                        this.setState({
                            innerToggle: 'gte'
                        });

                        newRange.gte = date;
                    }
                } else {
                    const interval = {
                        gte: date - dateRange.gte,
                        lte: dateRange.lte - date
                    };

                    if (interval.gte <= interval.lte) {
                        this.setState({
                            innerToggle: 'gte'
                        });

                        newRange.gte = date;
                    } else {
                        this.setState({
                            innerToggle: 'lte'
                        });

                        newRange.lte = date;
                    }
                }
            }
            onChange({ range: newRange });
        }
    }

    keyupHandler(e) {
        if ((e.keyCode || e.which) === 37) {
            this.prevDay();
        } else if ((e.keyCode || e.which) === 38) {
            this.prevWeek();
        } else if ((e.keyCode || e.which) === 39) {
            this.nextDay();
        } else if ((e.keyCode || e.which) === 40) {
            this.nextWeek();
        }
    }

    nextMonth() {
        const { year, month } = this.state;

        if (month !== 11) {
            this.setState({
                direction: 'left',
                month: month + 1
            });
        } else {
            this.setState({
                direction: 'left',
                year: year + 1,
                month: 0
            });
        }
    }

    prevMonth() {
        const { year, month } = this.state;

        if (month !== 0) {
            this.setState({
                direction: 'right',
                month: month - 1
            });
        } else {
            this.setState({
                direction: 'right',
                year: year - 1,
                month: 11
            });
        }
    }

    nextWeek() {
        const { date } = this.props,
            newDate = addDays(date, 7);
        this.changeHandler(newDate);
        if (date.getMonth() !== newDate.getMonth()) {
            this.nextMonth();
        }
    }

    prevWeek() {
        const { date } = this.props,
            newDate = addDays(date, -7);
        this.changeHandler(newDate);
        if (date.getMonth() !== newDate.getMonth()) {
            this.prevMonth();
        }
    }

    nextDay() {
        const { date } = this.props,
            newDate = addDays(date, 1);
        this.changeHandler(newDate);
        if (date.getMonth() !== newDate.getMonth()) {
            this.nextMonth();
        }
    }

    prevDay() {
        const { date } = this.props,
            newDate = addDays(date, -1);
        this.changeHandler(newDate);
        if (date.getMonth() !== newDate.getMonth()) {
            this.prevMonth();
        }
    }

    render() {
        const props = {
            date: this.props.date,
            dateRange: this.props.dateRange,
            type: this.props.type,
            month: this.state.month,
            year: this.state.year,
            direction: this.state.direction,
            onNext: this.nextMonth,
            onPrev: this.prevMonth,
            onChange: this.changeHandler,
            dateBoundary: this.props.dateBoundary,
            weekStart: this.props.weekStart
        };

        return (
            <DatePickerContext {...props} />
        );
    }
}

DatePickerContextContainer.propTypes = {
    /** Value of date */
    date: PropTypes.instanceOf(Date),
    /** Value of date range */
    dateRange: PropTypes.shape({
        /** Greater than or equal value */
        gte: PropTypes.instanceOf(Date),
        /** Lower than or equal value */
        lte: PropTypes.instanceOf(Date)
    }),
    /** Type of Datepicker */
    type: PropTypes.oneOf(['week', 'range', 'single']),
    /** Function to trigger when date change */
    onChange: PropTypes.func,
    dateBoundary: PropTypes.object,
    weekStart: PropTypes.oneOf(['monday', 'sunday']),
};

DatePickerContextContainer.defaultProps = {
    date: new Date(),
    dateRange: {
        gte: new Date(),
        lte: new Date(),
    },
    type: 'single',
    onChange: undefined,
    dateBoundary: undefined,
    weekStart: 'monday'
};


export default DatePickerContextContainer;