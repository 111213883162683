import React from 'react';
import { useIntl } from "react-intl";
import PropTypes from 'prop-types';

import {
    Autocomplete,
    Avatar,
    Button,
    Drawer,
    Label,
    Select,
    Tag,
} from '@quidlo/ui';

import { DateRangePicker } from '../../../../ui';
import ProjectPill from "../../../../ui/ProjectPill/ProjectPill";

import style from './DrawerFilters.module.scss';

import messages from './DrawerFilters.i18n';

const DrawerFilters = ({
    options,
    filters,
    datepickerProps,
    confirm,
    disabled,
}) => {
    const intl = useIntl();
    return (
        <Drawer name="Filters" visibilityPosition={250}>
            <div className={style.drawerFilters}>
                <div className={style.row}>
                    <div className={style.topFilters}>
                        <div className={style.datepicker}>
                            <DateRangePicker {...datepickerProps} size="small" analyticsID="Reports - Drawer" contextOptions={{ zIndex: 100, fixed: true }} />
                        </div>
                    </div>
                </div>
                <div className={style.row}>
                    <div className={style['size-2']}>
                        <Label
                            icon="project"
                            text={intl.formatMessage(messages.projects)}
                            size="small"
                        />
                        <div className={style.select}>
                            <Autocomplete
                                {...filters.projects}
                                fieldId="reports-projec-drawer"
                                options={options.projects.map(p => ({
                                    label: <ProjectPill {...p} />,
                                    labelText: p.name,
                                    value: p.id
                                }))}
                                onlyselect
                                allselect
                                multiselect
                                analyticsID="Reports - Projects"
                            />
                        </div>
                    </div>
                    <div className={style['size-1']}>
                        <Label
                            icon="tag"
                            text={intl.formatMessage(messages.tags)}
                            size="small"
                        />
                        <div className={style.select}>
                            <Autocomplete
                                {...filters.tags}
                                fieldId="reports-tags"
                                options={options.tags.map(t => ({
                                    label: <Tag text={t.name} />,
                                    labelText: t.name,
                                    value: t.id,
                                }))}
                                onlyselect
                                allselect
                                multiselect
                                analyticsID="Reports - Tags"
                            />
                        </div>
                    </div>
                    <div className={style['size-2']}>
                        <Label
                            icon="user"
                            text={intl.formatMessage(messages.people)}
                            size="small"
                        />
                        <div className={style.select}>
                            <Autocomplete
                                fieldId="reports-users"
                                {...filters.users}
                                options={options.users.map(t => ({
                                    label: (
                                        <Tag
                                            avatar={
                                                <Avatar
                                                    picture={t.avatarUrl}
                                                    firstName={t.firstName}
                                                    lastName={t.lastName}
                                                />
                                            }
                                            text={`${t.firstName} ${t.lastName}`}
                                        />
                                    ),
                                    labelText: `${t.firstName} ${t.lastName}`,
                                    value: t.id,
                                }))}
                                onlyselect
                                allselect
                                multiselect
                                analyticsID="Reports - Users"
                            />
                        </div>
                    </div>
                    <div className={style['size-1']}>
                        <Label
                            icon="dollar"
                            text={intl.formatMessage(messages.billable)}
                            size="small"
                        />
                        <div className={style.select}>
                            <Select
                                {...filters.billable}
                                options={options.billable.map(v => ({
                                    label: intl.formatMessage(
                                        messages[`billable${v}`],
                                    ),
                                    labelText: intl.formatMessage(
                                        messages[`billable${v}`],
                                    ),
                                    value: v,
                                }))}
                                size="large"
                            />
                        </div>
                    </div>
                    {filters.statuses &&
                        <div className={style['size-1']}>
                            <Label
                                text={intl.formatMessage(messages.status)}
                                size="small"
                            />
                            <div className={style.select}>
                                <Autocomplete
                                    {...filters.statuses}
                                    fieldId="reports-statuses"
                                    options={options.statuses.map(t => ({
                                        label: t[0].toUpperCase() + t.slice(1),
                                        labelText: t,
                                        value: t,
                                    }))}
                                    onlyselect
                                    allselect
                                    multiselect
                                    analyticsID="Reports - Statuses"
                                />
                            </div>
                        </div>
                    }
                    <div>
                        <Button
                            text={intl.formatMessage(messages.show)}
                            shadow
                            onClick={() => confirm()}
                            disabled={disabled}
                        />
                    </div>
                </div>
            </div>
        </Drawer>
    );
}

DrawerFilters.propTypes = {
    options: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    datepickerProps: PropTypes.object.isRequired,
    confirm: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

DrawerFilters.defaultProps = {
    disabled: false,
};

export default DrawerFilters;
