import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Space } from "@quidlo/ui";

import style from './Container.module.scss';

const Container = ({
    children,
    marginLeft,
    marginTop
}) => (
    <div
        className={cx(style.container, marginLeft && style.marginLeft, marginTop && style.marginTop)}>
        <Space pv="xl">
            {children}
        </Space>
    </div>
);

Container.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    marginLeft: PropTypes.bool,
    marginTop: PropTypes.bool
};

Container.defaultProps = {
    marginLeft: true,
    marginTop: true
};

export default Container;