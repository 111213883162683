import React, { useEffect } from 'react';
import { useLocation } from "react-router-dom";

import useUI from "@quidlo/common/hooks/useUI";
import localDB from "@quidlo/common/utils/localDB";

import PollModal from "./PollModal";
import polls from "./polls.json";

const Polls = () => {
    const location = useLocation();
    const { modalOpen } = useUI();

    useEffect(() => {
        if (Math.random() < 0.01) {
            const pollsHistory = localDB.LSget('polls-history') || [];
            const poll = polls
                .filter(p => !pollsHistory.includes(p.question))
                .sort(() => Math.random() - 0.5)[0];

            if (poll) {
                setTimeout(() => {
                    modalOpen('pollModal', poll, () => {
                        const pollsHistory = localDB.LSget('polls-history');
                        if (pollsHistory) {
                            localDB.LSset('polls-history', [...pollsHistory, poll.question]);
                        } else {
                            localDB.LSset('polls-history', [poll.question]);
                        }

                    });
                }, 1000);
            }
        }
    }, [location]);

    return (<PollModal />);
}

export default Polls;