import mixpanel from 'mixpanel-browser';

class analytics {
    static async init({ client, clientApp } = {}) {
        try {
            mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
                batch_requests: false,
                debug: !!process.env.REACT_APP_MIXPANEL_DEBUG
            });
            mixpanel.register({
                'Environment': process.env.REACT_APP_INSTANCE_NAME,
                'Client': client || 'Web',
                'Client App': clientApp || 'Timesheets',
                'Version': window.version
            });
        } catch (e) {
            console.log(e);
        }
    }

    static logEvent(name, data, options) {
        try {
            if (mixpanel) {
                mixpanel.track(name, {
                    ...data,
                }, options);
            }
        } catch (e) {
            console.log(e);
        }
    }

    static reset() {
        mixpanel.reset();
    }

    static identifyUser(id, user = {}, workspace = {}) {
        try {
            if (mixpanel) {
                mixpanel.identify(`${id}`);
                mixpanel.people.set({
                    'id': user.id,
                    'User ID': user.id,
                    'email': user.email,
                    'Email': user.email,
                    'first_name': user.firstName,
                    'last_name': user.lastName,
                    'Workspace Code': workspace.code,
                    'Workspace Name': workspace.name,
                    'Workspace Role': workspace.organizationRole,
                });
            }
        } catch (e) {
            console.log(e);
        }
    }

    static logScreenDisplay(screenName) {
        this.logEvent('Screen Display', {
            'Name': screenName
        });
    }

    static logModalDisplay(modalName) {
        this.logEvent('Modal Display', {
            'Name': modalName
        });
    }

    static logToastDisplay(type, message) {
        this.logEvent('Toast Display', {
            'Type': type,
            'Message': message
        });
    }

    static logAppSumoSessionStart() {
        this.logEvent('Auth - AppSumo - Session Start');
    }

    static logAppSumoSessionSuccess() {
        this.logEvent('Auth - AppSumo - Success');
    }

    static logAuthSignUpSuccess(method) {
        this.logEvent('Auth - Sign Up - Success', {
            'Method': method
        });
    }

    static logAuthSignInSuccess(method) {
        this.logEvent('Auth - Sign In - Success', {
            'Method': method
        });
    }

    static logAuthSignOut() {
        this.logEvent('Auth - Sign Out', null);
        mixpanel.reset();
    }

    static logAuthWorkspaceCreationSuccess(invitedUsers) {
        this.logEvent('Auth - Workspace Creation - Success', {
            'Invited Users Number': invitedUsers
        });
    }

    static logAuthWorkspaceCreationCancel() {
        this.logEvent('Auth - Workspace Creation - Cancel');
    }

    static logAuthWorkspaceAvatarUploaded() {
        this.logEvent('Auth - Workspace Creation - Avatar Uploaded');
    }

    static logTimesheetsSubscriptionInvoiceInfoAttempt() {
        this.logEvent('Timesheets - Subscription - Invoice Info Attempt');
    }

    static logTimesheetsSubscriptionInvoiceInfoSuccess() {
        this.logEvent('Timesheets - Subscription - Invoice Info Success');
    }

    static logTimesheetsSubscriptionInvoiceInfoFail() {
        this.logEvent('Timesheets - Subscription - Invoice Info Fail');
    }

    static logTimesheetsSubscriptionPlanSelected(plan) {
        this.logEvent('Timesheets - Subscription - Plan Selected', {
            Plan: plan
        });
    }

    static logTimesheetsSubscriptionPaymentAttempt() {
        this.logEvent('Timesheets - Subscription - Payment Attempt');
    }

    static logTimesheetsSubscriptionPaymentSuccess() {
        this.logEvent('Timesheets - Subscription - Payment Success');
    }

    static logTimesheetsAutotrackerStart() {
        this.logEvent('Timesheets - Autotracker - Start');
    }

    static logTimesheetsAutotrackerStop(duration) {
        this.logEvent('Timesheets - Autotracker - Stop', {
            'Duration': duration
        });
    }

    static logTimesheetsApprovalsTimeApprovalRequestCreate({ projects, totalTime, startDate, endDate }) {
        this.logEvent('Timesheets - Approvals - Time Approval Request - Create', {
            'Number of Projects': projects,
            'Total Time': totalTime,
            'Number of Days': Math.round((endDate - startDate) / 86400000) + 1,
            'Start Date': startDate.toISOString(),
            'End Date': endDate.toISOString(),
        });
    }

    static logTimesheetsApprovalsTimeApprovalRequestCancelCreate() {
        this.logEvent('Timesheets - Approvals - Time Approval Request - Cancel Create');
    }

    static logTimesheetsApprovalsTimeApprovalRequestAccept({ people, projects, startDate, endDate }) {
        this.logEvent('Timesheets - Approvals - Time Approval Request - Accept', {
            'Number of Projects': projects,
            'Number of People': people,
            'Number of Days': Math.round((endDate - startDate) / 86400000) + 1,
            'Start Date': startDate.toISOString(),
            'End Date': endDate.toISOString(),
        });
    }

    static logTimesheetsApprovalsTimeApprovalRequestCancelAccept() {
        this.logEvent('Timesheets - Approvals - Time Approval Request - Cancel Accept');
    }

    static logTimesheetsApprovalsTimeApprovalRequestReject({ messageLength }) {
        this.logEvent('Timesheets - Approvals - Time Approval Request - Reject', {
            "Message Length": messageLength,
        });
    }

    static logTimesheetsApprovalsTimeApprovalRequestCancelReject() {
        this.logEvent('Timesheets - Approvals - Time Approval Request - Cancel Reject');
    }

    static logTimesheetsTrackerTimebarSwipe(direction) {
        this.logEvent('Timesheets - Tracker - Timebar Swipe', {
            "Direction": direction
        });
    }

    static logTimesheetsTrackerTodayButtonClick() {
        this.logEvent('Timesheets - Tracker - Today Button Click');
    }

    static logTimesheetsTrackerArrowButtonClick(direction) {
        this.logEvent('Timesheets - Tracker - Arrow Button Click', {
            "Direction": direction
        });
    }

    static logTimesheetsTrackerCalendarSelect() {
        this.logEvent('Timesheets - Tracker - Calendar Select');
    }

    static logTimesheetsFilters({
        screenName,
        date,
        projectsNo,
        statusesNo,
        peopleNo,
        tagsNo,
        period,
        type,
        isBillable
    }) {
        let dateProps = null;
        if (date.gte && date.lte) {
            dateProps = {
                "Start Date": date.gte.toISOString(),
                "End Date": date.lte.toISOString(),
                "Number of Days": Math.round((date.lte - date.gte) / 86400000) + 1,
            }
        } else if (date) {
            dateProps = {
                "Date": date.toISOString(),
            }
        }

        this.logEvent('Timesheets - Filters - Select', {
            "Screen Name": screenName,
            ...dateProps,
            "Number of Projects": projectsNo,
            "Number of Statuses": statusesNo,
            "Number of People": peopleNo,
            "Number of Tags": tagsNo,
            "Period": period,
            "Type": type,
            "Billable": isBillable,
        });
    }

    static logTimesheetsDatePickerSelect({ id, date, mode }) {
        let dateProps = null;
        if (date.gte && date.lte) {
            dateProps = {
                "Start Date": date.gte.toISOString(),
                "End Date": date.lte.toISOString(),
                "Number of Days": Math.round((date.lte - date.gte) / 86400000) + 1,
            }
        } else if (date) {
            dateProps = {
                "Date": date.toISOString(),
            }
        }

        this.logEvent('Timesheets - DatePicker - Select', {
            "ID": id,
            ...dateProps,
            "Mode": mode,
        });
    }

    static logTimesheetsDatePickerOpen({ id }) {
        this.logEvent('Timesheets - DatePicker - Open', {
            "ID": id,
        });
    }

    static logTimesheetsDatePickerClose({ id }) {
        this.logEvent('Timesheets - DatePicker - Close', {
            "ID": id,
        });
    }

    static logTimesheetsAutocompleteSelect({ id, number, trigger }) {
        this.logEvent('Timesheets - Autocomplete - Select', {
            "ID": id,
            "Number of Selected": number,
            "Trigger": trigger
        });
    }

    static logTimesheetsAutocompleteFilter({ id, phraseLength }) {
        this.logEvent('Timesheets - Autocomplete - Filter', {
            "ID": id,
            "Phrase Length": phraseLength,
        });
    }

    static logTimesheetsAutocompleteOnlyClick({ id }) {
        this.logEvent('Timesheets - Autocomplete - Only - Click', {
            "ID": id,
        });
    }

    static logTimesheetsAutocompleteOpen({ id }) {
        this.logEvent('Timesheets - Autocomplete - Open', {
            "ID": id,
        });
    }

    static logTimesheetsAutocompleteClose({ id }) {
        this.logEvent('Timesheets - Autocomplete - Close', {
            "ID": id,
        });
    }

    static logTimesheetsAutocompleteValidationError({ id, message }) {
        this.logEvent('Timesheets - Autocomplete - Validation Error', {
            "ID": id,
            "Message": message,
        });
    }

    static logTimesheetsInputFocus({ id }) {
        this.logEvent('Timesheets - Input - Focus', {
            "ID": id,
        });
    }

    static logTimesheetsInputValue({ id, value }) {
        this.logEvent('Timesheets - Input - Value', {
            "ID": id,
            "Value": value,
        });
    }

    static logTimesheetsInputValidationError({ id, message }) {
        this.logEvent('Timesheets - Input - Validation Error', {
            "ID": id,
            "Message": message,
        });
    }

    static logTimesheetsSuggestionClick({ id }) {
        this.logEvent('Timesheets - Suggestion - Click', {
            "ID": id,
        });
    }

    static logTimesheetsReportsCreateCopyLink({ days, people, projects, statuses, tags, period, type }) {
        this.logEvent('Timesheets - Reports - Create Report - Copy Link', {
            "Number of Days": days,
            "Number of People": people,
            "Number of Projects": projects,
            "Number of Statuses": statuses,
            "Number of Tags": tags,
            "Period": period,
            "Type": type,
        });
    }

    static logTimesheetsReportsCreateSend({ emails, dateLock, days, people, projects, statuses, tags, period, type }) {
        this.logEvent('Timesheets - Reports - Create Report - Send', {
            "Number of Emails": emails,
            "Lock Date": dateLock,
            "Number of Days": days,
            "Number of People": people,
            "Number of Projects": projects,
            "Number of Statuses": statuses,
            "Number of Tags": tags,
            "Period": period,
            "Type": type,
        });
    }

    static logTimesheetsReportsCreateExport(method, { days, people, projects, statuses, tags, period, type }) {
        this.logEvent('Timesheets - Reports - Create Report - Export', {
            "Method": method,
            "Number of Days": days,
            "Number of People": people,
            "Number of Projects": projects,
            "Number of Statuses": statuses,
            "Number of Tags": tags,
            "Period": period,
            "Type": type,
        });
    }

    static logTimesheetsReportsTaskEdit({ actor, interaction, isDesc, isTags, isDuration, isDate }) {
        this.logEvent('Timesheets - Reports - Task Edit', {
            "Actor": actor,
            "Interaction": interaction,
            "Description Changed": !!isDesc,
            "Tags Changed": !!isTags,
            "Duration Changed": !!isDuration,
            "Date Changed": !!isDate,
        });
    }

    static logTimesheetsReportsTaskDelete(actor) {
        this.logEvent('Timesheets - Reports - Task Delete', {
            "Actor": actor,
        });
    }

    static logTimesheetsTrackerTaskCreate({ descLength, tagsLength, duration, date }) {
        this.logEvent('Timesheets - Tracker - Task Create', {
            "Description Lenght": descLength,
            "Number of Tags": tagsLength,
            "Duration": duration,
            "Date": date.toISOString(),
        });
    }

    static logTimesheetsTrackerTaskEdit({ actor, interaction, isDesc, isTags, isDuration, isDate, isProject }) {
        this.logEvent('Timesheets - Tracker - Task Edit', {
            "Actor": actor,
            "Interaction": interaction,
            "Description Changed": !!isDesc,
            "Tags Changed": !!isTags,
            "Duration Changed": !!isDuration,
            "Date Changed": !!isDate,
            "Project Changed": !!isProject,
        });
    }

    static logTimesheetsTrackerTaskDelete(mode) {
        this.logEvent('Timesheets - Tracker - Task Delete', {
            "Edit Mode": mode
        });
    }

    static logTimesheetsSettingsDailyReport(enabled) {
        this.logEvent('Timesheets - Settings - Daily Report', {
            "Enabled": enabled
        });
    }

    static logTimesheetsPollOpen({ question }) {
        this.logEvent('Timesheets - Poll - Open', {
            "Question": question
        });
    }

    static logTimesheetsPollCancel({ question, role }) {
        this.logEvent('Timesheets - Poll - Cancel', {
            "Question": question,
            "Role": role
        });
    }

    static logTimesheetsPollAnswer({ question, options, text, role }) {
        this.logEvent('Timesheets - Poll - Answer', {
            "Question": question,
            "Options": options,
            "Text": text,
            "Role": role
        });
    }

    static logError(message) {
        this.logEvent('Error', {
            "Message": message,
        });
    }
}

export default analytics;
