import { defineMessages } from 'react-intl';

export default defineMessages({
    cancel: {
        id: 'timer.cancel',
        defaultMessage: 'Cancel',
    },
    accept: {
        id: 'timer.accept',
        defaultMessage: 'Accept',
    },
    deleteTask: {
        id: 'timer.deleteTask',
        defaultMessage: 'Delete task',
    },
    editTask: {
        id: 'timer.editTask',
        defaultMessage: 'Edit task',
    },
    replayTask: {
        id: 'timer.replayTask',
        defaultMessage: 'Replay task',
    },
    wrongTimeFormat: {
        id: 'timer.wrongTimeFormat',
        defaultMessage: 'Incorrect time format',
    },
    descriptionMin: {
        id: 'timer.descriptionMin',
        defaultMessage: 'Description should be at least {min} characters long',
    },
    descriptionMax: {
        id: 'timer.descriptionMax',
        defaultMessage: 'Description should be at most {max} characters long',
    },
    person: {
        id: 'timer.person',
        defaultMessage: 'Person',
    },
    duration: {
        id: 'timer.duration',
        defaultMessage: 'Duration',
    },
    startStop: {
        id: 'timer.startStop',
        defaultMessage: 'Start - Stop',
    },
    task: {
        id: 'timer.task',
        defaultMessage: 'Task',
    },
    tags: {
        id: 'timer.tags',
        defaultMessage: 'Tags',
    },
});