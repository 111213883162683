import React, { useCallback,useEffect } from 'react';
import { useIntl } from 'react-intl';

import useForm from "@quidlo/common/hooks/useForm2";
import useUI from "@quidlo/common/hooks/useUI";
import validators from "@quidlo/common/utils/validators";
import { Button, Input, Space } from "@quidlo/ui";

import illustration from "../../../../../../assets/modals/document.png";
import { createTagMutation } from "../../../../../../queries/tags.queries";
import ModalHOC, { ModalActions, ModalContent, ModalHeader } from "../../../../../../ui/Modal/Modal";

import messages from './AddTag.i18n';

const FORM = {
    name: {
        value: '',
        validators: [validators.isRequired(), validators.minLength(2)]
    }
}

const AddTag = () => {
    const
        intl = useIntl(),
        { modal, modalClose } = useUI(),
        [form, { isFormValid, cleanForm, touchForm }] = useForm(FORM),
        { mutate: addTag } = createTagMutation();

    const save = () => {
        if (isFormValid) {
            addTag({ name: form.name.value });
            cleanForm();
            modalClose();
        }
    }

    const keydownHandler = useCallback(event => {
        if (
            event.keyCode === 13 && isFormValid
        ) {
            addTag({ name: form.name.value });
            cleanForm();
            modalClose();
        }
    });

    useEffect(() => {
        if (modal.id === 'addTag') {
            document.addEventListener('keydown', keydownHandler);
        }
        return () => {
            document.removeEventListener('keydown', keydownHandler);
        };
    }, [modal.id, form.name.value])

    return (
        <>
            <ModalHeader>
                {intl.formatMessage(messages.createTag)}
            </ModalHeader>
            <ModalContent>
                <Input
                    label={intl.formatMessage(messages.globalTagName)}
                    {...form.name}
                    name="add-tag-name"
                    isRequired
                />
            </ModalContent>
            <ModalActions>
                <Space mr="m">
                    <Button
                        color="white"
                        text={intl.formatMessage(messages.cancel)}
                        onClick={modalClose}
                    />
                </Space>
                <Button
                    color="blue"
                    text={intl.formatMessage(messages.save)}
                    onClick={save}
                    disabled={!isFormValid}
                    onDisabledClick={touchForm}
                    shadow
                />
            </ModalActions>
        </>
    );
};

export default ModalHOC(
    AddTag,
    {
        id: 'addTag',
        size: 'small',
        illustration,
    }
);
