import React, { useMemo } from 'react';
import { useIntl } from "react-intl";
import cx from "classnames";
import { motion } from "framer-motion"
import PropTypes from "prop-types";

import useAuthentication from "@quidlo/common/hooks/useAuthentication";
import useForm from "@quidlo/common/hooks/useForm2";
import useUI from "@quidlo/common/hooks/useUI";
import useWorkspaceFeatures from "@quidlo/common/hooks/useWorkspaceFeatures";
import analytics from "@quidlo/common/utils/analytics";
import { durationParser, durationShortFormatter } from "@quidlo/common/utils/duration";
import { timeParser } from "@quidlo/common/utils/time";
import validators from "@quidlo/common/utils/validators";
import { Autocomplete, Avatar, Button, Input, Space, Tag, Tooltip } from "@quidlo/ui";

import useSuggestions from "../../../../hooks/useSuggestions";
import { addTaskMutation } from "../../../../queries/tasks.queries";
import {
    getTrackerFiltersProjectsByUserQuery,
    getTrackerFiltersProjectsQuery, getTrackerFiltersTagsQuery,
    getTrackerFiltersUsersQuery
} from "../../../../queries/tracker.queries";
import { Suggestions } from "../../../../ui";
import ProjectPill from "../../../../ui/ProjectPill/ProjectPill";
import { dateToString } from "../../../../utils/dateHelpers";

import style from "./TimeEntryForm.module.scss";

import messages from "./TimeEntryForm.i18n";

const TimeEntryForm = ({ active, date, scope }) => {
    const intl = useIntl();
    const { features } = useWorkspaceFeatures();
    const { mutateAsync: addTask } = addTaskMutation();
    const { modalOpen } = useUI();
    const { user } = useAuthentication();

    const [form, { isFormValid, cleanForm, touchForm, formError }] = useForm(useMemo(() => ({
        user: {
            value: undefined,
            validators: scope === 'all' ? [validators.isRequired(intl.formatMessage(messages.userIsReq))] : []
        },
        timeFrom: {
            value: '',
            validators: features?.task_with_from_to ? [validators.isRequired(), validators.isTime()] : []
        },
        timeTo: {
            value: '',
            validators: features?.task_with_from_to ? [validators.isRequired(), validators.isTime()] : []
        },
        duration: {
            value: '',
            validators: [validators.isRequired(), validators.isDuration()]
        },
        title: {
            value: '',
            validators: features?.required_task_description ? [
                validators.minLength(3, intl.formatMessage(messages.descriptionMin, { min: 3 })),
                validators.maxLength(500, intl.formatMessage(messages.descriptionMax, { max: 500 })),
            ] : []
        },
        project: {
            value: undefined,
            validators: [
                validators.isRequired(intl.formatMessage(messages.projectIsReq))
            ]
        },
        tags: { value: [] },
    }), [scope, features]), {
        validators: features?.task_with_from_to ? [
            form => form.timeFrom.value > form.timeTo.value ? intl.formatMessage(messages.timeStartStopError) : null,
        ] : []
    });

    const { data: usersOptions } = getTrackerFiltersUsersQuery();
    const { data: projectsOptions } = getTrackerFiltersProjectsQuery();
    const { data: projectsByUserOptions } = getTrackerFiltersProjectsByUserQuery(form.user.value);
    const { data: tagsOptions } = getTrackerFiltersTagsQuery(form.project.value);

    const onDurationBlur = () => {
        const durationMins = durationParser(form.duration.value);
        let [hours, minutes] = form.timeFrom.value.split(':');
        minutes = parseInt(minutes) + durationMins;
        hours = parseInt(hours) + (minutes > 59 ? Math.floor(minutes / 60) : 0);
        minutes = minutes > 59 ? minutes % 60 : minutes;
        if (hours < 24) {
            form.timeTo.onChange(`${hours < 10 ? `0${hours}` : hours }:${minutes < 10 ? `0${minutes}` : minutes}`);
        }
    }

    const onTimeFromBlur = () => {
        form.timeFrom.onChange(timeParser(form.timeFrom.value));
        if (form.timeFrom.value) {
            if (form.timeFrom.value && form.timeTo.value) {
                const timeFrom = new Date(`${dateToString(date)} ${timeParser(form.timeFrom.value)}`);
                const timeTo = new Date(`${dateToString(date)} ${form.timeTo.value}`);
                const duration = timeTo - timeFrom;
                if (duration>=0) {
                    form.duration.onChange(durationShortFormatter(duration/60000));
                }
            }
        }
    }

    const onTimeToBlur = () => {
        form.timeTo.onChange(timeParser(form.timeTo.value));
        if (form.timeTo.value) {
            if (form.timeFrom.value && form.timeTo.value) {
                const timeFrom = new Date(`${dateToString(date)} ${form.timeFrom.value}`);
                const timeTo = new Date(`${dateToString(date)} ${timeParser(form.timeTo.value)}`);
                const duration = timeTo - timeFrom;
                if (duration>=0) {
                    form.duration.onChange(durationShortFormatter(duration/60000));
                } else {
                    form.timeTo.onChange(timeParser(form.timeFrom.value));
                    form.duration.onChange('0');
                }
            }
        }
    }

    const onDurationSuggestionClick = value => {
        form.duration.onChange(value);
        const durationMins = durationParser(value);
        let [hours, minutes] = form.timeFrom.value.split(':');
        minutes = parseInt(minutes) + durationMins;
        hours = parseInt(hours) + (minutes > 59 ? Math.floor(minutes / 60) : 0);
        minutes = minutes > 59 ? minutes % 60 : minutes;
        if (hours < 24) {
            form.timeTo.onChange(`${hours < 10 ? `0${hours}` : hours }:${minutes < 10 ? `0${minutes}` : minutes}`);
        }
    }

    const {
        users: usersSuggestions,
        titles: titlesSuggestions,
        projects: projectsSuggestions,
        tags: tagsSuggestions,
        durations: durationsSuggestions,
        feedSuggestions
    } = useSuggestions();

    const submitTask = async () => {
        const {
            duration,
            title,
            project,
            tags
        } = form;

        if (!isFormValid) {
            touchForm();
            return
        }

        const durationMins = durationParser(duration.value);
        if (durationMins > 1440) modalOpen('confirmDurationExceeded', {});

        const task = {
            projectId: project.value,
            title: title.value.replace('\t',''),
            durationMins,
            tagIds: tags.value,
            userId: form.user.value || user.id,
            date: dateToString(date)
        };

        if (features?.task_with_from_to) {
            task.timeFrom = form.timeFrom.value;
            task.timeTo = form.timeTo.value;
        }

        await addTask(task)
        analytics.logTimesheetsTrackerTaskCreate({
            descLength: task.title.length,
            tagsLength: task.tagIds.length,
            duration: task.durationMins,
            date
        })

        feedSuggestions('users', task.user || user.id);
        feedSuggestions('titles', task.title);
        feedSuggestions('projects', task.projectId);
        if (task.tagIds.length < 3) {
            task.tagIds.forEach(t => {
                feedSuggestions('tags', t);
            })
        }
        feedSuggestions('durations', durationShortFormatter(task.durationMins));
        cleanForm();
    };

    return (
        <motion.div
            className={style.root}
            variants={{
                active: { opacity: 1, y: 0, display: 'flex' },
                inActive: { opacity: 0, y: 30, transitionEnd: { display: 'none' } }
            }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            animate={active? 'active' : 'inActive'}
            initial="inActive"
        >
            {
                (scope === 'all') ? (
                    <Space mr="s" className={style.user}>
                        <Autocomplete
                            {...form.user}
                            fieldId="timer-user"
                            label={intl.formatMessage(messages.user)}
                            options={usersOptions
                                .map(u => ({
                                    label: (
                                        <Tag
                                            avatar={
                                                <Avatar
                                                    picture={u.avatarUrl}
                                                    firstName={u.firstName}
                                                    lastName={u.lastName}
                                                />
                                            }
                                            text={`${u.firstName} ${u.lastName}`}
                                        />
                                    ),
                                    labelText: `${u.firstName[0]}. ${u.lastName}`,
                                    value: u.userId,
                                }))}
                            disabled={!usersOptions.length}
                            analyticsID="Tracker - Create - User"
                        />
                        <div className={cx([style.suggestions, form.user.error && style.hidden])}>
                            <Suggestions
                                options={usersOptions.map(o => ({
                                    label: `${o.firstName[0]}. ${o.lastName}`,
                                    value: o.id
                                }))}
                                suggestions={usersSuggestions}
                                onClick={e => form.user.onChange(e)}
                                maxNumber={1}
                                analyticsID="Tracker - Create - User"
                            />
                        </div>
                    </Space>
                ) : (
                    <Space mr="s" className={style.avatar}>
                        <Avatar
                            firstName={user.firstName}
                            lastName={user.lastName}
                            picture={user.avatarUrl}
                        />
                    </Space>
                )
            }
            <Space mr="s" className={style.title}>
                <Input
                    {...form.title}
                    fieldId="timer-title"
                    label={intl.formatMessage(messages.whatHaveBeenDone)}
                    analyticsID="Tracker - Create - Description"
                />
                <div className={cx([style.suggestions, form.title.error && style.hidden])}>
                    <Suggestions
                        suggestions={titlesSuggestions}
                        onClick={e => form.title.onChange(e)}
                        maxNumber={3}
                        analyticsID="Tracker - Create - Description"
                    />
                </div>
            </Space>
            <Space mr="s" className={style.project}>
                {
                    (scope === 'all') ? (
                        <>
                            <Autocomplete
                                {...form.project}
                                fieldId="timer-project"
                                label={intl.formatMessage(messages.project)}
                                options={projectsByUserOptions
                                    .map(p => ({
                                        label: (
                                            <ProjectPill {...p} isBillable={p.billable}  />
                                        ),
                                        labelText: p.name,
                                        value: p.id,
                                    }))}
                                disabled={!projectsByUserOptions.length}
                                analyticsID="Tracker - Create - Project"
                            />
                            <div className={cx([style.suggestions, form.project.error && style.hidden])}>
                                <Suggestions
                                    options={
                                        projectsByUserOptions
                                            .map(p => ({
                                                label: p.name,
                                                value: p.id,
                                            }))
                                    }
                                    suggestions={projectsSuggestions}
                                    onClick={e => form.project.onChange(e)}
                                    analyticsID="Tracker - Create - Project"
                                />
                            </div>
                        </>
                    ) : (
                        <>
                            <Autocomplete
                                {...form.project}
                                fieldId="timer-project"
                                label={intl.formatMessage(messages.project)}
                                options={projectsOptions
                                    .map(p => ({
                                        label: (
                                            <ProjectPill {...p}  />
                                        ),
                                        labelText: p.name,
                                        value: p.id,
                                    }))}
                                disabled={!projectsOptions.length}
                                analyticsID="Tracker - Create - Project"
                            />
                            <div className={cx([style.suggestions, form.project.error && style.hidden])}>
                                <Suggestions
                                    options={
                                        projectsOptions
                                            .map(p => ({
                                                label: p.name,
                                                value: p.id,
                                            }))
                                    }
                                    suggestions={projectsSuggestions}
                                    onClick={e => form.project.onChange(e)}
                                    analyticsID="Tracker - Create - Project"
                                />
                            </div>
                        </>
                    )
                }
            </Space>

            <Space mr="s" className={style.tag}>
                <Autocomplete
                    {...form.tags}
                    fieldId="timer-tags"
                    label={intl.formatMessage(messages.tags)}
                    options={tagsOptions.map(t => ({
                        label: <Tag text={t.name} />,
                        labelText: t.name,
                        value: t.id,
                    }))}
                    noOptionsText={
                        form.project.value
                            ? undefined
                            : intl.formatMessage(messages.pleaseSelect)
                    }
                    disabled={!form.project.value}
                    multiselect
                    type="chips"
                    analyticsID="Tracker - Create - Tags"
                />
                <div className={cx([style.suggestions, form.tags.error && style.hidden])}>
                    <Suggestions
                        options={
                            tagsOptions
                                .map(t => ({
                                    label: t.name,
                                    value: t.id,
                                }))
                        }
                        suggestions={tagsSuggestions}
                        onClick={e => form.tags.onChange([e])}
                        analyticsID="Tracker - Create - Tags"
                    />
                </div>
            </Space>
            {
                features?.task_with_from_to && (
                    <>
                        <Space className={style.time}>
                            <Input
                                {...form.timeFrom}
                                onBlur={onTimeFromBlur}
                                fieldId="timer-time-start"
                                label={intl.formatMessage(messages.timeStart)}
                                analyticsID="Tracker - Create - Time start"
                            />
                        </Space>
                        <Space className={style.timeDash} mh="xxs" pt="s">-</Space>
                        <Space mr="s" className={style.time}>
                            <Input
                                {...form.timeTo}
                                onBlur={onTimeToBlur}
                                fieldId="timer-time-stop"
                                label={intl.formatMessage(messages.timeStop)}
                                analyticsID="Tracker - Create - Time stop"
                            />
                        </Space>
                    </>
                )
            }
            <Space mr="s" className={style.duration}>
                <Input
                    {...form.duration}
                    onBlur={onDurationBlur}
                    fieldId="timer-duration"
                    label={intl.formatMessage(messages.duration)}
                    analyticsID="Tracker - Create - Duration"
                />
                <div className={cx([style.suggestions, form.duration.error && style.hidden])}>
                    <Suggestions
                        maxNumber={3}
                        suggestions={durationsSuggestions}
                        onClick={onDurationSuggestionClick}
                        analyticsID="Tracker - Create - Duration"
                    />
                </div>
            </Space>
            <div className={style.button}>
                <Tooltip text={formError}>
                    <Button
                        icon="plus"
                        size="large"
                        iconSize="medium"
                        color="blue"
                        onClick={submitTask}
                        onDisabledClick={touchForm}
                        disabled={!isFormValid || !form.duration.value}
                        shadow
                    />
                </Tooltip>
            </div>
        </motion.div>
    );
}

TimeEntryForm.propTypes = {
    scope: PropTypes.oneOf(['my', 'all']),
    date: PropTypes.instanceOf(Date).isRequired,
    active: PropTypes.bool.isRequired,
};

export default TimeEntryForm;