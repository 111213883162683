import fetch from "../utils/fetch";

const dateTimeReviewer = (key, value) => {
    let a;
    if (typeof value === 'string') {
        a = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/.exec(value);
        if (a) {
            return new Date(value);
        }
    }
    return value;
}

const
    // LS = local storage
    LSset = (key, value) => {
        const db = JSON.parse(window.localStorage.getItem('TSStorage')) || {};
        db[key] = value;
        window.localStorage.setItem('TSStorage', JSON.stringify(db));
    },
    LSget = key => {
        const db = JSON.parse(window.localStorage.getItem('TSStorage'), dateTimeReviewer) || {};
        return db[key];
    },
    // LSW = local storage per Workspace
    LSWset = (key, value) => {
        const user = LSget('user');
        const workspace = LSget('workspace');
        const db = JSON.parse(window.localStorage.getItem('TSWorkspaceStorage')) || {};
        if (!db[user.id]) db[user.id] = {};
        if (!db[user.id][workspace]) db[user.id][workspace] = {};
        db[user.id][workspace][key] = value;
        window.localStorage.setItem('TSWorkspaceStorage', JSON.stringify(db));
    },
    LSWget = key => {
        const user = LSget('user');
        const workspace = LSget('workspace');
        const db = JSON.parse(window.localStorage.getItem('TSWorkspaceStorage'), dateTimeReviewer) || {};
        return db?.[user.id]?.[workspace]?.[key];
    },
    APIset = (userId, key, value) =>
        fetch.patch(`/users/${userId}/settings`, { [key]: value }),
    APIget = async (userId, key) => {
        if (!userId) return;

        const { data } = await fetch.get(`/users/${userId}/settings`);
        return data[key]
    }

export default {
    LSset,
    LSget,
    LSWset,
    LSWget,
    APIset,
    APIget
};
