import React from 'react';

import useUI from "@quidlo/common/hooks/useUI";
import { Toaster } from "@quidlo/ui";

const Toasts = () => {
    const { toasts } = useUI();

    return (<Toaster toasts={toasts} />);
}

export default Toasts;