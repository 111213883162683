import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { AnimatePresence } from "framer-motion";

import { WORKSPACE_ROLE } from "@quidlo/common/constants/main";
import useUI from "@quidlo/common/hooks/useUI";
import useWorkspace from "@quidlo/common/hooks/useWorkspace";

import { getWorkspaceSubscriptionQuery } from "../../../queries/workspace.queries";
import ApprovalList from '../../Approvals';
import ApprovalCreate from '../../Approvals/ApprovalCreate';
import ApprovalDetails from '../../Approvals/ApprovalDetails';
import MyApprovals from "../../Approvals/MyRequests/MyRequests";
import PotentialApprovalDetails from '../../Approvals/PotentialApprovalDetails';
import ApprovalRequest from "../../Approvals/ToApprove";
import ChatbotList from "../../Chatbots/ChatbotList";
import Error404 from '../../Errors/Error404';
import GoogleChatConfigure from "../../Integrations/GoogleChatConfigure";
import GoogleChatConnect from "../../Integrations/GoogleChatConnect";
import SlackConnect from "../../Integrations/SlackConnect";
import PayWall from '../../PayWall';
import Profile from '../../Profile/Profile';
import ProjectsEdit from '../../Projects/Edit';
import ProjectsList from '../../Projects/List';
import ReportsCreate from '../../Reports/Create';
import ReportsShared from '../../Reports/Shared';
import Settings from '../../Settings';
import ApiKeys from "../../Settings/ApiKeys";
import Features from '../../Settings/Features';
import Subscription from '../../Settings/Subscription';
import Workspace from '../../Settings/Workspace';
import Tracker from '../../Tracker';
import Users from '../../Users';
import UsersDelete from '../../UsersDelete';

const
    Routing = () => {
        const location = useLocation();
        const { data: subscription } = getWorkspaceSubscriptionQuery();
        const { workspace } = useWorkspace();
        const { modalOpen } = useUI();

        useEffect(() => {
            if (subscription?.isExpired && workspace?.organizationRole !== WORKSPACE_ROLE.OWNER) {
                modalOpen('subscriptionExpired');
            }
        }, [subscription, workspace])

        return (
            <AnimatePresence mode="wait">
                <Routes location={location} key={location.pathname}>
                    <Route exact path="/" element={<Tracker />} />
                    <Route exact path="tracker" element={<Tracker />} />
                    <Route exact path="projects" element={<ProjectsList />} />
                    <Route exact path="projects/:id" element={<ProjectsEdit />} />
                    <Route exact path="reports" element={<ReportsCreate />} />
                    <Route exact path="reports/shared/:code" element={<ReportsShared />} />

                    <Route exact path="approvals/list" element={<ApprovalList />} >
                        <Route exact path='my' element={<MyApprovals />} />
                        <Route exact path='requests' element={<ApprovalRequest />} />
                        <Route index element={<MyApprovals />} />
                    </Route>
                    <Route exact path="approvals/create" element={<ApprovalCreate />} />
                    <Route exact path="approvals/create/details/:from/:to/:id" element={<PotentialApprovalDetails />} />
                    <Route exact path="approvals/details/:id" element={<ApprovalDetails />} />
                    <Route exact path="approvals/requested/details/:id" element={<ApprovalDetails />} />
                    <Route exact path="users" element={<Users />} />
                    <Route exact path="delete-users" element={<UsersDelete />} />
                    <Route exact path="settings/apikeys" element={<ApiKeys />} />
                    <Route exact path="settings" element={<Settings />} >
                        <Route exact path='features' element={<Features />} />
                        <Route exact path='workspace' element={<Workspace />} />
                        <Route exact path='subscription' element={<Subscription />} />
                        <Route index element={<Workspace />} />
                    </Route>
                    <Route exact path="paywall" element={<PayWall />} />
                    <Route exact path="profile" element={<Profile />} />
                    <Route exact path="integrations/chatbots" element={<ChatbotList />} />
                    <Route exact path="integrations/chatbots/list" element={<ChatbotList />} />
                    <Route exact path="integrations/googlechat/connect" element={<GoogleChatConnect />} />
                    <Route exact path="integrations/googlechat/configure" element={<GoogleChatConfigure />} />
                    <Route exact path="integrations/slack/connect" element={<SlackConnect />} />

                    <Route path="*" element={<Error404 />} />
                </Routes>
            </AnimatePresence>
        );
    };

export default React.memo(Routing);
