import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import style from './NotificationLabel.module.scss';

const NotificationLabel = ({ title, description }) =>
    (
        <div className={cx(style.notificationLabel)}>
            <div className={cx(style.title)}>
                <span>
                    {title}
                </span>
            </div>
            <div className={cx(style.description)}>
                <span>
                    {description}
                </span>
            </div>
        </div>
    );

NotificationLabel.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
};

export default NotificationLabel;
