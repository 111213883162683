import React, { Component } from 'react';
import cx from 'classnames';
import deepequal from 'deep-equal';
import PropTypes from 'prop-types';

import COLORS from '@quidlo/common/constants/colors';

import style from './ListRow.module.scss';

class ListRow extends Component {
    shouldComponentUpdate(newProps) {
        return (
            !deepequal(this.props.data, newProps.data) ||
            this.props.status !== newProps.status
        );
    }

    render() {
        const {
                data,
                status,
                renderRow,
                width,
                hoverable,
                color,
                onFocus,
            } = this.props,
            props = {};
        if (onFocus) {
            props.onFocus = () => onFocus(this.element);
            props.role = 'button';
            props.tabIndex = '0';
        }

        return (
            <div
                className={cx(
                    style.listRow,
                    style[`status-${status}`],
                    hoverable && style.hoverable,
                    color && style.color,
                )}
                {...props}
                style={{
                    width,
                    borderColor: data.isArchived
                        ? COLORS.PROJECT_ARCHIVED_COLOR
                        : color,
                }}
                ref={ref => {
                    this.element = ref;
                }}>
                {color && (
                    <div
                        className={style.label}
                        style={{
                            backgroundColor: data.isArchived
                                ? COLORS.PROJECT_ARCHIVED_COLOR
                                : color,
                        }}
                    />
                )}
                {renderRow(data, status)}
            </div>
        );
    }
}

ListRow.propTypes = {
    data: PropTypes.object.isRequired,
    status: PropTypes.string,
    renderRow: PropTypes.func.isRequired,
    width: PropTypes.string,
    hoverable: PropTypes.bool,
    color: PropTypes.string,
    onFocus: PropTypes.func,
};

ListRow.defaultProps = {
    status: 'normal',
    width: '100%',
    hoverable: true,
    color: 'transparent',
    onFocus: undefined,
};

export default ListRow;
