import { defineMessages } from 'react-intl';

export default defineMessages({
    downloadCSV: {
        id: 'exportMenu.downloadCSV',
        defaultMessage: 'Download CSV file'
    },
    downloadPDF: {
        id: 'reportCreate.downloadPDF',
        defaultMessage: 'Download PDF file'
    },
    downloadXLSX: {
        id: 'reportCreate.downloadXLSX',
        defaultMessage: 'Download Excel file'
    },
    exportToGoogleSheets: {
        id: 'reportCreate.exportToGoogleSheets',
        defaultMessage: 'Export to Google Sheets'
    },
});