import { defineMessages } from 'react-intl';

export default defineMessages({
    inviteUsers: {
        id: 'inviteUsers.inviteUsers',
        defaultMessage: 'Invite users',
    },
    addUserEmail: {
        id: 'inviteUsers.addUserEmail',
        defaultMessage: 'Enter e-mail addresses:',
    },
    invite: {
        id: 'inviteUsers.invite',
        defaultMessage: 'Invite',
    },
    cancel: {
        id: 'inviteUsers.cancel',
        defaultMessage: 'Cancel',
    },
    userEmails: {
        id: 'inviteUsers.userEmails',
        defaultMessage: 'Users e-mails',
    },
    emailAlreadyInvited: {
        id: 'inviteUsers.emailAlreadyInvited',
        defaultMessage: 'This email is already invited: {emails}',
    },
    emailsAlreadyInvited: {
        id: 'inviteUsers.emailsAlreadyInvited',
        defaultMessage: 'These emails are already invited: {emails}',
    },
    starterinfo1: {
        id: 'inviteUsers.starterinfo1',
        defaultMessage: 'You are allowed to invite up to <b>10 users in your plan</b>.',
    },
    upgrade: {
        id: 'inviteUsers.upgrade',
        defaultMessage: 'UPGRADE',
    },
    starterinfo2: {
        id: 'inviteUsers.starterinfo2',
        defaultMessage: 'your plan to add more!',
    },
});

