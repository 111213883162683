import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import COLORS from '@quidlo/common/constants/colors';
import { stringToInteger } from '@quidlo/common/utils/stringToInteger';

import avatars from './DefaultAvatars';

import styles from './Avatar.module.scss';

const isImage = url => {
        return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url) || url.includes('google');
    },
    Avatar = ({ picture, firstName, lastName }) => {
        const sig =
            firstName && lastName
                ? `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`
                : '',
            color = COLORS.PREDEFINED_COLORS[stringToInteger(`${firstName} ${lastName}`, COLORS.PREDEFINED_COLORS.length)],
            avatar = avatars[stringToInteger(`${firstName} ${lastName}`, avatars.length)];

        return (
            <div
                className={cx(styles.avatar, styles.signature)}
                style={{ '--color': color.color, borderColor: color.color }}>
                <span>{sig}</span>
                {
                    picture && isImage(picture) && (
                        <img src={picture}/>
                    )
                }
                {
                    !picture && !sig.length && (
                        <img src={avatar}/>
                    )
                }
            </div>
        );
    };

Avatar.propTypes = {
    picture: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
};

Avatar.defaultProps = {
    picture: undefined,
    firstName: undefined,
    lastName: undefined,
};

export default Avatar;
