import { addDays, setWeekday } from "../../utils/dateHelpers";

const predefinedModes = {
    weekToDate: {
        name: 'weekToDate',
        label: 'Week to date',
        getValue: ({ weekStart }) => {
            const today = new Date();
            return ({
                gte: setWeekday(today, 0, weekStart),
                lte: today,
            })
        },
    },
    lastWeek: {
        name: 'lastWeek',
        label: 'Last week',
        getValue: ({ weekStart }) => {
            const lastweek = addDays(new Date(), -7);
            return ({
                gte: setWeekday(lastweek, 0, weekStart),
                lte: setWeekday(lastweek, 6, weekStart),
            });
        },
    },
    monthToDate: {
        name: 'monthToDate',
        label: 'Month to date',
        getValue: () => {
            const month = (new Date()).getMonth(),
                year = (new Date()).getFullYear();
            return ({
                gte: new Date(year, month, 1),
                lte: new Date(),
                mode: 'monthToDate'
            });
        },
    },
    lastMonth: {
        name: 'lastMonth',
        label: 'Last month',
        getValue: () => {
            const month = (new Date()).getMonth(),
                year = (new Date()).getFullYear();
            return ({
                gte: new Date(year, month - 1, 1),
                lte: new Date(year, month, 0),
                mode: 'lastMonth'
            });
        },
    }
}

export default predefinedModes;