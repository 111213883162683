import { defineMessages } from 'react-intl';

export default defineMessages({
    subscriptionSettings: {
        id: 'settings.subscriptionSettings',
        defaultMessage: 'Subscription settings',
    },
    starterTitle: {
        id: 'subscription.starterTitle',
        defaultMessage: 'Starter',
    },
    starterSubtitle: {
        id: 'subscription.starterSubtitle',
        defaultMessage: 'For small teams to achieve big dreams',
    },
    starterDescription: {
        id: 'subscription.starterDescription',
        defaultMessage: 'You are currently using a STARTER Free Plan, in which you can have up to 10 users in your workspace. Find info about plans',
    },
    starterAlt: {
        id: 'subscription.starterAlt',
        defaultMessage: 'Starter',
    },
    trialTitle: {
        id: 'subscription.trialTitle',
        defaultMessage: 'FREE TRIAL - {days} {dayForm} left',
    },
    trialSubtitle: {
        id: 'subscription.trialSubtitle',
        defaultMessage: 'For companies that need a reliable time tracker',
    },
    trialDescription: {
        id: 'subscription.trialDescription',
        defaultMessage: 'Currently you are using the FREE TRIAL of the PRO plan, in which you can add unlimited number of users to your workspace, as well as take advantage of the additional features. You can upgrade to the PRO plan anytime. Find info about plans',
    },
    trialAlt: {
        id: 'subscription.trialAlt',
        defaultMessage: 'Trial',
    },
    proTitle: {
        id: 'subscription.proTitle',
        defaultMessage: 'PRO',
    },
    proSubtitle: {
        id: 'subscription.proSubtitle',
        defaultMessage: 'For companies that need a reliable time tracker',
    },
    proDescription: {
        id: 'subscription.proDescription',
        defaultMessage: 'You can cancel your subscription plan at any time. If you choose to cancel, you will continue to have access to PRO PLAN until the end of your current subscription period and won’t be charged again. By cancelling the subscription you will be transfered to STARTER PLAN, where you can have up to 10 users in the workspace. Find info about plans',
    },
    proAlt: {
        id: 'subscription.proAlt',
        defaultMessage: 'Pro',
    },
    appSumoTitle: {
        id: 'subscription.appSumoTitle',
        defaultMessage: 'PRO',
    },
    appSumoSubtitle: {
        id: 'subscription.appSumoSubtitle',
        defaultMessage: 'Lifetime access',
    },
    appSumoDescription: {
        id: 'subscription.appSumoDescription',
        defaultMessage: 'You are currently enrolled in our subscription plan with lifetime access! You can cancel your subscription at any time. If you choose to cancel, you will continue to have access to PRO PLAN until the end of your current subscription period and won’t be charged again. By cancelling the subscription you will be transfered to STARTER PLAN, where you can have up to 10 users in the workspace. Find info about plans ',
    },
    appSumoAlt: {
        id: 'subscription.appSumoAlt',
        defaultMessage: 'Pro',
    },
    here: {
        id: 'subscription.here',
        defaultMessage: 'here',
    },
    manageSubscription: {
        id: 'subscription.manageSubscription',
        defaultMessage: 'Manage subscription',
    },
    upgrade: {
        id: 'subscription.upgrade',
        defaultMessage: 'Upgrade',
    },
    youAreCurrentlyUsing: {
        id: 'subscription.youAreCurrentlyUsing',
        defaultMessage: 'You are currently using a PRO PLAN  started on {valid_from} and ends {until_on} {valid_to}.',
    },
    youAreCurrentlyEnrolled: {
        id: 'subscription.youAreCurrentlyEnrolled',
        defaultMessage: 'You are currently enrolled in our subscription plan with lifetime access!',
    },
    endOfTheWorld: {
        id: 'subscription.endOfTheWorld',
        defaultMessage: 'the end of the world...'
    },
    until: {
        id: 'subscription.until',
        defaultMessage: 'until',
    },
    to: {
        id: 'subscription.to',
        defaultMessage: 'to',
    },
    days: {
        id: 'subscription.days',
        defaultMessage: 'days',
    },
    day: {
        id: 'subscription.day',
        defaultMessage: 'day',
    },
    no: {
        id: 'subscription.no',
        defaultMessage: 'no',
    },
});