import React, { Fragment } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { durationFormatter } from '@quidlo/common/utils/duration';
import { Avatar, Icon, InfoCard, Pill, Tag, Tooltip } from '@quidlo/ui';

import { ApprovalStatus, List, ListCell } from '../../../../ui';
import noApprovalsPNG from '../assets/noApprovals.png';

import style from './MyRequestsList.module.scss';

import messages from './MyRequestsList.i18n';

const MyRequestsList = ({ data, filters }) => {
    const intl = useIntl();

    if (!data?.list?.length && data?.from) {
        return (
            <div className={style.infoCardWrapper}>
                <InfoCard
                    header={intl.formatMessage(messages.noDataHeader)}
                    text={intl.formatMessage(messages.noDataBody)}
                    illustration={<img src={noApprovalsPNG} alt="a man at a desk" />}
                />
            </div>
        )
    }

    const renderRow = ({
        project: {
            name,
            color,
            is_billable
        },
        id,
        status,
        request_date,
        from,
        to,
        tags,
        duration_mins,
        user: {
            first_name,
            last_name,
            avatar_url
        }
    }) => {

        return (
            <Fragment>
                <ListCell width="224px" align="left">
                    <Tag text={name} color={color} type={'stroked'} icon={is_billable ? 'dollar' : ''} />
                </ListCell>
                <ListCell width="144px">
                    <ApprovalStatus status={status} />
                </ListCell>
                <ListCell width="112px" align='center'>
                    <FormattedDate value={request_date} weekday={"short"} />{', '}
                    <FormattedDate value={request_date} month="short" />{' '}
                    <FormattedDate value={request_date} day="2-digit" />
                </ListCell>
                <ListCell width="72px" align='center'>
                    <Tooltip text={`${first_name} ${last_name}`}>
                        <Avatar picture={avatar_url} firstName={first_name} lastName={last_name} />
                    </Tooltip>
                </ListCell>
                <ListCell width="144px" align='center'>
                    <FormattedDate value={from} month="short" />{' '}
                    <FormattedDate value={from} day="2-digit" /> -{' '}
                    <FormattedDate value={to} month="short" />{' '}
                    <FormattedDate value={to} day="2-digit" />
                </ListCell>
                <ListCell align="left">
                    <span>
                        {
                            tags.length === 2 && tags[0].name.length <= 6 && tags[1].name.length <= 6 ?
                                <span>
                                    <Tooltip text={tags[0].name} key={tags[0].id}>
                                        <Tag text={tags[0].name} style={{ maxWidth: '94px' }} />
                                    </Tooltip>
                                    <Tooltip text={tags[1].name} key={tags[1].id}>
                                        <Tag text={tags[1].name} style={{ maxWidth: '94px' }} />
                                    </Tooltip>
                                </span>
                                : tags.length > 1 && tags[0].name.length <= 3 && tags[1].name.length <= 3 ?
                                    <span>
                                        <Tooltip text={tags[0].name} key={tags[0].id}>
                                            <Tag text={tags[0].name} style={{ maxWidth: '94px' }} />
                                        </Tooltip>
                                        <Tooltip text={tags[1].name} key={tags[1].id}>
                                            <Tag text={tags[1].name} style={{ maxWidth: '94px' }} />
                                        </Tooltip>
                                        <Tooltip text={tags.slice(2)
                                            .map(tag => tag.name)
                                            .join(', ')}
                                        >
                                            <Tag text={`+${tags.length - 2}`} style={{ maxWidth: '94px' }} />
                                        </Tooltip>
                                    </span>
                                    : tags.length > 1 && tags[0].name.length > 5 ?
                                        <span>
                                            <Tooltip text={tags[0].name} key={tags[0].id}>
                                                <Tag text={tags[0].name} style={{ maxWidth: '94px' }} />
                                            </Tooltip>
                                            <Tooltip text={tags.slice(1)
                                                .map(tag => tag.name)
                                                .join(', ')}
                                            >
                                                <Tag text={`+${tags.length - 1}`} style={{ maxWidth: '94px' }} />
                                            </Tooltip>
                                        </span>
                                        : tags.length === 1 && tags[0].name.length > 15 ?
                                            <Tooltip text={tags[0].name} key={tags[0].id}>
                                                <Tag text={tags[0].name} style={{ maxWidth: '140px' }} />
                                            </Tooltip>
                                            : ((tags.length === 1 && tags[0].name.length <= 15) || (tags.length > 1 && tags[0].name.length < 5)) ?
                                                <span>
                                                    {tags.length > 0 && <Tag key={tags[0].id} text={tags[0].name} />}
                                                    {tags.length > 1 &&
                                                        <Tooltip text={tags.slice(1)
                                                            .map(tag => tag.name)
                                                            .join(', ')}
                                                        >
                                                            <Tag text={`+${tags.length - 1}`} style={{ maxWidth: '94px' }} />
                                                        </Tooltip>
                                                    }
                                                </span>
                                                : <span>
                                                    {tags.length > 0 && <Tag key={tags[0].id} text={tags[0].name}
                                                        style={{ maxWidth: '90px' }} />}
                                                    {tags.length > 1 &&
                                                        <Tooltip text={tags.slice(1)
                                                            .map(tag => tag.name)
                                                            .join(', ')}
                                                        >
                                                            <Tag text={`+${tags.length - 1}`} style={{ maxWidth: '94px' }} />
                                                        </Tooltip>
                                                    }
                                                </span>
                        }
                    </span>
                </ListCell>
                <ListCell width="96px" align="center">
                    {durationFormatter(duration_mins)}
                </ListCell>
                <ListCell width="96px" align="center" last>
                    <Link to={`/approvals/details/${id}`} >
                        <Icon icon={'caret-right'} color={'blue'} size={'small'} />
                    </Link>
                </ListCell>
            </Fragment>
        );
    };

    return (
        <div className={style.row}>
            <List
                key={JSON.stringify(filters)}
                title={intl.formatMessage(messages.all)}
                rightChildren={(<Pill size="small" text={`${intl.formatMessage(messages.total)}  ${durationFormatter(data.duration_mins_sum)}`} />)}
                header={(
                    <Fragment>
                        <ListCell width='224px' align="center" header>
                            <FormattedMessage {...messages.project} />
                        </ListCell>
                        <ListCell width='144px' align="center" header>
                            <FormattedMessage {...messages.status} />
                        </ListCell>
                        <ListCell width='112px' align="center" header>
                            <FormattedMessage {...messages.requestDate} />
                        </ListCell>
                        <ListCell width='72px' align="center" header>
                            <FormattedMessage {...messages.person} />
                        </ListCell>
                        <ListCell width='144px' align="center" header>
                            <FormattedMessage {...messages.period} />
                        </ListCell>
                        <ListCell align="center" header>
                            <FormattedMessage {...messages.tags} />
                        </ListCell>
                        <ListCell width="96px" align="center" header>
                            <FormattedMessage {...messages.duration} />
                        </ListCell>
                        <ListCell width="96px" align="center" header>
                            <FormattedMessage {...messages.details} />
                        </ListCell>
                    </Fragment>
                )}
                data={data.list}
                renderRow={renderRow}
            />
        </div>
    );

};

MyRequestsList.propTypes = {
    filters: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
};

export default MyRequestsList;
