import React, { PureComponent } from 'react';
import deepEqual from 'deep-equal';
import PropTypes from 'prop-types';

import { Button, Space } from "@quidlo/ui";

import ListFooter from '../ListFooter';
import ListHeader from '../ListHeader';
import ListRow from '../ListRow';
import ListRowNoResult from '../ListRowNoResult';

import List from './List';

const CURSOR_INCREMENT = 100;

class ListContainer extends PureComponent {
    constructor(props) {
        super(props);
        const
            data = {},
            dataStatus = {},
            ids = [];

        for (let i = 0, len = props.data.length; i < len; i += 1) {
            const { id } = props.data[i];
            dataStatus[id] = 'normal';
            data[id] = props.data[i];
            ids.push(id);
        }

        this.state = {
            cursor: CURSOR_INCREMENT,
            data,
            dataStatus,
            ids,
            activeRowElement: undefined
        };

        this.activeRow = this.activeRow.bind(this);
        this.keyupHandler = this.keyupHandler.bind(this);
        this.clickHandler = this.clickHandler.bind(this);
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (this.props.autostatus) {
            const { data, dataStatus, ids } = this.state,
                newDataStatus = {};

            for (let i = 0, len = newProps.data.length; i < len; i += 1) {
                const { id } = newProps.data[i];
                if (!dataStatus[id]) {
                    newDataStatus[id] = 'added';
                    ids.push(id);
                } else if (dataStatus[id] === 'added') {
                    newDataStatus[id] = 'added';
                } else if (dataStatus[id] === 'edit') {
                    newDataStatus[id] = 'edit';
                } else if (!deepEqual(data[id], newProps.data[i])) {
                    newDataStatus[id] = 'changed';
                } else {
                    newDataStatus[id] = 'normal';
                }

                data[id] = newProps.data[i];
            }

            for (let i = 0, len = ids.length; i < len; i += 1) {
                if (!newDataStatus[ids[i]]) {
                    newDataStatus[ids[i]] = 'deleted';
                }
            }
            this.setState({
                data,
                dataStatus: newDataStatus,
                ids
            });
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keyup', this.keyupHandler);
    }

    keyupHandler(e) {
        if ((e.keyCode || e.which) === 27 || (e.keyCode || e.which) === 13) {
            if (document && document.activeElement) document.activeElement.blur();
            this.activeRow();
        }
    }

    clickHandler(e) {
        if (e.type === 'click' && this.state.activeRowElement && !this.state.activeRowElement.contains(e.target)) {
            this.activeRow();
        }
    }

    activeRow(row = undefined, element = undefined) {
        document.removeEventListener('keyup', this.keyupHandler);
        document.removeEventListener('click', this.clickHandler);
        if (row) {
            document.addEventListener('keyup', this.keyupHandler);
            document.addEventListener('click', this.clickHandler);

            this.setState(({ dataStatus, activeRow }) => ({
                dataStatus: {
                    ...dataStatus,
                    [activeRow]: 'normal',
                    [row]: 'edit'
                },
                activeRow: row,
                activeRowElement: element
            }));
        } else {
            this.setState(({ dataStatus, activeRow }) => ({
                dataStatus: {
                    ...dataStatus,
                    [activeRow]: 'normal'
                },
                activeRowElement: undefined
            }));
        }
    }

    render() {
        const
            {
                data,
                dataStatus,
                ids,
            } = this.state,
            {
                header,
                footer,
                renderRow,
                editable,
                autostatus,
            } = this.props,
            props = {
                rightChildren: this.props.rightChildren,
                title: this.props.title,
                color: this.props.color,
                icon: this.props.icon,
                rightIcon: this.props.rightIcon,
                active: !!this.state.activeRowElement,
                noListShadow: this.props.noListShadow,
            };

        if (autostatus) {
            return (
                <List {...props}>
                    {header && (
                        <ListHeader>
                            {header}
                        </ListHeader>
                    )}
                    {this.props.data.length ? ids.map(id => {
                        const
                            row = data[id],
                            rowProps = {
                                data: row,
                                status: dataStatus[id],
                                renderRow,
                                color: row.color
                            };
                        if (editable) {
                            rowProps.onFocus = element => this.activeRow(row.id, element);
                        }
                        return (
                            <ListRow key={row.id} {...rowProps} />
                        );
                    }) : (
                        <ListRowNoResult noResults={this.props.noResultsText} />
                    )}
                    {
                        footer && (
                            <ListFooter>
                                {footer}
                            </ListFooter>
                        )
                    }
                </List>
            );
        }
        return (
            <>
                <List {...props}>
                    {header && (
                        <ListHeader hidden={!this.props.data.length}>
                            {header}
                        </ListHeader>
                    )}
                    {this.props.data.length ? this.props.data.slice(0, this.state.cursor).map(row => {
                        const
                            rowProps = {
                                data: row,
                                renderRow,
                            };
                        if (editable) {
                            rowProps.onFocus = element => this.activeRow(row.id, element);
                        }
                        return (
                            <ListRow key={row.id} {...rowProps} />
                        );
                    }) : (
                        <ListRowNoResult noResults={this.props.noResultsText} />
                    )}
                    {
                        footer && (
                            <ListFooter>
                                {footer}
                            </ListFooter>
                        )
                    }
                </List>
                {
                    this.props.data.length > this.state.cursor && (
                        <Space mt={"s"} direction={'row'} style={{ justifyContent: 'center' }}>
                            <Button color="white" type={'bordered'} onClick={() => this.setState({ cursor: this.state.cursor + CURSOR_INCREMENT })} text={`Display more (${this.props.data.length - this.state.cursor})`} />
                        </Space>
                    )
                }
            </>

        );
    }
}

ListContainer.propTypes = {
    title: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    color: PropTypes.string,
    icon: PropTypes.string,
    rightIcon: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    data: PropTypes.array.isRequired,
    header: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    footer: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    rightChildren: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    renderRow: PropTypes.func.isRequired,
    autostatus: PropTypes.bool,
    editable: PropTypes.bool,
    noResultsText: PropTypes.string,
    noListShadow: PropTypes.bool,
};

ListContainer.defaultProps = {
    title: undefined,
    color: undefined,
    icon: undefined,
    rightIcon: undefined,
    rightChildren: undefined,
    footer: undefined,
    autostatus: true,
    editable: false,
    noResultsText: undefined,
    header: undefined,
    noListShadow: false
};

export default ListContainer;
