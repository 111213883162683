import React, { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import useWorkspace from "@quidlo/common/hooks/useWorkspace";
import { dateToString } from '@quidlo/common/utils/dateHelpers';
import { durationFormatter } from '@quidlo/common/utils/duration';
import { Counter, Pill, Space, Tooltip } from '@quidlo/ui';

import { ApprovalStatus, ListCell, ListDocked } from '../../../../ui';
import ProjectPill from "../../../../ui/ProjectPill/ProjectPill";
import { periodToDate, periodToString } from '../../../../utils/periodString';
import { filterStatuses } from '../utils';

import style from '../TimeView/TimeView.module.scss';

import messages from './PeopleView.i18n';

const PeopleView = ({ data, openReport, isStatus }) => {
    const intl = useIntl();
    const { workspace } = useWorkspace();
    const weekStart = workspace?.weekStart || 'monday';

    return (
        <div>
            <Space mv="m">
                <div className={style.totalTimeWrapper}>
                    <Pill color="white" text={`${intl.formatMessage(messages.totalTimeInAllProjects)} ${durationFormatter(data.durationSummaryMins || 0)}`} size="small" />
                </div>
            </Space>
            {data.users.map(user => (
                <Space mv="m" key={user.id}>
                    <ListDocked
                        title={`${user.firstName} ${user.lastName}`}
                        rightChildren={
                            <Pill
                                text={`${intl.formatMessage(messages.total)} ${durationFormatter(user.durationSummaryMins || 0)}`}
                                size="small"
                            />
                        }
                        headerDock={
                            <Fragment>
                                {isStatus &&
                                    <ListCell width="200px" align="center" header>
                                        <FormattedMessage {...messages.status} />
                                    </ListCell>
                                }
                                <ListCell width="224px" align="center" header>
                                    <FormattedMessage {...messages.project} />
                                </ListCell>
                                <ListCell align="center" header>
                                    <FormattedMessage {...messages.duration} />
                                </ListCell>
                            </Fragment>
                        }
                        header={
                            <Fragment>
                                {user.projects[0].periods.map(({ periodCode }) => (
                                    <ListCell key={periodCode} align="center">
                                        {periodToString(
                                            periodCode,
                                            intl,
                                            false,
                                            weekStart,
                                        )}
                                    </ListCell>
                                ))}
                            </Fragment>
                        }
                        footerDock={
                            <Fragment>
                                <ListCell width="224px" align="left" footer>
                                    <FormattedMessage {...messages.summary} />
                                </ListCell>
                                {isStatus && <ListCell width="200px" align="center" />}
                                <ListCell align="center" footer>
                                    {durationFormatter(user.durationSummaryMins || 0)}
                                </ListCell>
                            </Fragment>
                        }
                        footer={
                            <Fragment>
                                {user.periods.map(
                                    ({ periodCode, durationSummaryMins }) => {
                                        const periodDates =
                                            periodToDate(periodCode);
                                        return (
                                            <ListCell
                                                key={periodCode}
                                                align="center"
                                                footer
                                                onClick={() =>
                                                    openReport(
                                                        {
                                                            gte: dateToString(periodDates[0]),
                                                            lte: dateToString(periodDates[1]),
                                                        },
                                                        undefined,
                                                        undefined,
                                                        [user.id],
                                                        'tasks',
                                                        undefined,
                                                        undefined,
                                                    )
                                                }>
                                                {durationFormatter(
                                                    durationSummaryMins || 0,
                                                )}
                                            </ListCell>
                                        );
                                    },
                                )}
                            </Fragment>
                        }
                        data={user.projects}
                        renderRowDock={({
                            id,
                            name,
                            color,
                            isBillable,
                            isArchived,
                            durationSummaryMins,
                            approval_statuses
                        }) => {
                            const statuses = filterStatuses(approval_statuses);
                            return (
                                <Fragment>
                                    {isStatus &&
                                        <ListCell width="200px" align="center">
                                            <Space direction="row" style={{ justifyContent: "center" }}>
                                                <ApprovalStatus status={statuses[0]} />
                                                {statuses.length > 1 &&
                                                    <Space ml={"xxs"}>
                                                        <Tooltip text={statuses.slice(1).map(a => a.charAt(0).toUpperCase() + a.slice(1)).join(', ')}>
                                                            <Counter number={statuses.length - 1} />
                                                        </Tooltip>
                                                    </Space>
                                                }
                                            </Space>
                                        </ListCell>
                                    }
                                    <ListCell
                                        width="224px"
                                        onClick={() =>
                                            openReport(
                                                undefined,
                                                [id],
                                                undefined,
                                                [user.id],
                                                'tasks',
                                                undefined,
                                                undefined,
                                            )
                                        }>
                                        <ProjectPill color={color} name={name} isBillable={isBillable} isArchived={isArchived}  />
                                    </ListCell>
                                    <ListCell align="center">
                                        {durationFormatter(durationSummaryMins || 0)}
                                    </ListCell>
                                </Fragment>
                            )}}
                        renderRow={({ id, periods }) => (
                            <Fragment>
                                {periods.map(
                                    ({ periodCode, durationSummaryMins }) => {
                                        if (durationSummaryMins > 0) {
                                            const periodDates =
                                                periodToDate(periodCode);
                                            return (
                                                <ListCell
                                                    key={periodCode}
                                                    align="center"
                                                    onClick={() =>
                                                        openReport(
                                                            {
                                                                gte: dateToString(periodDates[0]),
                                                                lte: dateToString(periodDates[1]),
                                                            },
                                                            [id],
                                                            undefined,
                                                            [user.id],
                                                            'tasks',
                                                            undefined,
                                                            undefined,
                                                        )
                                                    }>
                                                    {durationFormatter(
                                                        durationSummaryMins || 0,
                                                    )}
                                                </ListCell>
                                            );
                                        }
                                        return (
                                            <ListCell
                                                key={periodCode}
                                                align="center"
                                            />
                                        );
                                    },
                                )}
                            </Fragment>
                        )}
                        cellsAmount={user.periods.length}
                    />
                </Space>
            ))}
        </div>
    );
}

PeopleView.propTypes = {
    data: PropTypes.object,
    openReport: PropTypes.func.isRequired,
    isStatus: PropTypes.bool,
};

PeopleView.defaultProps = {
    data: {},
    isStatus: false,
};

export default PeopleView;
