import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

import { addDays, setWeekday } from '../../utils/dateHelpers';

import style from './MonthView.module.scss';

const getDaysArray = (year, month, weekStart) => {
        const
            days = [],
            lastDay = setWeekday(new Date(year, month + 1, 0), 6, weekStart);
        let firstDay = setWeekday(new Date(year, month, 1), 0, weekStart);
        while (firstDay <= lastDay) {
            days.push(firstDay);
            firstDay = addDays(firstDay, 1);
        }
        return days;
    },
    MonthView = ({
        date,
        dateRange,
        month,
        year,
        weekStart,
        onChange,
        dateBoundary,
    }) => {
        const [days, setDays] = useState(getDaysArray(year, month, weekStart));
        useEffect(() => {
            setDays(getDaysArray(year, month, weekStart));
        }, [year, month, weekStart]);

        return (
            <motion.div
                key={`${year}-${month}`}
                className={cx(style.monthView)}
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.9, opacity: 0 }}
            >
                <div>
                    {days.map(d => {
                        const
                            isTransparent = (d.getMonth() !== month),
                            isRanged = (dateRange.lte && dateRange.gte && d > dateRange.gte && d < dateRange.lte),
                            isRangeStart = (dateRange.gte && d.toLocaleDateString() === dateRange.gte.toLocaleDateString()),
                            isRangeEnd = (dateRange.lte && d.toLocaleDateString() === dateRange.lte.toLocaleDateString()),
                            isSelected = (date && d.toLocaleDateString() === date.toLocaleDateString()),
                            isToday = (d.toLocaleDateString() === (new Date()).toLocaleDateString()),
                            isOutOfInitialRange = (dateBoundary && dateBoundary.lte && dateBoundary.gte && (d < dateBoundary.gte || d > dateBoundary.lte));

                        return (
                            <div
                                className={cx(
                                    style.day,
                                    (isTransparent && style.transparent),
                                    (isRanged && style.ranged),
                                    (isRangeStart && style.rangeStart),
                                    (isRangeEnd && style.rangeEnd),
                                    (isSelected && style.selected),
                                    (isToday && style.today),
                                    ((dateBoundary && isOutOfInitialRange) && style.disabled)
                                )}
                                key={d.toLocaleString()}
                                onClick={() => {
                                    onChange(d);
                                }}
                                onKeyPress={undefined}
                            >
                                <span>{d.getDate()}</span>
                            </div>
                        );
                    })}
                </div>
            </motion.div>
        );
    };

MonthView.propTypes = {
    date: PropTypes.instanceOf(Date),
    dateRange: PropTypes.shape({
        gte: PropTypes.instanceOf(Date),
        lte: PropTypes.instanceOf(Date)
    }),
    month: PropTypes.number.isRequired,
    year: PropTypes.number.isRequired,
    weekStart: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    dateBoundary: PropTypes.object,
};

MonthView.defaultProps = {
    date: undefined,
    dateRange: PropTypes.shape({
        gte: undefined,
        lte: undefined
    }),
    onChange: undefined,
    dateBoundary: undefined
};

export default MonthView;