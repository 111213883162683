import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import COLORS from "@quidlo/common/constants/colors";

import icons from './svg';

import style from './Icon.module.scss';

const colors = {
        white: COLORS.COLORS.WHITE,
        black: COLORS.COLORS.BLACK,
        blue: COLORS.COLORS.BLUE,
        grey: COLORS.COLORS.GREY,
        flint: COLORS.COLORS.FLINT,
        'flint-60': COLORS.COLORS.FLINT60,
        ecru: COLORS.COLORS.ECRU,
        transparent: 'transparent',
    },
    sizes = {
        small: 20,
        medium: 24,
        large: 32,
    },
    Icon = ({ icon, color, size, onClick, noAnimation }) => (
        <div
            onClick={onClick}
            className={cx(style.icon, onClick && style.onClick, !noAnimation && style.animation)}
            style={{
                '--size': `${sizes[size]}px`,
                '--color': colors[color] || color
            }}
        >
            {icons?.[icon]?.[size] || icons?.[icon]?.[24]}
        </div>
    );

Icon.propTypes = {
    icon: PropTypes.string.isRequired,
    color: PropTypes.string,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    onClick: PropTypes.func,
    noAnimation: PropTypes.bool
};

Icon.defaultProps = {
    color: 'black',
    size: 'medium',
    onClick: undefined,
    noAnimation: false
};

export default Icon;
