import { defineMessages } from 'react-intl';

export default defineMessages({
    sharingManyProjects: {
        id: 'reportCreate.sharingManyProjects',
        defaultMessage: 'Sharing many projects'
    },
    areYouSure: {
        id: 'reportCreate.areYouSure',
        defaultMessage: 'Are you sure you want to share a report for more than one project?'
    },
});