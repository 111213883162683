import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import COLORS from "@quidlo/common/constants/colors";
import { REPORT_TYPES, REPORT_VIEWS } from '@quidlo/common/constants/main';
import { WORKSPACE_ROLE } from "@quidlo/common/constants/main";
import useForm from "@quidlo/common/hooks/useForm2";
import useUI from "@quidlo/common/hooks/useUI";
import useWorkspace from "@quidlo/common/hooks/useWorkspace";
import { Button, Header, Icon, InfoCard, Search, Space, Switch } from "@quidlo/ui";

import { editProjectMutation, getProjectsListAllInfiniteQuery } from "../../../queries/projects.queries";
import { Container, InfiniteLoader } from "../../../ui";
import ViewHOC from '../../../ui/View/View';
import { dateToString } from "../../../utils/dateHelpers";
import CreateProject from "../modals/CreateProject";

import style from "./ProjectsList.module.scss";

import messages from "./ProjectsList.i18n";

const ROLES_CREATE_PROJECT = [WORKSPACE_ROLE.OWNER, WORKSPACE_ROLE.ADMIN];

const FORM = {
    searchPhrase: {
        value: ''
    },
    showArchived: {
        value: false
    }
}

const ProjectsList = () => {
    const
        { modalOpen } = useUI(),
        intl = useIntl(),
        navigate = useNavigate(),
        [filters] = useForm(FORM),
        { workspace } = useWorkspace(),
        { data, isFetched, isFetchingNextPage, hasNextPage, fetchNextPage } = getProjectsListAllInfiniteQuery({
            sort: 'name',
            'suggestions-for': filters.searchPhrase.value,
            status: filters.showArchived.value ? 'all' : 'active'
        }),
        { mutate: editProject } = editProjectMutation(),
        createProject = () => {
            modalOpen('createProject', {});
        },
        openReport = projectId => {
            const string = window.btoa(JSON.stringify({
                date: {
                    gte: dateToString(new Date(new Date().getFullYear(), new Date().getMonth(), 1)),
                    lte: dateToString(new Date())
                },
                projects: [projectId],
                type: REPORT_TYPES[0],
                period: REPORT_VIEWS[0],
                statuses: [],
                users: [],
                tags: []
            }));
            navigate(`/reports#${string}`);
        },
        openProjectDetails = projectId => {
            navigate(`/projects/${projectId}`);
        },
        archiveProject = (id, isArchived) => {
            editProject( { id, delta: { isArchived } });
        };
    const hasPermissionToCreateProject = ROLES_CREATE_PROJECT.find(role => role === workspace?.organizationRole)
    const projects = useMemo(() => data?.pages ? data.pages.filter(f => !!f).flat(1) : [], [data]);

    return (
        <div className={style.projectsList}>
            <Container>
                <Space mb="l">
                    <Header
                        title={intl.formatMessage(messages.projects)}
                        icon="project"
                        size="large"
                        underline
                        leftChildren={
                            <Space ml="s">
                                <div className={style.archiveSwitch}>
                                    <Switch
                                        {...filters.showArchived}
                                        options={[
                                            {
                                                value: false,
                                                label: 'archived',
                                            },
                                            {
                                                value: true,
                                                label: 'active',
                                            },
                                        ]}
                                        size="small"
                                    />
                                    <Space mh="xxs">
                                        <span>
                                            Show archived
                                        </span>
                                    </Space>
                                </div>
                            </Space>
                        }
                        rightChildren={
                            <div className={style.headerRight}>
                                <div className={style.search}>
                                    <Search
                                        onSearch={filters.searchPhrase.onChange}
                                    />
                                </div>
                                {hasPermissionToCreateProject && (
                                    <Button
                                        text={intl.formatMessage(
                                            messages.createProject,
                                        )}
                                        onClick={createProject}
                                        type="filled"
                                        color="blue"
                                        shadow
                                    />
                                )}
                            </div>
                        }
                    />
                </Space>
                <Space>
                    {isFetched && !projects.length ? (
                        <div className={style.noResultsContainer}>
                            <InfoCard
                                header={intl.formatMessage(
                                    messages.noProjectsHeader,
                                )}
                                text={intl.formatMessage(
                                    messages.noProjectsBody,
                                )}
                                actions={[{
                                    text: intl.formatMessage(
                                        messages.createProject,
                                    ),
                                    onClick: createProject,
                                }]}
                            />
                        </div>
                    ) : (
                        <div className={style.list}>
                            {
                                projects.map(p => (
                                    <Space mb="xs" pl="l" pr="s" className={style.row} key={p.id} style={{
                                        '--color': p.isArchived
                                            ? COLORS.PROJECT_ARCHIVED_COLOR
                                            : p.color,
                                    }}>
                                        <div className={style.name} onClick={() => {
                                            openProjectDetails(p.id);
                                        }} >
                                            <span>
                                                {p.name}
                                            </span>
                                            {p.isBillable && (
                                                <Space ml="xxs">
                                                    <Icon
                                                        icon="dollar"
                                                        color={
                                                            p.isArchived
                                                                ? COLORS.PROJECT_ARCHIVED_COLOR
                                                                : p.color
                                                        }
                                                        noAnimation
                                                    />
                                                </Space>
                                            )}
                                        </div>
                                        <div className={style.actions}>
                                            <a
                                                className={style.action}
                                                title={intl.formatMessage(messages.edit)}
                                                onClick={() => openProjectDetails(p.id)}
                                            >
                                                <Space p="xxs">
                                                    <Icon icon="edit" size="medium" color={p.isArchived ? 'flint' : 'blue'} />
                                                </Space>
                                            </a>
                                            <a
                                                className={style.action}
                                                title={intl.formatMessage(messages.archive)}
                                                onClick={() => archiveProject(
                                                    p.id,
                                                    !p.isArchived,
                                                )}
                                            >
                                                <Space p="xxs">
                                                    <Icon icon="archive" size="medium" color={p.isArchived ? 'flint' : 'blue'} />
                                                </Space>
                                            </a>
                                            <a
                                                className={style.action}
                                                title={intl.formatMessage(messages.report)}
                                                onMouseDown={() => openReport(p.id)}
                                            >
                                                <Space p="xxs">
                                                    <Icon icon="report" size="medium" color={p.isArchived ? 'flint' : 'blue'} />
                                                </Space>
                                            </a>
                                        </div>
                                    </Space>
                                ))
                            }
                        </div>
                    )}
                </Space>
                <InfiniteLoader
                    action={fetchNextPage}
                    isMore={hasNextPage && !isFetchingNextPage}
                />
            </Container>
            <CreateProject />
        </div>
    );
};


export default ViewHOC(
    ProjectsList,
    {
        title: 'Projects',
        menu: true,
        topbar: true,
        auth: {
            roles: ['manager', 'admin', 'owner']
        },
        subscription: true
    }
);
