import React, { Fragment } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { FEATURE } from "@quidlo/common/constants/main";
import useWorkspaceFeatures from "@quidlo/common/hooks/useWorkspaceFeatures";
import { durationFormatter } from '@quidlo/common/utils/duration';
import { timeParser } from "@quidlo/common/utils/time";
import { Avatar, Counter, Dot, Header, Pill, Space, Tag, Tooltip } from '@quidlo/ui';

import { getPotentialApprovalDetailsQuery } from '../../../queries/approvals.queries';
import { Container, List, ListCell, Text } from '../../../ui';
import ViewHOC from '../../../ui/View/View';

import style from './PotentialApprovalDetails.module.scss';

import messages from './PotentialApprovalDetails.i18n';

const PotentialApprovalDetails = () => {
    const params = useParams(),
        { features } = useWorkspaceFeatures(),
        { data: {
            project,
            user,
            tasks,
            duration_mins_sum,
            from,
            to,
        } } = getPotentialApprovalDetailsQuery({
            ...params
        }),
        intl = useIntl();

    const renderRow = ({
        date,
        duration_mins,
        title,
        tags,
        time_from,
        time_to,
    }) => {

        return (
            <Fragment>
                <ListCell width="224px" align="left">
                    <Tag text={project.name} color={project.color} type={'stroked'} icon={project.is_billable ? 'dollar' : ''} />
                </ListCell>
                <ListCell width="112px">
                    {date}
                </ListCell>
                <ListCell width="72px" align='center'>
                    <Tooltip text={`${user.first_name} ${user.last_name}`}>
                        <Avatar picture={user.avatar_url} firstName={user.first_name} lastName={user.last_name}  />
                    </Tooltip>
                </ListCell>
                <ListCell align='left'>
                    <Text lines={2}>
                        {title}
                    </Text>
                </ListCell>
                <ListCell width="224px" align="left">
                    <span>
                        {
                            tags.length === 2 && tags[0].name.length <= 6 && tags[1].name.length <= 6 ?
                                <span>
                                    <Tooltip text={tags[0].name} key={tags[0].id}>
                                        <Tag text={tags[0].name} style={{ maxWidth: '94px' }} />
                                    </Tooltip>
                                    <Tooltip text={tags[1].name} key={tags[1].id}>
                                        <Tag text={tags[1].name} style={{ maxWidth: '94px' }} />
                                    </Tooltip>
                                </span>
                                : tags.length > 1 && tags[0].name.length <= 3 && tags[1].name.length <= 3 ?
                                    <span>
                                        <Tooltip text={tags[0].name} key={tags[0].id}>
                                            <Tag text={tags[0].name} style={{ maxWidth: '94px' }} />
                                        </Tooltip>
                                        <Tooltip text={tags[1].name} key={tags[1].id}>
                                            <Tag text={tags[1].name} style={{ maxWidth: '94px' }} />
                                        </Tooltip>
                                        <Tooltip text={tags.slice(2)
                                            .map(tag => tag.name)
                                            .join(', ')}
                                        >
                                            <Counter number={tags.length - 2} />
                                        </Tooltip>
                                    </span>
                                    : tags.length > 1 && tags[0].name.length > 5 ?
                                        <span>
                                            <Tooltip text={tags[0].name} key={tags[0].id}>
                                                <Tag text={tags[0].name} style={{ maxWidth: '94px' }} />
                                            </Tooltip>
                                            <Tooltip text={tags.slice(1)
                                                .map(tag => tag.name)
                                                .join(', ')}
                                            >
                                                <Counter number={tags.length - 1} />
                                            </Tooltip>
                                        </span>
                                        : tags.length === 1 && tags[0].name.length > 15 ?
                                            <Tooltip text={tags[0].name} key={tags[0].id}>
                                                <Tag text={tags[0].name} style={{ maxWidth: '140px' }} />
                                            </Tooltip>
                                            : ((tags.length === 1 && tags[0].name.length <= 15) || (tags.length > 1 && tags[0].name.length < 5)) ?
                                                <span>
                                                    {tags.length > 0 && <Tag key={tags[0].id} text={tags[0].name} />}
                                                    {tags.length > 1 &&
                                                        <Tooltip text={tags.slice(1)
                                                            .map(tag => tag.name)
                                                            .join(', ')}
                                                        >
                                                            <Counter number={tags.length - 1} />
                                                        </Tooltip>
                                                    }
                                                </span>
                                                : <span>
                                                    {tags.length > 0 && <Tag key={tags[0].id} text={tags[0].name}
                                                        style={{ maxWidth: '90px' }} />}
                                                    {tags.length > 1 &&
                                                        <Tooltip text={tags.slice(1)
                                                            .map(tag => tag.name)
                                                            .join(', ')}
                                                        >
                                                            <Counter number={tags.length - 1} />
                                                        </Tooltip>
                                                    }
                                                </span>
                        }
                    </span>
                </ListCell>
                {
                    features?.task_with_from_to && (
                        <ListCell width="120px" align="left">
                            <span>
                                {time_from && time_to && `${timeParser(time_from)} - ${timeParser(time_to)}`}
                            </span>
                        </ListCell>
                    )
                }
                <ListCell width="96px" align="center">
                    {durationFormatter(duration_mins)}
                </ListCell>
            </Fragment>
        );
    };

    return (
        <Container>
            <Header
                title={intl.formatMessage(messages.details)}
                size="large"
                goBack
                underline
            />
            <Space mt={'m'}>
                <List
                    title={
                        <div className={style.title}>
                            <Dot color={'blue'} />
                            {intl.formatMessage(messages.loggedFrom)}{' '}
                            <FormattedDate value={new Date(from)} weekday={'short'} />{', '}
                            <FormattedDate value={new Date(from)} day="2-digit" /> -{' '}
                            <FormattedDate value={new Date(to)} weekday={'short'} />{', '}
                            <FormattedDate value={new Date(to)} day="2-digit" />
                        </div>
                    }
                    rightChildren={(<Pill size="small" text={`${intl.formatMessage(messages.total)}  ${durationFormatter(duration_mins_sum)}`} />)}
                    header={(
                        <Fragment>
                            <ListCell width='224px' align="center" header>
                                <FormattedMessage {...messages.project} />
                            </ListCell>
                            <ListCell width='112px' align="center" header>
                                <FormattedMessage {...messages.logDate} />
                            </ListCell>
                            <ListCell width='72px' align="center" header>
                                <FormattedMessage {...messages.person} />
                            </ListCell>
                            <ListCell width='224px' align="center" header>
                                <FormattedMessage {...messages.description} />
                            </ListCell>
                            <ListCell align="center" header>
                                <FormattedMessage {...messages.tags} />
                            </ListCell>
                            {
                                features?.task_with_from_to && (
                                    <ListCell width="150px" align="center" header>
                                        <FormattedMessage {...messages.startStop} />
                                    </ListCell>
                                )
                            }
                            <ListCell width='96px' align="center" header>
                                <FormattedMessage {...messages.duration} />
                            </ListCell>
                        </Fragment>
                    )}
                    data={tasks}
                    renderRow={renderRow}
                />
            </Space>
        </Container>
    );
};

export default ViewHOC((PotentialApprovalDetails),
    {
        title: 'Approval - Create Details',
        menu: true,
        topbar: true,
        auth: {},
        subscription: true,
        feature: FEATURE.APPROVALS
    }
);