import React, { useEffect, useMemo } from 'react';
import { useIntl } from "react-intl";
import PropTypes from 'prop-types';

import { APPROVAL_STATUS } from "@quidlo/common/constants/main";
import useAuthentication from "@quidlo/common/hooks/useAuthentication";
import useForm from "@quidlo/common/hooks/useForm2";
import useUI from "@quidlo/common/hooks/useUI";
import useWorkspace from "@quidlo/common/hooks/useWorkspace";
import useWorkspaceFeatures from "@quidlo/common/hooks/useWorkspaceFeatures";
import analytics from "@quidlo/common/utils/analytics";
import { durationFormatter, durationParser,durationShortFormatter } from "@quidlo/common/utils/duration";
import { timeParser } from "@quidlo/common/utils/time";
import validators from "@quidlo/common/utils/validators";
import { Avatar, Counter, Input, Select, Space, Tag, Tooltip } from "@quidlo/ui";

import { editTaskMutation } from "../../queries/tasks.queries";
import { getTrackerFiltersTagsQuery } from "../../queries/tracker.queries";
import { ListActions, ListCell, Text } from "../../ui";

import style from "./Project.module.scss";

import messages from "./Project.i18n";

const TaskRow = ({
    id,
    user: { id: userId, firstName, lastName, avatarUrl },
    approvalStatus,
    durationMins,
    title,
    tags,
    status,
    project,
    date,
    timeFrom,
    timeTo
}) => {
    const intl = useIntl();
    const { modalOpen } = useUI();
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    const { features } = useWorkspaceFeatures();
    const { data: projectTags } = getTrackerFiltersTagsQuery(project.id);
    const { mutate: editTask } = editTaskMutation();

    const [editForm, { cleanForm, isFormDirty, isFormValid }] = useForm(useMemo(() => ({
        title: {
            value: '',
            validators: features?.required_task_description ? [validators.isRequired(), validators.minLength(2), validators.maxLength(400)] : []
        },
        timeFrom: {
            value: '',
            validators: features?.task_with_from_to ? [validators.isRequired(), validators.isTime()] : []
        },
        timeTo: {
            value: '',
            validators: features?.task_with_from_to ? [validators.isRequired(), validators.isTime()] : []
        },
        durationMins: {
            value: '',
            validators: [validators.isRequired(), validators.isDuration()]
        },
        tags: {
            value: []
        }
    }), [features]));

    const openEditTaskModal = (id, actor) => {
        modalOpen('editTask', { id, actor, source: 'tracker' });
    };

    const onDurationBlur = () => {
        if (features?.task_with_from_to && editForm.timeFrom.value) {
            const durationMins = durationParser(editForm.durationMins.value);
            let [hours, minutes] = editForm.timeFrom.value.split(':');
            minutes = parseInt(minutes) + durationMins;
            hours = parseInt(hours) + (minutes > 59 ? Math.floor(minutes / 60) : 0);
            minutes = minutes > 59 ? minutes % 60 : minutes;
            if (hours < 24) {
                editForm.timeTo.onChange(`${hours < 10 ? `0${hours}` : hours }:${minutes < 10 ? `0${minutes}` : minutes}`);
            }
        }
    }

    const onTimeFromBlur = () => {
        editForm.timeFrom.onChange(timeParser(editForm.timeFrom.value));
        if (editForm.timeFrom.value) {
            if (editForm.timeFrom.value && editForm.timeTo.value) {
                const timeFrom = new Date(`${date} ${timeParser(editForm.timeFrom.value)}`);
                const timeTo = new Date(`${date} ${editForm.timeTo.value}`);
                const duration = timeTo - timeFrom;
                if (duration>=0) {
                    editForm.durationMins.onChange(durationShortFormatter(duration/60000));
                }
            }
        }
    }

    const onTimeToBlur = () => {
        editForm.timeTo.onChange(timeParser(editForm.timeTo.value));
        if (editForm.timeTo.value) {
            if (editForm.timeFrom.value && editForm.timeTo.value) {
                const timeFrom = new Date(`${date} ${editForm.timeFrom.value}`);
                const timeTo = new Date(`${date} ${timeParser(editForm.timeTo.value)}`);
                const duration = timeTo - timeFrom;
                if (duration>=0) {
                    editForm.durationMins.onChange(durationShortFormatter(duration/60000));
                }
            }
        }
    }

    const isEditable = approvalStatus !== APPROVAL_STATUS.ACCEPTED && !project.isFrozen;

    const actions = useMemo(() => {
        const
            isUserAdminOrOwner = () => {
                return ['admin', 'owner'].filter(role => role === workspace.role).length > 0;
            };

        if ((!project.isAdminRestricted || isUserAdminOrOwner()) && !project.isFrozen) {
            return  [
                {
                    title: intl.formatMessage(messages.deleteTask),
                    icon: 'trash',
                    onClick: () => {
                        modalOpen('deleteTask', { id }, () => {
                            analytics.logTimesheetsReportsTaskDelete(userId === user.id ? 'self' : workspace.role);
                        });
                    }
                },
                {
                    title: intl.formatMessage(messages.editTask),
                    icon: 'edit',
                    onClick: () => openEditTaskModal(id)
                }];
        }
        return []
    }, [project, workspace])

    useEffect(() => {
        if (status === 'normal' && isFormDirty && isFormValid) {
            const delta = {};
            if (editForm.title.isDirty) {
                delta.title = editForm.title.value
            }
            if (editForm.durationMins.isDirty) {
                delta.durationMins = durationParser(editForm.durationMins.value)
            }
            if (editForm.tags.isDirty) {
                delta.tagIds = editForm.tags.value
            }
            if (editForm.timeFrom.isDirty) {
                delta.timeFrom = editForm.timeFrom.value
            }
            if (editForm.timeTo.isDirty) {
                delta.timeTo = editForm.timeTo.value
            }

            if ((delta.timeFrom || delta.timeTo) && !delta.durationMins) {
                // przeliczyc duartion
            }

            editTask({ id, delta });
            analytics.logTimesheetsTrackerTaskEdit({
                actor: userId === user.id ? 'self' : workspace.role,
                interaction: 'Inline',
                isDesc: !!delta.title,
                isTimeFrom: !!delta.timeFrom,
                isTimeTo: !!delta.timeTo,
                isDuration: !!delta.durationMins,
                isTags: !!delta.tagIds
            })
            cleanForm();
        } else if (status === 'edit') {
            editForm.title.setValue(title);
            editForm.timeFrom.setValue(timeFrom);
            editForm.timeTo.setValue(timeTo);
            editForm.durationMins.setValue(durationFormatter(durationMins));
            editForm.tags.setValue(tags.map(t => t.id));
        }
    }, [status])

    return (
        <>
            <ListCell width="72px" align="center">
                <Tooltip text={`${firstName} ${lastName}`}>
                    <Avatar firstName={firstName} lastName={lastName} picture={avatarUrl} />
                </Tooltip>
            </ListCell>
            <ListCell align="left">
                {
                    status === 'edit' && isEditable ? (
                        <Input
                            {...editForm.title}
                            analyticsID="Tracker - Edit - Description"
                        />
                    ) : (
                        <div className={style.taskContainer}>
                            <Text lines={2}>
                                {title}
                            </Text>
                        </div>
                    )
                }
            </ListCell>
            <ListCell width="250px" align="left">
                {
                    status === 'edit' && isEditable ? (
                        <Select
                            {...editForm.tags}
                            options={projectTags.map(t => ({
                                label: t.name,
                                labelText: t.name,
                                value: t.id
                            }))}
                            multiselect
                            isNarrow
                        />
                    ) : (
                        <span>
                            {
                                tags.length === 2 && tags[0].name.length <= 6 && tags[1].name.length <= 6 ?
                                    <span>
                                        <Tooltip text={tags[0].name} key={tags[0].id}>
                                            <Tag text={tags[0].name} style={{ maxWidth: '94px' }} />
                                        </Tooltip>
                                        <Tooltip text={tags[1].name} key={tags[1].id}>
                                            <Tag text={tags[1].name} style={{ maxWidth: '94px' }} />
                                        </Tooltip>
                                    </span>
                                    : tags.length > 1 && tags[0].name.length <= 3 && tags[1].name.length <= 3 ?
                                        <span>
                                            <Tooltip text={tags[0].name} key={tags[0].id}>
                                                <Tag text={tags[0].name} style={{ maxWidth: '94px' }} />
                                            </Tooltip>
                                            <Tooltip text={tags[1].name} key={tags[1].id}>
                                                <Tag text={tags[1].name} style={{ maxWidth: '94px' }} />
                                            </Tooltip>
                                            <Tooltip text={tags.slice(2)
                                                .map(tag => tag.name)
                                                .join(', ')}
                                            >
                                                <Counter number={tags.length - 2} />
                                            </Tooltip>
                                        </span>
                                        : tags.length > 1 && tags[0].name.length > 5 ?
                                            <span>
                                                <Tooltip text={tags[0].name} key={tags[0].id}>
                                                    <Tag text={tags[0].name} style={{ maxWidth: '94px' }} />
                                                </Tooltip>
                                                <Tooltip text={tags.slice(1)
                                                    .map(tag => tag.name)
                                                    .join(', ')}
                                                >
                                                    <Counter number={tags.length - 1} />
                                                </Tooltip>
                                            </span>
                                            : tags.length === 1 && tags[0].name.length > 15 ?
                                                <Tooltip text={tags[0].name} key={tags[0].id}>
                                                    <Tag text={tags[0].name} style={{ maxWidth: '140px' }} />
                                                </Tooltip>
                                                : ((tags.length === 1 && tags[0].name.length <= 15) || (tags.length > 1 && tags[0].name.length < 5)) ?
                                                    <span>
                                                        {tags.length > 0 && <Tag key={tags[0].id} text={tags[0].name} />}
                                                        {tags.length > 1 &&
                                                            <Tooltip text={tags.slice(1)
                                                                .map(tag => tag.name)
                                                                .join(', ')}
                                                            >
                                                                <Counter number={tags.length - 1} />
                                                            </Tooltip>
                                                        }
                                                    </span>
                                                    : <span>
                                                        {tags.length > 0 && <Tag key={tags[0].id} text={tags[0].name}
                                                            style={{ maxWidth: '90px' }} />}
                                                        {tags.length > 1 &&
                                                            <Tooltip text={tags.slice(1)
                                                                .map(tag => tag.name)
                                                                .join(', ')}
                                                            >
                                                                <Counter number={tags.length - 1} />
                                                            </Tooltip>
                                                        }
                                                    </span>
                            }
                        </span>
                    )
                }
            </ListCell>
            {
                features?.task_with_from_to && (
                    <ListCell width="120px" align="left">
                        {
                            status === 'edit' && isEditable ? (
                                <div className={style.time}>
                                    <Space>
                                        <Input
                                            {...editForm.timeFrom}
                                            onBlur={onTimeFromBlur}
                                            analyticsID="Tracker - Edit - Time start"
                                        />
                                    </Space>
                                    <Space mh="xxs" pt="s">-</Space>
                                    <Space>
                                        <Input
                                            {...editForm.timeTo}
                                            onBlur={onTimeToBlur}
                                            analyticsID="Tracker - Edit - Time stop"
                                        />
                                    </Space>
                                </div>
                            ) : (
                                <span>
                                    {timeFrom && timeTo && `${timeParser(timeFrom)} - ${timeParser(timeTo)}`}
                                </span>
                            )
                        }
                    </ListCell>
                )
            }
            <ListCell width="220px" align="left" last>
                {
                    status === 'edit' && isEditable ? (
                        <Input
                            {...editForm.durationMins}
                            onBlur={onDurationBlur}
                            analyticsID="Tracker - Edit - Duration"
                        />
                    ) : (
                        <span>
                            {durationFormatter(durationMins)}
                        </span>
                    )
                }
            </ListCell>
            {isEditable && <ListActions actions={actions} />}
        </>
    );
};


TaskRow.propTypes = {
    id: PropTypes.number.isRequired,
    user: PropTypes.object.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    title: PropTypes.string.isRequired,
    tags: PropTypes.array.isRequired,
    durationMins: PropTypes.number.isRequired,
    approvalStatus: PropTypes.string.isRequired,
    project: PropTypes.object.isRequired,
    status: PropTypes.string.isRequired,
    date: PropTypes.string,
    timeFrom: PropTypes.string,
    timeTo: PropTypes.string,
};

TaskRow.defaultProps = {
    firstName: "",
    lastName: "",
    date: null,
    timeFrom: null,
    timeTo: null,
};

export default TaskRow;
