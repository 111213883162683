import { useCallback, useContext } from 'react';
import { useIntl } from "react-intl";

import PasswordRecoveryService from '../api/PasswordRecoveryService';
import SessionService from "../api/SessionService";
import UsersService from '../api/UsersService';
import { AuthContext } from '../providers/AuthProvider';
import analytics from "../utils/analytics";
import cookies from "../utils/cookies";
import { getTimezone } from "../utils/timezone";

import useUI from "./useUI";

import messages from "./useAuthentication.i18n";

const useAuthentication = () => {
    const { toastPush } = useUI();
    const intl = useIntl();

    const {
        isInitialized,
        user,
        userId,
        setUserId,
        setQuidloToken,
        fetchUser,
    } = useContext(AuthContext);

    const signUpApple = async ({ authorization }) => {
        const token = authorization?.code,
            firstName = authorization?.givenName,
            lastName = authorization?.familyName;

        try {
            const { data } = SessionService.postApple(
                token,
                firstName,
                lastName,
            );
            if (data && data.token && data.userId) {
                analytics.logAuthSignUpSuccess('apple');
                setQuidloToken(data.token);
            }
        } catch (e) {
            console.log(e);
            toastPush({
                text: intl.formatMessage(messages.authFailed),
                color: 'red',
            });
        }
    };

    const signInApple = async ({ authorization }) => {
        const token = authorization?.code,
            firstName = authorization?.givenName,
            lastName = authorization?.familyName;

        try {
            const { data } = await SessionService.postApple(
                token,
                firstName,
                lastName,
            );
            if (data && data.token && data.userId) {
                await setQuidloToken(data.token);
                analytics.logAuthSignInSuccess('apple')
            }
        } catch (e) {
            console.log(e);
            toastPush({
                text: intl.formatMessage(messages.authFailed),
                color: 'red',
            });
        }
    };

    const signUpCredentials = async (email, password) => {
        const { data } = await UsersService.postCredentials({ email, password, policy: true, terms: true });
        analytics.logAuthSignUpSuccess('password')
        return data;
    };

    const signInCredentials = async (email, password) => {
        const { data } = await SessionService.postCredentials(
            email,
            password,
            getTimezone(),
        );
        if (data?.token && data?.userId) {
            analytics.logAuthSignInSuccess('password')
            setQuidloToken(data.token);
            setUserId(data.userId);
            return data;
        }
    };

    const signOut = () => {
        cookies.set(process.env.REACT_APP_AUTHTOKEN_NAME, '');
        setQuidloToken(null);
        analytics.logAuthSignOut();
        setTimeout(() => {
            window.location.replace(process.env.REACT_APP_AUTH_URL);
        }, 1000)
    };

    const activateAccount = async (email, activationCode) => {
        await UsersService.activate(email, activationCode);
        toastPush({
            text: intl.formatMessage(messages.accountActivated)
        });
    };

    const patchUser = useCallback(async delta => {
        try {
            await UsersService.patch(userId, delta);
            toastPush({
                text: intl.formatMessage(messages.userEdited)
            });
            fetchUser();
        } catch (e) {
            toastPush({
                text: e.response.data.message,
                color: 'red',
            });
            console.log(e)
        }
    }, [userId]);

    const patchUserAvatar = async avatar => {
        if (avatar) {
            const formData = new FormData();
            formData.append('image', avatar);

            await UsersService.postAvatar(userId, formData);
            fetchUser();
        }
    };

    const resetPassword = async email => {
        return PasswordRecoveryService.request(email);
    };

    const setPassword = async (email, token, password) => {
        try {
            await PasswordRecoveryService.set(email, token, password);
            toastPush({
                text: intl.formatMessage(messages.passwordSet)
            });
            return true;
        } catch (e) {
            console.log(e);
            return false;
        }
    };

    return {
        isInitialized,
        user,
        fetchUser,
        signUpApple,
        signUpCredentials,
        signInApple,
        signInCredentials,
        signOut,
        activateAccount,
        patchUser,
        patchUserAvatar,
        resetPassword,
        setPassword,
    };
};

export default useAuthentication;
