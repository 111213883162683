import FreeProPNG from './assets/free-pro.png';
import StarterPNG from './assets/starter.png';

const subscriptionViews = {
    starter: {
        title: 'starterTitle',
        subtitle: 'starterSubtitle',
        description: 'starterDescription',
        image: StarterPNG,
        button: 'upgrade',
        href: '/paywall',
        alt: 'starterAlt'
    },
    trial: {
        title: 'trialTitle',
        subtitle: 'trialSubtitle',
        description: 'trialDescription',
        image: FreeProPNG,
        button: 'upgrade',
        href: '/paywall',
        alt: 'trialAlt'
    },
    pro: {
        title: 'proTitle',
        subtitle: 'proSubtitle',
        description: 'proDescription',
        image: FreeProPNG,
        button: 'manageSubscription',
        subscriptionTime: 'subscriptionTime',
        youAreCurrentlyUsing: 'youAreCurrentlyUsing',
        alt: 'proAlt',
    },
    appSumo: {
        title: 'appSumoTitle',
        subtitle: 'appSumoSubtitle',
        description: 'appSumoDescription',
        image: FreeProPNG,
        button: 'manageSubscription',
        subscriptionTime: 'subscriptionTime',
        youAreCurrentlyEnrolled: 'youAreCurrentlyEnrolled',
        alt: 'appSumoAlt',
    }
};

export default subscriptionViews;