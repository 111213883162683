import fetch from '../utils/fetch';

export default {
    getAll: (workspaceCode, userId) =>
        fetch.get(`/v1/${workspaceCode}/users/${userId}/participated-projects`, { headers: { silent: true } }),
    getAllManaged: (workspaceCode, userId) =>
        fetch.get(`/v1/${workspaceCode}/users/${userId}/managed-projects`, { headers: { silent: true } }),
    getAdministrableAll: (userId, workspaceCode, query) =>
        fetch.get(`/v1/${workspaceCode}/users/${userId}/managed-projects`, { params: query }),
    get: (workspaceCode, userId, id) =>
        fetch.get(`/v1/${workspaceCode}/users/${userId}/managed-projects/${id}`),
    post: (workspaceCode, project) =>
        fetch.post(`/v1/${workspaceCode}/projects`, project),
    patch: (workspaceCode, userId, id, delta) =>
        fetch.patch(`/v1/${workspaceCode}/users/${userId}/managed-projects/${id}`, delta),
    delete: (workspaceCode, userId, id) =>
        fetch.delete(`/v1/${workspaceCode}/users/${userId}/managed-projects/${id}`),
    getProjectTags: (workspaceCode, userId,  id) =>
        fetch.get(`/v1/${workspaceCode}/users/${userId}/managed-projects/${id}/tags`),
    postProjectTag: (workspaceCode, userId, id, tag) =>
        fetch.post(`/v1/${workspaceCode}/users/${userId}/managed-projects/${id}/tags`, tag),
    deleteProjectTag: (workspaceCode, userId, id, tagId) =>
        fetch.delete(`/v1/${workspaceCode}/users/${userId}/managed-projects/${id}/tags/${tagId}`),
    getProjectUsers: (workspaceCode, userId, id) =>
        fetch.get(`/v1/${workspaceCode}/users/${userId}/managed-projects/${id}/participants`),
    postProjectUser: (workspaceCode, userId, id, user) =>
        fetch.post(`/v1/${workspaceCode}/users/${userId}/managed-projects/${id}/participants`, user),
    patchProjectUser: (workspaceCode, userId, projectId, id, delta) =>
        fetch.patch(`/v1/${workspaceCode}/users/${userId}/managed-projects/${projectId}/participants/${id}`, delta),
    deleteProjectUser: (workspaceCode, userId, projectId, id) =>
        fetch.delete(`/v1/${workspaceCode}/users/${userId}/managed-projects/${projectId}/participants/${id}`),
    getParticipatedProjectTags: (workspaceCode, userId,  id) =>
        fetch.get(`/v1/${workspaceCode}/users/${userId}/participated-projects/${id}/tags`),
};