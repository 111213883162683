import React from 'react';
import { useIntl } from "react-intl";

import useUI from "@quidlo/common/hooks/useUI";
import useWorkspace from "@quidlo/common/hooks/useWorkspace";
import { Button } from '@quidlo/ui';

import illustration from "../../../../../assets/modals/padlock.png";
import ModalHOC, { ModalActions, ModalContent, ModalHeader } from "../../../../../ui/Modal/Modal";

import style from './CannotDeleteAccount.module.scss';

import messages from './CannotDeleteAccount.i18n';

const CannotDeleteAccount = () => {
    const intl = useIntl();
    const { selectWorkspace } = useWorkspace();
    const { modal, modalClose } = useUI();

    const agree = async () => {
        if (modal?.data?.workspaceCodeToRedirect) {
            selectWorkspace(modal?.data?.workspaceCodeToRedirect, '/users');
        }
        modalClose();
    };

    return (
        <>
            <ModalHeader>
                {intl.formatMessage(messages.title)}
            </ModalHeader>
            <ModalContent>
                <div className={style.subtitle}>
                    {intl.formatMessage(messages.subtitle1)}
                </div>
                <div className={style.subtitlebold}>
                    {intl.formatMessage(messages.subtitle2)}
                </div>
            </ModalContent>
            <ModalActions>
                <Button color="blue" text={intl.formatMessage(messages.ok)} onClick={agree} />
            </ModalActions>
        </>
    );
}

export default ModalHOC(
    CannotDeleteAccount,
    {
        id: 'cannotDeleteAccount',
        size: 'small',
        illustration,
    }
);