import { defineMessages } from 'react-intl';

export default defineMessages({
    upgradeTitle: {
        id: 'payWall.upgradeTitle',
        defaultMessage: 'Upgrade your plan now!',
    },
    upgradeSubtitle: {
        id: 'payWall.upgradeSubtitle',
        defaultMessage: 'Boost your experience with Quidlo Timesheets Pro features! Pay one flat price for the whole workspace. No fees per user.',
    },
    upgradeAlt: {
        id: 'payWall.upgradeAlt',
        defaultMessage: 'A woman with a wand',
    },
    subscriptionExpiredTitle: {
        id: 'payWall.subscriptionExpiredTitle',
        defaultMessage: 'Your subscription plan just expired!',
    },
    subscriptionExpiredSubtitle: {
        id: 'payWall.subscriptionExpiredSubtitle',
        defaultMessage: 'Unfortunately your Quidlo Timesheets Pro subscription plan has come to an end! To continue, please pick a plan that suits your needs the best.',
    },
    subscriptionExpiredAlt: {
        id: 'payWall.subscriptionExpiredAlt',
        defaultMessage: 'A woman with a wand',
    },
    trialExpiredTitle: {
        id: 'payWall.trialExpiredTitle',
        defaultMessage: 'Your 30 day trial free trial just expired!',
    },
    trialExpiredSubtitle: {
        id: 'payWall.trialExpiredSubtitle',
        defaultMessage: 'Unfortunately your Quidlo Timesheets Pro Free Trial has come to an end! To continue, please pick a plan that suits your needs the best.',
    },
    trialExpiredAlt: {
        id: 'payWall.trialExpiredAlt',
        defaultMessage: 'A woman with a wand',
    },
    compareAllPlanFeatures: {
        id: 'payWall.compareAllPlanFeatures',
        defaultMessage: 'Compare all plan features',
    },
    save20: {
        id: 'payWall.save20',
        defaultMessage: 'Save 20%',
    }
});