import avatar1 from './avatar_1.png';
import avatar2 from './avatar_2.png';
import avatar3 from './avatar_3.png';
import avatar4 from './avatar_4.png';
import avatar5 from './avatar_5.png';
import avatar6 from './avatar_6.png';
import avatar7 from './avatar_7.png';
import avatar8 from './avatar_8.png';

const avatars = [avatar1, avatar2, avatar3, avatar4, avatar5, avatar5, avatar6, avatar7, avatar8];

export default avatars;