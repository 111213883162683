import DURATION_FORMATS from '../constants/durationFormats';

export const durationParser = value => {
    const string = value.trim();
    let
        hours = 0,
        minutes = 0;
    if (DURATION_FORMATS.HOURS_MINUTES_WITH_COMMA.test(string)) {
        minutes = 60 * Number(string.replace(',', '.').replace('h', ''));
    } else if (DURATION_FORMATS.HOURS_MINUTES.test(string)) {
        [, hours, minutes] = /^(\d+)[:h -]\s?(\d+)m?$/.exec(string);
    } else if (DURATION_FORMATS.MINUTES.test(string)) {
        [, minutes] = /^(\d+)m$/.exec(string);
    } else if (DURATION_FORMATS.HOURS.test(string)) {
        [, hours] = /^(\d+)[h]?$/.exec(string);
    }

    return Math.round((60 * Number(hours)) + Number(minutes));
};
export const durationFormatter = number => {
    const
        isNegative = number < 0,
        hours = Math.floor((isNegative ? number * -1 : number) / 60),
        minutes = (isNegative ? number * -1 : number) - (60 * hours);

    return `${isNegative ? '-' : ''}${hours}h ${Math.floor(minutes)}m`;
};

export const durationShortFormatter = number => {
    const
        isNegative = number < 0,
        hours = Math.floor((isNegative ? number * -1 : number) / 60),
        minutes = (isNegative ? number * -1 : number) - (60 * hours);

    if (hours && minutes) {
        return `${isNegative ? '-' : ''}${hours}h ${Math.floor(minutes)}m`;
    } else if (hours) {
        return `${isNegative ? '-' : ''}${Math.floor(hours)}h`;
    }
    return `${isNegative ? '-' : ''}${Math.floor(minutes)}m`;
};
