import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import useForm from '@quidlo/common/hooks/useForm2';
import useUI from '@quidlo/common/hooks/useUI';
import useWorkspace from "@quidlo/common/hooks/useWorkspace";
import analytics from "@quidlo/common/utils/analytics";
import { Button, Input, Space } from '@quidlo/ui';

import illustration from "../../../../assets/modals/man-board.png";
import ModalHOC, { ModalActions, ModalContent,ModalHeader } from "../../../../ui/Modal";

import style from './PollModal.module.scss';

import messages from './PollModal.i18n';

const FORM = {
    options: {
        value: []
    },
    text: {
        value: '',
    }
}

const PollModal = () => {
    const
        intl = useIntl(),
        { workspace } = useWorkspace(),
        [form, { isFormValid }] = useForm(FORM, {}),
        [poll, setPoll] = useState({}),
        { modal: { id, data, callback }, modalClose } = useUI(),

        cancel = () => {
            analytics.logTimesheetsPollCancel({
                question: poll.question,
                role: workspace?.role,
            });
            modalClose();

            if (callback) {
                callback();
            }
        },

        save = () => {
            analytics.logTimesheetsPollAnswer({
                question: poll.question,
                options: form.options.value,
                text: form.text.value,
                role: workspace?.role,
            })

            if (data.send) {
                data.send(form.options.value, form.text.value);
            }

            modalClose();

            if (callback) {
                callback();
            }
        };

    useEffect(() => {
        if (id === 'pollModal') {
            analytics.logTimesheetsPollOpen({
                question: poll.question,
                role: workspace?.role,
            });
            setPoll(data);
        } else {
            setPoll({})
        }
    }, [id])

    return (
        <>
            {
                poll.question && (
                    <ModalHeader>
                        {poll.question}
                    </ModalHeader>
                )
            }
            <ModalContent>
                <Space mb="l" className={style.options}>
                    {poll?.options?.map(o => (
                        <Space mb="xxs" className={style.option} key={o}>
                            <label>
                                {
                                    poll.multi ? (
                                        <input
                                            onChange={() => {
                                                if (form.options.value.includes(o)) {
                                                    form.options.onChange(form.options.value.filter(op => o !== op))
                                                } else  {
                                                    form.options.onChange([...form.options.value, o])
                                                }
                                            }}
                                            type="checkbox"
                                            checked={form.options.value.includes(o)}
                                        />
                                    ) : (
                                        <input
                                            onChange={e => {
                                                form.options.onChange([e.target.value])
                                            }}
                                            id={poll.question}
                                            type="radio"
                                            checked={form.options.value[0] === o}
                                            value={o}
                                        />
                                    )
                                }
                                {o}
                            </label>
                        </Space>
                    ))}
                </Space>
                <Space mb="l">
                    <Input
                        {...form.text}
                        label={intl.formatMessage(messages.placeholder)}
                    />
                </Space>
                <Space>
                    <div className={style.subtitle}>
                        {intl.formatMessage(messages.yourAnswers)}
                    </div>
                </Space>
            </ModalContent>
            <ModalActions>
                <Space mr="m">
                    <Button
                        color="white"
                        text={intl.formatMessage(messages.cancel)}
                        onClick={cancel}
                    />
                </Space>
                <Button
                    disabled={!isFormValid}
                    color="blue"
                    text={intl.formatMessage(messages.send)}
                    onClick={save}
                />
            </ModalActions>
        </>
    );
};

export default ModalHOC(
    PollModal,
    {
        id: 'pollModal',
        size: 'medium',
        illustration
    }
);