import { defineMessages } from 'react-intl';

export default defineMessages({
    password: {
        id: 'profile.personal.password',
        defaultMessage: 'Password',
    },
    repeatedPassword: {
        id: 'profile.personal.repeatedPassword',
        defaultMessage: 'Repeat new password',
    },
    newPassword: {
        id: 'profile.personal.newPassword',
        defaultMessage: 'New password',
    },
    oldPassword: {
        id: 'profile.personal.oldPassword',
        defaultMessage: 'Old password',
    },
    passwordsDoNotMatch: {
        id: 'profile.personal.passwordsDoNotMatch',
        defaultMessage: 'Passwords do not match',
    },
    save: {
        id: 'profile.personal.save',
        defaultMessage: 'Save',
    },
    changePassword: {
        id: 'profile.personal.changePassword',
        defaultMessage: 'Change password',
    },
    cancel: {
        id: 'profile.personal.cancel',
        defaultMessage: 'Cancel',
    },
    editPassword: {
        id: 'profile.personal.editPassword',
        defaultMessage: 'Edit password',
    },
});