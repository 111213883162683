import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import cx from 'classnames';
import { AnimatePresence } from "framer-motion";
import PropTypes from 'prop-types';

import { Icon } from '@quidlo/ui';

import MonthView from './MonthView';

import style from './DatePickerContext.module.scss';

function arraymove(arr, fromIndex, toIndex) {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
}

const DatePickerContext = ({
    date,
    dateRange,
    type,
    month,
    year,
    onNext,
    onPrev,
    onChange,
    dateBoundary,
    weekStart
}) => {
    const
        props = {
            month,
            year,
            date: (type !== 'range' ? date : undefined),
            dateRange: (type !== 'single' ? dateRange : { gte: undefined, lte: undefined }),
            onChange,
            dateBoundary,
            weekStart
        },
        weekdays = [
            {
                id: 'weekdays.monday.short',
                description: 'Monday - one letter',
                defaultMessage: 'M',
                key: 'monday'
            }, {
                id: 'weekdays.tuesday.short',
                description: 'Tuesday - one letter',
                defaultMessage: 'T',
                key: 'tuesday'
            }, {
                id: 'weekdays.wednesday.short',
                description: 'Wednesday - one letter',
                defaultMessage: 'W',
                key: 'wednesday'
            }, {
                id: 'weekdays.thursday.short',
                description: 'Thursday - one letter',
                defaultMessage: 'T',
                key: 'thursday'
            }, {
                id: 'weekdays.friday.short',
                description: 'Friday - one letter',
                defaultMessage: 'F',
                key: 'friday'
            }, {
                id: 'weekdays.saturday.short',
                description: 'saturday - one letter',
                defaultMessage: 'S',
                key: 'saturday'
            }, {
                id: 'weekdays.sunday.short',
                description: 'Sunday - one letter',
                defaultMessage: 'S',
                key: 'sunday'
            },
        ];

    if (weekStart === 'sunday') {
        arraymove(weekdays, 6, 0);
    }

    return (
        <div className={cx(style.context)}>
            <div className={style.month}>
                <div>
                    <a onClick={onPrev} onKeyPress={onPrev}>
                        <Icon icon="caret-left" />
                    </a>
                </div>
                <div className={style.name}>
                    <FormattedDate value={new Date(year, month, 1)} month="long" year="numeric" />
                </div>
                <div>
                    <a onClick={onNext} onKeyPress={onPrev}>
                        <Icon icon="caret-right" />
                    </a>
                </div>
            </div>
            <div className={style.weeknames}>
                {weekdays.map(day => (
                    <div key={day.key}>
                        <FormattedMessage {...day} />
                    </div>
                ))}
            </div>
            <div className={style.view}>
                <AnimatePresence initial={false}>
                    <MonthView {...props} />
                </AnimatePresence>
            </div>
        </div>
    );
};

DatePickerContext.propTypes = {
    date: PropTypes.instanceOf(Date).isRequired,
    dateRange: PropTypes.shape({
        gte: PropTypes.instanceOf(Date).isRequired,
        lte: PropTypes.instanceOf(Date).isRequired
    }).isRequired,
    type: PropTypes.oneOf(['week', 'range', 'single']),
    month: PropTypes.number.isRequired,
    year: PropTypes.number.isRequired,
    weekStart: PropTypes.string.isRequired,
    direction: PropTypes.string,
    onNext: PropTypes.func,
    onPrev: PropTypes.func,
    onChange: PropTypes.func,
    dateBoundary: PropTypes.object
};

DatePickerContext.defaultProps = {
    direction: 'left',
    type: 'single',
    onNext: undefined,
    onPrev: undefined,
    onChange: undefined,
    dateBoundary: undefined
};

export default DatePickerContext;