import React, { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { Button, Counter, Header, Space, Tag, Tooltip } from '@quidlo/ui';

import { Container, List, ListActions, ListCell } from '../../../ui';
import ProjectPill from "../../../ui/ProjectPill/ProjectPill";

import style from './ChatbotList.module.scss';

import messages from './ChatbotList.i18n';

const howToAddRoom = () => {
        window.open(
            'https://quidlo.helpsite.com/articles/37060-hangouts-room-configuration',
            '_blank',
        );
    },
    ChatbotList = ({
        slackAccountConnected,
        googleChatRooms,
        slackTeams,
        handleDelete,
        handleEdit,
        handleSlackTeamDelete,
        hasPermissionToSeeRooms,
    }) => {
        const intl = useIntl();
        return (
            <div className={style.listContainer}>
                <Container>
                    <div className={style.infoCardContainer}>
                        <Space mb="l">
                            <Header
                                title={intl.formatMessage(messages.headerTitle)}
                                icon="chatbot"
                                size="large"
                                underline
                            />
                        </Space>
                        {hasPermissionToSeeRooms && (
                            <Space mb="l">
                                <div className={style.list}>
                                    <List
                                        title={intl.formatMessage(
                                            messages.listHeader,
                                        )}
                                        icon=""
                                        rightIcon={
                                            <img
                                                src={`${process.env.PUBLIC_URL}/img/hangouts@2x.png`}
                                                alt="hangouts icon"
                                            />
                                        }
                                        header={
                                            <Fragment>
                                                <ListCell
                                                    width="250px"
                                                    align="center"
                                                    header>
                                                    <FormattedMessage
                                                        {...messages.room}
                                                    />
                                                </ListCell>
                                                <ListCell
                                                    width="350px"
                                                    align="center"
                                                    header>
                                                    <FormattedMessage
                                                        {...messages.project}
                                                    />
                                                </ListCell>
                                                <ListCell align="center" header>
                                                    <FormattedMessage
                                                        {...messages.intent}
                                                    />
                                                </ListCell>
                                            </Fragment>
                                        }
                                        rightChildren={
                                            <Button
                                                type="plain"
                                                text={intl.formatMessage(
                                                    messages.addRoom,
                                                )}
                                                onClick={howToAddRoom}
                                            />
                                        }
                                        noResultsText={intl.formatMessage(
                                            messages.noRoomsConfigured,
                                        )}
                                        data={googleChatRooms}
                                        renderRow={({
                                            id,
                                            spaceName,
                                            projects,
                                            intents,
                                        }) => (
                                            <Fragment>
                                                <ListCell
                                                    width="300px"
                                                    align="left">
                                                    <div>{spaceName}</div>
                                                </ListCell>
                                                <ListCell width="450px">
                                                    <div className={style.cell}>
                                                        {projects
                                                            .slice(0, 3)
                                                            .map(p => <ProjectPill key={p.id} {...p} />)
                                                        }
                                                        {projects.length > 3 && (
                                                            <Tooltip
                                                                text={projects
                                                                    .slice(3)
                                                                    .map(
                                                                        p => p.name,
                                                                    )
                                                                    .join(', ')}>
                                                                <Counter
                                                                    number={
                                                                        projects.length -
                                                                        3
                                                                    }
                                                                />
                                                            </Tooltip>
                                                        )}
                                                    </div>
                                                </ListCell>
                                                <ListCell>
                                                    <div className={style.cell}>
                                                        {intents
                                                            .slice(0, 1)
                                                            .map(intent => (
                                                                <div
                                                                    key={intent.id}>
                                                                    <Tag
                                                                        text={
                                                                            intent.name
                                                                        }
                                                                        style={{
                                                                            maxWidth:
                                                                                '120px',
                                                                        }}
                                                                    />
                                                                </div>
                                                            ))}
                                                        {intents.length > 1 && (
                                                            <Tooltip
                                                                text={intents
                                                                    .slice(1)
                                                                    .map(
                                                                        i => i.name,
                                                                    )
                                                                    .join(', ')}>
                                                                <Counter
                                                                    number={
                                                                        intents.length -
                                                                        1
                                                                    }
                                                                />
                                                            </Tooltip>
                                                        )}
                                                    </div>
                                                </ListCell>
                                                <ListActions
                                                    actions={[
                                                        {
                                                            title: 'edit',
                                                            icon: 'edit',
                                                            onClick: () => {
                                                                handleEdit(id);
                                                            },
                                                        },
                                                        {
                                                            title: 'Delete',
                                                            icon: 'trash',
                                                            onClick: () => {
                                                                handleDelete(id);
                                                            },
                                                        },
                                                    ]}
                                                />
                                            </Fragment>
                                        )}
                                    />
                                </div>
                            </Space>
                        )}
                        {slackAccountConnected && (
                            <Space>
                                <div className={style.list}>
                                    <List
                                        title={intl.formatMessage(
                                            messages.slackListHeader,
                                        )}
                                        icon=""
                                        rightIcon={
                                            <img
                                                src={`${process.env.PUBLIC_URL}/img/slack@2x.png`}
                                                alt="slack icon"
                                            />
                                        }
                                        rightChildren={
                                            <a
                                                href={
                                                    process.env
                                                        .REACT_APP_ADD_TO_SLACK_URL
                                                }>
                                                <img
                                                    alt="Add to Slack"
                                                    height="40"
                                                    width="139"
                                                    src="https://platform.slack-edge.com/img/add_to_slack.png"
                                                    srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
                                                />
                                            </a>
                                        }
                                        data={slackTeams}
                                        renderRow={({ slackUserId, team }) => (
                                            <Fragment>
                                                <ListCell align="left">
                                                    <div>{team}</div>
                                                </ListCell>
                                                <ListActions
                                                    actions={[
                                                        {
                                                            title: 'Delete',
                                                            icon: 'trash',
                                                            onClick: () => {
                                                                handleSlackTeamDelete(
                                                                    slackUserId,
                                                                );
                                                            },
                                                        },
                                                    ]}
                                                />
                                            </Fragment>
                                        )}
                                    />
                                </div>
                            </Space>
                        )}
                    </div>
                </Container>
            </div>
        )};

ChatbotList.propTypes = {
    googleChatAccountConnected: PropTypes.bool.isRequired,
    slackAccountConnected: PropTypes.bool.isRequired,
    googleChatRooms: PropTypes.array.isRequired,
    slackTeams: PropTypes.array.isRequired,
    handleDelete: PropTypes.func.isRequired,
    handleEdit: PropTypes.func.isRequired,
    handleSlackTeamDelete: PropTypes.func.isRequired,
    hasPermissionToSeeRooms: PropTypes.bool,
};

ChatbotList.defaultProps = {
    hasPermissionToSeeRooms: false,
};

export default ChatbotList;
