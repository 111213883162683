import React from 'react';
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";

import SharedReportsService from "@quidlo/common/api/SharedReportsService";
import useAuthentication from "@quidlo/common/hooks/useAuthentication";
import useUI from "@quidlo/common/hooks/useUI";
import useWorkspace from "@quidlo/common/hooks/useWorkspace";
import analytics from "@quidlo/common/utils/analytics";
import clipboard from "@quidlo/common/utils/clipboard";
import { Button, Tooltip } from "@quidlo/ui";

import messages from "./ReportCreate.i18n";

const CopyToClipboard = () => {
    const location = useLocation();
    const { toastPush } = useUI();
    const intl = useIntl();
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();

    const copy = async () => {
        const getUrl = async () => {
            const filters = JSON.parse(window.atob(location.hash.substr(1)));
            const { data:{ reportUrl } } = await SharedReportsService.post(workspace.code, user.id, filters)
            return reportUrl;
        }

        if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
            await navigator.clipboard.write([new ClipboardItem({ "text/plain": getUrl() })]); // eslint-disable-line
        } else {
            await clipboard.copy(await getUrl())
        }

        toastPush({
            text: intl.formatMessage(messages.linkCopied)
        });

        const filters = JSON.parse(window.atob(location.hash.substr(1)));
        analytics.logTimesheetsReportsCreateCopyLink({
            days: ((new Date(filters.date.lte)) - (new Date(filters.date.gte))) / ((1000*60*60*24)),
            people: filters.users.length,
            projects: filters.projects.length,
            statuses: filters.statuses.length,
            tags: filters.tags.length,
            period: filters.period,
            type: filters.type
        });
    }

    return (
        <Tooltip
            text={intl.formatMessage(
                messages.copyLink,
            )}
            position="bottom">
            <Button
                icon="link"
                iconColor="blue"
                size="medium"
                iconSize="medium"
                color="white"
                type="bordered"
                onClick={copy}
                disabled={!location.hash}
            />
        </Tooltip>
    )
};

export default CopyToClipboard
