import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'rejectRequestsModal.title',
        defaultMessage: 'Withdraw accepted approval',
    },
    description1: {
        id: 'rejectRequestsModal.description1',
        defaultMessage: 'Are you sure you want to approve the following requests?'
    },
    ifYouWouldLike: {
        id: 'rejectRequestsModal.ifYouWouldLike',
        defaultMessage: 'If you would like to include a message, type below:',
    },
    message: {
        id: 'rejectRequestsModal.message',
        defaultMessage: 'Message (optional)',
    },
    cancel: {
        id: 'rejectRequestsModal.cancel',
        defaultMessage: 'Cancel',
    },
    withdraw: {
        id: 'rejectRequestsModal.withdraw',
        defaultMessage: 'Withdraw',
    },
});
