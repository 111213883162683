import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import style from './Counter.module.scss';

const Counter = ({ number, size }) => (
    <div className={cx(style.counter, style[`size-${size}`])}>
        <span>
            {number > 0 ? '+' : '-'}
            {number}
        </span>
    </div>
);

Counter.propTypes = {
    number: PropTypes.number.isRequired,
    size: PropTypes.oneOf(['small', 'medium']),
};

Counter.defaultProps = {
    size: 'medium',
};

export default Counter;
