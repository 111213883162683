import fetch from '../utils/fetch';

export default {
    get: (workspaceCode, userId, { date, projects = [], users = [], tags = [], type, period, billable, statuses = [] }) =>
        fetch.get(`/v1/${workspaceCode}/users/${userId}/reports/${type}`, { params: {
            period,
            statuses: statuses.join(','),
            billable: billable === 'all' ? '' : billable,
            'date-from': date.gte,
            'date-to': date.lte,
            'project-ids': projects.join(','),
            'user-ids': users.join(','),
            'tag-ids': tags.join(',')
        } }),
    getProjects: (workspaceCode, userId) =>
        fetch.get(`/v1/${workspaceCode}/users/${userId}/reports/filters/projects`,{ params: {
            'project-ids': ''
        } }),
    getTagsByProjects: (workspaceCode, userId, projects) =>
        fetch.get(`/v1/${workspaceCode}/users/${userId}/reports/filters/tags`, { params: {
            'project-ids': projects.join(',')
        } }),
    getUsersByProjects: (workspaceCode, userId, projectIds) =>
        fetch.get(`/v1/${workspaceCode}/users/${userId}/reports/filters/users`, { params: {
            'project-ids': projectIds.join(',')
        } }),
    getCSV: (workspaceCode, userId, { date, projects = [], users = [], tags = [], type, period, billable }) =>
        fetch.get(`/v1/${workspaceCode}/users/${userId}/reports/${type}/generate-csv`, {
            params: {
                period,
                billable: billable === 'all' ? '' : billable,
                'date-from': date.gte,
                'date-to': date.lte,
                'project-ids': projects.join(','),
                'user-ids': users.join(','),
                'tag-ids': tags.join(',')
            } }),
    getPDF: (workspaceCode, userId, { date, projects = [], users = [], tags = [], type, period, billable }) =>
        fetch.get(`/v1/${workspaceCode}/users/${userId}/reports/${type}/generate-pdf`, {
            responseType: 'arraybuffer',
            params: {
                period,
                billable: billable === 'all' ? '' : billable,
                'date-from': date.gte,
                'date-to': date.lte,
                'project-ids': projects.join(','),
                'user-ids': users.join(','),
                'tag-ids': tags.join(',')
            }
        }),
    getXLSX: (workspaceCode, userId, { date, projects = [], users = [], tags = [], type, period, billable }) =>
        fetch.get(`/v1/${workspaceCode}/users/${userId}/reports/${type}/generate-xlsx`, {
            responseType: 'arraybuffer',
            params: {
                period,
                billable: billable === 'all' ? '' : billable,
                'date-from': date.gte,
                'date-to': date.lte,
                'project-ids': projects.join(','),
                'user-ids': users.join(','),
                'tag-ids': tags.join(',')
            }
        }),
    exportToGoogleSheets: (workspaceCode, userId, { date, projects = [], users = [], tags = [], type, period, billable }, googleAccessToken ) => {
        const params = {
            period,
            billable: billable === 'all' ? '' : billable,
            'date-from': date.gte,
            'date-to': date.lte,
            'project-ids': projects.join(','),
            'user-ids': users.join(','),
            'tag-ids': tags.join(',')
        };
        return fetch.post(`/v1/${workspaceCode}/users/${userId}/reports/${type}/export-to-google-spreadsheets?period=${params.period}&billable=${params.billable}&date-from=${params['date-from']}&date-to=${params['date-to']}&project-ids=${params['project-ids']}&user-ids=${params['user-ids']}&tag-ids=${params['tag-ids']}`, { googleAccessToken });
    }
};