import { defineMessages } from 'react-intl';

export default defineMessages({
    all: {
        id: 'toApproveList.all',
        defaultMessage: 'All',
    },
    project: {
        id: 'toApproveList.project',
        defaultMessage: 'Project',
    },
    status: {
        id: 'toApproveList.status',
        defaultMessage: 'Status',
    },
    requestDate: {
        id: 'toApproveList.requestDate',
        defaultMessage: 'Request date',
    },
    person: {
        id: 'toApproveList.person',
        defaultMessage: 'Person',
    },
    duration: {
        id: 'toApproveList.duration',
        defaultMessage: 'Duration',
    },
    period: {
        id: 'toApproveList.period',
        defaultMessage: 'Period',
    },
    tags: {
        id: 'toApproveList.tags',
        defaultMessage: 'Tags',
    },
    details: {
        id: 'toApproveList.details',
        defaultMessage: 'Details',
    },
    total: {
        id: 'toApproveList.total',
        defaultMessage: 'Total:',
    },
    approve: {
        id: 'toApproveList.approve',
        defaultMessage: 'Approve',
    },
    reject: {
        id: 'toApproveList.reject',
        defaultMessage: 'Reject',
    },
    noDataHeader: {
        id: 'toApproveList.noDataHeader',
        defaultMessage: 'No time approvals to display'
    },
    noDataBody: {
        id: 'noDataBodyFilter.noDataBody',
        defaultMessage: 'No time approval requests found that match the selected filters. Try changing the filters.'
    },
});