import { defineMessages } from 'react-intl';

export default defineMessages({
    projects: {
        id: 'toApprove.projects',
        defaultMessage: 'Projects',
    },
    people: {
        id: 'toApprove.people',
        defaultMessage: 'People',
    },
    tags: {
        id: 'toApprove.tags',
        defaultMessage: 'Tags',
    },
    status: {
        id: 'toApprove.status',
        defaultMessage: 'Status',
    },
    apply: {
        id: 'toApprove.apply',
        defaultMessage: 'Apply',
    },
    accepted: {
        id: 'toApprove.accepted',
        defaultMessage: 'Accepted'
    },
    rejected: {
        id: 'toApprove.rejected',
        defaultMessage: 'Rejected'
    },
    pending: {
        id: 'toApprove.pending',
        defaultMessage: 'Pending'
    },
    withdrawn: {
        id: 'toApprove.withdrawn',
        defaultMessage: 'Withdrawn'
    },
});