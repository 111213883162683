import React from 'react';
import cx from "classnames";
import { motion } from "framer-motion";
import PropTypes from 'prop-types';

import { Icon } from "@quidlo/ui";

import style from "./Switch.module.scss";

const Switch = ({
    value,
    onChange,
    disabledStart
}) => (
    <div className={style.switch} onClick={() => onChange((value === 'start' || disabledStart) ? 'add' : 'start')}>
        <div className={cx([style.option, disabledStart && style.disabled])}>
            <Icon icon="timer" color={(value === 'start' || disabledStart) ? 'white' : 'blue'} />
            {
                value === 'start' && (
                    <motion.div
                        className={style.bg}
                        layoutId={`tracker-add-switch`}
                    />
                )
            }
        </div>
        <div className={style.option}>
            <Icon icon="plus-circle" color={value === 'add' ? 'white' : 'blue'} />
            {
                value === 'add' && (
                    <motion.div
                        className={style.bg}
                        layoutId={`tracker-add-switch`}
                    />
                )
            }
        </div>
    </div>
);

Switch.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    disabledStart: PropTypes.bool.isRequired
};


export default Switch;
