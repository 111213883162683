import React from 'react';
import { useIntl } from 'react-intl';

import useUI from '@quidlo/common/hooks/useUI';
import analytics from "@quidlo/common/utils/analytics";
import { Button, Space } from '@quidlo/ui';

import illustration from "../../../../assets/modals/happy-ipad.png";
import { approveRequestMutation } from '../../../../queries/approvals.queries';
import ModalHOC, { ModalActions, ModalContent, ModalHeader } from "../../../../ui/Modal/Modal";
import SummaryTable from "../../components/SummaryTable";

import style from './ApproveRequestsModal.module.scss';

import messages from './ApproveRequestsModal.i18n';

const ApproveRequestsModal = () => {
    const
        intl = useIntl(),
        { modal, modalClose } = useUI(),
        { projectsToApprove, dateRange } = modal.data || {},
        { mutate: approveApproval } = approveRequestMutation(),
        approve = () => {
            const people = projectsToApprove.reduce((acc, { user }) => acc.includes(user.id) ? acc : [...acc, user.id], [])
            analytics.logTimesheetsApprovalsTimeApprovalRequestAccept({
                people: people.length,
                projects: projectsToApprove.length,
                startDate: dateRange.gte,
                endDate: dateRange.lte,
            })
            approveApproval(projectsToApprove.map(proj => proj.id))
            modal.callback()
            modalClose()
        },
        cancel = () => {
            analytics.logTimesheetsApprovalsTimeApprovalRequestCancelAccept();
            modalClose();
            modal.callback();
        };

    return (
        <>
            <ModalHeader>
                {intl.formatMessage(messages.title)}
            </ModalHeader>
            <ModalContent>
                <Space mb="m" className={style.description}>
                    {`${intl.formatMessage(messages.description)} `}
                </Space>
                <Space mb="m">
                    <SummaryTable dateRange={dateRange} list={projectsToApprove} />
                </Space>
            </ModalContent>
            <ModalActions>
                <Space mr="m">
                    <Button
                        color="white"
                        text={intl.formatMessage(messages.cancel)}
                        onClick={cancel}
                    />
                </Space>
                <Button
                    color="blue"
                    text={intl.formatMessage(messages.approve)}
                    onClick={approve}
                    shadow
                />
            </ModalActions>
        </>
    )
};

export default ModalHOC(
    ApproveRequestsModal,
    {
        id: 'approveRequests',
        size: 'small',
        illustration,
    }
);