import React, { useEffect, useRef } from 'react';
import cx from "classnames";
import { motion } from "framer-motion";
import PropTypes from 'prop-types';

import Space from "../../Space";

import { ReactComponent as SVGRemove } from "./svg/remove.svg";

import style from "./Toast.module.scss";

const
    DEFAULT_DISPLAY_TIME = 5000,
    Toast = ({ text, color, close }) => {
        const timer = useRef();

        useEffect(() => {
            timer.current = setTimeout(close, DEFAULT_DISPLAY_TIME);

            return () => {
                clearTimeout(timer.current);
            }
        }, []);
        return (
            <motion.div
                className={cx(style.toast, style[`color-${color}`])}
                initial={{ x: 5, opacity:0 }}
                animate={{ x: 0, opacity:1 }}
                exit={{ x: 5, opacity:0 }}
            >
                <Space pv="s" ph="m">
                    <motion.div
                        className={style.content}
                        initial={{ opacity:0 }}
                        animate={{ opacity:1 }}
                        exit={{ opacity:0 }}
                        transition={{
                            delay: 0.2,
                        }}
                    >
                        <Space className={style.text} mr="s">
                            <span>
                                {text}
                            </span>
                        </Space>
                        <a
                            className={style.close}
                            onClick={close}
                        >
                            <SVGRemove width={16} />
                        </a>
                    </motion.div>
                </Space>
            </motion.div>
        );
    };

Toast.propTypes = {
    id: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    color: PropTypes.oneOf(['red', 'green', 'yellow', 'blue']),
    close: PropTypes.func.isRequired,
};

Toast.defaultProps = {
    color: 'green'
};

export default Toast;
