import { defineMessages } from 'react-intl';

export default defineMessages({
    approved: {
        id: 'approval.status.approved',
        defaultMessage: 'Approved'
    },
    pending: {
        id: 'approval.status.pending',
        defaultMessage: 'Pending'
    },
    rejected: {
        id: 'approval.status.rejected',
        defaultMessage: 'Rejected'
    },
    'reports.periods.month': {
        id: 'reports.periods.month',
        defaultMessage: 'Months'
    },
    'reports.periods.day': {
        id: 'reports.periods.day',
        defaultMessage: 'Days'
    },
    'reports.periods.week': {
        id: 'reports.periods.week',
        defaultMessage: 'Weeks'
    },
    'reports.types.tasks': {
        id: 'reports.types.tasks',
        defaultMessage: 'Tasks'
    },
    'reports.types.time': {
        id: 'reports.periods.time',
        defaultMessage: 'Projects'
    },
    'reports.types.people': {
        id: 'reports.periods.people',
        defaultMessage: 'People'
    },
});
