import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'deleteWorkspace.title',
        defaultMessage: 'Delete workspace',
    },
    subtitle: {
        id: 'deleteWorkspace.subtitle',
        defaultMessage: 'Are you sure you want to delete the workspace? This action is not reversible.'
    },
    alt: {
        id: 'deleteWorkspace.alt',
        defaultMessage: 'An exit',
    },
    delete: {
        id: 'deleteWorkspace.delete',
        defaultMessage: 'Delete',
    },
    cancel: {
        id: 'deleteWorkspace.cancel',
        defaultMessage: 'Cancel',
    },
});
