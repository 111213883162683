import React from 'react';
import PropTypes from 'prop-types';

import Animation from "../Animation";

import anim from "./anim.json"

import style from './ProgressCircle.module.scss';

const
    ProgressCircle = ({ children }) => (
        <div
            className={style.progressCircle}
        >
            <div className={style.animation}>
                <Animation height={55} width={55} animationData={anim} loop={true} autoplay={true} />
            </div>
            <div className={style.children}>
                {children}
            </div>
        </div>
    );

ProgressCircle.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
};

export default ProgressCircle;
