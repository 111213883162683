import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import analytics from "@quidlo/common/utils/analytics";
import { Tooltip } from '@quidlo/ui';

import style from './Suggestions.module.scss';

const Suggestions = ({ options, suggestions, onClick, maxNumber, analyticsID }) => {
    const sugs = useMemo(() => {
        if (options) {
            if (suggestions.length) {
                return suggestions.map(s => options.find(o => o.value === s)).filter(o => o).splice(0,maxNumber)
            } else {
                return options.splice(0,maxNumber)
            }
        } else {
            return suggestions.map(s => ({ label: s, value: s })).splice(0,maxNumber)
        }
    }, [options, suggestions])

    const click = value => () => {
        if (analyticsID) {
            analytics.logTimesheetsSuggestionClick({ id: analyticsID })
        }
        onClick(value)
    }

    return (
        <div className={style.suggestions}>
            {sugs.map((v, i) => {
                return (
                    <Tooltip text={v.label} position="bottom" key={v.value}>
                        <div
                            className={style.chip}
                            key={v.value}
                            onClick={click(v.value)}
                            style={{ animationDelay: `${(i - 1) * 0.2}s` }}
                        >
                            <span className={style.label}>{v.label}</span>
                        </div>
                    </Tooltip>
                )
            })}
        </div>
    );
};

Suggestions.propTypes = {
    options: PropTypes.arrayOf(PropTypes.object),
    suggestions: PropTypes.array,
    onClick: PropTypes.func.isRequired,
    maxNumber: PropTypes.number,
    analyticsID: PropTypes.string
};

Suggestions.defaultProps = {
    options: null,
    suggestions: [],
    maxNumber: 2,
    analyticsId: null
};

export default Suggestions;
