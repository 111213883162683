import { defineMessages } from 'react-intl';

export default defineMessages({
    sorryPage: {
        id: 'error404.sorryPage',
        defaultMessage: 'Sorry, this page doesn’t exist',
    },
    checkRecent: {
        id: 'error404.checkRecent',
        defaultMessage: 'Check recent project activity instead',
    },
    goTimer: {
        id: 'error404.goTimer',
        defaultMessage: 'Go to tracker',
    }
});