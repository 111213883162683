import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

const InfiniteLoader = ({
    action,
    isMore,
}) => {
    const elementRef = useRef(null),
        [isLoading, setIsLoading] = useState(false),
        onScroll = () => {
            if (isMore && !isLoading && (elementRef.current.getBoundingClientRect().top <= window.innerHeight)) {
                setIsLoading(true);
                action().then(() => {
                    setIsLoading(false);
                }).catch(() => {
                    setIsLoading(false);
                });
            }
        };

    useEffect(() => {
        window.addEventListener('scroll', onScroll, false);
        return (() => {
            window.removeEventListener('scroll', onScroll, false);
        });
    }, [isMore, isLoading]);

    return (
        <div ref={elementRef} />
    );
};

InfiniteLoader.propTypes = {
    action: PropTypes.func,
    isMore: PropTypes.bool,
};

InfiniteLoader.defaultProps = {
    action: undefined,
    isMore: false
};

export default InfiniteLoader;
