import { defineMessages } from 'react-intl';

export default defineMessages({
    proFeatures: {
        id: 'proFeatures.proFeatures',
        defaultMessage: 'PRO features',
    },
    requiredFieldsTitle: {
        id: 'proFeatures.requiredFieldsTitle',
        defaultMessage: 'Required fields',
    },
    requiredFieldsDescription: {
        id: 'proFeatures.requiredFieldsDescription',
        defaultMessage: 'Timesheets can set the <b>time entry description field</b> as required on the Tracker.',
    },
    requiredFieldsDescriptionActive: {
        id: 'proFeatures.requiredFieldsDescriptionActive',
        defaultMessage: 'Timesheets set the <b>time entry description field</b>  as required on the Tracker.',
    },
    startAndEndTimeEntryTitle: {
        id: 'proFeatures.startAndEndTimeEntryTitle',
        defaultMessage: 'Start & end time entry ',
    },
    startAndEndTimeEntryDescriptionOn: {
        id: 'proFeatures.startAndEndTimeEntryDescriptionOn',
        defaultMessage: 'Timesheets allows each time entry to contain information about the time it started and ended.',
    },
    startAndEndTimeEntryDescriptionOff: {
        id: 'proFeatures.startAndEndTimeEntryDescriptionOff',
        defaultMessage: 'Timesheets can allow each time entry to contain information about the time it started and ended.',
    },
    timeApprovalsTitle: {
        id: 'proFeatures.timeApprovalsTitle',
        defaultMessage: 'Time approvals',
    },
    timeApprovalsDescription: {
        id: 'proFeatures.timeApprovalsDescription',
        defaultMessage: 'Timesheets can allow users to request and receive approvals of their time entries in projects.',
    },
    timeApprovalsDescriptionActive: {
        id: 'proFeatures.timeApprovalsDescriptionActive',
        defaultMessage: 'Timesheets allows users to request and receive approvals of their time entries within the app.',
    },
    unlimitedUsersTitle: {
        id: 'proFeatures.unlimitedUsersTitle',
        defaultMessage: 'Unlimited users',
    },
    unlimitedUsersDescription: {
        id: 'proFeatures.unlimitedUsersDescription',
        defaultMessage: 'Timesheets can activate an unlimited number of users in your Workspace.',
    },
    publicProjectsTitle: {
        id: 'proFeatures.publicProjectsTitle',
        defaultMessage: 'Public projects',
    },
    publicProjectsDescription: {
        id: 'proFeatures.publicProjectsDescription',
        defaultMessage: 'Timesheets can allow to optionally set projects in the workspace as public. It means all workspace members can join public projects and add their time entries.',
    },
    managersAndAdminsManagementTitle: {
        id: 'proFeatures.managersAndAdminsManagementTitle',
        defaultMessage: 'Managers & admins management',
    },
    managersAndAdminsManagementDescription: {
        id: 'proFeatures.managersAndAdminsManagementDescription',
        defaultMessage: 'Timesheets can allow workspace owner to assign admin or manager roles to other workspace members.',
    },
    upgrade: {
        id: 'proFeatures.upgrade',
        defaultMessage: 'Upgrade',
    },
});
