import React, { useEffect, useMemo } from 'react';
import { FormattedDate } from "react-intl";
import PropTypes from 'prop-types';

import { APPROVAL_STATUS } from "@quidlo/common/constants/main";
import useAuthentication from "@quidlo/common/hooks/useAuthentication";
import useForm from "@quidlo/common/hooks/useForm2";
import useUI from "@quidlo/common/hooks/useUI";
import useWorkspace from "@quidlo/common/hooks/useWorkspace";
import useWorkspaceFeatures from "@quidlo/common/hooks/useWorkspaceFeatures";
import analytics from "@quidlo/common/utils/analytics";
import { durationFormatter, durationParser, durationShortFormatter } from "@quidlo/common/utils/duration";
import { timeParser } from "@quidlo/common/utils/time";
import validators from "@quidlo/common/utils/validators";
import { Avatar, Icon, Input, Select, Space, Tag, Tooltip } from "@quidlo/ui";

import { editTaskMutation } from "../../../../queries/tasks.queries";
import { getTrackerFiltersTagsQuery } from "../../../../queries/tracker.queries";
import { ApprovalStatus, ListCell, Text } from "../../../../ui";
import ProjectPill from "../../../../ui/ProjectPill/ProjectPill";
import { stringToDate } from "../../../../utils/dateHelpers";

const TaskRow = ({
    date,
    user: {
        id: userId,
        firstName,
        lastName,
        avatarUrl,
    },
    time_from,
    time_to,
    durationMins,
    title,
    tags,
    approval_status,
    project: {
        name,
        color,
        isBillable,
        isArchived,
    },
    id: taskId,
    status,
    project,
    isStatus,
    isEditable
}) => {
    const { modalOpen } = useUI();
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    const { features } = useWorkspaceFeatures();
    const { mutate: editTask } = editTaskMutation();
    const { data: projectTags } = getTrackerFiltersTagsQuery(project.id);

    const [editForm, { cleanForm, isFormDirty, isFormValid }] = useForm(useMemo(() => ({
        title: {
            value: '',
            validators: features?.required_task_description ? [validators.isRequired(), validators.minLength(2), validators.maxLength(400)] : []
        },
        timeFrom: {
            value: '',
            validators: features?.task_with_from_to ? [validators.isRequired(), validators.isTime()] : []
        },
        timeTo: {
            value: '',
            validators: features?.task_with_from_to ? [validators.isRequired(), validators.isTime()] : []
        },
        durationMins: {
            value: '',
            validators: [validators.isRequired(), validators.isDuration()]
        },
        tags: {
            value: []
        }
    }), [features]));

    useEffect(() => {
        if (status === 'normal' && isFormDirty && isFormValid) {
            const delta = {};
            if (editForm.title.isDirty) {
                delta.title = editForm.title.value
            }
            if (editForm.durationMins.isDirty) {
                delta.durationMins = durationParser(editForm.durationMins.value)
            }
            if (editForm.tags.isDirty) {
                delta.tagIds = editForm.tags.value
            }
            if (editForm.timeFrom.isDirty) {
                delta.timeFrom = editForm.timeFrom.value
            }
            if (editForm.timeTo.isDirty) {
                delta.timeTo = editForm.timeTo.value
            }
            editTask({ id: taskId, delta });
            analytics.logTimesheetsReportsTaskEdit({
                actor: userId === user.id ? 'self' : workspace.role,
                interaction: 'Inline',
                isDesc: !!delta.title,
                isDuration: !!delta.durationMins,
                isTimeFrom: !!delta.timeFrom,
                isTimeTo: !!delta.timeTo,
                isTags: !!delta.tagIds
            })
            cleanForm();
        } else if (status === 'edit') {
            editForm.title.setValue(title);
            if (features?.task_with_from_to && time_from && time_to) {
                editForm.timeFrom.setValue(timeParser(time_from));
                editForm.timeTo.setValue(timeParser(time_to));
            }
            editForm.durationMins.setValue(durationFormatter(durationMins));
            editForm.tags.setValue(tags.map(t => t.id));
        }
    }, [status]);

    const onDurationBlur = () => {
        const durationMins = durationParser(editForm.durationMins.value);
        let [hours, minutes] = editForm.timeFrom.value.split(':');
        minutes = parseInt(minutes) + durationMins;
        hours = parseInt(hours) + (minutes > 59 ? Math.floor(minutes / 60) : 0);
        minutes = minutes > 59 ? minutes % 60 : minutes;
        if (hours < 24) {
            editForm.timeTo.onChange(`${hours < 10 ? `0${hours}` : hours }:${minutes < 10 ? `0${minutes}` : minutes}`);
        }
    }

    const onTimeFromBlur = () => {
        editForm.timeFrom.onChange(timeParser(editForm.timeFrom.value));
        if (editForm.timeFrom.value) {
            if (editForm.timeFrom.value && editForm.timeTo.value) {
                const timeFrom = new Date(`${date} ${timeParser(editForm.timeFrom.value)}`);
                const timeTo = new Date(`${date} ${editForm.timeTo.value}`);
                const duration = timeTo - timeFrom;
                if (duration>=0) {
                    editForm.durationMins.onChange(durationShortFormatter(duration/60000));
                }
            }
        }
    }

    const onTimeToBlur = () => {
        editForm.timeTo.onChange(timeParser(editForm.timeTo.value));
        if (editForm.timeTo.value) {
            if (editForm.timeFrom.value && editForm.timeTo.value) {
                const timeFrom = new Date(`${date} ${editForm.timeFrom.value}`);
                const timeTo = new Date(`${date} ${timeParser(editForm.timeTo.value)}`);
                const duration = timeTo - timeFrom;
                if (duration>=0) {
                    editForm.durationMins.onChange(durationShortFormatter(duration/60000));
                }
            }
        }
    }

    return (
        <>
            <ListCell width="224px">
                <Tooltip text={name} position="bottom">
                    <ProjectPill color={color} name={name} isBillable={isBillable} isArchived={isArchived}  />
                </Tooltip>
            </ListCell>
            {isStatus &&
                <ListCell width="100px" align="center">
                    <ApprovalStatus status={approval_status} />
                </ListCell>
            }
            <ListCell width="112px" align="center">
                <FormattedDate value={stringToDate(date)} day="2-digit" weekday="short" month="short" />
            </ListCell>
            <ListCell width="95px" align="center">
                <Tooltip text={`${firstName} ${lastName}`} position="right">
                    <Avatar firstName={firstName} lastName={lastName} picture={avatarUrl} />
                </Tooltip>
            </ListCell>
            <ListCell width="200px" align="center">
                {
                    status === 'edit' && workspace && approval_status !== 'accepted' ? (
                        <Input
                            {...editForm.title}
                            analyticsID="Reports - Edit - Description"
                        />
                    ) : (
                        <div>
                            <Text lines={2}>
                                {title}
                            </Text>
                        </div>
                    )
                }
            </ListCell>
            <ListCell align="left">
                {
                    status === 'edit' && workspace && approval_status !== 'accepted' ? (
                        <Select
                            {...editForm.tags}
                            options={projectTags.map(t => ({
                                label: t.name,
                                labelText: t.name,
                                value: t.id
                            }))}
                            multiselect
                            isNarrow
                        />
                    ) : (
                        <span>
                            {
                                tags.length === 2 && tags[0].name.length <= 6 && tags[1].name.length <= 6 ?
                                    <span>
                                        <Tooltip text={tags[0].name} key={tags[0].id}>
                                            <Tag text={tags[0].name} style={{ maxWidth: '94px' }} />
                                        </Tooltip>
                                        <Tooltip text={tags[1].name} key={tags[1].id}>
                                            <Tag text={tags[1].name} style={{ maxWidth: '94px' }} />
                                        </Tooltip>
                                    </span>
                                    : tags.length > 1 && tags[0].name.length <= 3 && tags[1].name.length <= 3 ?
                                        <span>
                                            <Tooltip text={tags[0].name} key={tags[0].id}>
                                                <Tag text={tags[0].name} style={{ maxWidth: '94px' }} />
                                            </Tooltip>
                                            <Tooltip text={tags[1].name} key={tags[1].id}>
                                                <Tag text={tags[1].name} style={{ maxWidth: '94px' }} />
                                            </Tooltip>
                                            <Tooltip text={tags.slice(2)
                                                .map(tag => tag.name)
                                                .join(', ')}
                                            >
                                                <Tag text={`+${tags.length - 2}`} style={{ maxWidth: '94px' }} />
                                            </Tooltip>
                                        </span>
                                        : tags.length > 1 && tags[0].name.length > 5 ?
                                            <span>
                                                <Tooltip text={tags[0].name} key={tags[0].id}>
                                                    <Tag text={tags[0].name} style={{ maxWidth: '94px' }} />
                                                </Tooltip>
                                                <Tooltip text={tags.slice(1)
                                                    .map(tag => tag.name)
                                                    .join(', ')}
                                                >
                                                    <Tag text={`+${tags.length - 1}`} style={{ maxWidth: '94px' }} />
                                                </Tooltip>
                                            </span>
                                            : tags.length === 1 && tags[0].name.length > 15 ?
                                                <Tooltip text={tags[0].name} key={tags[0].id}>
                                                    <Tag text={tags[0].name} style={{ maxWidth: '140px' }} />
                                                </Tooltip>
                                                : ((tags.length === 1 && tags[0].name.length <= 15) || (tags.length > 1 && tags[0].name.length < 5)) ?
                                                    <span>
                                                        <Tag key={tags[0].id} text={tags[0].name} />
                                                        {tags.length > 1 &&
                                                        <Tooltip text={tags.slice(1)
                                                            .map(tag => tag.name)
                                                            .join(', ')}
                                                        >
                                                            <Tag text={`+${tags.length - 1}`} style={{ maxWidth: '94px' }} />
                                                        </Tooltip>
                                                        }
                                                    </span>
                                                    : <span>
                                                        {tags.length > 0 &&
                                                        <Tag key={tags[0].id} text={tags[0].name} style={{ maxWidth: '90px' }} />}
                                                        {tags.length > 1 &&
                                                        <Tooltip text={tags.slice(1)
                                                            .map(tag => tag.name)
                                                            .join(', ')}
                                                        >
                                                            <Tag text={`+${tags.length - 1}`} style={{ maxWidth: '94px' }} />
                                                        </Tooltip>
                                                        }
                                                    </span>
                            }
                        </span>
                    )
                }

            </ListCell>
            {
                features?.task_with_from_to && (
                    <ListCell width="120px" align="left">
                        {
                            status === 'edit' && isEditable ? (
                                <Space direction="row">
                                    <Space>
                                        <Input
                                            {...editForm.timeFrom}
                                            analyticsID="Reports - Edit - Time start"
                                            onBlur={onTimeFromBlur}
                                        />
                                    </Space>
                                    <Space mh="xxs" pt="s">-</Space>
                                    <Space>
                                        <Input
                                            {...editForm.timeTo}
                                            analyticsID="Reports - Edit - Time stop"
                                            onBlur={onTimeToBlur}
                                        />
                                    </Space>
                                </Space>
                            ) : (
                                <span>
                                    {time_from && time_to && `${timeParser(time_from)} - ${timeParser(time_to)}`}
                                </span>
                            )
                        }
                    </ListCell>
                )
            }
            <ListCell width="96px" align="center">
                {
                    status === 'edit' && workspace && approval_status !== 'accepted' ? (
                        <Input
                            {...editForm.durationMins}
                            analyticsID="Reports - Edit - Duration"
                            onBlur={onDurationBlur}
                        />
                    )
                        : (
                            <span>
                                {durationFormatter(durationMins || 0)}
                            </span>)
                }
            </ListCell>
            {
                isEditable && (
                    <ListCell width={'95px'} align="center" last>
                        {
                            (approval_status !== APPROVAL_STATUS.ACCEPTED) && (
                                <Space direction="row">
                                    <Space mr="xs">
                                        <Icon
                                            icon="edit"
                                            color="blue"
                                            onClick={() => modalOpen('editTask', { id: taskId, source: 'reports', actor: userId === user.id ? 'self' : workspace.role })}
                                        />
                                    </Space>
                                    <Icon
                                        icon="trash"
                                        color="blue"
                                        onClick={() => modalOpen('deleteTask', { id: taskId, source: 'reports' }, () => {
                                            analytics.logTimesheetsReportsTaskDelete(userId === user.id ? 'self' : workspace.role);
                                        })}
                                    />
                                </Space>
                            )
                        }
                    </ListCell>
                )
            }
        </>
    )
};


TaskRow.propTypes = {
    date: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    user: PropTypes.object.isRequired,
    firstName: PropTypes.string,
    approval_status: PropTypes.string,
    lastName: PropTypes.string,
    title: PropTypes.string.isRequired,
    tags: PropTypes.array.isRequired,
    durationMins: PropTypes.number.isRequired,
    time_from: PropTypes.string,
    time_to: PropTypes.string,
    project: PropTypes.object.isRequired,
    status: PropTypes.string.isRequired,
    isEditable: PropTypes.bool,
    isStatus: PropTypes.bool,
};

TaskRow.defaultProps = {
    firstName: "",
    lastName: "",
    isEditable: false,
    isStatus: false,
    approval_status: null,
    time_from: null,
    time_to: null,
};

export default TaskRow;
