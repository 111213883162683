import React, { Fragment } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useParams } from 'react-router-dom';

import { FEATURE } from "@quidlo/common/constants/main";
import useUI from '@quidlo/common/hooks/useUI';
import useWorkspace from '@quidlo/common/hooks/useWorkspace';
import useWorkspaceFeatures from "@quidlo/common/hooks/useWorkspaceFeatures";
import { durationFormatter } from '@quidlo/common/utils/duration';
import { timeParser } from "@quidlo/common/utils/time";
import { Avatar, AvatarStack, Button, Counter, Header, Icon, Pill, Space, Tag, Tooltip } from '@quidlo/ui';

import { getApprovalDetailsQuery, getApprovalRequestDetailsQuery } from '../../../queries/approvals.queries';
import { ApprovalStatus, Container, List, ListCell, Text } from '../../../ui';
import ViewHOC from '../../../ui/View/View';
import ApproveRequestsModal from '../modals/ApproveRequestsModal';
import RejectRequestsModal from '../modals/RejectRequestsModal';
import WithdrawAcceptedApprovalModal from '../modals/WithdrawAcceptedApprovalModal';

import style from './ApprovalDetails.module.scss';

import messages from './ApprovalDetails.i18n';

const ApprovalDetails = () => {
    const { id } = useParams(),
        location = useLocation(),
        { workspace } = useWorkspace(),
        { features } = useWorkspaceFeatures(),
        isRequestedDetails = location.pathname.includes('requested'),
        isManager = workspace.projectsRole === 'manager',
        { data: {
            from,
            to,
            project,
            user,
            tasks,
            duration_mins_sum,
            request_date,
            status,
            manager,
            managers,
            note
        } } = isRequestedDetails ? getApprovalRequestDetailsQuery(id) : getApprovalDetailsQuery(id),
        { modalOpen } = useUI(),
        intl = useIntl();

    const renderRow = ({
        date,
        duration_mins,
        title,
        tags,
        time_from,
        time_to,
    }) => {
        return (
            <>
                <ListCell width="224px" align="left">
                    <Tag text={project.name} color={project.color} type={'stroked'} icon={project.is_billable ? 'dollar' : ''} />
                </ListCell>
                <ListCell width="112px">
                    {date}
                </ListCell>
                <ListCell width="72px" align='center'>
                    <Tooltip text={`${user.first_name} ${user.last_name}`}>
                        <Avatar picture={user.avatar_url} firstName={user.first_name} lastName={user.last_name} />
                    </Tooltip>
                </ListCell>
                <ListCell align='left'>
                    <Text lines={2}>
                        {title}
                    </Text>
                </ListCell>
                <ListCell width="224px" align="left">
                    <span>
                        {
                            tags.length === 2 && tags[0].name.length <= 6 && tags[1].name.length <= 6 ?
                                <span>
                                    <Tooltip text={tags[0].name} key={tags[0].id}>
                                        <Tag text={tags[0].name} style={{ maxWidth: '94px' }} />
                                    </Tooltip>
                                    <Tooltip text={tags[1].name} key={tags[1].id}>
                                        <Tag text={tags[1].name} style={{ maxWidth: '94px' }} />
                                    </Tooltip>
                                </span>
                                : tags.length > 1 && tags[0].name.length <= 3 && tags[1].name.length <= 3 ?
                                    <span>
                                        <Tooltip text={tags[0].name} key={tags[0].id}>
                                            <Tag text={tags[0].name} style={{ maxWidth: '94px' }} />
                                        </Tooltip>
                                        <Tooltip text={tags[1].name} key={tags[1].id}>
                                            <Tag text={tags[1].name} style={{ maxWidth: '94px' }} />
                                        </Tooltip>
                                        <Tooltip text={tags.slice(2)
                                            .map(tag => tag.name)
                                            .join(', ')}
                                        >
                                            <Counter number={tags.length - 2} />
                                        </Tooltip>
                                    </span>
                                    : tags.length > 1 && tags[0].name.length > 5 ?
                                        <span>
                                            <Tooltip text={tags[0].name} key={tags[0].id}>
                                                <Tag text={tags[0].name} style={{ maxWidth: '94px' }} />
                                            </Tooltip>
                                            <Tooltip text={tags.slice(1)
                                                .map(tag => tag.name)
                                                .join(', ')}
                                            >
                                                <Counter number={tags.length - 1} />
                                            </Tooltip>
                                        </span>
                                        : tags.length === 1 && tags[0].name.length > 15 ?
                                            <Tooltip text={tags[0].name} key={tags[0].id}>
                                                <Tag text={tags[0].name} style={{ maxWidth: '140px' }} />
                                            </Tooltip>
                                            : ((tags.length === 1 && tags[0].name.length <= 15) || (tags.length > 1 && tags[0].name.length < 5)) ?
                                                <span>
                                                    {tags.length > 0 && <Tag key={tags[0].id} text={tags[0].name} />}
                                                    {tags.length > 1 &&
                                                        <Tooltip text={tags.slice(1)
                                                            .map(tag => tag.name)
                                                            .join(', ')}
                                                        >
                                                            <Counter number={tags.length - 1} />
                                                        </Tooltip>
                                                    }
                                                </span>
                                                : <span>
                                                    {tags.length > 0 && <Tag key={tags[0].id} text={tags[0].name}
                                                        style={{ maxWidth: '90px' }} />}
                                                    {tags.length > 1 &&
                                                        <Tooltip text={tags.slice(1)
                                                            .map(tag => tag.name)
                                                            .join(', ')}
                                                        >
                                                            <Counter number={tags.length - 1} />
                                                        </Tooltip>
                                                    }
                                                </span>
                        }
                    </span>
                </ListCell>
                {
                    features?.task_with_from_to && (
                        <ListCell width="120px" align="left">
                            <span>
                                {time_from && time_to && `${timeParser(time_from)} - ${timeParser(time_to)}`}
                            </span>
                        </ListCell>
                    )
                }
                <ListCell width="96px" align="center">
                    {durationFormatter(duration_mins)}
                </ListCell>
            </>
        );
    };

    return (
        <Container>
            <Header
                title={intl.formatMessage(messages.details)}
                size="large"
                goBack
                underline
                rightChildren={
                    status === 'accepted' && isManager &&
                    <Button
                        onClick={() => modalOpen('withdrawAcceptedApproval', { projectsToWithdraw: [{ id, user, project, duration_mins: duration_mins_sum }], dateRange: {
                            gte: new Date(from),
                            lte: new Date(to)
                        } })}
                        text={intl.formatMessage(messages.withdrawApproval)}
                        shadow
                        size={'small'}
                    />
                }
            />
            {note &&
                <Space mt={'m'}>
                    <div className={style.noteFromManagerWrapper}>
                        <img src={require('./assets/paperplane.png')} alt="Paperplane" />
                        <div className={style.rightSide}>
                            <span className={style.title}>{intl.formatMessage(messages.commentFromManager)}</span>
                            <span className={style.note}>{note}</span>
                        </div>
                    </div>
                </Space>
            }
            <Space mt="m">
                <List
                    title={
                        <div className={style.title}>
                            <Space mr={'xxs'}>
                                <ApprovalStatus status={status} />
                            </Space>
                            {intl.formatMessage(messages.requestFrom)}{' '}
                            <FormattedDate value={request_date} weekday={'short'} />{' '}
                            <FormattedDate value={request_date} day="2-digit" />
                            {status === 'withdrawn' &&
                                <Space ml={'xxs'}>
                                    <Tooltip position="right" text={intl.formatMessage(messages.tooltip)}>
                                        <Icon icon={'info'} color={'blue'} />
                                    </Tooltip>
                                </Space>
                            }
                        </div>
                    }
                    rightChildren={
                        <div className={style.headerRightChildrenWrapper}>
                            {managers.length > 1 && status === 'pending' &&
                                <AvatarStack avatars={managers} /> }
                            {managers.length === 1 && status === 'pending' &&
                                <Tag
                                    avatar={
                                        <Avatar
                                            picture={managers[0]?.avatar_url}
                                            firstName={managers[0]?.first_name}
                                            lastName={managers[0]?.last_name}
                                        />
                                    }
                                    text={`${managers[0]?.first_name} ${managers[0]?.last_name}`}
                                />
                            }
                            {manager?.first_name &&
                                <Tag
                                    avatar={
                                        <Avatar
                                            picture={manager.avatar_url}
                                            firstName={manager.first_name}
                                            lastName={manager.last_name}
                                        />
                                    }
                                    text={`${manager.first_name} ${manager.last_name}`}
                                />
                            }
                            <Pill size="small" text={`${intl.formatMessage(messages.total)}  ${durationFormatter(duration_mins_sum)}`} />
                            {
                                status === 'pending' && isManager && isRequestedDetails &&
                                <Fragment>
                                    <Button
                                        onClick={() => modalOpen('rejectRequests', { projectsToReject: [{ id, user, project, duration_mins: duration_mins_sum }], dateRange: {
                                            gte: new Date(from),
                                            lte: new Date(to)
                                        } })}
                                        text={intl.formatMessage(messages.reject)}
                                        color={'white'}
                                        shadow
                                        size={'small'}
                                    />
                                    <Button
                                        onClick={() => modalOpen('approveRequests', { projectsToApprove: [{ id, user, project, duration_mins: duration_mins_sum }], dateRange: {
                                            gte: new Date(from),
                                            lte: new Date(to)
                                        } })}
                                        text={intl.formatMessage(messages.approve)}
                                        shadow
                                        size={'small'}
                                    />
                                </Fragment>
                            }
                        </div>
                    }
                    header={(
                        <Fragment>
                            <ListCell width='224px' align="center" header>
                                <FormattedMessage {...messages.project} />
                            </ListCell>
                            <ListCell width='112px' align="center" header>
                                <FormattedMessage {...messages.logDate} />
                            </ListCell>
                            <ListCell width='72px' align="center" header>
                                <FormattedMessage {...messages.person} />
                            </ListCell>
                            <ListCell width='224px' align="center" header>
                                <FormattedMessage {...messages.description} />
                            </ListCell>
                            <ListCell align="center" header>
                                <FormattedMessage {...messages.tags} />
                            </ListCell>
                            {
                                features?.task_with_from_to && (
                                    <ListCell width="150px" align="center" header>
                                        <FormattedMessage {...messages.startStop} />
                                    </ListCell>
                                )
                            }
                            <ListCell width='96px' align="center" header>
                                <FormattedMessage {...messages.duration} />
                            </ListCell>
                        </Fragment>
                    )}
                    data={tasks}
                    renderRow={renderRow}
                />
            </Space>
            <ApproveRequestsModal />
            <RejectRequestsModal />
            <WithdrawAcceptedApprovalModal />
        </Container>
    );
};

export default ViewHOC((ApprovalDetails),
    {
        title: 'Approvals - Request Details',
        menu: true,
        topbar: true,
        auth: {},
        subscription: true,
        feature: FEATURE.APPROVALS
    }
);