import React from 'react';
import { useIntl } from 'react-intl';

import useUI from "@quidlo/common/hooks/useUI";
import { Button, Space } from "@quidlo/ui";

import illustration from "../../../assets/modals/trash.png";
import { deleteTaskMutation } from "../../../queries/tasks.queries";
import ModalHOC, { ModalActions, ModalContent, ModalHeader } from "../../../ui/Modal";

import messages from './DeleteTask.i18n';

const
    DeleteTask = () => {
        const
            intl = useIntl(),
            { modal, modalClose } = useUI(),
            { mutateAsync: deleteTask } = deleteTaskMutation(),
            { id } = modal?.data || {},
            save = async () => {
                await deleteTask(id);
                if (modal.callback) {
                    modal.callback();
                }
                modalClose();
            };

        return (
            <>
                <ModalHeader>
                    {intl.formatMessage(messages.deleteTask)}
                </ModalHeader>
                <ModalContent>
                    {intl.formatMessage(messages.deleteTaskMsg)}
                </ModalContent>
                <ModalActions>
                    <Space mr="m">
                        <Button
                            color="white"
                            text={intl.formatMessage(messages.cancel)}
                            onClick={modalClose}
                        />
                    </Space>
                    <Button
                        color="blue"
                        text={intl.formatMessage(messages.save)}
                        onClick={save}
                        shadow
                    />
                </ModalActions>
            </>
        );
    };

export default ModalHOC(
    DeleteTask,
    {
        id: 'deleteTask',
        size: 'small',
        illustration,
    }
);