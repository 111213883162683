import { defineMessages } from 'react-intl';

export default defineMessages({
    timeToApproveInThisPeriod: {
        id: 'potentialApprovalsList.timeToApproveInThisPeriod',
        defaultMessage: 'Time to approve in this period',
    },
    project: {
        id: 'potentialApprovalsList.project',
        defaultMessage: 'Project',
    },
    duration: {
        id: 'potentialApprovalsList.duration',
        defaultMessage: 'Duration',
    },
    period: {
        id: 'potentialApprovalsList.period',
        defaultMessage: 'Period',
    },
    tags: {
        id: 'potentialApprovalsList.tags',
        defaultMessage: 'Tags',
    },
    person: {
        id: 'potentialApprovalsList.person',
        defaultMessage: 'Person',
    },
    details: {
        id: 'potentialApprovalsList.details',
        defaultMessage: 'Details',
    },
    noDataHeader: {
        id: 'potentialApprovalsList.noDataHeaderFilters',
        defaultMessage: 'No time approval requests found'
    },
    noDataBody: {
        id: 'potentialApprovalsList.noDataBodyFilters',
        defaultMessage: 'No time approval requests found that match the selected filters. Try changing the filters.'
    },
    total: {
        id: 'potentialApprovalsList.total',
        defaultMessage: 'Total:',
    }
});