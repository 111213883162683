import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { Animation } from '..';

import animationData from './json/data.json';

import style from './Spinner.module.scss';

import messages from './Spinner.i18n';

const Spinner = ({ active }) => {
    const intl = useIntl();
    return !!active && (
        <div className={style.dataSpinner}>
            <Animation animationData={animationData} />
            <div className={style.text}>
                {intl.formatMessage(messages.loading)}
            </div>
        </div>
    );
}

Spinner.propTypes = {
    active: PropTypes.bool,
};

Spinner.defaultProps = {
    active: false,
};

export default Spinner;
