import fetch from '../utils/fetch';

export default {
    get: userId =>
        fetch.get(`/v1/users/${userId}`),
    patch: (userId, delta) =>
        fetch.patch(`/v1/users/${userId}`, delta),
    getSettings: userId =>
        fetch.get(`/v1/users/${userId}/settings`),
    patchSettings: (userId, delta) =>
        fetch.patch(`/v1/users/${userId}/settings`, delta),
    postCredentials: query =>
        fetch.post('/v1/sign-up-process', query),
    postAvatar: (userId, formData) =>
        fetch.post(`/v1/users/${userId}/avatar`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }),
    activate: (email, token) =>
        fetch.post(`/v1/sign-up-process/${email}/activation`, { token }),
    resendActivationEmail: email =>
        fetch.post(`/v1/sign-up-process/${email}/resend-token-action`),
    resendInvitationEmail: (workspaceCode, userId) =>
        fetch.post(`/v1/${workspaceCode}/invited-users/${userId}/resend-invitation-action`),
    getAll: (workspaceCode, query = {}) =>
        fetch.get(`/v1/${workspaceCode}/users`, { params: query }),
    getAdministrableAll: (workspaceCode, query = {}) =>
        fetch.get(`/v1/${workspaceCode}/administrable-users`, { params: query }),
    inviteUsers: (workspaceCode, emails = [], projects = []) =>
        fetch.post(`/v1/${workspaceCode}/invited-users`, { emails, projects }),
    deleteUser: (workspaceCode, userId) =>
        fetch.delete(`/v1/${workspaceCode}/administrable-users/${userId}`),
    patchUser: (workspaceCode, userId, delta) =>
        fetch.patch(`/v1/${workspaceCode}/administrable-users/${userId}`, delta),
    patchPassword: (userId, delta) => fetch.patch(`/v1/users/${userId}/password`, delta),
    getMyProjectsParticipants: (workspaceCode, userId) =>
        fetch.get(`/v1/${workspaceCode}/users/${userId}/managed-projects/participants`),
};
