const
    MINUTE_MS = 60000,
    dateToYYYYMMDD = date => {
        const
            month = `00${date.getMonth() + 1}`.slice(-2),
            day = `00${date.getDate()}`.slice(-2);

        return `${date.getFullYear()}-${month}-${day}`;
    },
    dateToString = date => {
        const
            newDate = new Date(date.getTime()),
            TZOffset = newDate.getTimezoneOffset() - date.getTimezoneOffset();

        if (TZOffset === 0) {
            return dateToYYYYMMDD(newDate);
        }

        return dateToYYYYMMDD(new Date(newDate.getTime() - (TZOffset * MINUTE_MS)));
    };

export {
    dateToString,
};
