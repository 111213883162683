import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';

import useAuthentication from "@quidlo/common/hooks/useAuthentication";
import useUI from "@quidlo/common/hooks/useUI";
import useWorkspace from "@quidlo/common/hooks/useWorkspace";

import { Menu } from "../../ui";
import { FeedbackButton, TopBar } from "../../ui";

import AddTaxInformation from "./modals/AddTaxInformation";
import DataValidationDidntSucceed from "./modals/DataValidationDidntSucceed";
import SubscriptionExpired from "./modals/SubscriptionExpired";
import Egg from "./Egg";
import menuItems from './menu';
import Polls from "./Polls";
import Routing from "./Routing";
import Snackbar from "./Snackbar";
import Toasts from "./Toasts";

import style from "./App.module.scss";

const App = () => {
    const {
            isMenu,
        } = useUI(),
        { isInitialized, user } = useAuthentication(),
        { workspace } = useWorkspace(),
        init = () => {
            if (process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID) {
                TagManager.initialize({
                    gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID
                });
            }

            const redirectUrl = sessionStorage.getItem('redirectUrl');
            if (redirectUrl) {
                sessionStorage.removeItem('redirectUrl');
            }

            const preloader = document.getElementById('app-preloader');

            if (preloader) {
                preloader.style.opacity = 0;
                clearInterval(window.preloaderInterval);
                setTimeout(() => {
                    preloader.parentNode.removeChild(preloader);
                }, 1000);
            }
        };

    useEffect(() => {
        if (isInitialized) {
            init();
        }
    }, [isInitialized])

    if (isInitialized) {
        return (
            <div className={style.app}>
                <TopBar />
                <Menu hidden={!isMenu} items={menuItems} />
                <div className={style.main}>
                    <Snackbar />
                    <div className={style.content}>
                        <Routing />
                    </div>
                </div>
                {
                    (user || workspace) ? (
                        <>
                            <SubscriptionExpired />
                            <AddTaxInformation />
                            <DataValidationDidntSucceed />
                        </>
                    ) : null
                }
                <FeedbackButton />
                <Egg />
                <Toasts />
                <Polls />
                <div id="portal" />
            </div>
        );
    }
    return <div />;
};


export default App;
