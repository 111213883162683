import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import ListFooter from '../ListFooter';
import ListHeader from '../ListHeader';
import ListRow from '../ListRow';

import ListDocked from './ListDocked';

import style from './ListDocked.module.scss';

/** List main container */
const ListContainer = ({
    title,
    color,
    icon,
    rightChildren,
    data,
    header,
    headerDock,
    footer,
    footerDock,
    renderRow,
    renderRowDock,
    cellsAmount,
}) => {
    const props = {
            rightChildren,
            title,
            color,
            icon,
        },
        rowWidth = (cellsAmount < 5 ? '100%' : `${20 * cellsAmount}%`);
    return (
        <ListDocked {...props}>
            <div className={cx(style.dock)}>
                <ListHeader>
                    {headerDock}
                </ListHeader>
                {data.map(row => {
                    const
                        rowProps = {
                            data: row,
                            renderRow: renderRowDock,
                            hoverable: false,
                        };
                    return (
                        <ListRow key={row.id} {...rowProps} />
                    );
                })}
                {
                    footerDock && (
                        <ListFooter>
                            {footerDock}
                        </ListFooter>
                    )
                }
            </div>
            <div className={cx(style.well)}>
                <ListHeader width={rowWidth}>
                    {header}
                </ListHeader>
                {data.map(row => {
                    const
                        rowProps = {
                            data: row,
                            width: rowWidth,
                            renderRow,
                            hoverable: false,
                        };
                    return (
                        <ListRow key={row.id} {...rowProps} />
                    );
                })}
                {
                    footer && (
                        <ListFooter width={rowWidth}>
                            {footer}
                        </ListFooter>
                    )
                }
            </div>
        </ListDocked>
    );
};

ListContainer.propTypes = {
    title: PropTypes.string,
    color: PropTypes.string,
    icon: PropTypes.string,
    rightChildren: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    data: PropTypes.array.isRequired,
    header: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    headerDock: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    footer: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    footerDock: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    renderRow: PropTypes.func.isRequired,
    renderRowDock: PropTypes.func.isRequired,
    cellsAmount: PropTypes.number,
};

ListContainer.defaultProps = {
    title: undefined,
    color: undefined,
    icon: undefined,
    rightChildren: undefined,
    footerDock: undefined,
    footer: undefined,
    cellsAmount: 1
};

export default ListContainer;
