import { defineMessages } from 'react-intl';

export default defineMessages({
    report: {
        id: 'settings.report',
        defaultMessage: 'Report',
    },
    loadMore: {
        id: 'settings.loadMore',
        defaultMessage: 'Load more',
    },
    basicInformation: {
        id: 'settings.basicInformation',
        defaultMessage: 'Basic information',
    },
    receiptInfo: {
        id: 'settings.receiptInfo',
        defaultMessage: 'Receipt info',
    },
    subscriptionSettings: {
        id: 'settings.subscriptionSettings',
        defaultMessage: 'Subscription',
    },
    uploadYour: {
        id: 'settings.uploadYour',
        defaultMessage: 'Upload your workspace logo',
    },
    minSize: {
        id: 'settings.minSize',
        defaultMessage: 'Min. size 150px x 150px, max. weight 800KB',
    },
    typename: {
        id: 'settings.typename',
        defaultMessage: 'Workspace name',
    },
    save: {
        id: 'settings.save',
        defaultMessage: 'Save',
    },
    weekStart: {
        id: 'settings.weekStart',
        defaultMessage: 'Week start',
    },
    monday: {
        id: 'settings.monday',
        defaultMessage: 'Monday',
    },
    sunday: {
        id: 'settings.sunday',
        defaultMessage: 'Sunday',
    },
    add: {
        id: 'settings.add',
        defaultMessage: 'Add',
    },
    minSizeError: {
        id: 'settings.minSizeError',
        defaultMessage: 'Min. weight should be 0,01KB',
    },
    maxSizeError: {
        id: 'settings.maxSizeError',
        defaultMessage: 'Max. weight should be 800KB',
    },
    wrongTypeError: {
        id: 'settings.wrongTypeError',
        defaultMessage: 'You can upload file with one of types: {types}',
    },
    unexpectedError: {
        id: 'settings.unexpectedError',
        defaultMessage: 'Unexpected error',
    },
});