import React from 'react';
import { Link, useMatch } from 'react-router-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Space } from '..';

import style from './Header.module.scss';

const HeaderTab = ({ to, label, active, onClick }) =>
{
    const match = useMatch(to);
    return (
        <Space mv="s" >
            {
                to ?
                    (
                        <Link className={cx([style.tab, match && style.active])} to={to}>
                            <span>
                                {label}
                            </span>
                        </Link>
                    ) :
                    (
                        <div className={cx([style.tab, active && style.active])} onClick={onClick}>
                            <span>
                                {label}
                            </span>
                        </div>
                    )
            }
        </Space>
    );
}

HeaderTab.propTypes = {
    label: PropTypes.string.isRequired,
    to: PropTypes.string,
    active: PropTypes.bool,
    onClick: PropTypes.func,
};

HeaderTab.defaultProps = {
    to: null,
    active: false,
    onClick: null
};

export default HeaderTab;
