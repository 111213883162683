import React, { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import useWorkspace from "@quidlo/common/hooks/useWorkspace";
import useWorkspaceFeatures from "@quidlo/common/hooks/useWorkspaceFeatures";
import { durationFormatter } from '@quidlo/common/utils/duration';
import {  Pill, Space } from '@quidlo/ui';

import { List, ListCell } from '../../../../ui';
import { periodToString } from '../../../../utils/periodString';

import TaskRow from "./TaskRow";

import style from '../TimeView/TimeView.module.scss';

import messages from './TasksView.i18n';

const TasksView = ({
    data,
    isEditable,
    isStatus,
}) => {
    const intl = useIntl();
    const { workspace } = useWorkspace();
    const { features } = useWorkspaceFeatures();

    const weekStart = workspace?.weekStart || 'monday';
    const renderRow = (props, status) => (<TaskRow {...props} status={status} isStatus={isStatus} isEditable={isEditable} />),
        projects = data.periods.filter(p => p.tasks.length)
            .map(({
                periodCode,
                durationSummaryMins,
                tasks
            }) => {
                return (
                    <Space key={periodCode} mb="l">
                        <List
                            color={periodCode}
                            editable={isEditable}
                            title={periodToString(periodCode, intl, true, weekStart)}
                            rightChildren={(<Pill text={`${intl.formatMessage(messages.total)} ${durationFormatter(durationSummaryMins || 0)}`} size="small" />)}
                            header={(
                                <Fragment>
                                    <ListCell width="224px" align="center" header>
                                        <FormattedMessage {...messages.project} />
                                    </ListCell>
                                    {isStatus &&
                                        <ListCell width="100px" align="center" header>
                                            <FormattedMessage {...messages.status} />
                                        </ListCell>
                                    }
                                    <ListCell width="112px" align="center" header>
                                        <FormattedMessage {...messages.date} />
                                    </ListCell>
                                    <ListCell width="95px" align="center" header>
                                        <FormattedMessage {...messages.person} />
                                    </ListCell>
                                    <ListCell width="200px" align="center" header>
                                        <FormattedMessage {...messages.description} />
                                    </ListCell>
                                    <ListCell align="center"  header>
                                        <FormattedMessage {...messages.tags} />
                                    </ListCell>
                                    {
                                        features?.task_with_from_to && (
                                            <ListCell width="120px" align="center" header>
                                                <FormattedMessage {...messages.startStop} />
                                            </ListCell>
                                        )
                                    }
                                    <ListCell width="96px" align="center" header>
                                        <FormattedMessage {...messages.duration} />
                                    </ListCell>
                                    {
                                        (isEditable) && (
                                            <ListCell width="95px" align="center"  header>
                                                <FormattedMessage {...messages.actions} />
                                            </ListCell>
                                        )
                                    }
                                </Fragment>
                            )}
                            data={tasks}
                            renderRow={renderRow}
                            autostatus={false}
                        />
                    </Space>
                )
            })

    return (
        <div>
            <Space mv="m">
                <div className={style.totalTimeWrapper}>
                    <Pill color="white" text={`${intl.formatMessage(messages.totalTimeInAllProjects)} ${durationFormatter(data.durationSummaryMins || 0)}`} size="small" />
                </div>
            </Space>
            {projects}
        </div>
    );
};

TasksView.propTypes = {
    data: PropTypes.object,
    isEditable: PropTypes.bool,
    isStatus: PropTypes.bool,
};

TasksView.defaultProps = {
    data: {},
    isEditable: false,
    isStatus: false
};

export default TasksView;
