import React from 'react';
import { useIntl } from "react-intl";

import useUI from "@quidlo/common/hooks/useUI";
import { Button, Space } from '@quidlo/ui';

import illustration from "../../../../assets/modals/deal.png";
import { editUserMutation } from "../../../../queries/users.queries";
import ModalHOC, { ModalActions, ModalContent, ModalHeader } from "../../../../ui/Modal/Modal";

import messages from './ChangeOwner.i18n';

const ChangeOwner = () => {
    const { modal, modalClose } = useUI();
    const intl = useIntl()
    const { mutate } = editUserMutation();

    const changeOwner = () => {
        const { organizationRole } = modal.data;
        mutate({ delta: { organizationRole }, userId: modal?.data?.userId });
        modalClose();
    };

    return (
        <>
            <ModalHeader>
                {intl.formatMessage(messages.title)}
            </ModalHeader>
            <ModalContent>
                {intl.formatMessage(messages.subtitle)}
            </ModalContent>
            <ModalActions>
                <Space mr="m">
                    <Button color="white" text={intl.formatMessage(messages.cancel)} onClick={modalClose} />
                </Space>
                <Button color="blue" text={intl.formatMessage(messages.approve)} onClick={changeOwner} />
            </ModalActions>
        </>
    );
};

export default ModalHOC(
    ChangeOwner,
    {
        id: 'changeRoleConfirm',
        size: 'small',
        illustration,
    }
);