import React, { Fragment } from 'react';
import { FormattedMessage } from "react-intl";
import PropTypes from 'prop-types';

import useWorkspaceFeatures from "@quidlo/common/hooks/useWorkspaceFeatures";
import { durationFormatter } from "@quidlo/common/utils/duration";
import { Pill, Space } from "@quidlo/ui";

import { List, ListCell } from "../../ui";

import TaskRow from './TaskRow';

import messages from "./Project.i18n";

const Project = ({ project }) => {
    const { features } = useWorkspaceFeatures();

    const durationSummaryMins = project.tasks.filter(t => t).map(t => (t.status === 'deleting' ? 0 : +t.durationMins)).reduce((acc, i) => acc + i, 0)

    const renderRow = (props, status) => (<TaskRow {...props} status={status} project={project} />);

    return (
        <Space mb="m">
            <List
                title={project.name}
                color={project.color}
                icon={project.isBillable ? 'dollar' : ''}
                rightChildren={(<Pill size="small" text={durationFormatter(durationSummaryMins)} />)}
                header={(
                    <Fragment>
                        <ListCell width="72px" align="center" header>
                            <FormattedMessage {...messages.person} />
                        </ListCell>
                        <ListCell align="center" header>
                            <FormattedMessage {...messages.task} />
                        </ListCell>
                        <ListCell width="250px" align="center" header>
                            <FormattedMessage {...messages.tags} />
                        </ListCell>
                        {
                            features?.task_with_from_to && (
                                <ListCell width="120px" align="center" header>
                                    <FormattedMessage {...messages.startStop} />
                                </ListCell>
                            )
                        }
                        <ListCell width="220px" align="center" header>
                            <FormattedMessage {...messages.duration} />
                        </ListCell>
                    </Fragment>
                )}
                data={project.tasks}
                renderRow={renderRow}
                editable={!project.isAdminRestricted}
            />
        </Space>
    );
};

Project.propTypes = {
    project: PropTypes.object.isRequired,
};

export default Project;
