import React from 'react';
import { useIntl } from 'react-intl';

import useUI from "@quidlo/common/hooks/useUI";
import { Button, Space } from "@quidlo/ui";

import illustration from "../../../../assets/modals/man-lightnings.png";
import ModalHOC, { ModalActions, ModalContent, ModalHeader } from "../../../../ui/Modal";

import messages from "./DataValidationDidntSucceed.i18n";

const DataValidationDidntSucceed = () => {
    const { modalOpen, modalClose } = useUI();
    const intl = useIntl();
    return (
        <>
            <ModalHeader>
                {intl.formatMessage(messages.title)}
            </ModalHeader>
            <ModalContent>
                {`${intl.formatMessage(messages.description1)} `}
            </ModalContent>
            <ModalActions>
                <Space mr="m">
                    <Button
                        onClick={modalClose}
                        text={intl.formatMessage(messages.cancel)}
                        color="white"
                    />
                </Space>
                <Button
                    onClick={() => modalOpen('addTaxInformation', {})}
                    text={intl.formatMessage(messages.tryAgain)}
                    color="blue"
                />
            </ModalActions>
        </>

    );
};

export default ModalHOC(
    DataValidationDidntSucceed,
    {
        id: 'dataValidationDidntSucceed',
        size: 'small',
        illustration
    }
);