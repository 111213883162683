import UpgradePNG from './assets/upgrade-your-plan-now.png';
import ExpiredPNG from './assets/your-subscription-just-expired.png';

const headers = {
    trial: {
        title: 'upgradeTitle',
        subtitle: 'upgradeSubtitle',
        image: UpgradePNG,
        alt: 'upgradeAlt'
    },
    subscriptionExpired: {
        title: 'subscriptionExpiredTitle',
        subtitle: 'subscriptionExpiredSubtitle',
        image: ExpiredPNG,
        alt: 'subscriptionExpiredAlt'
    },
    trialExpired: {
        title: 'trialExpiredTitle',
        subtitle: 'trialExpiredSubtitle',
        image: ExpiredPNG,
        alt: 'trialExpiredAlt'
    }
};

export default headers;