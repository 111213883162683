import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import useUI from "@quidlo/common/hooks/useUI";
import analytics from "@quidlo/common/utils/analytics";
import { dateToString } from '@quidlo/common/utils/dateHelpers';
import { Button, Space } from '@quidlo/ui';

import illustration from "../../../../assets/modals/woman-rocket.png";
import { createApprovalMutation } from '../../../../queries/approvals.queries';
import ModalHOC, { ModalActions, ModalContent, ModalHeader } from "../../../../ui/Modal";
import SummaryTable from "../../components/SummaryTable";

import style from "./CreateRequestModal.module.scss";

import messages from "./CreateRequestModal.i18n";

const CreateRequestModal = () => {
    const { modal, modalClose } = useUI(),
        { projectsToApprove, date, totalTimeOfChosenTasks } = modal.data,
        { mutateAsync: createApproval } = createApprovalMutation(),
        requestTimeApproval = async () => {
            await createApproval({
                from: dateToString(date.gte),
                to: dateToString(date.lte),
                project_ids: projectsToApprove.map(proj => proj.project.id)
            })
            analytics.logTimesheetsApprovalsTimeApprovalRequestCreate({
                projects: projectsToApprove.length,
                totalTime: totalTimeOfChosenTasks,
                startDate: date.gte,
                endDate: date.lte,
            })
            modalClose()
            modal.callback()
        },
        cancel = () => {
            analytics.logTimesheetsApprovalsTimeApprovalRequestCancelCreate();
            modalClose()
        },
        intl = useIntl();

    const dateRange = useMemo(() => {
        if (!projectsToApprove?.length) {
            return { gte: null, lte: null }
        }

        let
            gte = new Date(projectsToApprove[0].from),
            lte = new Date(projectsToApprove[0].to);

        projectsToApprove.forEach(proj => {
            if (new Date(proj.from) < gte) {
                gte = new Date(proj.from);
            }
            if (new Date(proj.to) > lte) {
                lte = new Date(proj.to);
            }
        })

        return { gte, lte };

    }, [projectsToApprove])

    return (
        <>
            <ModalHeader>
                {intl.formatMessage(messages.title)}
            </ModalHeader>
            <ModalContent>
                <Space mb="m" className={style.description}>
                    {`${intl.formatMessage(messages.description)} `}
                </Space>
                <Space mb="m">
                    <SummaryTable dateRange={dateRange} list={projectsToApprove} />
                </Space>
            </ModalContent>
            <ModalActions>
                <Space mr="m">
                    <Button
                        onClick={cancel}
                        text={intl.formatMessage(messages.cancel)}
                        color="white"
                    />
                </Space>
                <Button
                    onClick={requestTimeApproval}
                    text={intl.formatMessage(messages.request)}
                    color="blue"
                />
            </ModalActions>
        </>
    );
};

export default ModalHOC(
    CreateRequestModal,
    {
        id: 'createApprovalRequest',
        size: 'small',
        illustration,
    }
);