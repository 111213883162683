import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'createNewApiKey.title',
        defaultMessage: 'Create New API Key'
    },
    save: {
        id: 'createNewApiKey.save',
        defaultMessage: 'Save'
    },
    firstParagraph: {
        id: 'createNewApiKey.firstParagraph',
        defaultMessage: 'New Api Key created and it will be displayed only now,'
    },
    secondParagraph: {
        id: 'createNewApiKey.secondParagraph',
        defaultMessage: 'Please store it somewhere safe because as soon as you navigate away from this page, we will not be able to retrieve or restore this generated token.'
    },
    keyName: {
        id: 'createNewApiKey.keyName',
        defaultMessage: 'API Key\'s name'
    },
});