import fetch from '../utils/fetch';

export default {
    query: userId => fetch.get(`/v1/users/${userId}/invitations`),
    accept: (userId, workspaceCode) =>
        fetch.post(
            `/v1/users/${userId}/invitations/${workspaceCode}/approve-action`,
        ),
    decline: (userId, workspaceCode) =>
        fetch.post(
            `/v1/users/${userId}/invitations/${workspaceCode}/decline-action`,
        ),
};
