import React from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { PROJECT_ROLE,WORKSPACE_ROLE } from "@quidlo/common/constants/main";
import useUI from "@quidlo/common/hooks/useUI";
import useWorkspace from "@quidlo/common/hooks/useWorkspace";
import { Button, Header, Space } from '@quidlo/ui';

import {
    editProjectMutation,
    getProjectsProjectQuery,
    getProjectsProjectUsersQuery
} from '../../../queries/projects.queries';
import { Container } from '../../../ui';
import ViewHOC from '../../../ui/View/View';

import AddTagsForm from './AddTagsForm/AddTagsForm';
import BasicParams from './BasicParams/BasicParams';
import AddTeamMembers from './modals/AddTeamMembers';
import DeleteProject from "./modals/DeleteProject";
import UsersList from './UsersList/UsersList';

import style from './ProjectsEdit.module.scss';

import messages from './ProjectsEdit.i18n';

const ProjectsEdit = () => {
    const
        { modalOpen } = useUI(),
        params = useParams(),
        intl = useIntl(),
        { workspace } = useWorkspace(),
        { data: users } = getProjectsProjectUsersQuery(params.id),
        { data: project } = getProjectsProjectQuery(params.id),
        { mutate: editProject } = editProjectMutation(params.id),
        isUserManaging = [WORKSPACE_ROLE.ADMIN, WORKSPACE_ROLE.OWNER].includes(workspace?.organizationRole) || project.projectRole === PROJECT_ROLE.MANAGER,

        toggleStatus = () => {
            editProject({ id: params.id, delta: { isArchived: !project.isArchived } })
        },
        headerActions =
            <div>
                <Button
                    color="transparent"
                    text={project.isArchived ? intl.formatMessage(messages.activateProject) : intl.formatMessage(messages.archiveProject)}
                    onClick={() => {
                        toggleStatus();
                    }}
                />
                {isUserManaging &&
                <div className={style.deleteBtn}>
                    <Button color="white" shadow text={intl.formatMessage(messages.deleteProject)} onClick={() => {
                        modalOpen('deleteProject', { id: project.id, name: project.name });
                    }} />
                </div>
                }
            </div>


    return (
        <div className={style.projectsEdit}>
            <Container>
                <Space mb="l">
                    <Header
                        title={intl.formatMessage(messages.editProject)}
                        icon="edit"
                        size="large"
                        underline
                        rightChildren={headerActions}
                    />
                </Space>
                <Space mb="l">
                    <BasicParams
                        projectId={params.id}
                    />
                </Space>
                <Space mb="l">
                    <AddTagsForm
                        projectId={params.id}
                    />
                </Space>
                <Space mb="s">
                    <Header
                        size="large"
                        title={intl.formatMessage(messages.projectTeam)}
                        icon="users"
                        styles={{ marginBottom: 0 }}
                        rightChildren={
                            !project.isArchived ? (
                                <div className={style.headerRight}>
                                    <Button
                                        text={intl.formatMessage(messages.addTeamMembers)}
                                        color="blue"
                                        shadow
                                        onClick={() => modalOpen('addTeamMembers', { id: params.id })}
                                    />
                                </div>
                            ) : null
                        }
                        underline
                    />
                </Space>
                <Space>
                    <UsersList
                        projectId={params.id}
                        users={users}
                    />
                </Space>
            </Container>
            <AddTeamMembers />
            <DeleteProject />
        </div>
    )
};

export default ViewHOC((ProjectsEdit),
    {
        title: 'Project',
        menu: true,
        topbar: true,
        auth: {
            roles: ['manager', 'admin', 'owner']
        },
        subscription: true
    }
);
