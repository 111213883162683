import React from 'react';
import { FormattedMessage } from 'react-intl';

import useUI from "@quidlo/common/hooks/useUI";
import { Button, Space } from "@quidlo/ui";

import illustration from "../../../../assets/modals/share.png";
import ModalHOC, { ModalActions, ModalContent, ModalHeader } from "../../../../ui/Modal/Modal";

import messages from './ShareMany.i18n';

const
    ShareMany = () => {
        const { modal, modalClose } = useUI();
        return (
            <>
                <ModalHeader>
                    <FormattedMessage {...messages.sharingManyProjects} />
                </ModalHeader>
                <ModalContent>
                    <FormattedMessage {...messages.areYouSure} />
                </ModalContent>
                <ModalActions>
                    <Space mr="m">
                        <Button
                            color="white"
                            text="Cancel"
                            onClick={modalClose}
                        />
                    </Space>
                    <Button
                        color="blue"
                        text="Share"
                        onClick={modal.callback}
                        shadow
                    />
                </ModalActions>
            </>
        );
    };

export default ModalHOC(
    ShareMany,
    {
        id: 'shareMany',
        size: 'small',
        illustration,
    }
);