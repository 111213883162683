import fetch from '../utils/fetch';

export default {
    getPotentialApprovals: (workspaceCode, userId, { date, projects = [] }) =>
        fetch.get(`/v2/${workspaceCode}/users/${userId}/potential-approvals`, { params: {
            from: date.gte,
            to: date.lte,
            'project-ids': projects.join(','),
        } }),
    getPotentialApproval: (workspaceCode, userId, params) =>
        fetch.get(`/v2/${workspaceCode}/users/${userId}/potential-approvals/details`, { params: {
            from: params.from,
            to: params.to,
            'project-id': params.id,
        } }),

    getRequestedApprovals: (workspaceCode, userId, { date, projects = [], statuses = [], people = [] }) =>
        fetch.get(`/v2/${workspaceCode}/users/${userId}/requested-approvals`, { params: {
            from: date.gte,
            to: date.lte,
            'project-ids': projects.join(','),
            statuses: statuses.join(','),
            'user-ids': people.join(','),
        } }),
    getRequestedApprovalsCount: (workspaceCode, userId, params) =>
        fetch.get(`/v2/${workspaceCode}/users/${userId}/requested-approvals/count`, { params: {
            from: params?.from,
            to: params?.to,
        } }),
    getRequestedApproval: (workspaceCode, userId, id) =>
        fetch.get(`/v2/${workspaceCode}/users/${userId}/requested-approvals/${id}`),
    approveRequest: (workspaceCode, userId, approvalId) =>
        fetch.post(`/v2/${workspaceCode}/users/${userId}/requested-approvals/${approvalId}/accept`),
    rejectRequest: (workspaceCode, userId, params) =>
        fetch.post(`/v2/${workspaceCode}/users/${userId}/requested-approvals/${params.id}/reject`, {
            note: params.note
        }),
    withdrawRequest: (workspaceCode, userId, params) =>
        fetch.post(`/v2/${workspaceCode}/users/${userId}/requested-approvals/${params.id}/withdraw`, {
            note: params.note
        }),

    getApprovals: (workspaceCode, userId, { date, projects = [], statuses = [] }) =>
        fetch.get(`/v2/${workspaceCode}/users/${userId}/approvals`, { params: {
            from: date.gte,
            to: date.lte,
            'project-ids': projects.join(','),
            statuses: statuses.join(',')
        } }),
    getApproval: (workspaceCode, userId, id) =>
        fetch.get(`/v2/${workspaceCode}/users/${userId}/approvals/${id}`),
    postApproval: (workspaceCode, userId, params) =>
        fetch.post(`/v2/${workspaceCode}/users/${userId}/approvals`, params),
};