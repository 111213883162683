import React from 'react';
import cx from "classnames";
import PropTypes from 'prop-types';

import { Context, Icon } from "@quidlo/ui";

import { dateToString } from "../../utils/dateHelpers";
import DatePickerContext from "../DatePickerContext";

import style from './InputDate.module.scss';

const InputDate = ({
    name,
    label,
    hint,
    value,
    error,
    onChange,
    disabled
}) => {
    const
        changeHandler = newValue => {
            onChange(newValue)
        };

    return (
        <Context
            context={(
                <DatePickerContext
                    onChange={val => {changeHandler(val.date)}}
                    date={value || new Date()}
                    type="single"
                />
            )}
        >
            <div
                className={cx(
                    style.inputDate,
                    (error) && style.error,
                    value && style.filled,
                    disabled && style.disabled
                )}
                role="button"
                tabIndex="0"
            >
                <div className={style.input}>
                    {value && dateToString(value)}
                </div>
                <span className={style.bar} />
                <div className={style.icon}>
                    <Icon icon="calendar" size="small" color="blue" />
                </div>
                {label ? <label htmlFor={name}>{label}</label> : null}
                {hint ? <span className={cx(style.hint)}>{hint}</span> : null}
                {error && <span className={style.errormessage}>{error}</span>}
            </div>
        </Context>
    )
}

InputDate.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    hint: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string
    ]),
    error: PropTypes.node,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
};

InputDate.defaultProps = {
    name: 'dateInput',
    label: '',
    hint: '',
    value: '',
    error: '',
    onChange: undefined,
    disabled: false,
};

export default InputDate;
