import React, { useRef, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../Icon';

import style from './Search.module.scss';

let timeout = null;

const timeoutDelay = 800,
    Search = ({ onSearch, placeholder }) => {
        const [isActive, setIsActive] = useState(false),
            [isFocused, setIsFocused] = useState(false),
            inputRef = useRef(null),
            toggleSearch = () => {
                setIsActive(true);
                inputRef.current.focus();
            },
            toggleFocus = isFoc => {
                setIsFocused(isFoc);
            },
            search = event => {
                const {
                    target: { value },
                } = event;

                clearTimeout(timeout);

                timeout = setTimeout(() => {
                    onSearch(value);
                }, timeoutDelay);
            };

        return (
            <div
                className={cx(
                    style.search,
                    isActive && style.active,
                    isFocused && style.focused,
                )}
                onClick={toggleSearch}
            >
                <div className={style.leftPart} />
                <div className={style.centerPart}>
                    <input
                        className={style.input}
                        onChange={search}
                        onFocus={() => {
                            toggleFocus(true);
                        }}
                        onBlur={() => {
                            toggleFocus(false);
                        }}
                        placeholder={placeholder}
                        ref={inputRef}
                    />
                </div>
                <div className={style.rightPart} />
                <div className={style.icon}>
                    <Icon icon="search" color="grey" />
                </div>
            </div>
        );
    };

Search.propTypes = {
    onSearch: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
};

Search.defaultProps = {
    placeholder: 'Search...',
};

export default Search;
