import { defineMessages } from 'react-intl';

export default defineMessages({
    deleteProject: {
        id: 'projectsEdit.deleteProject',
        defaultMessage: 'Delete project'
    },
    areYouSure: {
        id: 'projectsEdit.areYouSure',
        defaultMessage: 'Are you sure you want to delete <b>{name}</b> project permanently?'
    },
    allTimeEntries: {
        id: 'projectsEdit.allTimeEntries',
        defaultMessage: 'All time entries, project information and members information in the project will be deleted'
    },
    makeSure: {
        id: 'projectsEdit.makeSure',
        defaultMessage: 'Make sure to read what will be deleted'
    },
    declaration: {
        id: 'projectsEdit.declaration',
        defaultMessage: 'Yes, permanently delete this project'
    },
    cancel: {
        id: 'projectsEdit.cancel',
        defaultMessage: 'Cancel'
    },
    delete: {
        id: 'projectsEdit.delete',
        defaultMessage: 'Delete'
    },
});