import React from 'react';
import { useIntl } from 'react-intl';

import useForm from "@quidlo/common/hooks/useForm2";
import useUI from "@quidlo/common/hooks/useUI";
import validators from "@quidlo/common/utils/validators";
import { Button, Input, Space } from "@quidlo/ui";

import illustration from "../../../../assets/modals/apikey.png";
import {
    editWorkspaceAPIKeyMutation,
} from "../../../../queries/workspace.queries";
import ModalHOC, { ModalActions, ModalContent, ModalHeader } from "../../../../ui/Modal/Modal"

import style from "./CreateNewApiKey.module.scss";

import messages from './CreateNewApiKey.i18n';

const FORM = {
    keyName: {
        value: '',
        validators: [validators.isRequired()]
    }
}

const CreateNewApiKey = () => {
    const
        intl = useIntl(),
        { modal, modalClose } = useUI(),
        [form, { isFormValid, touchForm }] = useForm(FORM),
        { mutate: patchApiKey } = editWorkspaceAPIKeyMutation(),
        save = async () => {
            await patchApiKey({
                name: modal?.data?.name,
                delta: { new_name: form.keyName.value }
            });
            modalClose()
        };

    return (
        <>
            <ModalHeader>
                {intl.formatMessage(messages.title)}
            </ModalHeader>
            <ModalContent>
                <p>{intl.formatMessage(messages.firstParagraph)}</p>
                <p className={style.key}>{modal?.data?.key}</p>
                <p>{intl.formatMessage(messages.secondParagraph)}</p>
                <Space mt="m">
                    <Input
                        {...form.keyName}
                        fieldId="api-key-name"
                        label={intl.formatMessage(messages.keyName)}
                        isRequired
                    />
                </Space>
            </ModalContent>
            <ModalActions>
                <Button
                    shadow
                    color="blue"
                    text={intl.formatMessage(messages.save)}
                    onClick={save}
                    onDisabledClick={touchForm}
                    disabled={!isFormValid}
                />
            </ModalActions>
        </>

    );
};


export default ModalHOC(
    CreateNewApiKey,
    {
        id: 'createNewApiKey',
        size: 'small',
        illustration,
    }
);