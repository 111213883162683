import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'cannotDeleteWorkspace.title',
        defaultMessage: 'Cannot delete workspace',
    },
    subtitle: {
        id: 'cannotDeleteWorkspace.subtitle',
        defaultMessage: 'You are not able to delete the workspace unless you first  remove all of the users.',
    },
    alt: {
        id: 'cannotDeleteWorkspace.alt',
        defaultMessage: 'A chat window',
    },
    manageUsers: {
        id: 'cannotDeleteWorkspace.manageUsers',
        defaultMessage: 'Manage users',
    },
    cancel: {
        id: 'cannotDeleteWorkspace.cancel',
        defaultMessage: 'Cancel',
    },
});
