import { useContext } from 'react';
import { useIntl } from "react-intl";

import InvitationsService from '@quidlo/common/api/InvitationsService';
import { AuthContext } from '@quidlo/common/providers/AuthProvider';

import useUI from "./useUI";

import messages from './useInvitations.i18n';

const useInvitations = () => {
    const { toastPush } = useUI();
    const intl = useIntl();
    const { user, invitations, fetchInvitations, fetchUser } = useContext(AuthContext);

    const acceptInvitation = async workspaceCode => {
        InvitationsService.accept(user.id, workspaceCode).then( async () => {
            toastPush({
                text: intl.formatMessage(messages.invitationAccepted)
            })
            await fetchInvitations();
            await fetchUser();
        }).catch(err => {
            toastPush({
                text: err.response.data.message || intl.formatMessage(messages.invitationAccepted),
                color: 'red'
            })
        })

    };
    const declineInvitation = async workspaceCode => {
        await InvitationsService.decline(user.id, workspaceCode);
        toastPush({
            text: intl.formatMessage(messages.invitationDeclined)
        })
        await fetchInvitations();
        await fetchUser();
    };

    return {
        invitations,
        acceptInvitation,
        declineInvitation,
    };
};

export default useInvitations;
