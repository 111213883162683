import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import UsersService from "@quidlo/common/api/UsersService";
import useUI from "@quidlo/common/hooks/useUI";
import useWorkspace from "@quidlo/common/hooks/useWorkspace";
import { Button, Card } from "@quidlo/ui";

import CannotDeleteWorkspace from "./modals/CannotDeleteWorkspace";
import DeleteWorkspace from "./modals/DeleteWorkspace";

import style from "./WorkspaceDelete.module.scss";

import messages from './WorkspaceDelete.i18n';

const
    WorkspaceDelete = () => {
        const intl = useIntl();
        const { workspace } = useWorkspace();
        const { modalOpen } = useUI();

        const deleteWorkspaceModal = async () => {
            const { data } = await UsersService.getAll(workspace.code);
            if (data.length > 1) {
                modalOpen('cannotDeleteWorkspace');
            } else {
                modalOpen('deleteWorkspace');
            }
        };

        return (
            <>
                <Card
                    title={intl.formatMessage(messages.deleteWorkspace)}
                    headerRightChildren={ <Button
                        size='small'
                        shadow
                        color="white"
                        text={intl.formatMessage(messages.delete)}
                        onClick={deleteWorkspaceModal}
                    />}
                >
                    <div className={style.root}>
                        <div className={style.left}>
                            <p>
                                <FormattedMessage
                                    {...messages.byDeleting}
                                    values={{
                                        b: chunks => <strong> {chunks} </strong>,
                                        workspace: workspace.name
                                    }}
                                />
                            </p>
                        </div>
                    </div>
                </Card>
                <DeleteWorkspace />
                <CannotDeleteWorkspace />
            </>
        );
    };

export default WorkspaceDelete;
