import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'cannotDeleteAccount.title',
        defaultMessage: 'Cannot delete the account',
    },
    subtitle1: {
        id: 'cannotDeleteAccount.subtitle1',
        defaultMessage: 'You are not able to delete your account because you have other members in your workspaces that you are the owner of.',
    },
    subtitle2: {
        id: 'cannotDeleteAccount.subtitle2',
        defaultMessage: 'To delete your account, you have to remove those members or make someone else an owner of that workspace.',
    },
    ok: {
        id: 'cannotDeleteAccount.ok',
        defaultMessage: 'Ok',
    },
    alt: {
        id: 'cannotDeleteAccount.alt',
        defaultMessage: 'A message',
    },
});