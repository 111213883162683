import { useCallback, useContext } from 'react';
import { useIntl } from "react-intl";

import WorkspacesService from '../api/WorkspacesService';
import { AuthContext } from '../providers/AuthProvider';

import useUI from "./useUI";

import messages from "./useWorkspace.i18n";

const useWorkspace = () => {
    const { toastPush } = useUI();
    const intl = useIntl();

    const {
        userId,
        fetchUser,
        workspace,
        selectWorkspace,
    } = useContext(AuthContext);

    const patchWorkspace = useCallback(async delta => {
        await WorkspacesService.patch(userId, workspace.code, delta);
        toastPush({
            text: intl.formatMessage(messages.workspaceEdited)
        });
        fetchUser();
    }, [workspace]);

    const patchWorkspaceAvatar = async (avatar, workspaceCode) => {
        if (avatar) {
            const formData = new FormData();
            formData.append('image', avatar);

            await WorkspacesService.postAvatar(userId, workspaceCode || workspace.code, formData);
            fetchUser();
        }
    };

    const createWorkspace = async workspace => {
        const { data } = await WorkspacesService.post(userId, workspace);
        toastPush({
            text: intl.formatMessage(messages.workspaceCreated)
        });
        fetchUser();
        return data.code;
    };

    const leaveWorkspace = async workspaceCode => {
        try {
            await WorkspacesService.leave(userId, workspaceCode);
            toastPush({
                text: intl.formatMessage(messages.workspaceLeft)
            });
            fetchUser();
        } catch (e) {
            toastPush({
                text: intl.formatMessage(messages.cannotLeaveWorkspace),
                color: 'red',
            });
        }
    }

    const deleteWorkspace = async workspaceCode => {
        await WorkspacesService.delete(userId, workspaceCode);
        toastPush({
            text: intl.formatMessage(messages.workspaceDeleted)
        });
        window.location.replace('/')
        fetchUser();
    };

    return {
        workspace,
        patchWorkspace,
        patchWorkspaceAvatar,
        selectWorkspace,
        createWorkspace,
        leaveWorkspace,
        deleteWorkspace,
    };
};

export default useWorkspace;
