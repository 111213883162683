import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'rejectRequestsModal.title',
        defaultMessage: 'Reject requests',
    },
    description: {
        id: 'rejectRequestsModal.description',
        defaultMessage: 'Are you sure you want to reject the following requests?',
    },
    ifYouWouldLike: {
        id: 'rejectRequestsModal.ifYouWouldLike',
        defaultMessage: 'If you would like to include a message, type below:',
    },
    message: {
        id: 'rejectRequestsModal.message',
        defaultMessage: 'Message (optional)',
    },
    alt: {
        id: 'rejectRequestsModal.alt',
        defaultMessage: 'A man with a cellphone',
    },
    cancel: {
        id: 'rejectRequestsModal.cancel',
        defaultMessage: 'Cancel',
    },
    reject: {
        id: 'rejectRequestsModal.reject',
        defaultMessage: 'Reject',
    },
});
