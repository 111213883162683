import React, { useMemo } from 'react';
import { useIntl } from "react-intl";
import cx from "classnames";
import { motion } from "framer-motion"
import PropTypes from "prop-types";

import useAuthentication from "@quidlo/common/hooks/useAuthentication";
import useAutoTracker from "@quidlo/common/hooks/useAutoTracker";
import useForm from "@quidlo/common/hooks/useForm2";
import useWorkspaceFeatures from "@quidlo/common/hooks/useWorkspaceFeatures";
import validators from "@quidlo/common/utils/validators";
import { Autocomplete, Avatar, Button, Input, Space, Tag } from "@quidlo/ui";

import useSuggestions from "../../../../hooks/useSuggestions";
import { getTrackerFiltersProjectsQuery, getTrackerFiltersTagsQuery } from "../../../../queries/tracker.queries";
import { Suggestions } from "../../../../ui";
import ProjectPill from "../../../../ui/ProjectPill/ProjectPill";
import { dateToString } from "../../../../utils/dateHelpers";

import style from "./AutoTrackerForm.module.scss";

import messages from "./AutoTrackerForm.i18n";

const AutotrackerForm = ({ active }) => {
    const intl = useIntl();
    const { user } = useAuthentication();
    const { features } = useWorkspaceFeatures();
    const { start: startAutoTracker } = useAutoTracker();
    const { data: projectsOptions } = getTrackerFiltersProjectsQuery();
    const {
        titles: titlesSuggestions,
        projects: projectsSuggestions,
        tags: tagsSuggestions,
        feedSuggestions
    } = useSuggestions();

    const [form, { isFormValid, cleanForm, touchForm }] = useForm(useMemo(() => ({
        title: {
            value: '',
            validators: features?.required_task_description ? [
                validators.minLength(3, intl.formatMessage(messages.descriptionMin, { min: 3 })),
                validators.maxLength(500, intl.formatMessage(messages.descriptionMax, { max: 500 })),
            ] : []
        },
        project: {
            value: undefined,
            validators: [
                validators.isRequired(intl.formatMessage(messages.projectIsReq))
            ]
        },
        tags: { value: [] },
    }), [features?.required_task_description]));

    const { data: tagsOptions } = getTrackerFiltersTagsQuery(form.project.value);

    const submit = () => {
        const {
            title,
            project,
            tags
        } = form;

        if (!isFormValid) {
            return;
        }

        const task = {
            projectId: project.value,
            title: title.value.replace('\t',''),
            tagIds: tags.value,
            userId: user.id,
            date: dateToString(new Date())
        }

        startAutoTracker(task)
        feedSuggestions('titles', task.title);
        feedSuggestions('projects', task.projectId);
        if (task.tagIds.length < 3) {
            task.tagIds.forEach(t => {
                feedSuggestions('tags', t);
            })
        }
        cleanForm();
    };

    return (
        <motion.div
            className={style.root}
            variants={{
                active: { opacity: 1, y: 0, display: 'flex' },
                inActive: { opacity: 0, y: -30, transitionEnd: { display: 'none' }  }
            }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            animate={active? 'active' : 'inActive'}
            initial="inActive"
        >
            <Space mr="s" className={style.avatar}>
                <Avatar
                    firstName={user.firstName}
                    lastName={user.lastName}
                    picture={user.avatarUrl}
                />
            </Space>
            <Space mr="s" className={style.title}>
                <Input
                    {...form.title}
                    fieldId="timer-title"
                    label={intl.formatMessage(messages.whatHaveBeenDone)}
                    analyticsID="Tracker - Create - Description"
                />
                <div className={cx([style.suggestions, form.title.error && style.hidden])}>
                    <Suggestions
                        suggestions={titlesSuggestions}
                        onClick={e => form.title.onChange(e)}
                        maxNumber={3}
                        analyticsID="Tracker - Create - Description"
                    />
                </div>
            </Space>
            <Space mr="s" className={style.project}>
                <Autocomplete
                    {...form.project}
                    fieldId="timer-project"
                    label={intl.formatMessage(messages.project)}
                    options={projectsOptions
                        .map(p => ({
                            label: (
                                <ProjectPill {...p}  />
                            ),
                            labelText: p.name,
                            value: p.id,
                        }))}
                    disabled={!projectsOptions.length}
                    analyticsID="Tracker - Create - Project"
                />
                <div className={cx([style.suggestions, form.project.error && style.hidden])}>
                    <Suggestions
                        options={
                            projectsOptions
                                .map(p => ({
                                    label: p.name,
                                    value: p.id,
                                }))
                        }
                        suggestions={projectsSuggestions}
                        onClick={e => form.project.onChange(e)}
                        analyticsID="Tracker - Create - Project"
                    />
                </div>
            </Space>
            <Space mr="s" className={style.tag}>
                <Autocomplete
                    {...form.tags}
                    fieldId="timer-tags"
                    label={intl.formatMessage(messages.tags)}
                    options={tagsOptions.map(t => ({
                        label: <Tag text={t.name} />,
                        labelText: t.name,
                        value: t.id,
                    }))}
                    noOptionsText={
                        form.project.value
                            ? undefined
                            : intl.formatMessage(messages.pleaseSelect)
                    }
                    disabled={!form.project.value}
                    multiselect
                    type="chips"
                    analyticsID="Tracker - Create - Tags"
                />
                <div className={cx([style.suggestions, form.tags.error && style.hidden])}>
                    <Suggestions
                        options={
                            tagsOptions
                                .map(t => ({
                                    label: t.name,
                                    value: t.id,
                                }))
                        }
                        suggestions={tagsSuggestions}
                        onClick={e => form.tags.onChange([e])}
                        analyticsID="Tracker - Create - Tags"
                    />
                </div>
            </Space>
            <div className={style.button}>
                <Button
                    icon="play"
                    size="large"
                    iconSize="medium"
                    color="white"
                    iconColor="blue"
                    type="bordered"
                    disabled={!isFormValid}
                    onDisabledClick={touchForm}
                    onClick={submit}
                    shadow
                />
            </div>
        </motion.div>
    );
}

AutotrackerForm.propTypes = {
    active: PropTypes.bool.isRequired,
};

export default AutotrackerForm;