import { defineMessages } from 'react-intl';

export default defineMessages({
    duration: {
        id: 'timeEntryForm.duration',
        defaultMessage: 'Duration'
    },
    whatHaveBeenDone: {
        id: 'timeEntryForm.whatHaveBeenDone',
        defaultMessage: 'Task description'
    },
    tags: {
        id: 'timeEntryForm.tags',
        defaultMessage: 'Tags',
    },
    project: {
        id: 'timeEntryForm.project',
        defaultMessage: 'Project name',
    },
    user: {
        id: 'timeEntryForm.user',
        defaultMessage: 'People',
    },
    userIsReq: {
        id: 'timeEntryForm.userIsReq',
        defaultMessage: 'Please select a person',
    },
    projectIsReq: {
        id: 'timeEntryForm.projectIsReq',
        defaultMessage: 'Please select a project',
    },
    wrongTimeFormat: {
        id: 'timeEntryForm.wrongTimeFormat',
        defaultMessage: 'Incorrect time format',
    },
    descriptionMin: {
        id: 'timeEntryForm.descriptionMax',
        defaultMessage: 'Description should be at least {min} characters long',
    },
    descriptionMax: {
        id: 'timeEntryForm.descriptionMax',
        defaultMessage: 'Description should be at most {max} characters long',
    },
    pleaseSelect: {
        id: 'timeEntryForm.pleaseSelect',
        defaultMessage: 'Please select a project first',
    },
    timeStart: {
        id: 'timeEntryForm.timeStart',
        defaultMessage: 'Start'
    },
    timeStop: {
        id: 'timeEntryForm.timeStop',
        defaultMessage: 'Stop'
    },
    timeStartStopError: {
        id: 'timeEntryForm.timeStartStopError',
        defaultMessage: 'Start time must be equal or greater than Stop time'
    },
});