import fetch from '../utils/fetch';

export default {
    getIntents: () => fetch.get('/v1/intents'),
    getRooms: (userId, workspaceCode) => (
        fetch.get(`/v1/${workspaceCode}/users/${userId}/hangouts-rooms`)
    ),
    getRoom: (userId, workspaceCode, roomId) => (
        fetch.get(`/v1/${workspaceCode}/users/${userId}/hangouts-rooms/${roomId}`)
    ),
    editRoom: (userId, workspaceCode, roomId, query) => (
        fetch.patch(`/v1/${workspaceCode}/users/${userId}/hangouts-rooms/${roomId}`, query)
    ),
    deleteRoom: (userId, workspaceCode, roomId) => (
        fetch.delete(`/v1/${workspaceCode}/users/${userId}/hangouts-rooms/${roomId}`)
    ),
    getSlackTeams: (userId, workspaceCode) => (
        fetch.get(`/v1/${workspaceCode}/users/${userId}/slack-user`)
    ),
    deleteSlackTeam: (userId, workspaceCode, slackUserId) => (
        fetch.delete(`/v1/${workspaceCode}/users/${userId}/slack-user/${slackUserId}`)
    ),
};
