import { useIntl } from "react-intl";
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import UsersService from "@quidlo/common/api/UsersService";
import useAuthentication from "@quidlo/common/hooks/useAuthentication";
import useUI from "@quidlo/common/hooks/useUI";
import useWorkspace from "@quidlo/common/hooks/useWorkspace";

import { messagesToasts } from "../messages";

export const getUsersListAll = () => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    return useInfiniteQuery(
        [
            { workspace: workspace.code, user: user.id },
            'users', 'list-all',
        ],
        async () => {
            return (await UsersService.getAdministrableAll(workspace.code))?.data
        },
        {
            getNextPageParam: (lastPage, pages) => {
                if (lastPage.length < 15) return null;
                return pages.length * 15
            },
            initialData: [],
            // enabled: !!workspace?.code && !!user?.id,
        })
};

export const getUsersListAllInifiniteQuery = (filters = {}) => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    return useInfiniteQuery(
        [
            { workspace: workspace.code, user: user.id },
            'users', 'list-all',
            filters
        ],
        async ({ pageParam = 0 }) => {
            return (await UsersService.getAdministrableAll(workspace.code, { limit: 15, offset: pageParam, ...filters }))?.data
        },
        {
            getNextPageParam: (lastPage, pages) => {
                if (lastPage.length < 15) return null;
                return pages.length * 15
            },
            initialData: [],
            // enabled: !!workspace?.code && !!user?.id,
        })
};

export const getUsersTotalQuery = () => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    return useQuery(
        [
            { workspace: workspace.code, user: user.id },
            'users', 'total',
        ],
        async () => Number((await UsersService.getAdministrableAll(workspace.code, { limit: 0 }))?.headers['x-total-count']),
        {
            // enabled: !!workspace?.code && !!user?.id,
        })
};

export const addUsersMutation = () => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    const queryClient = useQueryClient();
    const { toastPush } = useUI();
    const intl = useIntl();

    return useMutation(async (emails= []) => (await UsersService.inviteUsers(workspace.code, emails)), {
        onSuccess: () => {
            toastPush({
                text: intl.formatMessage(messagesToasts.userAdded)
            });
            queryClient.invalidateQueries([
                { workspace: workspace.code, user: user.id },
                'users', 'total',
            ])
            queryClient.invalidateQueries([
                { workspace: workspace.code, user: user.id },
                'users', 'list-all',
            ])
        },
        onError: e => {
            toastPush({
                text: e?.response?.data?.message || intl.formatMessage(messagesToasts.unexpectedError),
                color: 'red'
            });
        }
    })};

export const resendInvitationMutation = () => {
    const { workspace } = useWorkspace();
    const { toastPush } = useUI();
    const intl = useIntl();

    return useMutation(async ({ userId }) => (await UsersService.resendInvitationEmail(workspace.code, userId)), {
        onSuccess: (data, params) => {
            toastPush({
                text: intl.formatMessage(messagesToasts.invitationHasBeenSent, { email: params.email })
            });
        },
        onError: e => {
            toastPush({
                text: e?.response?.data?.message || intl.formatMessage(messagesToasts.unexpectedError),
                color: 'red'
            });
        }
    })};

export const editUserMutation = () => {
    const { user, fetchUser } = useAuthentication();
    const { workspace } = useWorkspace();
    const queryClient = useQueryClient();
    const { toastPush } = useUI();
    const intl = useIntl();

    return useMutation(async ({ userId, delta }) => (await UsersService.patchUser(workspace.code, userId, delta)), {
        onSuccess: ({ data }) => {
            toastPush({
                text: intl.formatMessage(messagesToasts.userEdited)
            });
            queryClient.invalidateQueries([
                { workspace: workspace.code, user: user.id },
                'users', 'list-all',
            ])
            queryClient.invalidateQueries([
                { workspace: workspace.code, user: user.id },
                'users', 'user', data.id,
            ])
            fetchUser();
        },
        onError: e => {
            toastPush({
                text: e?.response?.data?.message || intl.formatMessage(messagesToasts.unexpectedError),
                color: 'red'
            });
        }
    })};

export const deleteUserMutation = () => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    const queryClient = useQueryClient();
    const { toastPush } = useUI();
    const intl = useIntl();

    return useMutation(async userId => (await UsersService.deleteUser(workspace.code, userId)), {
        onSuccess: () => {
            toastPush({
                text: intl.formatMessage(messagesToasts.userRemoved)
            });
            queryClient.invalidateQueries([
                { workspace: workspace.code, user: user.id },
                'users', 'list-all',
            ])
            queryClient.invalidateQueries([
                { workspace: workspace.code, user: user.id },
                'users', 'total',
            ])
        },
        onError: e => {
            toastPush({
                text: e?.response?.data?.message || intl.formatMessage(messagesToasts.unexpectedError),
                color: 'red'
            });
        }
    })};

