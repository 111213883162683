import TIME_FORMATS from '../constants/timeFormats';

export const timeParser = value => {
    if (!value) {
        return '00:00';
    }

    const string = value.trim();
    let
        hours = 0,
        minutes = 0,
        abbrev = '';
    if (TIME_FORMATS.HOURS_MINUTES_24.test(string)) {
        [, hours, minutes] = TIME_FORMATS.HOURS_MINUTES_24.exec(string);
    } else if (TIME_FORMATS.HOURS_MINUTES_12.test(string)) {
        [, hours, minutes, abbrev] = TIME_FORMATS.HOURS_MINUTES_12.exec(string);
    } else if (TIME_FORMATS.HOURS_24.test(string)) {
        [, hours] = TIME_FORMATS.HOURS_24.exec(string);
    } else if (TIME_FORMATS.HOURS_12.test(string)) {
        [, hours,abbrev] = TIME_FORMATS.HOURS_12.exec(string);
    }

    hours = parseInt(hours, 10);
    minutes = parseInt(minutes, 10);

    if (abbrev === 'pm' || abbrev === 'PM') {
        hours += 12;
    }

    return `${hours < 10 ? `0${hours}` : hours }:${minutes < 10 ? `0${minutes}` : minutes}`;
};
export const timeFormatter = date => {
    if (date) {
        const
            hours = (new Date(date)).getHours(),
            minutes = (new Date(date)).getMinutes();

        return `${hours}:${minutes < 10 ? `0${minutes}` : minutes }`;
    }
    return '';
};
