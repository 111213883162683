import React, { useEffect, useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { useIntl } from "react-intl";
import { useNavigate } from 'react-router-dom';
import { motion } from "framer-motion";

import { WORKSPACE_ROLE } from "@quidlo/common/constants/main";
import useAuthentication from "@quidlo/common/hooks/useAuthentication";
import useUI from "@quidlo/common/hooks/useUI";
import useWorkspace from "@quidlo/common/hooks/useWorkspace";
import useWorkspaceFeatures from "@quidlo/common/hooks/useWorkspaceFeatures";
import analytics from "@quidlo/common/utils/analytics";

import MobileScreen from '../../components/MobileScreen';
import { messagesToasts } from "../../messages";
import { getWorkspaceSubscriptionQuery } from "../../queries/workspace.queries";

import style from './View.module.scss';

export default function ViewHOC(ViewComponent, config) {
    const ViewHOCClass = props => {
        const
            intl = useIntl(),
            navigate = useNavigate(),
            { toastPush, setIsMenu, setIsTopbar } = useUI(),
            { user, signOut, isInitialized } = useAuthentication(),
            { workspace } = useWorkspace(),
            { data: subscription } = getWorkspaceSubscriptionQuery(),
            { features } = useWorkspaceFeatures(),
            [isDisplayed, setIsDisplayed] = useState(false);

        const isAuthenticated = user && workspace;

        const init = () => {
            if (isMobile && !isTablet) {
                setIsMenu(false);
                setIsTopbar(false);
            } else {
                setIsMenu(config.menu);
                setIsTopbar(config.topbar);
            }
        };
        useEffect(() => {
            if (!isInitialized) return;
            if (!config.auth) {
                setIsDisplayed(true);
                init();
                return;
            }

            // View requires active session
            if (config.auth && !isAuthenticated) {
                sessionStorage.setItem('redirectUrl', window.location.href);
                signOut();
                return;
            }

            // View requires active subscription
            if (config.subscription && subscription?.isExpired) {
                navigate(workspace?.organizationRole === WORKSPACE_ROLE.OWNER ? '/paywall' : '/profile');
                return;
            }

            // View requires enabled feature
            if (config.feature && !features[config.feature]) {
                navigate('/settings/features');
                return;
            }

            // View requires certain user role
            if (config.auth?.roles) {
                const { projectsRole, organizationRole } = workspace;
                const role = ((projectsRole === 'manager' && organizationRole === 'employee') ? 'manager' : organizationRole);
                if (!config.auth.roles.includes(role)) {
                    navigate('/tracker');
                    toastPush({
                        text: intl.formatMessage(messagesToasts.accessDenied),
                        color: 'red'
                    });
                    return;
                }
            }

            document.title = `Timesheets${config.title ? ` - ${config.title}` : ''}`;
            analytics.logScreenDisplay(config.title);
            setIsDisplayed(true);
            init();
        }, [isInitialized, workspace, subscription]);

        if (!config.mobile && isMobile && !isTablet) {
            return (<MobileScreen />);
        }

        return (
            <motion.div
                data-key={config.title}
                key={config.title}
                className={style.view}
                initial={{ opacity: 0 }}
                animate={{
                    opacity: 1,
                    position: "absolute",
                    top: 0,
                    left: 0,
                    transitionEnd: {
                        position: "relative",
                        top: 'auto',
                        left: 'auto',
                    },
                }}
                exit={{ opacity: 0 }}
                transition={{
                    duration: 0.1,
                }}
            >
                {(isDisplayed ? <ViewComponent {...props} /> : <div />)}
            </motion.div>
        );
    };

    return ViewHOCClass;
}