import { defineMessages } from 'react-intl';

export default defineMessages({
    authFailed: {
        id: 'useAuthentication.authFailed',
        defaultMessage: 'Authorization failed',
    },
    wrongAccountIvitation: {
        id: 'useAuthentication.wrongAccountIvitation',
        defaultMessage: 'Wrong account for invitation',
    },
    accountActivated: {
        id: 'useAuthentication.accountActivated',
        defaultMessage: 'Account activated',
    },
    userEdited: {
        id: 'useAuthentication.userEdited',
        defaultMessage: 'User edited',
    },
    passwordSet: {
        id: 'useAuthentication.passwordSet',
        defaultMessage: 'Password set',
    },
});
