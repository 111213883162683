import { addDays, setWeekday } from './dateHelpers';

const
    monthDate = string => {
        const [year, month] = string.split('-M');
        return [
            new Date(+year, month - 1, 1),
            new Date(+year, +month, 0)
        ];
    },
    weekDate = (string, weekStart) => {
        const
            [year, week] = string.split('-W'),
            firstThursday = setWeekday(new Date(+year, 0, 4), 3, 'monday');

        return [
            addDays(firstThursday, (7 * (week - 1)) - (weekStart === 'sunday' ? 4 : 3)),
            addDays(firstThursday, (7 * (week - 1)) + (weekStart === 'sunday' ? 2 : 3))
        ];
    },
    dayDate = string => {
        const [year, day] = string.split('-D');
        return [
            new Date(+year, 0, +day),
            new Date(+year, 0, +day)
        ];
    },
    periodToDate = period => {
        if (period.includes('-M')) {
            return monthDate(period);
        } else if (period.includes('-W')) {
            return weekDate(period);
        } else if (period.includes('-D')) {
            return dayDate(period);
        }
        return undefined;
    },
    periodToString = (period, intl, weekday = false, weekStart = 'monday') => {
        if (period.includes('-M')) {
            return intl.formatDate(monthDate(period)[0], { month: 'long', year: 'numeric' });
        } else if (period.includes('-W')) {
            const week = weekDate(period, weekStart);
            return `${intl.formatDate(week[0], {
                month: 'short',
                day: 'numeric'
            })} - ${intl.formatDate(week[1], { month: 'short', day: 'numeric' })}`;
        } else if (period.includes('-D')) {
            const day = dayDate(period)[0];
            return weekday ? intl.formatDate(day, {
                month: 'long',
                day: 'numeric',
                weekday: 'long'
            }) : intl.formatDate(day, { month: 'short', day: 'numeric' });
        }
        return undefined;
    };

export {
    periodToDate,
    periodToString
};
