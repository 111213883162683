import { defineMessages } from 'react-intl';

export default defineMessages({
    deleteUser: {
        id: 'usersList.deleteUser',
        defaultMessage: 'Remove User'
    },
    areYouSure: {
        id: 'usersList.areYouSure',
        defaultMessage: 'Are you sure you want to remove this user from project?'
    },
    cancel: {
        id: 'usersList.cancel',
        defaultMessage: 'Cancel'
    },
    remove: {
        id: 'usersList.remove',
        defaultMessage: 'Remove'
    },
});