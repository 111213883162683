import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import style from './Dot.module.scss';

const Dot = ({ color }) => <div className={cx(style.dot, style[`color-${color}`],)} />;

Dot.propTypes = {
    color: PropTypes.oneOf(['green', 'red', 'yellow', 'blue', 'grey'])
};

export default Dot;
