import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { getProjectsProjectQuery } from "../../../../queries/projects.queries";
import { List, ListCell } from '../../../../ui';
import ProjectRemoveUser from "../modals/ProjectRemoveUser";

import UserRow from "./UserRow";

import style from './UsersList.module.scss';

import messages from './UsersList.i18n';

const UsersList = ({
    users,
    projectId,
}) => {
    const intl = useIntl();

    const { data: project } = getProjectsProjectQuery(projectId);
    const usersSorted = useMemo(() => {
        return users.sort((a, b) => a.firstName.localeCompare(b.firstName)).map(u => ({
            ...u,
            id: u.userId,
        }))
    },[users])

    return (
        <div className={style.usersList}>
            {users.length > 0 && (
                <div className={style.roleTable}>
                    <List
                        header={
                            <>
                                <ListCell align="center" header>
                                    {intl.formatMessage(messages.person)}
                                </ListCell>
                                <ListCell width="350px" align="center" header>
                                    {intl.formatMessage(messages.email)}
                                </ListCell>
                                <ListCell width="250px" align="center" header>
                                    {intl.formatMessage(messages.projectRole)}
                                </ListCell>
                            </>
                        }
                        data={usersSorted}
                        renderRow={(user, status) => (<UserRow user={user} status={status} projectId={projectId} />)}
                        editable={!project.isArchived}
                    />
                </div>
            )}
            <ProjectRemoveUser />
        </div>
    )
};

UsersList.propTypes = {
    users: PropTypes.array,
    projectId: PropTypes.string.isRequired,
};

UsersList.defaultProps = {
    users: [],
};

export default UsersList;
