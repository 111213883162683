import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import style from './ListRowNoResult.module.scss';

import messages from './ListRowNoResult.i18n';

const
    ListRowNoResult = ({
        noResults,
        width
    }) => {
        const intl = useIntl();
        return (
            <div className={style.listRow} style={{ width }}>
                {noResults || intl.formatMessage(messages.noResults)}
            </div>
        );
    }

ListRowNoResult.propTypes = {
    width: PropTypes.string,
    noResults: PropTypes.string,
};

ListRowNoResult.defaultProps = {
    width: '100%',
    noResults: undefined
};

export default ListRowNoResult;