import React, { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';

import useForm from '@quidlo/common/hooks/useForm2';
import useUI from '@quidlo/common/hooks/useUI';
import useWorkspaceFeatures from "@quidlo/common/hooks/useWorkspaceFeatures";
import analytics from "@quidlo/common/utils/analytics";
import { durationFormatter, durationParser, durationShortFormatter } from '@quidlo/common/utils/duration';
import { timeParser } from '@quidlo/common/utils/time';
import validators from "@quidlo/common/utils/validators";
import { Autocomplete, Button, Input, Space, Tag } from '@quidlo/ui';

import illustration from "../../../assets/modals/pencil.png";
import { getProjectsListQuery } from '../../../queries/projects.queries';
import { editTaskMutation, getTaskQuery } from "../../../queries/tasks.queries";
import { getTrackerFiltersTagsQuery } from "../../../queries/tracker.queries";
import { InputDate  } from "../../../ui";
import ModalHOC, { ModalActions, ModalContent, ModalHeader } from "../../../ui/Modal/Modal";
import ProjectPill from "../../../ui/ProjectPill/ProjectPill";
import { dateToString } from '../../../utils/dateHelpers';

import style from './EditTask.module.scss';

import messages from './EditTask.i18n';

const EditTask = () => {
    const
        intl = useIntl(),
        { features } = useWorkspaceFeatures(),
        { modal, modalClose } = useUI(),
        { id }  =  modal?.data || {},
        [form, { isFormValid, isFormDirty, touchForm, formError }] = useForm(useMemo(() => ({
            date: {
                value: new Date(),
                validators: [validators.isRequired()]
            },
            project: {
                value: null,
                validators: [validators.isRequired()]
            },
            title: {
                value: '',
                validators: features?.required_task_description ? [validators.isRequired(), validators.minLength(2), validators.maxLength(400)] : []
            },
            timeFrom: {
                value: '',
                validators: features?.task_with_from_to ? [validators.isRequired(), validators.isTime()] : []
            },
            timeTo: {
                value: '',
                validators: features?.task_with_from_to ? [validators.isRequired(), validators.isTime()] : []
            },
            durationMins: {
                value: '',
                validators: [validators.isRequired(), validators.isDuration()]
            },
            tags: {
                value: []
            }
        }),[features]), {
            validators: features?.task_with_from_to ? [
                form => form.timeFrom.value > form.timeTo.value ? intl.formatMessage(messages.timeStartStopError) : null,
            ] : []
        }),
        { data: task } = getTaskQuery(id),
        { data: projects } = getProjectsListQuery(),
        { data: tags } = getTrackerFiltersTagsQuery(form.project.value),
        { mutate: editTask } = editTaskMutation(),
        patchTask = () => {
            if (!isFormValid) return;
            const delta = {};
            if (dateToString(form.date.value) !== task.date) {
                delta.date = dateToString(form.date.value);
            }
            if (form.project.value !== task.projectId) {
                delta.projectId = form.project.value;
            }
            if (form.durationMins.value !== task.durationMins) {
                delta.durationMins = durationParser(form.durationMins.value);
            }
            if (form.title.value !== task.title) {
                delta.title = form.title.value;
            }
            if (form.tags.value !== task.tags) {
                delta.tagIds = form.tags.value;
            }
            if (form.timeFrom.isDirty) {
                delta.timeFrom = form.timeFrom.value
            }
            if (form.timeTo.isDirty) {
                delta.timeTo = form.timeTo.value
            }
            editTask({ id, delta });
            if (modal.data.source === 'tracker') {
                analytics.logTimesheetsTrackerTaskEdit({
                    actor: modal.data.actor,
                    interaction: 'modal',
                    isDesc: !!delta.title,
                    isTags: !!delta.tags,
                    isDuration: !!delta.durationMins,
                    isDate: !!delta.date,
                    isProject: !!delta.projectId,
                    isTimeFrom: !!delta.timeFrom,
                    isTimeTo: !!delta.timeTo,
                });
            } else if (modal.data.source === 'reports') {
                analytics.logTimesheetsReportsTaskEdit({
                    actor: modal.data.actor,
                    interaction: 'modal',
                    isDesc: !!delta.title,
                    isTags: !!delta.tags,
                    isDuration: !!delta.durationMins,
                    isDate: !!delta.date,
                    isProject: !!delta.projectId,
                    isTimeFrom: !!delta.timeFrom,
                    isTimeTo: !!delta.timeTo,
                });
            }
            modalClose();
        };

    const onDurationBlur = () => {
        const durationMins = durationParser(form.durationMins.value);
        let [hours, minutes] = form.timeFrom.value.split(':');
        minutes = parseInt(minutes) + durationMins;
        hours = parseInt(hours) + (minutes > 59 ? Math.floor(minutes / 60) : 0);
        minutes = minutes > 59 ? minutes % 60 : minutes;
        if (hours < 24) {
            form.timeTo.onChange(`${hours < 10 ? `0${hours}` : hours }:${minutes < 10 ? `0${minutes}` : minutes}`);
        }
    }

    const onTimeFromBlur = () => {
        form.timeFrom.onChange(timeParser(form.timeFrom.value));
        if (form.timeFrom.value) {
            if (form.timeFrom.value && form.timeTo.value) {
                const timeFrom = new Date(`${dateToString(form.date.value)} ${timeParser(form.timeFrom.value)}`);
                const timeTo = new Date(`${dateToString(form.date.value)} ${timeParser(form.timeTo.value)}`);
                const duration = timeTo - timeFrom;
                if (duration>=0) {
                    form.durationMins.onChange(durationShortFormatter(duration/60000));
                }
            }
        }
    }

    const onTimeToBlur = () => {
        form.timeTo.onChange(timeParser(form.timeTo.value));
        if (form.timeTo.value) {
            if (form.timeFrom.value && form.timeTo.value) {
                const timeFrom = new Date(`${dateToString(form.date.value)} ${timeParser(form.timeFrom.value)}`);
                const timeTo = new Date(`${dateToString(form.date.value)} ${timeParser(form.timeTo.value)}`);
                const duration = timeTo - timeFrom;
                if (duration>=0) {
                    form.durationMins.onChange(durationShortFormatter(duration/60000));
                }
            }
        }
    }


    useEffect(() => {
        if (task) {
            form.date.setValue(new Date(task.date.replace(/-/g, '/')));
            form.project.setValue(task.projectId);
            form.title.setValue(task.title);
            form.durationMins.setValue(durationFormatter(task.durationMins));
            form.tags.setValue(task.tags.map(t => t.id));
            if (task.timeFrom) {
                form.timeFrom.setValue(timeParser(task.timeFrom));
            }
            if (task.timeTo) {
                form.timeTo.setValue(timeParser(task.timeTo));
            }
        }
    }, [task]);

    return (
        <>
            <ModalHeader>
                {intl.formatMessage(messages.editTask)}
            </ModalHeader>
            <ModalContent>
                <div className={style.row}>
                    <div className={style.column}>
                        <Input
                            {...form.title}
                            label={intl.formatMessage(messages.task)}
                            analyticsID="Edit Task - Description"
                            isRequired={features?.required_task_description}
                        />
                    </div>
                </div>
                <div className={style.row}>
                    <div className={style.column}>
                        <Autocomplete
                            {...form.project}
                            label={intl.formatMessage(messages.project)}
                            name="task-project"
                            options={projects
                                .map(p => ({
                                    label: <ProjectPill{...p} />,
                                    labelText: p.name,
                                    value: p.id
                                }))}
                            analyticsID="Edit Task - Project"
                            isRequired
                        />
                    </div>
                </div>
                <div className={style.row}>
                    <div className={style.column}>
                        <InputDate
                            {...form.date}
                            label={intl.formatMessage(messages.date)}
                            name="task-date"
                            intl={intl}
                            isRequired
                        />
                    </div>
                    <div className={style.column}>
                        {tags.length > 0 && (
                            <Autocomplete
                                {...form.tags}
                                label={intl.formatMessage(
                                    messages.tags,
                                )}
                                options={tags.map(t => ({
                                    label: <Tag text={t.name} />,
                                    labelText: t.name,
                                    value: t.id,
                                }))}
                                noOptionsText={
                                    form.tags.value
                                        ? undefined
                                        : intl.formatMessage(
                                            messages.pleaseSelect,
                                        )
                                }
                                multiselect
                                type="chips"
                                analyticsID="Edit Task - Tags"
                                optionsLength={3}
                            />
                        )}
                    </div>
                </div>
                <div className={style.row}>
                    {
                        features?.task_with_from_to && (
                            <>
                                <div className={style.column}>
                                    <Input
                                        {...form.timeFrom}
                                        onBlur={onTimeFromBlur}
                                        label={intl.formatMessage(
                                            messages.start,
                                        )}
                                        analyticsID="Edit Task - TimeFrom"
                                        isRequired
                                    />
                                </div>
                                <div className={style.column}>
                                    <Input
                                        {...form.timeTo}
                                        error={form.timeTo.error || formError}
                                        onBlur={onTimeToBlur}
                                        label={intl.formatMessage(
                                            messages.stop,
                                        )}
                                        analyticsID="Edit Task - TimeTo"
                                        isRequired
                                    />
                                </div>
                            </>
                        )
                    }

                    <div className={style.column}>
                        <Input
                            {...form.durationMins}
                            onBlur={onDurationBlur}
                            label={intl.formatMessage(
                                messages.duration,
                            )}
                            analyticsID="Edit Task - Duration"
                            isRequired
                        />
                    </div>
                </div>
            </ModalContent>
            <ModalActions>
                <Space mr="m">
                    <Button
                        color="white"
                        text={intl.formatMessage(messages.cancel)}
                        onClick={modalClose}
                    />
                </Space>
                <Button
                    color="blue"
                    text={intl.formatMessage(messages.save)}
                    disabled={!isFormValid || !isFormDirty}
                    onDisabledClick={touchForm}
                    onClick={patchTask}
                    shadow
                />
            </ModalActions>
        </>
    );
};

export default ModalHOC(
    EditTask,
    {
        id: 'editTask',
        size: 'large',
        illustration,
    }
);
