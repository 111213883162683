import { defineMessages } from 'react-intl';

export default defineMessages({
    starterAlt: {
        id: 'card.starterAlt',
        defaultMessage: 'A woman standing in front of a desk',
    },
    starterTitle: {
        id: 'card.starterTitle',
        defaultMessage: 'Starter',
    },
    starterPrice: {
        id: 'card.starterPrice',
        defaultMessage: '$0 / month',
    },
    starterSubtitle: {
        id: 'card.starterSubtitle',
        defaultMessage: 'For small teams to achieve big dreams',
    },
    starterPerk1: {
        id: 'card.starterPerk1',
        defaultMessage: 'No credit card required',
    },
    starterPerk2: {
        id: 'card.starterPerk2',
        defaultMessage: 'Up to 10 users',
    },
    starterButton: {
        id: 'card.starterButton',
        defaultMessage: 'Continue with starter',
    },
    proAlt: {
        id: 'card.proAlt',
        defaultMessage: 'A man and a woman on other sides of the desk',
    },
    proTitle: {
        id: 'card.proTitle',
        defaultMessage: 'Pro',
    },
    annualProPrice: {
        id: 'card.annualProPrice',
        defaultMessage: '$29 / month',
    },
    proPrice: {
        id: 'card.proPrice',
        // eslint-disable-next-line no-template-curly-in-string
        defaultMessage: '${price} / month',
    },
    billedMonthly: {
        id: 'card.billedMonthly',
        defaultMessage: 'Billed monthly',
    },
    billedAnnually: {
        id: 'card.billedAnnually',
        defaultMessage: 'Billed annually',
    },
    proSubtitle: {
        id: 'card.proSubtitle',
        defaultMessage: 'For companies that need a reliable time tracker',
    },
    proPerk1: {
        id: 'card.proPerk1',
        defaultMessage: 'Unlimited number of users',
    },
    proPerk2: {
        id: 'card.proPerk2',
        defaultMessage: 'Additional features',
    },
    proButton: {
        id: 'card.proButton',
        defaultMessage: 'Switch to pro',
    },
    customAlt: {
        id: 'card.customAlt',
        defaultMessage: 'A mobile phone',
    },
    customTitle: {
        id: 'card.customTitle',
        defaultMessage: 'Custom',
    },
    customPrice: {
        id: 'card.customPrice',
        defaultMessage: 'Let\'s talk',
    },
    customSubtitle: {
        id: 'card.customSubtitle',
        defaultMessage: 'For businesses that need a custom solution',
    },
    customPerk1: {
        id: 'card.customPerk1',
        defaultMessage: 'Tailor Quidlo Timesheets to your needs',
    },
    customButton: {
        id: 'card.customButton',
        defaultMessage: 'Contact us',
    },
    bestForTeams: {
        id: 'card.bestForTeams',
        defaultMessage: 'Best for teams'
    }
});