import React from 'react';
import { useIntl } from 'react-intl';

import { Space } from "@quidlo/ui";

import illustration from "../../../../assets/modals/woman-time.png";
import { getWorkspaceOwnerQuery } from "../../../../queries/workspace.queries";
import ModalHOC, { ModalContent, ModalHeader } from "../../../../ui/Modal/Modal";

import style from "./SubscriptionExpired.module.scss";

import messages from "./SubscriptionExpired.i18n";

const SubscriptionExpired = () => {
    const
        intl = useIntl(),
        { data: owner } = getWorkspaceOwnerQuery();

    return (
        <>
            <ModalHeader>
                {intl.formatMessage(messages.subscriptionExpiredTitle)}
            </ModalHeader>
            <ModalContent>
                <Space mb="m" className={style.root}>
                    <span>
                        {intl.formatMessage(messages.subtitleBold)}
                        <strong>{intl.formatMessage(messages.subtitleRegular)}</strong>
                    </span>
                </Space>
                {intl.formatMessage(messages.workspaceOwner, { email: owner?.email })}
            </ModalContent>
        </>
    );
};

export default ModalHOC(
    SubscriptionExpired,
    {
        id: 'subscriptionExpired',
        size: 'small',
        illustration
    }
);