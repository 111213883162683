import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'createApprovalRequestModal.title',
        defaultMessage: 'Request time approval',
    },
    description: {
        id: 'createApprovalRequestModal.description',
        defaultMessage: 'Are you sure you want to request approval for the following work?',
    },
    alt: {
        id: 'createApprovalRequestModal.alt',
        defaultMessage: 'A pencil',
    },
    cancel: {
        id: 'createApprovalRequestModal.cancel',
        defaultMessage: 'Cancel',
    },
    request: {
        id: 'createApprovalRequestModal.request',
        defaultMessage: 'Request time approval',
    },
});
