/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import style from './ListActions.module.scss';
import {Icon} from '@quidlo/ui';

const
    ListActions = ({actions}) => {
        const buttons = actions.map(a => (
            <a
                key={a.title}
                className={cx(style.button, a.color && style[`color-${a.color}`])}
                title={a.title}
                onMouseDown={e => {
                    e.preventDefault();
                    a.onClick();
                }}
            >
                <Icon icon={a.icon} size={a.size} color={(a.color ? 'white' : 'blue')} />
            </a>
        ));
        return (
            <div className={style.listActions}>
                {buttons}
            </div>
        );
    };


ListActions.propTypes = {
    actions: PropTypes.array,
};

ListActions.defaultProps = {
    actions: [],
};

export default ListActions;