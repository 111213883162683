import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'profile.notifications.title',
        defaultMessage: 'Email notification settings'
    },
    newsletter: {
        id: 'profile.notifications.newsletter',
        defaultMessage: 'Newsletter'
    },
    newsletterDescription: {
        id: 'profile.notifications.newsletter.description',
        defaultMessage: 'From time to time, Timesheets may send you offers, promotional material and other information'
    },
    reports: {
        id: 'profile.notifications.reports',
        defaultMessage: 'Reports'
    },
    reportsDescription: {
        id: 'profile.notifications.reports.description',
        defaultMessage: 'Timesheets will regularly send information on users activities, such as summary reports'
    },
    save: {
        id: 'profile.notifications.save',
        defaultMessage: 'Save'
    }
});
