import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Space from '../Space';

import { ReactComponent as TickIcon } from './svg/tick.svg';

import style from './Checkbox.module.scss';

const Checkbox = ({ onChange, label, value, fieldId, cypressId, required, disabled, error }) => {
    const handleChange = () => {
        if (onChange) {
            onChange(!value)
        }
    }
    return (
        <div
            className={cx(
                style.checkbox,
                value && style.checked,
                error && style.error,
            )}>
            <div className={cx(style.check, label && style.labeled, disabled && style.disabled)} onClick={disabled ? undefined : handleChange}>
                <TickIcon />
            </div>
            <input
                onChange={handleChange}
                checked={value}
                id={fieldId}
                name={fieldId}
                type="checkbox"
                value={value}
                data-cy={cypressId}
            />
            <label htmlFor={fieldId} id={fieldId} >
                {required &&
                    <Space mr={'xxs'}>
                        <span className={style.required}>*</span>
                    </Space>
                }
                {label}
            </label>
            {error &&
                <span className={style.errormessage}>
                    {error}
                </span>
            }
        </div>
    );
}

Checkbox.propTypes = {
    label: PropTypes.node,
    value: PropTypes.bool,
    onSelect: PropTypes.func,
    onChange: PropTypes.func,
    fieldId: PropTypes.string,
    cypressId: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    error: PropTypes.string
};

Checkbox.defaultProps = {
    label: '',
    fieldId: '',
    error: '',
    value: false,
    onSelect: null,
    onChange: null,
    cypressId: null,
    disabled: false,
    required: false
};

export default Checkbox;
