import fetch from '../utils/fetch';

export default {
    get: (userId, workspaceCode) =>
        fetch.get(`/v1/users/${userId}/administered-organizations/${workspaceCode}/subscription`),
    getTaxIdInfo: (userId, workspaceCode) =>
        fetch.get(`/v1/users/${userId}/administered-organizations/${workspaceCode}/stripe/customer`),
    postTaxIdInfo: (userId, workspaceCode, data) =>
        fetch.post(`/v1/users/${userId}/administered-organizations/${workspaceCode}/stripe/customer`, data),
    putTaxIdInfo: (userId, workspaceCode, data) =>
        fetch.put(`/v1/users/${userId}/administered-organizations/${workspaceCode}/stripe/customer`, data),
    getTaxIdCountries: (workspaceCode, userId) =>
        fetch.get(`/v1/users/${userId}/administered-organizations/${workspaceCode}/stripe/taxes`),
    postSubscription: (userId, workspaceCode, subscription) =>
        fetch.post(
            `/v1/users/${userId}/administered-organizations/${workspaceCode}/stripe/checkout-session`,
            {
                period: subscription === 'monthly' ? 'month' : 'year'
            }
        ),
    postStarterSubscription: (userId, workspaceCode) =>
        fetch.post(`/v1/users/${userId}/administered-organizations/${workspaceCode}/subscription/starter`),
    getManageSubscriptionUrl: (userId, workspaceCode) =>
        fetch.post(
            `/v1/users/${userId}/administered-organizations/${workspaceCode}/stripe/customer-portal`,
        ),
};
