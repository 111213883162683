import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Space } from "@quidlo/ui";

import { isToday } from "../../../utils/dateHelpers";

import AutotrackerForm from "./AutotrackerForm/AutotrackerForm";
import TimeEntryForm from "./TimeEntryForm/TimeEntryForm";
import Marker from "./Marker";
import Switch from "./Switch";

import style from "./TrackerForm.module.scss";

const AddTaskForm = ({ date, scope }) => {
    const [mode, setMode] = useState('add');

    useEffect(() => {
        if (scope === 'all' || !isToday(date)) {
            setMode('add');
        }
    }, [scope, date])

    return (
        <Space ph="s" direction="row" className={style.root}>
            <Space mr="s" className={style.content}>
                <TimeEntryForm date={date} scope={scope} active={mode === 'add'} />
                <AutotrackerForm active={mode === 'start'} />
            </Space>
            <div className={style.switch}>
                <Switch onChange={setMode} value={mode} disabledStart={scope === 'all' || !isToday(date)} />
            </div>
            <Marker date={date} />
        </Space>
    );
};

AddTaskForm.propTypes = {
    scope: PropTypes.oneOf(['my', 'all']),
    date: PropTypes.instanceOf(Date).isRequired
};

export default AddTaskForm;

