import { defineMessages } from 'react-intl';

export default defineMessages({
    timeApprovals: {
        id: 'approvals.timeApprovals',
        defaultMessage: 'Time approvals',
    },
    requestTimeApproval: {
        id: 'approvals.requestTimeApproval',
        defaultMessage: 'Create new request',
    },
    reject: {
        id: 'approvals.reject',
        defaultMessage: 'Reject',
    },
    approve: {
        id: 'approvals.approve',
        defaultMessage: 'Approve',
    },
    myRequests: {
        id: 'approvals.myRequests',
        defaultMessage: 'My requests',
    },
    toApprove: {
        id: 'approvals.toApprove',
        defaultMessage: 'To approve',
    },
});