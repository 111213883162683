import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { AnimatePresence, motion } from "framer-motion";
import PropTypes from 'prop-types';

import { Portal } from '..';

import style from './Tooltip.module.scss';

const MARKER_WIDTH = 10;

const Tooltip = ({ text, position, children }) => {
    const [open, setOpen] = useState(false),
        [coordinates, setCoordinates] = useState({}),
        [width, setWidth] = useState('200px'),
        elementRef = useRef(null),
        contentRef = useRef(null),
        handleMouseEnter = () => setOpen(!!text),
        handleMouseLeave = () => setOpen(false);

    useEffect(() => {
        if (contentRef.current) {
            const elInfo = elementRef.current.getBoundingClientRect(),
                contentInfo =contentRef.current.getBoundingClientRect(),
                left = `${elInfo.left - 100 + elInfo.width / 2}px`,
                elementWidth = '200px';

            setWidth(elementWidth);
            setCoordinates({
                bottom:
                    position === 'bottom'
                        ?
                        `${document.body.clientHeight -
                            (window.scrollY + elInfo.top + elInfo.height + contentInfo.height)
                            - MARKER_WIDTH
                        }px` : `${
                            document.body.clientHeight -
                            (elInfo.top + window.scrollY)
                        + MARKER_WIDTH
                        }px`,
                left,
            });
        }
    }, [contentRef.current, open])

    return (
        <div
            className={cx(style.wrapper)}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            ref={elementRef}>
            {text && (
                <Portal>
                    <AnimatePresence>
                        {!!open&& (
                            <motion.div
                                className={cx(
                                    style.tooltip,
                                    style[`position-${position}`],
                                )}
                                ref={contentRef}
                                style={{
                                    bottom: coordinates.bottom,
                                    left: coordinates.left,
                                    width,
                                }}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                <div
                                    className={style.content}
                                    style={{ maxWidth: width }}>
                                    {text}
                                </div>
                            </motion.div>
                        )}
                    </AnimatePresence>
                </Portal>
            )}
            {children}
        </div>
    );
};

Tooltip.propTypes = {
    text: PropTypes.string,
    position: PropTypes.oneOf(['top', 'bottom', 'right']),
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

Tooltip.defaultProps = {
    text: '',
    position: 'top',
};

export default React.memo(Tooltip);
