import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'approveRequestsModal.title',
        defaultMessage: 'Approve requests',
    },
    description: {
        id: 'approveRequestsModal.description',
        defaultMessage: 'Are you sure you want to approve the following requests?',
    },
    projects: {
        id: 'approveRequestsModal.projects',
        defaultMessage: 'projects',
    },
    project: {
        id: 'approveRequestsModal.project',
        defaultMessage: 'project',
    },
    alt: {
        id: 'approveRequestsModal.alt',
        defaultMessage: 'A woman with rocket',
    },
    cancel: {
        id: 'approveRequestsModal.cancel',
        defaultMessage: 'Cancel',
    },
    approve: {
        id: 'approveRequestsModal.approve',
        defaultMessage: 'Approve',
    },
});
