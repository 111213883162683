import React, { useEffect } from 'react';
import { Navigate } from "react-router-dom";
import qs from 'qs';

import { WORKSPACE_ROLE } from "@quidlo/common/constants/main";
import useWorkspace from "@quidlo/common/hooks/useWorkspace";
import { Space } from "@quidlo/ui";

import ReceiptInfo from "./ReceiptInfo";
import SubscriptionCard from "./SubscriptionCard";

const Subscription = () => {
    const { workspace, selectWorkspace } = useWorkspace(),
        parsedSearch = location.search.substring(1).replace('+', '%2b'),
        { workspace_code } = qs.parse(parsedSearch);

    if (WORKSPACE_ROLE.OWNER !== workspace?.organizationRole) {
        return (
            <Navigate to="/settings/features" replace={true} />
        )
    }

    useEffect(() => {
        if(workspace_code) {
            selectWorkspace(workspace_code ,'/settings/subscription')
        }
    }, [])

    return (
        <Space pv="m">
            <Space mb="l">
                <ReceiptInfo />
            </Space>
            <Space mb="l">
                <SubscriptionCard />
            </Space>
        </Space>
    );
}

export default Subscription;

