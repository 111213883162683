import { defineMessages } from 'react-intl';

export default defineMessages({
    projects: {
        id: 'projectList.projects',
        defaultMessage: 'Projects'
    },
    edit: {
        id: 'projectsList.edit',
        defaultMessage: 'Edit'
    },
    report: {
        id: 'projectsList.report',
        defaultMessage: 'Report'
    },
    archive: {
        id: 'projectsList.archive',
        defaultMessage: 'Archive'
    },
    noProjectsHeader: {
        id: 'projectsList.noProjectsHeader',
        defaultMessage: 'No projects yet…'
    },
    noProjectsBody: {
        id: 'projectsList.noProjectsBody',
        defaultMessage: 'There are no projects yet, would you like to create one?'
    },
    createProject: {
        id: 'projectsList.createProject',
        defaultMessage: 'Create Project'
    },
});