import React from 'react';
import { useIntl } from 'react-intl';

import useForm from '@quidlo/common/hooks/useForm2';
import useUI from "@quidlo/common/hooks/useUI";
import analytics from "@quidlo/common/utils/analytics";
import { Button, Space } from '@quidlo/ui';

import illustration from "../../../../assets/modals/man-confused.png";
import { rejectRequestMutation } from '../../../../queries/approvals.queries';
import { Textarea } from '../../../../ui';
import ModalHOC, { ModalActions, ModalContent, ModalHeader } from "../../../../ui/Modal";
import SummaryTable from "../../components/SummaryTable";

import style from "./RejectRequestsModal.module.scss";

import messages from "./RejectRequestsModal.i18n";

const RejectRequestsModal = () => {
    const { modal, modalClose } = useUI(),
        { projectsToReject, dateRange } = modal.data || {},
        { mutate: rejectApproval } = rejectRequestMutation(),
        [form] = useForm({ message: { value: '' } }),
        reject = () => {
            analytics.logTimesheetsApprovalsTimeApprovalRequestReject({
                messageLength: form.message.value?.length || 0
            })
            rejectApproval(projectsToReject.map(proj => ({ id: proj.id, note: form.message.value })));
            modal.callback()
            modalClose()
        },
        cancel = () => {
            analytics.logTimesheetsApprovalsTimeApprovalRequestCancelReject();
            modal.callback();
            modalClose();
        },
        intl = useIntl();

    return (
        <>
            <ModalHeader>
                {intl.formatMessage(messages.title)}
            </ModalHeader>
            <ModalContent>
                <Space mb="m" className={style.description}>{intl.formatMessage(messages.description)}</Space>
                <Space mb="m">
                    <SummaryTable dateRange={dateRange} list={projectsToReject} />
                </Space>
                <Space mb="m" className={style.description}>{intl.formatMessage(messages.ifYouWouldLike)}</Space>
                <Space mb="m" ph="m" pv="xs" className={style.messageWrapper}>
                    <Textarea
                        {...form.message}
                        placeholder={intl.formatMessage(messages.message)}
                        rows={4}
                    />
                </Space>
            </ModalContent>
            <ModalActions>
                <Space mr="m">
                    <Button
                        onClick={cancel}
                        text={intl.formatMessage(messages.cancel)}
                        color="white"
                    />
                </Space>
                <Button
                    onClick={reject}
                    text={intl.formatMessage(messages.reject)}
                    color="blue"
                    shadow
                />
            </ModalActions>
        </>
    );
};

export default ModalHOC(
    RejectRequestsModal,
    {
        id: 'rejectRequests',
        size: 'small',
        illustration,
    }
);
