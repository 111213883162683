import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as IMGLogo } from '../../MobileScreen/img/logo-blue.svg';

import style from './NoPermissionToConfigure.module.scss';

import messages from './NoPermissionToConfigure.i18n';

const NoPermissionToConfigure = () => (
    <div className={style.mobile_screen}>
        <IMGLogo />
        <div className={style.card}>
            <div className={style.header}>
                <FormattedMessage {...messages.header} />
            </div>
            <div className={style.text}>
                <FormattedMessage {...messages.message} />
            </div>
        </div>
    </div>
);

export default NoPermissionToConfigure;
