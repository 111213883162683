import React, { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { REPORT_TYPES, REPORT_VIEWS } from '@quidlo/common/constants/main';
import useForm from "@quidlo/common/hooks/useForm2";
import validators from "@quidlo/common/utils/validators";
import { Header, PillBar, Space, Spinner } from '@quidlo/ui';

import { getSharedReportData,getSharedReportOptions } from "../../../queries/reports.queries";
import { Container, DateRangePicker } from '../../../ui';
import ViewHOC from '../../../ui/View/View';
import { dateToString, stringToDate } from "../../../utils/dateHelpers";
import Filters from "../components/Filters";
import ReportTable from "../components/ReportTable/ReportTable";

import ExportMenu from "./ExportMenu";

import style from "../Create/ReportCreate.module.scss";

import messagesGlobal from "../../../messages/global.i18n";
import messages from '../Create/ReportCreate.i18n';

const ReportShared = () => {
    const params = useParams(),
        intl = useIntl(),
        location = useLocation(),
        navigate = useNavigate(),
        { data: report } = getSharedReportOptions(params.code),
        { data, isLoading, isFetching } = getSharedReportData({ code: params.code, hash: location.hash }),
        [ filters, { isFormValid: isFiltersValid, touchForm: touchFilters } ] = useForm({
            date: {
                value:  {
                    gte: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                    lte: new Date()
                }
            },
            projects : {
                value: [],
                validators: [validators.isRequired(intl.formatMessage(messages.projectsAreReq))]
            },
            tags : {
                value: [],
                validators: [validators.isRequired(intl.formatMessage(messages.tagsAreReq))]
            },
            users : {
                value: [],
                validators: [validators.isRequired(intl.formatMessage(messages.usersAreReq))]
            },
            period : {
                value: REPORT_VIEWS[0]
            },
            type : {
                value: REPORT_TYPES[0]
            },
            billable : {
                value: ''
            }
        });
    const openReport = (
        date= {
            gte: dateToString(filters.date.value.gte),
            lte: dateToString(filters.date.value.lte)
        },
        projects = filters.projects.value,
        tags = filters.tags.value,
        users = filters.users.value,
        type = filters.type.value,
        period = filters.period.value,
        billable = filters.billable.value,
    ) => {

        if (projects) {
            const newFilters = {
                date,
                projects,
                users,
                tags,
                type,
                period,
                billable,
            }
            const string = window.btoa(JSON.stringify(newFilters));
            navigate(`/reports/shared/${params.code}/#${string}`);
        }
    };

    useEffect(() => {
        if (location.hash.length) {
            const newFilters = JSON.parse(window.atob(location.hash.substr(1)));
            filters.date.setValue({
                gte: new Date(newFilters.date.gte),
                lte: new Date(newFilters.date.lte),
            });
            filters.projects.setValue(newFilters.projects);
            filters.tags.setValue(newFilters.tags);
            filters.users.setValue(newFilters.users);
            filters.period.setValue(newFilters.period);
            filters.type.setValue(newFilters.type);
            filters.billable.setValue(newFilters.billable);
        }
    }, [location.hash]);

    useEffect(() => {
        if (!report) return;
        filters.date.setValue({
            gte: stringToDate(report.dateFrom),
            lte: stringToDate(report.dateTo),
        });
        filters.projects.setValue(report.projects.map(p => p.id));
        filters.tags.setValue(report.tags.map(t => t.id));
        filters.users.setValue(report.users.map(u => u.id));
        filters.period.setValue(report.defaultViewSettings.period);
        filters.type.setValue(report.defaultViewSettings.type);
        filters.billable.setValue(report.billable);

        openReport(
            {
                gte: report.dateFrom,
                lte: report.dateTo,
            },
            report.projects.map(p => p.id),
            report.tags.map(t => t.id),
            report.users.map(u => u.id),
            report.defaultViewSettings.type,
            report.defaultViewSettings.period,
            report.billable,
        );
    }, [report])

    const options = useMemo(() => {
        if (!report) return ({
            projects: [],
            tags: [],
            users: [],
            periods: REPORT_VIEWS.map(p => ({
                label: intl.formatMessage(messagesGlobal[`reports.periods.${p}`]),
                value: p
            })),
            types: REPORT_TYPES.map(t => ({
                label: intl.formatMessage(messagesGlobal[`reports.types.${t}`]),
                value: t
            })),
            billable: ['all', 'true', 'false'],
        });


        return ({
            projects: report.projects || [],
            tags: report.tags.find(t => t.id === 'g-0') ?
                [
                    { id: 'g-0', name: 'untagged' },
                    ...report.tags.filter(
                        t => t.id !== 'g-0',
                    ),
                ]
                : report.tags,
            users: report.users || [],
            periods: REPORT_VIEWS.map(p => ({
                label: intl.formatMessage(messagesGlobal[`reports.periods.${p}`]),
                value: p
            })),
            types: REPORT_TYPES.map(t => ({
                label: intl.formatMessage(messagesGlobal[`reports.types.${t}`]),
                value: t
            })),
            billable: ['all', 'true', 'false'],
        })
    }, [report]);


    return (
        <div className={style.report}>
            <Container marginLeft={false} marginTop={false}>
                <Space mb="l">
                    <Header
                        title={intl.formatMessage(messages.reports)}
                        icon="report"
                        size="large"
                        underline
                        rightChildren={
                            <>
                                <ExportMenu
                                    disabled={!location.hash}
                                />
                            </>
                        }
                    />
                </Space>
                <Space mb="m">
                    <div className={style.filtersRow}>
                        <Space mr={'s'}>
                            <DateRangePicker
                                date={filters.date.value}
                                analyticsID="Reports - Shared"
                                onChange={filters.date.onChange}
                            />
                        </Space>
                        <Space mr={'s'}>
                            <PillBar
                                {...filters.period}
                                options={options.periods}
                                fieldId="period"
                            />
                        </Space>
                        <Space mr={'s'}>
                            <PillBar
                                {...filters.type}
                                options={options.types}
                                fieldId="type"
                            />
                        </Space>
                    </div>
                </Space>
                <Space mb="l">
                    <Filters
                        options={options}
                        filters={filters}
                        confirm={openReport}
                        disabled={!isFiltersValid}
                        touchFilters={touchFilters}
                    />
                </Space>
                <Space>
                    {
                        (isLoading || isFetching) ? (
                            <Spinner active={true} />
                        ) : (
                            <ReportTable data={data} openReport={openReport} isShared />
                        )
                    }
                </Space>
            </Container>
        </div>
    );
};

export default ViewHOC(
    ReportShared,
    {
        title: 'Shared Report',
        menu: false,
        topbar: false,
    },
);
