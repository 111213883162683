import { defineMessages } from 'react-intl';

export default defineMessages({
    total: {
        id: 'timeview.total',
        defaultMessage: 'Total:'
    },
    person: {
        id: 'timeview.person',
        defaultMessage: 'Person'
    },
    duration: {
        id: 'timeview.duration',
        defaultMessage: 'Duration'
    },
    status: {
        id: 'timeview.status',
        defaultMessage: 'Status'
    },
    summary: {
        id: 'timeview.summary',
        defaultMessage: 'Summary'
    },
    totalTimeInAllProjects: {
        id: 'timeview.totalTimeInAllProjects',
        defaultMessage: 'Total time in all projects:'
    },
});