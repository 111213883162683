import axios from 'axios';

import cookies from '../utils/cookies';

const defaultOptions = {
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            'Content-Type': 'application/json',
        },
    },
    fetch = axios.create(defaultOptions);

fetch.interceptors.request.use(config => {
    const newConfig = { ...config },
        token = cookies.get(process.env.REACT_APP_AUTHTOKEN_NAME);

    if (token) {
        newConfig.headers.Authorization = `Bearer ${token}`;
        return newConfig;
    }

    return config;
});

fetch.interceptors.response.use(
    response => response,
    error => {
        if (error?.response?.status === 401) {
            if (process.env.REACT_APP_AUTH_URL && !window.location.href.includes(process.env.REACT_APP_AUTH_URL)) {
                cookies.set(process.env.REACT_APP_AUTHTOKEN_NAME, '');
                window.location.replace(process.env.REACT_APP_AUTH_URL);
                return Promise.reject(error);
            }
        }
        return Promise.reject(error);
    },
);

export default fetch;
