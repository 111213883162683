import React, { useCallback, useEffect, useState } from 'react';
import cx from "classnames";
import PropTypes from 'prop-types';

import { Tag } from "../index";

import style from "./InputCollection.module.scss";

const InputCollection = ({
    name,
    label,
    value,
    error,
    isFocused,
    isTouched,
    onChange,
    onFocus,
    onBlur,
    size,
    cypressId
}) => {
    const
        [inputValue, setInputValue] = useState(''),
        add = v => {
            if (v) {
                const newValue = [...value];
                newValue.push(v);
                setInputValue('');
                onChange(newValue);
            }
        },
        blurHandler = () => {
            add(inputValue);
            onBlur();
        },
        changeHandler = v => {
            setInputValue(v);
        },
        keydownHandler = useCallback(event => {
            if (event.key === 'Enter' || event.key === ' ') {
                event.preventDefault();
                add(inputValue);
            }
        }),
        remove = v => {
            const newValue = value.filter(newValueVal => newValueVal !== v);

            onChange(newValue);
        };

    useEffect(() => {
        if (isFocused) {
            document.removeEventListener('keydown', keydownHandler);
            document.addEventListener('keydown', keydownHandler);
        } else {
            document.removeEventListener('keydown', keydownHandler);
        }
        return () => {
            document.removeEventListener('keydown', keydownHandler);
        }
    }, [isFocused, inputValue])

    return (
        <div
            className={cx(
                style.input,
                (value.length || inputValue) && style.filled,
                error && style.error,
                style[`size-${size}`],
            )}>
            <div className={style.content}>
                <div className={style.flex}>
                    {
                        value.map(v => (
                            <div className={style.tagWrapper} key={v}>
                                <Tag
                                    size="large"
                                    text={v}
                                    onRemove={() => remove(v)}
                                />
                            </div>
                        ))
                    }
                    <input
                        type="text"
                        name={name}
                        autoComplete="off"
                        value={inputValue || ''}
                        onChange={e => {
                            changeHandler(e.target.value);
                        }}
                        onBlur={blurHandler}
                        onFocus={onFocus}
                        data-cy={cypressId}
                    />
                </div>
                <span className={style.bar} />
                {label ? <label htmlFor={name}>{label}</label> : null}
                <span className={style.errormessage}>{error}</span>
            </div>
        </div>
    );
};

InputCollection.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.array,
    error: PropTypes.node,
    isFocused: PropTypes.bool,
    isTouched: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    cypressId: PropTypes.string
};

InputCollection.defaultProps = {
    name: '',
    label: '',
    value: '',
    error: undefined,
    size: 'medium',
    isFocused: false,
    isTouched: false,
    onFocus: undefined,
    onBlur: undefined,
    cypressId: undefined,
};

export default InputCollection;

