import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'receiptInfo.title',
        defaultMessage: 'Receipt info',
    },
    subtitle: {
        id: 'receiptInfo.subtitle',
        defaultMessage: 'Only companies are allowed to upgrade with Quidlo Timesheets subscription plan. Enter below and it will appear on all your receipts.',
    },
    description: {
        id: 'receiptInfo.description',
        defaultMessage: 'Your receipts will be provided by Stripe and delivered to you by email.',
    },
    country: {
        id: 'receiptInfo.selectCountry',
        defaultMessage: 'Country',
    },
    taxId: {
        id: 'receiptInfo.taxId',
        defaultMessage: 'Tax ID',
    },
    taxNumber: {
        id: 'receiptInfo.taxNumber',
        defaultMessage: 'Tax number',
    },
    companyName: {
        id: 'receiptInfo.companyName',
        defaultMessage: 'Company name',
    },
    streetAndNumber: {
        id: 'receiptInfo.streetAndNumber',
        defaultMessage: 'Street and number',
    },
    state: {
        id: 'receiptInfo.state',
        defaultMessage: 'State',
    },
    postcode: {
        id: 'receiptInfo.postcode',
        defaultMessage: 'Postal code',
    },
    city: {
        id: 'receiptInfo.city',
        defaultMessage: 'City',
    },
    edit: {
        id: 'receiptInfo.edit',
        defaultMessage: 'Edit',
    },
    add: {
        id: 'receiptInfo.add',
        defaultMessage: 'Add',
    },
});
