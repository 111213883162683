import React from 'react';

import useUI from "@quidlo/common/hooks/useUI";

import IMGRadzio from './img/super-radzio-shadow.png';

import style from './SuperRadek.module.scss';

const
    SuperRadek = () => {
        const { modal } = useUI();
        if (modal.id === 'superRadek') {
            return (
                <div className={style.success}>
                    <div className={style.radzio}>
                        <img src={IMGRadzio} alt="Rad" />
                    </div>
                </div>
            );
        }
        return <div />;
    };

export default SuperRadek;