import { useIntl } from "react-intl";
import { useMutation, useQuery,useQueryClient  } from '@tanstack/react-query'

import ApiKeysService from "@quidlo/common/api/ApiKeysService";
import SubscriptionService from "@quidlo/common/api/SubscriptionService";
import WorkspacesService from "@quidlo/common/api/WorkspacesService";
import { SUBSCRIPTION_STATUS } from "@quidlo/common/constants/main";
import useAuthentication from "@quidlo/common/hooks/useAuthentication";
import useUI from "@quidlo/common/hooks/useUI";
import useWorkspace from "@quidlo/common/hooks/useWorkspace";
import Bugsnag from "@quidlo/common/utils/bugsnag";

import { messagesToasts } from "../messages";
import { differenceInDays } from "../utils/dateHelpers";

export const getWorkspaceAPIKeysQuery = () => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    return useQuery(
        [
            { workspace: workspace?.code, user: user.id },
            'workspace', 'apikeys'
        ],
        async () => (await ApiKeysService.getAPIKeys(user.id, workspace.code))?.data?.api_keys,
        {
            // enabled: !!workspace?.code && !!user?.id,
        }
    )
};

export const addWorkspaceAPIKeyMutation = () => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    const { toastPush } = useUI();
    const intl = useIntl();
    const queryClient = useQueryClient();
    return useMutation(async () => (await ApiKeysService.postAPIKey(user.id, workspace.code, {}))?.data, {
        onSuccess: () => {
            toastPush({
                text: intl.formatMessage(messagesToasts.apiKeyCreated)
            });
            queryClient.invalidateQueries([{ workspace: workspace?.code, user: user.id }, 'workspace', 'apikeys'])
        },
        onError: e => {
            toastPush({
                text: e?.response?.data?.message || intl.formatMessage(messagesToasts.unexpectedError)
            });
        }
    })
}

export const editWorkspaceAPIKeyMutation = () => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    const { toastPush } = useUI();
    const intl = useIntl();
    const queryClient = useQueryClient();
    return useMutation(async ({ name, delta }) => await ApiKeysService.patchAPIKey(user.id, workspace.code, name, delta), {
        // enabled: !!workspace?.code,
        onSuccess: () => {
            queryClient.invalidateQueries([{ workspace: workspace?.code, user: user.id }, 'workspace', 'apikeys'])
        },
        onError: e => {
            toastPush({
                text: e?.response?.data?.message || intl.formatMessage(messagesToasts.unexpectedError)
            });
        }
    })
}

export const deleteWorkspaceAPIKeyMutation = () => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    const queryClient = useQueryClient();
    const { toastPush } = useUI();
    const intl = useIntl();
    return useMutation(async key => await ApiKeysService.deleteAPIKey(user.id, workspace.code, key)?.data, {
        // enabled: !!workspace?.code,
        onSuccess: () => {
            toastPush({
                text: intl.formatMessage(messagesToasts.apiKeyDeleted)
            });
            queryClient.invalidateQueries([{ workspace: workspace?.code, user: user.id }, 'workspace', 'apikeys'])
        },
        onError: e => {
            toastPush({
                text: e?.response?.data?.message || intl.formatMessage(messagesToasts.unexpectedError)
            });
        }
    })
}

export const getWorkspaceTaxIDCountriesQuery = () => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    return useQuery(
        [
            { workspace: workspace?.code, user: user.id },
            'workspace', 'taxIdCountries'
        ],
        async () => {
            const countriesMap = new Map();
            const countries = (await SubscriptionService.getTaxIdCountries(workspace.code, user.id))?.data?.taxes;
            countries.forEach(c => {
                if (!countriesMap.has(c.country_code)) {
                    countriesMap.set(c.country_code, {
                        ...c,
                        taxes: [{
                            id: c.id,
                            description: c.description,
                            example: c.tax_example,
                        }]
                    })
                } else {
                    const tmp = countriesMap.get(c.country_code);
                    countriesMap.set(c.country_code, {
                        ...tmp,
                        taxes: [
                            ...tmp.taxes,
                            {

                                id: c.id,
                                description: c.description,
                                example: c.tax_example,

                            }
                        ]
                    })
                }
            })
            return Array.from(countriesMap.values());
        },
        {
            enabled: !!workspace?.code && !!user?.id,
            initialData: []
        }
    );
};

export const getWorkspaceTaxIDInfoQuery = () => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    return useQuery(
        [
            { workspace: workspace?.code, user: user.id },
            'workspace', 'taxId'
        ],
        async () => {
            try {
                const { data } = await SubscriptionService.getTaxIdInfo(user.id, workspace.code)
                return data;
            } catch (e) {
                return null;
            }
        },
        {
            enabled: !!workspace?.code && !!user?.id,
        }
    );
};

export const addWorkspaceTaxIDInfoMutation = () => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    const queryClient = useQueryClient();
    const { toastPush } = useUI();
    const intl = useIntl();
    return useMutation(async data => (await SubscriptionService.postTaxIdInfo(user.id, workspace.code, data)), {
        // enabled: !!workspace?.code,
        onSuccess: () => {
            toastPush({
                text: intl.formatMessage(messagesToasts.taxInfoSaved)
            });
            queryClient.invalidateQueries([
                { workspace: workspace?.code, user: user.id },
                'workspace', 'taxId'
            ])
        },
        onError: e => {
            toastPush({
                text: e?.response?.data?.message,
                color: 'red'
            });
        }
    })
}

export const editWorkspaceTaxIDInfoMutation = () => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    const queryClient = useQueryClient();
    const { toastPush } = useUI();
    const intl = useIntl();
    return useMutation(async data => (await SubscriptionService.putTaxIdInfo(user.id, workspace.code, data)), {
        // enabled: !!workspace?.code,
        onSuccess: () => {
            toastPush({
                text: intl.formatMessage(messagesToasts.taxInfoEdited)
            });
            queryClient.invalidateQueries([
                { workspace: workspace?.code, user: user.id },
                'workspace', 'taxId'
            ])
        },
        onError: e => {
            toastPush({
                text: e?.response?.data?.message,
                color: 'red'
            });
        }
    })
}

export const getWorkspaceSubscriptionQuery = () => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    return useQuery(
        [
            { workspace: workspace?.code, user: user?.id },
            'workspace', 'subscription'
        ],async () => {
            try {
                const { data } = await SubscriptionService.get(user?.id, workspace?.code);

                if (data?.current_subscription) {
                    const {
                        is_canceled,
                        status,
                        subscription_plan_name,
                        valid_from,
                        valid_to
                    } = (data?.current_subscription || {});

                    const validFrom = valid_from ?  new Date(valid_from) : null;
                    const validTo = valid_to ?  new Date(valid_to) : null;
                    let subscriptionStatus = null;

                    const days = validTo ? differenceInDays(validTo, new Date()) : 365

                    if (subscription_plan_name === 'expired') {
                        subscriptionStatus = SUBSCRIPTION_STATUS.EXPIRED
                    }
                    if (status === 'past_due') {
                        subscriptionStatus = SUBSCRIPTION_STATUS.PAST_DUE
                    }
                    if (subscription_plan_name === 'trial') {
                        if (days <= 7) {
                            subscriptionStatus = SUBSCRIPTION_STATUS.TRIAL_EXPIRING
                        } else {
                            subscriptionStatus = SUBSCRIPTION_STATUS.TRIAL
                        }

                    }
                    if (subscription_plan_name === 'pro') {
                        if (days <= 7) {
                            subscriptionStatus = SUBSCRIPTION_STATUS.PRO_EXPIRING
                        } else {
                            subscriptionStatus = SUBSCRIPTION_STATUS.PRO
                        }
                    }

                    return {
                        name: subscription_plan_name,
                        status: subscriptionStatus,
                        validFrom: validFrom,
                        validTo: validTo,
                        isActive: !is_canceled,
                        isExpired: subscriptionStatus === SUBSCRIPTION_STATUS.EXPIRED
                    }
                } else {
                    return {
                        name: null,
                        status: null,
                        validFrom: null,
                        validTo: null,
                        isActive: false,
                        isExpired: true
                    }
                }
            } catch (e) {
                Bugsnag.notify(e);
                return null
            }
        },
        {
            enabled: !!workspace?.code && !!user?.id,
        }
    );
};
export const getWorkspaceOwnerQuery = () => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    return useQuery(
        [
            { workspace: workspace?.code, user: user.id },
            'workspace', 'owner'
        ],
        async () => (await WorkspacesService.getOwner(workspace.code, user.id))?.data,
        {
            // enabled: !!workspace?.code && !!user?.id,
        }
    );
};

export const chooseStarterSubscriptionMutation = () => {
    const { user } = useAuthentication();
    const { workspace, fetchWorkspaceFeatures } = useWorkspace();
    const queryClient = useQueryClient();
    const { toastPush } = useUI();
    const intl = useIntl();
    return useMutation(async () => (await SubscriptionService.postStarterSubscription(user.id, workspace.code)), {
        onSuccess: () => {
            toastPush({
                text: intl.formatMessage(messagesToasts.subscriptionPlanChangedToStarter)
            });
            queryClient.invalidateQueries([
                { workspace: workspace?.code, user: user.id },
                'workspace', 'subscription'
            ]);
            fetchWorkspaceFeatures()
        },
        onError: e => {
            toastPush({
                text: e?.response?.data?.message,
                color: 'red'
            });
        },
    })
}