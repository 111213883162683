import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { SUBSCRIPTION_STATUS,WORKSPACE_ROLE } from "@quidlo/common/constants/main";
import useForm from '@quidlo/common/hooks/useForm2';
import useWorkspace from '@quidlo/common/hooks/useWorkspace';
import useWorkspaceFeatures from "@quidlo/common/hooks/useWorkspaceFeatures";
import { Button, Card, Space } from '@quidlo/ui';

import { getWorkspaceSubscriptionQuery } from "../../../../queries/workspace.queries";
import { LabeledSwitch } from '../../../../ui';

import style from "./ProFeatures.module.scss";

import messages from './ProFeatures.i18n';

const OPTIONS = [
    {
        value: false,
        label: '',
    },
    {
        value: true,
        label: '',
    },
];

const
    ProFeatures = () => {
        const
            { workspace } = useWorkspace(),
            { features, patchFeatures } = useWorkspaceFeatures(),
            { data: subscription } = getWorkspaceSubscriptionQuery(),
            isStarter = subscription?.name === 'starter',
            [form, { isFormDirty, getFormValue }] = useForm({
                required_task_description: {
                    value: features?.required_task_description || false
                },
                task_with_from_to: {
                    value: features?.task_with_from_to || false
                },
                approvals: {
                    value: features?.approvals || false
                },
                unlimitedUser: {
                    value: true,
                },
                publicProjects: {
                    value: true,
                },
                managersAndAdminsManagement: {
                    value: true,
                }
            }),
            intl = useIntl();

        useEffect(() => {
            if (isFormDirty) {
                const value = getFormValue();
                patchFeatures(value);
                Object.keys(value).forEach(key => {
                    form[key].setValue(value[key])
                })
            }
        }, [form, isFormDirty, getFormValue]);

        const isOwner = [WORKSPACE_ROLE.OWNER].includes(workspace?.organizationRole);
        const isOwnerOrAdmin = [WORKSPACE_ROLE.OWNER, WORKSPACE_ROLE.ADMIN].includes(workspace?.organizationRole);
        const isPro = subscription?.status === SUBSCRIPTION_STATUS.PRO;
        const isSubExpired = !subscription?.status;

        return (
            <div className={style.wrapper}>
                <Card
                    title={intl.formatMessage(messages.proFeatures)}
                    headerRightChildren={(isOwner && !isPro) ?
                        <Button
                            text={intl.formatMessage(messages.upgrade)}
                            size='small'
                            href={'/paywall'}
                        />
                        : null}
                >
                    <div>
                        <LabeledSwitch
                            {...form.required_task_description}
                            options={OPTIONS}
                            pro
                            size="small"
                            disabled={!isOwnerOrAdmin || isSubExpired}
                            inactive={isStarter}
                            title={intl.formatMessage(messages.requiredFieldsTitle)}
                            description={form.required_task_description.value ?
                                { ...messages.requiredFieldsDescriptionActive }
                                :
                                { ...messages.requiredFieldsDescription }
                            }
                        />
                        <Space mv="m">
                            <LabeledSwitch
                                {...form.task_with_from_to}
                                options={OPTIONS}
                                disabled={!isOwnerOrAdmin || isSubExpired}
                                inactive={isStarter}
                                pro
                                size="small"
                                title={intl.formatMessage(messages.startAndEndTimeEntryTitle)}
                                description={form.task_with_from_to.value ? messages.startAndEndTimeEntryDescriptionOn : messages.startAndEndTimeEntryDescriptionOff}
                            />
                        </Space>
                        <Space mb="m">
                            <LabeledSwitch
                                {...form.approvals}
                                options={OPTIONS}
                                pro
                                size="small"
                                disabled={!isOwnerOrAdmin || isSubExpired}
                                inactive={isStarter}
                                title={intl.formatMessage(messages.timeApprovalsTitle)}
                                description={form.approvals.value ?
                                    { ...messages.timeApprovalsDescriptionActive }
                                    :
                                    { ...messages.timeApprovalsDescription }
                                }
                            />
                        </Space>
                        {/*<Space mb="m">*/}
                        {/*    <LabeledSwitch*/}
                        {/*        {...form.unlimitedUser}*/}
                        {/*        options={OPTIONS}*/}
                        {/*        pro*/}
                        {/*        size="small"*/}
                        {/*        title={intl.formatMessage(messages.unlimitedUsersTitle)}*/}
                        {/*        description={intl.formatMessage(messages.unlimitedUsersDescription)}*/}
                        {/*    />*/}
                        {/*</Space>*/}
                        {/*<Space mb="m">*/}
                        {/*    <LabeledSwitch*/}
                        {/*        {...form.publicProjects}*/}
                        {/*        options={OPTIONS}*/}
                        {/*        pro*/}
                        {/*        size="small"*/}
                        {/*        title={intl.formatMessage(messages.publicProjectsTitle)}*/}
                        {/*        description={intl.formatMessage(messages.publicProjectsDescription)}*/}
                        {/*    />*/}
                        {/*</Space>*/}
                        {/*<LabeledSwitch*/}
                        {/*    {...form.managersAndAdminsManagement}*/}
                        {/*    options={OPTIONS}*/}
                        {/*    pro*/}
                        {/*    size="small"*/}
                        {/*    title={intl.formatMessage(messages.managersAndAdminsManagementTitle)}*/}
                        {/*    description={intl.formatMessage(messages.managersAndAdminsManagementDescription)}*/}
                        {/*/>*/}
                    </div>
                </Card>
            </div>
        );
    };

export default ProFeatures;
