import React from 'react';
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { PROJECT_ROLE,WORKSPACE_ROLE } from "@quidlo/common/constants/main";
import useWorkspace from "@quidlo/common/hooks/useWorkspace";
import useWorkspaceFeatures from "@quidlo/common/hooks/useWorkspaceFeatures";
import { InfoCard } from "@quidlo/ui";

import PeopleView from "../PeopleView";
import TasksView from "../TaskView";
import TimeView from "../TimeView";

import ThereAreNoPNG from './assets/there-are-no.png'

import style from "./ReportTable.module.scss";

import messages from './ReportTable.i18n';

const ReportTable = ({ data, openReport, isShared }) => {
    const intl = useIntl();
    const { workspace } = useWorkspace();
    const { features } = useWorkspaceFeatures();

    const isAdminManager = [WORKSPACE_ROLE.ADMIN, WORKSPACE_ROLE.OWNER].includes(workspace?.organizationRole) || workspace?.projectsRole === PROJECT_ROLE.MANAGER;

    if (!data?.filters) {
        return (
            <div className={style.infoContainer}>
                <InfoCard
                    illustration={<img src={ThereAreNoPNG} alt={'woman with calendar'} />}
                    header={intl.formatMessage(messages.noTimeEntries)}
                    text={intl.formatMessage(messages.matchSelectedFilters)}
                />
            </div>
        );
    }
    const { filters, report } = data;
    if (!report) {
        return (
            <div className={style.infoContainer}>
                <InfoCard
                    header={intl.formatMessage(messages.noresults)}
                    text={intl.formatMessage(messages.tryusing)}
                />
            </div>
        );
    }

    switch (filters.type) {
        case 'time': {
            if (report?.projects?.length) {
                return (
                    <TimeView
                        data={report}
                        openReport={openReport}
                        isStatus={!isShared && features?.approvals}
                    />
                );
            } else {
                return (
                    <div className={style.infoContainer}>
                        <InfoCard
                            illustration={<img src={ThereAreNoPNG}/>}
                            header={intl.formatMessage(messages.noReports)}
                            text={intl.formatMessage(messages.checkYourFilters)}
                        />
                    </div>
                );
            }
        }
        case 'tasks': {
            if (report?.periods?.length &&
                report?.periods?.reduce(
                    (acc, val) => acc + val.tasks.length,
                    0,
                )) {
                return (
                    <TasksView
                        data={report}
                        isEditable={!isShared && isAdminManager}
                        isStatus={!isShared && features?.approvals}
                    />
                );
            } else {
                return (
                    <div className={style.infoContainer}>
                        <InfoCard
                            illustration={<img src={ThereAreNoPNG}/>}
                            header={intl.formatMessage(messages.noReports)}
                            text={intl.formatMessage(messages.checkYourFilters)}
                        />
                    </div>
                );
            }
        }
        case 'people': {
            if (report?.users?.length) {
                return (
                    <PeopleView
                        data={report}
                        openReport={openReport}
                        isStatus={!isShared && features?.approvals}
                    />
                );
            } else {
                return (
                    <div className={style.infoContainer}>
                        <InfoCard
                            illustration={<img src={ThereAreNoPNG}/>}
                            header={intl.formatMessage(messages.noReports)}
                            text={intl.formatMessage(messages.checkYourFilters)}
                        />
                    </div>
                );
            }
        }
    }
};

ReportTable.propTypes = {
    data: PropTypes.object,
    openReport: PropTypes.func.isRequired,
    isShared: PropTypes.bool,
}

ReportTable.defaultProps = {
    isShared: false
}

export default ReportTable
