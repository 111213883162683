import { defineMessages } from 'react-intl';

export default defineMessages({
    leaveWorkspace: {
        id: 'workspaceLeave.leaveWorkspace',
        defaultMessage: 'Leave Workspace',
    },
    byLeaving: {
        id: 'workspaceLeave.byLeaving',
        defaultMessage: 'By leaving <b>{workspace}</b> Workspace, you will lose the access to all tasks, reports and projects.',
    },
    leave: {
        id: 'workspaceLeave.leave',
        defaultMessage: 'Leave',
    },
});