import Cookies from 'js-cookie';

export default {
    get: Cookies.get,
    set: (key, value, options = {}) =>
        Cookies.set(key, value, {
            domain: process.env.REACT_APP_DOMAIN,
            ...options
        }),
};
