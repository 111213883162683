import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../Icon';

import styles from './Tag.module.scss';

const Tag = ({ size, text, avatar, onRemove, type, color, icon, style }) => (
    <div
        className={cx(
            styles.tag,
            styles[`size-${avatar ? 'large' : size}`],
            styles[`type-${type}`],
            !avatar && styles[`padding-left`],
            !(onRemove || icon) && styles[`padding-right`],
        )}
        style={{
            ...style,
            '--color': color,
        }}>
        {avatar &&
            (<div className={styles.avatar}>
                {avatar}
            </div>
            )
        }
        <span className={styles.text}>{text}</span>
        {onRemove && (
            <a
                className={styles.remove}
                onClick={onRemove}
            >
                <Icon icon="close" size="small" />
            </a>
        )}
        {icon && (
            <div className={styles.icon}>
                <Icon icon={icon} color={color} size={size} />
            </div>
        )}
    </div>
);

Tag.propTypes = {
    type: PropTypes.oneOf(['normal', 'highlighted', 'stroked', 'dashed']),
    size: PropTypes.oneOf(['medium', 'large']),
    text: PropTypes.string.isRequired,
    color: PropTypes.string,
    avatar: PropTypes.node,
    icon: PropTypes.string,
    onRemove: PropTypes.func,
    style: PropTypes.shape({}),
};

Tag.defaultProps = {
    type: 'normal',
    size: 'medium',
    color: undefined,
    avatar: undefined,
    icon: undefined,
    onRemove: undefined,
    style: null,
};

export default Tag;
