import React from "react";

import { ReactComponent as Approvals } from './approvals.svg';
import { ReactComponent as Approvals_32 } from './approvals_32.svg';
import { ReactComponent as Archive } from './archive.svg';
import { ReactComponent as Calendar } from './calendar.svg';
import { ReactComponent as Calendar_20 } from './calendar_20.svg';
import { ReactComponent as Camera } from './camera.svg';
import { ReactComponent as CaretDown } from './caret-down.svg';
import { ReactComponent as CaretLeft } from './caret-left.svg';
import { ReactComponent as CaretRight } from './caret-right.svg';
import { ReactComponent as Chart } from './chart.svg';
import { ReactComponent as Chatbot } from './chatbot.svg';
import { ReactComponent as Close } from './close.svg';
import { ReactComponent as Close_20 } from './close_20.svg';
import { ReactComponent as Color } from './color.svg';
import { ReactComponent as Dollar } from './dollar.svg';
import { ReactComponent as Dollar_20 } from './dollar_20.svg';
import { ReactComponent as DollarCrossed } from './dollar-crossed.svg';
import { ReactComponent as Download } from './download.svg';
import { ReactComponent as Download_20 } from './download_20.svg';
import { ReactComponent as Edit } from './edit.svg';
import { ReactComponent as Edit_20 } from './edit_20.svg';
import { ReactComponent as Eye } from './eye.svg';
import { ReactComponent as Froze_20 } from './froze_20.svg';
import { ReactComponent as Help } from './help.svg';
import { ReactComponent as Help_20 } from './help_20.svg';
import { ReactComponent as Info } from './info.svg';
import { ReactComponent as Link } from './link.svg';
import { ReactComponent as Link_20 } from './link_20.svg';
import { ReactComponent as Lock_20 } from './lock_20.svg';
import { ReactComponent as Logout } from './logout.svg';
import { ReactComponent as Logout_20 } from './logout_20.svg';
import { ReactComponent as Mail } from './mail.svg';
import { ReactComponent as Mail_20 } from './mail_20.svg';
import { ReactComponent as Map } from './map.svg';
import { ReactComponent as Play } from './play.svg';
import { ReactComponent as Plus } from './plus.svg';
import { ReactComponent as PlusCircle } from './plus-circle.svg';
import { ReactComponent as Project } from './project.svg';
import { ReactComponent as Project_20 } from './project_20.svg';
import { ReactComponent as Project_32 } from './project_32.svg';
import { ReactComponent as Report } from './report.svg';
import { ReactComponent as Report_32 } from './report_32.svg';
import { ReactComponent as ReportAdd } from './report-add.svg';
import { ReactComponent as Resend } from './resend.svg';
import { ReactComponent as Search } from './search.svg';
import { ReactComponent as Settings } from './settings.svg';
import { ReactComponent as Settings_20 } from './settings_20.svg';
import { ReactComponent as Settings_32 } from './settings_32.svg';
import { ReactComponent as Stop } from './stop.svg';
import { ReactComponent as Tag } from './tag.svg';
import { ReactComponent as Tick } from './tick.svg';
import { ReactComponent as Tick_20 } from './tick_20.svg';
import { ReactComponent as Timer } from './timer.svg';
import { ReactComponent as Timer_32 } from './timer_32.svg';
import { ReactComponent as Trash } from './trash.svg';
import { ReactComponent as Trash_20 } from './trash_20.svg';
import { ReactComponent as TriangleDown } from './triangle-down.svg';
import { ReactComponent as TriangleUp } from './triangle-up.svg';
import { ReactComponent as User } from './user.svg';
import { ReactComponent as User_20 } from './user_20.svg';
import { ReactComponent as Users } from './users.svg';
import { ReactComponent as Workspace } from './workspace.svg';
import { ReactComponent as Workspace_20 } from './workspace_20.svg';
import { ReactComponent as Workspace_32 } from './workspace_32.svg';

export default {
    approvals: {
        24: <Approvals />,
        32: <Approvals_32 />
    },
    archive: {
        24: <Archive />
    },
    calendar: {
        20: <Calendar_20 />,
        24: <Calendar />,
    },
    camera: {
        24: <Camera />
    },
    'caret-down': {
        24: <CaretDown />
    },
    'caret-left': {
        24: <CaretLeft />
    },
    'caret-right': {
        24: <CaretRight />
    },
    chart: {
        24: <Chart />
    },
    chatbot: {
        24: <Chatbot />
    },
    close: {
        20: <Close_20 />,
        24: <Close />
    },
    color: {
        24: <Color />
    },
    dollar: {
        20: <Dollar_20 />,
        24: <Dollar />
    },
    'dollar-crossed': {
        24: <DollarCrossed />
    },
    download: {
        20: <Download_20 />,
        24: <Download />,
    },
    edit: {
        20: <Edit_20 />,
        24: <Edit />
    },
    eye: {
        24: <Eye />,
    },
    froze: {
        20: <Froze_20 />,
        24: <Froze_20 />,
    },
    help: {
        20: <Help_20 />,
        24: <Help />
    },
    info: {
        24: <Info />,
    },
    link: {
        20: <Link_20 />,
        24: <Link />,
    },
    lock: {
        20: <Lock_20 />,
        24: <Lock_20 />
    },
    logout: {
        20: <Logout_20 />,
        24: <Logout />,
    },
    mail: {
        20: <Mail_20 />,
        24: <Mail />,
    },
    map: {
        24: <Map />
    },
    play: {
        24: <Play />
    },
    plus: {
        24: <Plus />
    },
    'plus-circle': {
        24: <PlusCircle />
    },
    project: {
        20: <Project_20 />,
        24: <Project />,
        32: <Project_32 />,
    },
    report: {
        24: <Report />,
        32: <Report_32 />,
    },
    'report-add': {
        24: <ReportAdd />
    },
    resend: {
        24: <Resend />
    },
    search: {
        24: <Search />
    },
    settings: {
        20: <Settings_20 />,
        24: <Settings />,
        32: <Settings_32 />,
    },
    stop: {
        24: <Stop />
    },
    tag: {
        24: <Tag />
    },
    tick: {
        20: <Tick_20 />,
        24: <Tick />
    },
    timer: {
        24: <Timer />,
        32: <Timer_32 />,
    },
    trash: {
        20: <Trash_20 />,
        24: <Trash />
    },
    'triangle-down': {
        24: <TriangleDown />
    },
    'triangle-up': {
        24: <TriangleUp />
    },
    user: {
        20: <User_20 />,
        24: <User />
    },
    users: {
        24: <Users />
    },
    workspace: {
        20: <Workspace_20 />,
        24: <Workspace />,
        32: <Workspace_32 />,
    },

}