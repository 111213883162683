import React, { useEffect } from 'react';
import { useIntl } from "react-intl";
import cx from "classnames";

import useForm from "@quidlo/common/hooks/useForm2";
import { Button, Card, Checkbox } from "@quidlo/ui";

import { editSettingsMutation,getSettingsQuery } from "../../../queries/settings.queries";

import NotificationLabel from "./NotificationLabel";

import style from "./Notifications.module.scss";

import messages from "./Notifications.i18n";

const FORM = {
    newsletter: {
        value: false,
    },
    weeklySummary: {
        value: false,
    }
}

const Notifications = () => {
    const
        intl = useIntl(),
        { data } = getSettingsQuery(),
        { mutate } = editSettingsMutation(),
        [form, { isFormValid }] = useForm(FORM),
        saveForm = () => {
            if (!isFormValid) return

            mutate({
                emails: {
                    newsletter: form.newsletter.value,
                    weeklySummary: form.weeklySummary.value,
                }
            })
        };

    useEffect(() => {
        if (data) {
            form.newsletter.onChange(data?.emails?.newsletter);
            form.weeklySummary.onChange(data?.emails?.weeklySummary);
        }
    }, [data])

    return (
        <Card title={intl.formatMessage(messages.title)}>
            <div className={style.block}>
                <div className={style.row}>
                    <div className={cx(style.cell)}>
                        <Checkbox
                            label={
                                <NotificationLabel
                                    title={intl.formatMessage(messages.newsletter)}
                                    description={intl.formatMessage(messages.newsletterDescription)}
                                />}
                            {...form.newsletter}
                            fieldId={messages.newsletter.id}
                        />
                    </div>
                </div>
                <div className={style.row}>
                    <div className={cx(style.cell)}>
                        <Checkbox
                            label={
                                <NotificationLabel
                                    title={intl.formatMessage(messages.reports)}
                                    description={intl.formatMessage(messages.reportsDescription)}
                                />}
                            {...form.weeklySummary}
                            fieldId={messages.reports.id}
                        />
                    </div>
                </div>
                <div className={style.row}>
                    <div className={cx(style.cell, style.right, style.btnContainer)}>
                        <div className={style.saveBtn}>
                            <Button
                                onClick={saveForm}
                                text={intl.formatMessage(messages.save)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    );
};

export default Notifications;

