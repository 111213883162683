import { defineMessages } from 'react-intl';

export default defineMessages({
    projects: {
        id: 'reportsList.projects',
        defaultMessage: 'Projects'
    },
    tags: {
        id: 'reportsList.tags',
        defaultMessage: 'Tags'
    },
    people: {
        id: 'reportsList.people',
        defaultMessage: 'People'
    },
    view: {
        id: 'reportsList.view',
        defaultMessage: 'View'
    },
    type: {
        id: 'reportsList.type',
        defaultMessage: 'Type'
    },
    typetime: {
        id: 'reportsList.typetime',
        defaultMessage: 'Time by projects'
    },
    typetasks: {
        id: 'reportsList.typetasks',
        defaultMessage: 'Tasks'
    },
    typepeople: {
        id: 'reportsList.typepeople',
        defaultMessage: 'Time by people'
    },
    viewday: {
        id: 'reportsList.viewday',
        defaultMessage: 'Days'
    },
    viewweek: {
        id: 'reportsList.viewweek',
        defaultMessage: 'Weeks'
    },
    viewmonth: {
        id: 'reportsList.viewmonth',
        defaultMessage: 'Months'
    },
    show: {
        id: 'reportsList.show',
        defaultMessage: 'Show'
    },
    billable: {
        id: 'reportsList.billable',
        defaultMessage: 'Billable'
    },
    status: {
        id: 'reportsList.status',
        defaultMessage: 'Status'
    },
    billableall: {
        id: 'reportsList.billableall',
        defaultMessage: 'All'
    },
    billabletrue: {
        id: 'reportsList.billabletrue',
        defaultMessage: 'Yes'
    },
    billablefalse: {
        id: 'reportsList.billablefalse',
        defaultMessage: 'No'
    },
});