import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { FEATURE } from "@quidlo/common/constants/main";
import useWorkspace from '@quidlo/common/hooks/useWorkspace';
import { Button, Header } from '@quidlo/ui';

import { getRequestedApprovalsCountQuery } from '../../queries/approvals.queries';
import { Container } from '../../ui';
import ViewHOC from '../../ui/View/View';

import messages from './Approvals.i18n';

const Approvals = () => {
    const
        intl = useIntl(),
        { workspace } = useWorkspace(),
        isManager = workspace.projectsRole === 'manager' || workspace?.organizationRole === 'admin' || workspace.organizationRole === 'owner',
        navigate = useNavigate(),
        { data } = getRequestedApprovalsCountQuery(),
        location = useLocation();

    useEffect(() => {
        if(location.pathname === '/approvals/list') {
            navigate('/approvals/list/my')
        }
    }, [])

    return (
        <Container>
            <Header
                title={intl.formatMessage(messages.timeApprovals)}
                icon="approvals"
                size="large"
                tabs={
                    isManager ? [
                        {
                            to: '/approvals/list/my',
                            label: intl.formatMessage(messages.myRequests),
                        },
                        {
                            to: '/approvals/list/requests',
                            label: `${intl.formatMessage(messages.toApprove)} (${data?.count})`,
                        }
                    ] : []}
                rightChildren={
                    location.pathname === '/approvals/list/my' &&
                    <Button
                        color={'blue'}
                        href={'/approvals/create'}
                        size={'small'}
                        text={intl.formatMessage(messages.requestTimeApproval)}
                        shadow
                    />
                }
                underline
            />
            <div style={{ position: 'relative' }}>
                <Outlet />
            </div>
        </Container>
    );
};

export default ViewHOC((Approvals),
    {
        title: 'Approvals',
        menu: true,
        topbar: true,
        auth: {
            roles: ['employee', 'manager', 'admin', 'owner'],
        },
        subscription: true,
        feature: FEATURE.APPROVALS
    }
);

