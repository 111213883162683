import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate } from "react-router-dom";

import PollService from "@quidlo/common/api/PollService";
import useAuthentication from "@quidlo/common/hooks/useAuthentication";
import useUI from "@quidlo/common/hooks/useUI";
import useWorkspace from "@quidlo/common/hooks/useWorkspace";
import { Avatar, Button, Counter, Header, Space, Tooltip } from "@quidlo/ui";

import {
    getUsersListAllInifiniteQuery,
    getUsersTotalQuery,
    resendInvitationMutation
} from "../../queries/users.queries";
import { chooseStarterSubscriptionMutation } from '../../queries/workspace.queries';
import { Container, Explanation, InfiniteLoader, List, ListActions, ListCell } from "../../ui";
import ProjectPill from "../../ui/ProjectPill/ProjectPill";
import ViewHOC from '../../ui/View/View';
import PollModal from "../App/Polls/PollModal";
import DeleteUser from "../modals/DeleteUser";
import LeaveWorkspace from "../modals/LeaveWorkspace";

import DeleteUsersPNG from "./assets/delete-users-to-continue.png";

import style from "./UsersDelete.module.scss";

import messages from "./UsersDelete.i18n";

const UsersDelete = () => {
    const
        navigate = useNavigate(),
        intl = useIntl(),
        { modalOpen } = useUI(),
        { user } = useAuthentication(),
        { workspace } = useWorkspace(),
        { data, isFetchingNextPage, hasNextPage, fetchNextPage } = getUsersListAllInifiniteQuery(),
        { data: total } = getUsersTotalQuery(),
        { mutate: resendInvitation } = resendInvitationMutation(),
        { mutate: chooseStarter } = chooseStarterSubscriptionMutation(),
        deleteUser = (userId, name) => {
            if (userId === user.id) {
                modalOpen('leaveWorkspace');
            } else {
                modalOpen('deleteUser', { userId, name });
            }
        },

        continueWithFreePlan = async () => {
            const { id } = user;
            const { data } = await PollService.get(workspace.code, id);
            modalOpen('pollModal', {
                question: "Why did you decide to continue with free plan?",
                options: data.choices.map(c => c.choice),
                send: (options, text) => {
                    const choice = data.choices.find(c => c.choice === options[0]);
                    PollService.post(workspace.code, id, { choice_id: choice.id, additional_notes: text })
                },
            }, () => {
                chooseStarter()
                navigate('/settings/subscription');
            });
        };

    const users = useMemo(() => data?.pages ? data.pages.filter(f => !!f).flat(1) : [], [data]);

    return (
        <>
            <Container>
                <Space mb="l">
                    <Explanation
                        imageSrc={DeleteUsersPNG}
                        imageAlt={intl.formatMessage(messages.deleteUsersToContinueAlt)}
                        title={intl.formatMessage(messages.deleteUsersToContinueTitle)}
                    >
                        {intl.formatMessage(messages.deleteUsersToContinueSubtitle)}
                        <Link to="/paywall">{intl.formatMessage(messages.upgrade)}</Link>
                    </Explanation>
                </Space>
                <Space mb="l">
                    <Header
                        title={intl.formatMessage(messages.users, { total })}
                        icon="users"
                        size="large"
                        underline
                        rightChildren={(
                            <div className={style.headerRight}>
                                <Button
                                    text={intl.formatMessage(messages.continueWithFreePlan)}
                                    color="blue"
                                    shadow
                                    disabled={total > 10}
                                    onClick={continueWithFreePlan}
                                />
                            </div>
                        )}
                    />
                </Space>
                <Space mb="l">
                    <List
                        header={(
                            <>
                                <ListCell align="center" header width="110px">
                                    <FormattedMessage {...messages.status} />
                                </ListCell>
                                <ListCell align="center" header>
                                    <FormattedMessage {...messages.name} />
                                </ListCell>
                                <ListCell align="center" header>
                                    <FormattedMessage {...messages.email} />
                                </ListCell>
                                <ListCell align="center" header>
                                    <FormattedMessage {...messages.projects} />
                                </ListCell>
                                <ListCell width="180px" align="center" header>
                                    <FormattedMessage {...messages.role} />
                                </ListCell>
                            </>
                        )}
                        data={users}
                        renderRow={({
                            id,
                            firstName,
                            lastName,
                            avatarUrl,
                            email,
                            projects,
                            organizationRole,
                            status
                        }) => ((
                            <>
                                <ListCell width="110px">
                                    <span className={style.status}>
                                        {status === 'approved' ?
                                            <span className={style.active}>{intl.formatMessage(messages.active)}</span>
                                            :
                                            <span className={style.inactive}>{intl.formatMessage(messages.inactive)}</span>
                                        }
                                    </span>
                                </ListCell>
                                <ListCell>
                                    {(firstName && lastName && status === 'approved') && (
                                        <div className={style.credentialsAndAvatar}>
                                            <Avatar firstName={firstName} lastName={lastName} picture={avatarUrl} />
                                            <span className={style.credentials}>{firstName} {lastName}</span>
                                        </div>
                                    )}
                                    {(firstName && lastName && status !== 'approved') && (
                                        <div className={style.credentialsAndAvatar}>
                                            <Avatar firstName={firstName} lastName={lastName} picture={avatarUrl} />
                                            <div className={style.credentials}>
                                                <div>{firstName} {lastName}</div>
                                                <div>({intl.formatMessage(messages.pending)})</div>
                                            </div>
                                        </div>
                                    )}
                                    {(!firstName && !lastName && status !== 'approved') && (
                                        <div className={style.pending}>
                                            <FormattedMessage {...messages.pending} />
                                        </div>
                                    )}
                                </ListCell>
                                <ListCell>
                                    <span>{email}</span>
                                </ListCell>
                                <ListCell>
                                    {projects.length > 0 ?
                                        <div className={style.cell}>
                                            {projects.slice(0, 1).map(project => (
                                                <div key={project.id}>
                                                    <ProjectPill {...project} />
                                                </div>
                                            ))}
                                            {projects.length > 1 && (
                                                <Tooltip text={projects.slice(1, projects.length).map(p => p.name).join(', ')}>
                                                    <Counter number={projects.length - 1} />
                                                </Tooltip>
                                            )}
                                        </div>
                                        : <FormattedMessage {...messages.noProjects} />
                                    }
                                </ListCell>
                                <ListCell width="180px" last>
                                    <FormattedMessage {...messages[organizationRole]} />
                                </ListCell>
                                {organizationRole !== 'owner'
                                    &&
                                    <ListActions
                                        actions={status === 'approved' ? [
                                            {
                                                title: intl.formatMessage(messages.deleteUser),
                                                icon: 'trash',
                                                onClick: () => deleteUser(id, `${firstName} ${lastName}`)
                                            }] : [
                                            {
                                                title: intl.formatMessage(messages.deleteUser),
                                                icon: 'trash',
                                                onClick: () => deleteUser(id, `${firstName} ${lastName}`)
                                            },
                                            {
                                                title: intl.formatMessage(messages.resendInvitation),
                                                icon: 'resend',
                                                onClick: () => { resendInvitation(id, email); }
                                            }
                                        ]}
                                    />
                                }
                            </>
                        ))}
                        editable
                    />
                </Space>
                <InfiniteLoader
                    action={fetchNextPage}
                    isMore={hasNextPage && !isFetchingNextPage}
                />
            </Container>
            <LeaveWorkspace />
            <DeleteUser />
            <PollModal />
        </>
    );
};

export default ViewHOC(
    UsersDelete,
    {
        title: 'Delete Users',
        menu: true,
        topbar: true,
        auth: {
            roles: ['admin', 'owner']
        },
        subscription: false
    }
);
