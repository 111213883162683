import React, { useEffect, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import cx from "classnames";
import PropTypes from "prop-types";

import SubscriptionService from "@quidlo/common/api/SubscriptionService";
import useAuthentication from "@quidlo/common/hooks/useAuthentication";
import useForm from "@quidlo/common/hooks/useForm2";
import useUI from "@quidlo/common/hooks/useUI";
import useWorkspace from "@quidlo/common/hooks/useWorkspace";
import analytics from "@quidlo/common/utils/analytics";
import validators from "@quidlo/common/utils/validators";
import { Autocomplete, Button, Checkbox, Input, Select } from "@quidlo/ui";

import {
    addWorkspaceTaxIDInfoMutation,
    editWorkspaceTaxIDInfoMutation,
    getWorkspaceTaxIDCountriesQuery,
    getWorkspaceTaxIDInfoQuery
} from "../../../../../queries/workspace.queries";

import style from "./TaxIdForm.module.scss";

import messages from './TaxIdForm.i18n';

const FORM = {
    country: {
        value: '',
        validators: [validators.isRequired()]
    },
    tax_id: {
        value: '',
        validators: [validators.isRequired()]
    },
    tax_type_id: {
        value: '',
        validators: [validators.isRequired()]
    },
    company_name: {
        value: '',
        validators: [validators.isRequired()]
    },
    street: {
        value: '',
        validators: [validators.isRequired()]
    },
    state: {
        value: ''
    },
    post_code: {
        value: '',
        validators: [validators.isRequired()]
    },
    city: {
        value: '',
        validators: [validators.isRequired()]
    },
    declaration: {
        value: false,
        validators: [validators.isTrue()]
    }
}

const TaxIdForm = ({ setIsLoading }) => {
    const intl = useIntl();
    const interval = useRef(null);
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    const { modalOpen, modalClose, modal } = useUI();
    const { data: countries } = getWorkspaceTaxIDCountriesQuery();
    const { data: taxId } = getWorkspaceTaxIDInfoQuery();
    const { mutate: postTaxId } = addWorkspaceTaxIDInfoMutation();
    const { mutate: patchTaxId } = editWorkspaceTaxIDInfoMutation();
    const [form, { isFormValid }] = useForm(FORM),
        saveAndContinue = () => {
            const
                data = {
                    tax_type_id: Number(form.tax_type_id.value),
                    tax_id: form.tax_id.value,
                    country: form.country.value,
                    company_name: form.company_name.value,
                    city: form.city.value,
                    street: form.street.value,
                    post_code: form.post_code.value,
                    state: form.state.value
                };
            setIsLoading(true);
            analytics.logTimesheetsSubscriptionInvoiceInfoAttempt();
            if (taxId) {
                patchTaxId(data);
            } else {
                postTaxId(data);
            }
            interval.current = setInterval(async () => {
                try {
                    const { data } = await SubscriptionService.getTaxIdInfo(user.id, workspace.code);
                    if (data.status === 'pending') {
                        return;
                    }
                    setIsLoading(false);
                    clearInterval(interval.current);
                    if (data.status === 'verified' || data.status === 'unavailable' || data.status === null) {
                        if (modal.callback) {
                            modal.callback();
                        }
                        analytics.logTimesheetsSubscriptionInvoiceInfoSuccess();
                        modalClose();
                    } else if (data.status === 'unverified') {
                        analytics.logTimesheetsSubscriptionInvoiceInfoFail();
                        modalOpen('dataValidationDidntSucceed');
                    }
                } catch (er) {
                    setIsLoading(false);
                    clearInterval(interval.current);
                    modalClose()
                }
            }, 3000)
        };

    useEffect(() => {
        if (taxId && countries.length) {
            const country = countries.find(c => c.taxes.find(t => t.id === taxId.tax_type_id));
            form.country.setValue(country?.id);
            form.tax_id.setValue(taxId.tax_id || '');
            form.tax_type_id.setValue(String(taxId.tax_type_id));
            form.company_name.setValue(taxId.company_name || '');
            form.street.setValue(taxId.street || '');
            form.state.setValue(taxId.state || '');
            form.post_code.setValue(taxId.post_code || '');
            form.city.setValue(taxId.city || '');
            form.declaration.setValue(true);
        }
    }, [taxId, countries]);

    useEffect(() => {
        if (!form.country.value) return;
        const countryTaxes = countries.find(c => c.id === form.country.value)?.taxes;
        form.tax_type_id.onChange(String(countryTaxes[0].id));
    }, [form.country.value])

    const countriesOptions = useMemo(() => {
        return countries.map(country => ({
            label: country.country || 'Tax ID',
            labelText: country.country || 'Tax ID',
            value: country.id
        }))
    }, [countries]);

    const taxIdsOptions = useMemo(() => {
        return (countries.find(c => c.id === form.country.value))?.taxes.map(t => ({
            label: t.description,
            labelText: t.description,
            value: String(t.id)
        }));
    }, [countries, form.country.value]);

    const taxIdPlaceholder = useMemo(() => {
        return countries.find(c => c.id === form.country.value)?.taxes.find(t => t.id === Number(form.tax_type_id.value))?.example
    }, [countries, form.country.value, form.tax_type_id.value]);

    return (
        <div className={style.addTaxIdForm}>
            <div className={style.form}>
                <div className={cx(style.rowOne, style.row)}>
                    <Autocomplete
                        {...form.country}
                        label={intl.formatMessage(messages.country)}
                        options={countriesOptions}
                        isRequired
                    />
                    <Select
                        {...form.tax_type_id}
                        label={intl.formatMessage(messages.tax_type_id)}
                        options={taxIdsOptions}
                        disabled={!(taxIdsOptions?.length > 1)}
                        isRequired
                    />
                    <Input
                        {...form.tax_id}
                        label={intl.formatMessage(messages.tax_id)}
                        placeholder={taxIdPlaceholder}
                        isRequired
                    />
                </div>
                <div className={cx(style.rowTwo, style.row)}>
                    <Input
                        {...form.company_name}
                        label={intl.formatMessage(messages.company_name)}
                        isRequired
                    />
                    <Input
                        {...form.street}
                        label={intl.formatMessage(messages.street)}
                        isRequired
                    />
                </div>
                <div className={cx(style.rowThree, style.row)}>
                    <Input
                        {...form.state}
                        label={intl.formatMessage(messages.state)}
                    />
                    <Input
                        {...form.post_code}
                        label={intl.formatMessage(messages.post_code)}
                        isRequired
                    />
                    <Input
                        {...form.city}
                        label={intl.formatMessage(messages.city)}
                        isRequired
                    />
                </div>
                <div className={style.declaration}>
                    <Checkbox
                        {...form.declaration}
                        label={intl.formatMessage(messages.declaration)}
                        fieldId="declaration-of-being-enterpreneur"
                        key="declaration-of-being-enterpreneur"
                    />
                </div>
                <div className={style.buttonsWrapper}>
                    <Button
                        onClick={modalClose}
                        text={intl.formatMessage(messages.cancel)}
                        color="white"
                    />
                    <Button
                        type="bordered"
                        onClick={saveAndContinue}
                        disabled={!isFormValid}
                        text={intl.formatMessage(messages.saveAndContinue)}
                    />
                </div>
            </div>
        </div>
    );
};

TaxIdForm.propTypes = {
    setIsLoading: PropTypes.func.isRequired
}

export default TaxIdForm;