import { defineMessages } from 'react-intl';

export default defineMessages({
    invitationAccepted: {
        id: 'useInvitations.invitationAccepted',
        defaultMessage: 'Invitation accepted',
    },
    invitationAcceptedNotSuccessful: {
        id: 'useInvitations.invitationAcceptedNotSuccessful',
        defaultMessage: 'Invitation cannot be accepted',
    },
    invitationDeclined: {
        id: 'useInvitations.invitationDeclined',
        defaultMessage: 'Invitation declined',
    },
});
