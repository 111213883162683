import React, { useEffect, useState } from 'react';
import cx from "classnames";
import PropTypes from 'prop-types';

import style from "./Switch.module.scss";

const keydownHandler = event => {
        if (event.key === ' ') {
            event.preventDefault();
        }
    },
    Switch = ({
        value,
        onChange,
        options,
        fieldId,
        size,
        disabled,
        inactive
    }) => {
        const [isFocused, setIsFocused] = useState(false),
            focusHandler = () => {
                document.addEventListener('keyup', keyupHandler);
                document.addEventListener('keydown', keydownHandler);
                setIsFocused(true);
            },
            blurHandler = () => {
                document.removeEventListener('keyup', keyupHandler);
                document.removeEventListener('keydown', keydownHandler);
                setIsFocused(false);
            },
            toggle = () => {
                const toggleValue =
                    value === options[0].value
                        ? options[1].value
                        : options[0].value;

                onChange(toggleValue);
            },
            keyupHandler = event => {
                if (event.key === ' ') {
                    toggle();
                }
            };

        useEffect(() => {
            () => {
                document.removeEventListener('keyup', keyupHandler);
                document.removeEventListener('keydown', keydownHandler);
            };
        }, []);

        return (
            <div
                className={cx(
                    style.switch,
                    isFocused && style.focused,
                    style[`size-${size}`],
                    inactive && style.inactive,
                    size === 'small' && !value && style.off,
                    disabled && style.disabled,
                )}
                onClick={toggle}
                onBlur={blurHandler}
                onFocus={focusHandler}
                role="button"
                tabIndex="0">
                <label
                    htmlFor={fieldId}
                    className={cx(
                        value === options[0].value ? style.left : style.right,
                    )}>
                    {options.map(o => (
                        <div
                            key={o.value}
                            className={cx(
                                style.option,
                                o.value === value && style.active,
                            )}>
                            <span>{size === 'medium' && o.label}</span>
                        </div>
                    ))}
                </label>
            </div>
        );
    };

Switch.propTypes = {
    value: PropTypes.any,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.any,
        }),
    ),
    onChange: PropTypes.func.isRequired,
    persistent: PropTypes.bool,
    fieldId: PropTypes.string,
    size: PropTypes.oneOf(['small', 'medium']),
    disabled: PropTypes.bool,
    inactive: PropTypes.bool
};

Switch.defaultProps = {
    fieldId: 'switch',
    value: true,
    options: [
        {
            value: false,
            label: 'Off',
        },
        {
            value: true,
            label: 'On',
        }
    ],
    persistent: false,
    size: 'medium',
    disabled: false,
    inactive: false
};

export default Switch;
