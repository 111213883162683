import { defineMessages } from 'react-intl';

export default defineMessages({
    accountActions: {
        id: 'profile.accountActions',
        defaultMessage: 'Account actions',
    },
    deleteAccount: {
        id: 'profile.deleteAccount',
        defaultMessage: 'Delete account',
    },
    byDeleting: {
        id: 'profile.byDeleting',
        defaultMessage: 'By deleting your account, you will permanently erase all tasks, reports and projects. This action is not reversible.',
    },
});