import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from "react-router-dom";

import PollService from "@quidlo/common/api/PollService";
import SubscriptionService from "@quidlo/common/api/SubscriptionService";
import useAuthentication from '@quidlo/common/hooks/useAuthentication';
import useUI from "@quidlo/common/hooks/useUI";
import useWorkspace from "@quidlo/common/hooks/useWorkspace";
import analytics from "@quidlo/common/utils/analytics";
import { Button, Space } from '@quidlo/ui';

import { getUsersTotalQuery } from '../../queries/users.queries';
import { chooseStarterSubscriptionMutation, getWorkspaceTaxIDInfoQuery } from '../../queries/workspace.queries';
import { Container, Explanation } from '../../ui';
import ViewHOC from "../../ui/View/View";

import { ReactComponent as RightArrow } from './assets/right-arrow.svg';
import Card from './components/Card';
import HeaderViews from './HeaderViews';

import style from './PayWall.module.scss';

import messages from './PayWall.i18n';

const PayWall = () => {
    const
        { modalOpen } = useUI(),
        intl = useIntl(),
        navigate = useNavigate(),
        { user } = useAuthentication(),
        { workspace } = useWorkspace(),
        { data: taxData } = getWorkspaceTaxIDInfoQuery(),
        totalUsers = getUsersTotalQuery().data,
        [subscription, setSubscription] = useState('annually'),
        { mutate: chooseStarter } = chooseStarterSubscriptionMutation(),
        subscriptionMode = 'trial',
        view = HeaderViews[subscriptionMode],
        { id } = user,
        continueWithStarter = async () => {
            analytics.logTimesheetsSubscriptionPlanSelected('starter');
            const { code } = workspace;
            if (totalUsers > 10) {
                navigate('/delete-users');
            } else {
                const { data } = await PollService.get(workspace.code, id);
                modalOpen('pollModal', {
                    question: "Why did you decide to continue with free plan?",
                    options: data.choices.map(c => c.choice),
                    send: (options, text) => {
                        const choice = data.choices.find(c => c.choice === options[0]);
                        PollService.post(code, id, { choice_id: choice.id, additional_notes: text })
                    },
                }, () => {
                    chooseStarter();
                    navigate('/settings/subscription');
                });
            }
        },
        contactUs = () => window.open('https://www.quidlo.com/timesheets/contact'),
        upgrade = subscription => {
            analytics.logTimesheetsSubscriptionPlanSelected('pro');
            if (taxData?.status === 'verified') {
                SubscriptionService.postSubscription(id, workspace.code, subscription).then(({ data }) => {
                    analytics.logTimesheetsSubscriptionPaymentAttempt('pro');
                    window.location.replace(data.url);
                });
            } else {
                modalOpen('addTaxInformation', {}, () => SubscriptionService.postSubscription(id, workspace.code, subscription).then(({ data }) => {
                    analytics.logTimesheetsSubscriptionPaymentAttempt('pro');
                    window.location.replace(data.url);
                }));
            }
        };

    return (
        <Container>
            <div className={style.subscription}>
                <Space mb="l">
                    <Explanation
                        imageSrc={view.image}
                        imageAlt={intl.formatMessage(messages[view.alt])}
                        title={intl.formatMessage(messages[view.title])}
                    >
                        {intl.formatMessage(messages[view.subtitle])}
                    </Explanation>
                </Space>
                <Space mb="l">
                    <div className={style.buttonsWrapper}>
                        <div className={style.buttons}>
                            <Button
                                color={subscription === 'monthly' ? 'blue' : 'transparent'}
                                onClick={() => setSubscription('monthly')}
                                text="Billed Monthly"
                            />
                            <Button
                                color={subscription === 'annually' ? 'blue' : 'transparent'}
                                onClick={() => setSubscription('annually')}
                                text="Billed Annually"
                            />
                            <span className={style.save20}>{intl.formatMessage(messages.save20)}</span>
                        </div>
                    </div>
                </Space>
                <Space mb="l">
                    <div className={style.cards}>
                        <Card subscription="starter" action={continueWithStarter} />
                        <Card best subscription="pro" recurrence={subscription} action={() => upgrade(subscription)} />
                        <Card subscription="custom" action={contactUs} />
                    </div>
                </Space>
                <a href="https://www.quidlo.com/timesheets/pricing" target="_blank" rel="noopener noreferrer" className={style.comparePlans}>
                    {intl.formatMessage(messages.compareAllPlanFeatures)}
                    <RightArrow />
                </a>
            </div>
        </Container>
    );
};

export default ViewHOC((PayWall),
    {
        title: 'PayWall',
        menu: true,
        topbar: true,
        auth: {
            roles: ['admin', 'owner']
        },
    }
);