import React from 'react';
import { useIntl } from "react-intl";
import { useLocation, useParams } from "react-router-dom";

import SharedReportsService from "@quidlo/common/api/SharedReportsService";
import useUI from "@quidlo/common/hooks/useUI";
import {
    Button,
} from '@quidlo/ui';

import { DropdownMenu } from '../../../../ui';

import style from './ExportMenu.module.scss';

import messages from './ExportMenu.i18n';

const ExportMenu = () => {
    const intl = useIntl();
    const location = useLocation();
    const params = useParams();
    const { toastPush } = useUI();

    const downloadXLSX = async () => {
        const filters = JSON.parse(window.atob(location.hash.substr(1)));
        const resp = await SharedReportsService.getXLSX(params.code, filters);
        const blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' }),
            a = document.createElement('a'),
            url = window.URL.createObjectURL(blob),
            filename = `Timesheets-report-${(new Date().toISOString()).substr(0, 19)}.xlsx`;

        if (window?.navigator?.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, filename);
            return;
        }

        a.style = 'display: none';
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    };
    const downloadCSV = async () => {
        const filters = JSON.parse(window.atob(location.hash.substr(1)));
        const resp = await SharedReportsService.getCSV(params.code, filters);
        const blob = new Blob([resp.data], { type: 'text/csv' }),
            a = document.createElement('a'),
            url = window.URL.createObjectURL(blob),
            filename = `Timesheets-report-${(new Date().toISOString()).substr(0, 19)}.csv`;

        if (window?.navigator?.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, filename);
            return;
        }
        a.style = 'display: none';
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    };
    const downloadPDF = async () => {
        const filters = JSON.parse(window.atob(location.hash.substr(1)));
        const resp = await SharedReportsService.getPDF(params.code, filters);
        const blob = new Blob([resp.data], { type: 'application/pdf' }),
            a = document.createElement('a'),
            url = window.URL.createObjectURL(blob),
            filename = `Timesheets-report-${(new Date().toISOString()).substr(0, 19)}.pdf`;

        if (window?.navigator?.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, filename);
            return;
        }

        a.style = 'display: none';
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    };
    const exportToGoogleSheets = async () => {
        try {
            const filters = JSON.parse(window.atob(location.hash.substr(1)));

            const client = window.google.accounts.oauth2.initTokenClient({
                client_id: process.env.REACT_APP_GOOGLE_CLIENTID,
                scope: 'email https://www.googleapis.com/auth/drive.file',
                callback: async tokenResponse => {
                    const res = await SharedReportsService.exportToGoogleSheets(params.code, filters, tokenResponse.access_token)
                    window.open(res.data.url, '_blank');
                },
            });
            client.requestAccessToken();
        } catch (e) {
            console.log(e);
            toastPush({
                text: intl.formatMessage(messages.unexpectedError),
                color: 'red'
            });
        }
    };



    return (
        <DropdownMenu
            options={
                [[
                    {
                        label: intl.formatMessage(messages.downloadXLSX),
                        onClick: downloadXLSX,
                    },
                    {
                        label: intl.formatMessage(messages.downloadCSV),
                        onClick: downloadCSV,
                    },
                    {
                        label: intl.formatMessage(messages.downloadPDF),
                        onClick: downloadPDF,
                    },
                    {
                        label: intl.formatMessage(messages.exportToGoogleSheets),
                        onClick: exportToGoogleSheets,
                    },
                ]]
            }
            disabled={!location.hash}>
            <div className={style.buttonContainer}>
                <Button
                    icon="download"
                    size="medium"
                    iconSize="medium"
                    color="white"
                    type="bordered"
                    disabled={!location.hash}
                    iconColor="blue"
                />
            </div>
        </DropdownMenu>
    );
};

export default ExportMenu;
