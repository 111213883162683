import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import useUI from "@quidlo/common/hooks/useUI";
import { Button, Space } from "@quidlo/ui";

import illustration from "../../../../assets/modals/document.png";
import ModalHOC, { ModalActions, ModalContent, ModalHeader } from "../../../../ui/Modal/Modal";

import TaxIdForm from "./TaxIdForm";

import style from "./AddTaxInformation.module.scss";

import messages from "./AddTaxInformation.i18n";

const AddTaxInformation = () => {
    const
        intl = useIntl(),
        { modalClose } = useUI(),
        [isLoading, setIsLoading] = useState(false);

    if (isLoading) {
        return (
            <>
                <ModalHeader>
                    {intl.formatMessage(messages.weAreVerifying)}
                </ModalHeader>
                <ModalContent>
                    <div className={style.loader} />
                    <div className={style.subtitle}>
                        {intl.formatMessage(messages.thatMightTake)}
                    </div>
                </ModalContent>
                <ModalActions>
                    <Button
                        onClick={modalClose}
                        text={intl.formatMessage(messages.cancel)}
                        color="white"
                    />
                </ModalActions>
            </>
        );
    }

    return (
        <>
            <ModalHeader>
                {intl.formatMessage(messages.title)}
            </ModalHeader>
            <ModalContent>
                <Space mb="m" className={style.subtitle}>
                    {intl.formatMessage(messages.subtitle)}
                </Space>
                <TaxIdForm setIsLoading={setIsLoading} />
            </ModalContent>
        </>
    );
};

export default ModalHOC(
    AddTaxInformation,
    {
        id: 'addTaxInformation',
        size: 'large',
        illustration
    }
);