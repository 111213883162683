import { defineMessages } from 'react-intl';

export default defineMessages({
    selectWorkspace: {
        id: 'SelectWorkspace.selectWorkspace',
        defaultMessage: 'Select Workspace'
    },
    cancel: {
        id: 'SelectWorkspace.cancel',
        defaultMessage: 'Cancel'
    }
});