import React from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

import IntegrationsService from "@quidlo/common/api/IntegrationsService";
import useAuthentication from "@quidlo/common/hooks/useAuthentication";
import useWorkspace from "@quidlo/common/hooks/useWorkspace";
import { InfoCard, Space } from "@quidlo/ui";

import { Container } from "../../../ui";
import ViewHOC from '../../../ui/View/View';

import { ReactComponent as ChatbotIllu } from "./svg/chatbot-big.svg";

import messages from "./GoogleChatConnect.i18n";

const GoogleChatConnect = () => {
    const
        intl = useIntl(),
        location = useLocation(),
        { user } = useAuthentication(),
        { workspace } = useWorkspace();

    const connect = () => {
        const
            parsedSearch = location.search.substring(1).replace('+', '%2b'),
            parsedQuery = qs.parse(parsedSearch);

        IntegrationsService.googleChatConnect(
            workspace.code,
            user.id,
            {
                hangoutsUserId: parsedQuery.userId,
                redirectUrl: parsedQuery.redirectUrl,
                token: parsedQuery.token
            }).then(data => {
            if (data && data.redirectUrl) {
                window.location.replace(data.redirectUrl);
            }
        });
    };

    return (
        <div>
            <Container centered>
                <Space>
                    <InfoCard
                        header={intl.formatMessage(messages.user)}
                        text={intl.formatMessage(messages.message)}
                        actions={[{
                            text: intl.formatMessage(messages.ok),
                            onClick: connect,
                        }]}
                    >
                        <ChatbotIllu />
                    </InfoCard>
                </Space>
            </Container>
        </div>
    );
};

export default ViewHOC(
    GoogleChatConnect,
    {
        title: "Google Chat Connect",
        menu: true,
        topbar: true,
        auth: {
            roles: ['employee', 'manager', 'admin', 'owner'],
        },
        subscription: true
    }
);
