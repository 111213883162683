import { defineMessages } from 'react-intl';

export default defineMessages({
    country: {
        id: 'addTaxIdForm.country',
        defaultMessage: 'Country',
    },
    tax_type_id: {
        id: 'addTaxIdForm.tax_type_id',
        defaultMessage: 'Tax ID',
    },
    tax_id: {
        id: 'addTaxIdForm.tax_id',
        defaultMessage: 'Tax number',
    },
    company_name: {
        id: 'addTaxIdForm.company_name',
        defaultMessage: 'Company name',
    },
    street: {
        id: 'addTaxIdForm.street',
        defaultMessage: 'Street and number',
    },
    state: {
        id: 'addTaxIdForm.state',
        defaultMessage: 'State',
    },
    post_code: {
        id: 'addTaxIdForm.post_code',
        defaultMessage: 'Post code',
    },
    city: {
        id: 'addTaxIdForm.city',
        defaultMessage: 'City',
    },
    declaration: {
        id: 'addTaxIdForm.declaration',
        defaultMessage: 'I hereby declare that I act as an entrepreneur or entrepreneur with the rights of a consumer in conformity with the Terms & Conditions, and that all the data and details I provided are accurate and complete, as well as in accordance with the applicable legislation',
    },
    cancel: {
        id: 'addTaxInformation.cancel',
        defaultMessage: 'Cancel',
    },
    saveAndContinue: {
        id: 'addTaxInformation.saveAndContinue',
        defaultMessage: 'Save & continue',
    },
    taxInformationHasBeenAdded: {
        id: 'addTaxInformation.taxInformationHasBeenAdded',
        defaultMessage: 'Tax information has been added!',
    },
    taxInformationHasBeenEdited: {
        id: 'addTaxInformation.taxInformationHasBeenEdited',
        defaultMessage: 'Tax information has been edited!',
    },
});
