const
    DAY_MS = 86400000,
    MINUTE_MS = 60000,
    EPOCH = new Date(1970, 0, 1),
    isToday = date => {
        const today = new Date();
        return date.toDateString() === today.toDateString();
    },
    dateToYYYYMMDD = date => {
        const
            month = `00${date.getMonth() + 1}`.slice(-2),
            day = `00${date.getDate()}`.slice(-2);

        return `${date.getFullYear()}-${month}-${day}`;
    },
    addDays = (date, days) => {
        const
            TZOffset = (date.getTimezoneOffset() - EPOCH.getTimezoneOffset()) * MINUTE_MS,
            daysSinceEpoch = Math.floor((date - EPOCH - TZOffset) / DAY_MS);
        return new Date(1970, 0, daysSinceEpoch + days + 1);
    },
    differenceInDays = (date1, date2 = new Date()) => {
        const diffTime = (date1 - date2);
        return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    },
    setWeekday = (date, weekday, firstDayOfWeek) => {
        const currentWeekday = date.getDay();
        if (firstDayOfWeek === 'sunday') {
            return addDays(date, weekday - currentWeekday);
        }
        return addDays(date, (weekday - currentWeekday) + (currentWeekday === 0 ? -6 : 1));
    },
    daysArray = (gte, lte) => {
        const days = [];
        let date = new Date(gte.getTime());

        while (date <= lte) {
            days.push({
                id: date.getTime(),
                date,
                isToday: isToday(date)
            });
            date = addDays(date, 1);
        }
        return days;
    },
    dateToString = date => {
        const
            newDate = new Date(date.getTime()),
            TZOffset = newDate.getTimezoneOffset() - date.getTimezoneOffset();

        if (TZOffset === 0) {
            return dateToYYYYMMDD(newDate);
        }

        return dateToYYYYMMDD(new Date(newDate.getTime() - (TZOffset * MINUTE_MS)));
    },
    stringToDate = string => {
        const newDate = new Date(string);

        return new Date(newDate.getTime() + (newDate.getTimezoneOffset() * 60000));
    };

export {
    addDays,
    dateToString,
    daysArray,
    differenceInDays,
    isToday,
    setWeekday,
    stringToDate
};
