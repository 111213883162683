import React from 'react';
import { useIntl } from 'react-intl';
import cx from "classnames";

import useAuthentication from "@quidlo/common/hooks/useAuthentication";
import useUI from "@quidlo/common/hooks/useUI";
import useWorkspace from "@quidlo/common/hooks/useWorkspace";
import { stringToInteger } from '@quidlo/common/utils/stringToInteger';
import { Button, Icon } from "@quidlo/ui";

import illustration from "../../../../assets/modals/workspace.png";
import ModalHOC, { ModalActions, ModalContent, ModalHeader } from "../../../Modal/Modal";

import workspaceLogos from './DefaultWorkspaceLogos';

import style from "./SelectWorkspace.module.scss";

import messages from './SelectWorkspace.i18n';

const SelectWorkspace = () => {
    const
        intl = useIntl(),
        { modalClose } = useUI(),
        { user } = useAuthentication(),
        { workspace, selectWorkspace } = useWorkspace(),
        switchWorkspace = code => {
            selectWorkspace(code);
            modalClose();
        };

    return (
        <>
            <ModalHeader>
                {intl.formatMessage(messages.selectWorkspace)}
            </ModalHeader>
            <ModalContent>
                {user.organizations.map(ws => {
                    const active = ws.code === workspace.code,
                        workspaceDefaultLogo = workspaceLogos[stringToInteger(`${ws.name}`, workspaceLogos.length)];

                    return (
                        <div
                            className={cx(
                                style.workspace,
                                active && style.active,
                                style[`status-${ws.status}`],
                            )}
                            onClick={() => {
                                if (ws.status === 'approved')
                                    switchWorkspace(ws.code);
                            }}
                            key={ws.code}>
                            <div
                                className={style.logo}
                                style={{
                                    background: ws.logo
                                        ? `url(${ws.logo})`
                                        : `url(${workspaceDefaultLogo})`,
                                }}
                            />
                            <div className={style.name}>{ws.name}</div>
                            {ws.status !== 'invited' && <Icon icon="caret-right" color="blue" />}
                        </div>
                    )
                })}
            </ModalContent>
            <ModalActions>
                <Button color="white" text={intl.formatMessage(messages.cancel)} onClick={modalClose} />
            </ModalActions>
        </>
    );
};

export default ModalHOC(
    SelectWorkspace,
    {
        id: 'selectWorkspace',
        size: 'small',
        illustration,
    }
);