import { defineMessages } from 'react-intl';

export default defineMessages({
    reports: {
        id: 'reportCreate.reports',
        defaultMessage: 'Reports'
    },
    share: {
        id: 'reportCreate.share',
        defaultMessage: 'Share'
    },
    print: {
        id: 'reportCreate.print',
        defaultMessage: 'Print'
    },
    linkCopied: {
        id: 'reportCreate.linkCopied',
        defaultMessage: 'Link copied to clipboard'
    },
    copyLink: {
        id: 'reportCreate.copyLink',
        defaultMessage: 'Copy public link to clipboard'
    },
    sendReport: {
        id: 'reportCreate.sendReport',
        defaultMessage: 'Send report via email'
    },
    requestTimeApproval: {
        id: 'reportCreate.requestTimeApproval',
        defaultMessage: 'Request time approval'
    },
    pleaseSelect: {
        id: 'reportsList.pleaseSelect',
        defaultMessage: 'Please select a project first',
    },
    projectsAreReq: {
        id: 'reportsList.projectsAreReq',
        defaultMessage: 'Please select a project',
    },
    tagsAreReq: {
        id: 'reportsList.tagsAreReq',
        defaultMessage: 'Please select a tag',
    },
    usersAreReq: {
        id: 'reportsList.usersAreReq',
        defaultMessage: 'Please select a team member',
    },
});