import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { Space, Switch } from '@quidlo/ui';

import style from "./LabeledSwitch.module.scss";

const LabeledSwitch = ({
    title,
    description,
    pro,
    ...props
}) => (
    <div className={style.wrapper}>
        <div>
            <Switch {...props} />
        </div>
        <Space ml={"s"}>
            <div className={style.rightSide}>
                <Space mb={"s"} direction={'row'} style={{ alignItems: 'center' }}>
                    <span className={style.title}>{title}</span>
                    {pro &&
                        <Space ml={"xxs"}>
                            <div className={style.pro}>PRO</div>
                        </Space>
                    }
                </Space>
                <span className={style.description}>
                    <FormattedMessage
                        {...description}
                        values={{
                            b: chunks => <strong> {chunks} </strong>,
                        }}
                    />
                </span>
            </div>
        </Space>
    </div>
);

LabeledSwitch.propTypes = {
    value: PropTypes.any,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.any,
        }),
    ),
    onChange: PropTypes.func.isRequired,
    persistent: PropTypes.bool,
    fieldId: PropTypes.string,
    size: PropTypes.oneOf(['small', 'medium']),
    disabled: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.object,
    pro: PropTypes.bool,
    inactive: PropTypes.bool
};

LabeledSwitch.defaultProps = {
    fieldId: 'switch',
    value: true,
    options: [
        {
            value: false,
            label: 'Off',
        },
        {
            value: true,
            label: 'On',
        }
    ],
    persistent: false,
    size: 'medium',
    disabled: false,
    title: '',
    description: '',
    pro: false,
    inactive: false
};

export default LabeledSwitch;
