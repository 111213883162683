import { defineMessages } from 'react-intl';

export default defineMessages({
    projects: {
        id: 'reportsList.projects',
        defaultMessage: 'Projects'
    },
    tags: {
        id: 'reportsList.tags',
        defaultMessage: 'Tags'
    },
    status: {
        id: 'reportsList.status',
        defaultMessage: 'Status'
    },
    people: {
        id: 'reportsList.people',
        defaultMessage: 'People'
    },
    apply: {
        id: 'reportsList.apply',
        defaultMessage: 'Apply'
    },
    billable: {
        id: 'reportsList.billable',
        defaultMessage: 'Billable'
    },
    billableall: {
        id: 'reportsList.billableall',
        defaultMessage: 'All'
    },
    billabletrue: {
        id: 'reportsList.billabletrue',
        defaultMessage: 'Yes'
    },
    billablefalse: {
        id: 'reportsList.billablefalse',
        defaultMessage: 'No'
    },
    pleaseSelect: {
        id: 'reportsList.pleaseSelect',
        defaultMessage: 'Please select a project first',
    },
});