import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import useUI from "@quidlo/common/hooks/useUI";
import { Button, Card, Input } from "@quidlo/ui";

import { getWorkspaceTaxIDCountriesQuery, getWorkspaceTaxIDInfoQuery } from "../../../../queries/workspace.queries";

import style from "./ReceiptInfo.module.scss";

import messages from "./ReceiptInfo.i18n";

const ReceiptInfo = () => {
    const
        intl = useIntl(),
        { modalOpen } = useUI(),
        { data: taxId } = getWorkspaceTaxIDInfoQuery(),
        { data: countries } = getWorkspaceTaxIDCountriesQuery();

    const [countryName, taxType] = useMemo(() => {
        if (!taxId || !countries.length) return ['',''];

        const country = countries.find(c => c.taxes.find(t => t.id === taxId.tax_type_id));
        const tax = country.taxes.find(t => t.id === taxId.tax_type_id);

        return [country.country, tax.description || 'Tax ID']

    }, [taxId, countries]);

    return (
        <Card
            title={intl.formatMessage(messages.title)}
            headerRightChildren={(
                taxId?.tax_id ?
                    null
                    :
                    <Button
                        size="small"
                        text={intl.formatMessage(messages.add)}
                        color="blue"
                        shadow
                        onClick={() => {
                            modalOpen('addTaxInformation');
                        }}
                    />
            )}
        >
            <div className={style.receiptInfo}>
                {taxId?.tax_id ?
                    <div>
                        <div className={style.subtitle}>
                            {intl.formatMessage(messages.subtitle)}
                        </div>
                        <div className={style.description}>
                            {intl.formatMessage(messages.description)}
                        </div>
                        <div className={style.form}>
                            <div className={style.row}>
                                <Input
                                    label={intl.formatMessage(messages.taxId)}
                                    value={taxType}
                                    disabled
                                />
                                <Input
                                    label={intl.formatMessage(messages.taxNumber)}
                                    value={taxId.tax_id}
                                    disabled
                                />
                            </div>
                            <div className={style.row}>
                                <Input
                                    label={intl.formatMessage(messages.companyName)}
                                    value={taxId.company_name}
                                    disabled
                                />
                                <Input
                                    label={intl.formatMessage(messages.country)}
                                    value={countryName}
                                    disabled
                                />
                                <Input
                                    label={intl.formatMessage(messages.state)}
                                    value={taxId.state}
                                    disabled
                                />
                            </div>
                            <div className={style.row}>
                                <Input
                                    label={intl.formatMessage(messages.postcode)}
                                    value={taxId.post_code}
                                    disabled
                                />
                                <Input
                                    label={intl.formatMessage(messages.city)}
                                    value={taxId.city}
                                    disabled
                                />
                                <Input
                                    label={intl.formatMessage(messages.streetAndNumber)}
                                    value={taxId.street}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className={style.buttons}>
                            <Button
                                type="bordered"
                                onClick={() => modalOpen('addTaxInformation')}
                                text={intl.formatMessage(messages.edit)}
                                color="white"
                            />
                        </div>
                    </div>
                    :
                    <div>
                        <div className={style.subtitle}>
                            {intl.formatMessage(messages.subtitle)}
                        </div>
                        <div className={style.description}>
                            {intl.formatMessage(messages.description)}
                        </div>
                    </div>
                }
            </div>
        </Card>
    );
};

export default ReceiptInfo;