import React from 'react';
import { useIntl } from "react-intl";
import PropTypes from 'prop-types';

import { InfoCard, Space } from '@quidlo/ui';

import { Container } from '../../../ui';

import messages from './NoProjects.i18n';

const NoProjects = ({
    onClick,
}) => {
    const intl = useIntl();
    return (
        <div>
            <Container centered>
                <Space>
                    <InfoCard
                        header={intl.formatMessage(messages.user)}
                        text={intl.formatMessage(messages.message)}
                        actions={[{
                            text: intl.formatMessage(messages.ok),
                            onClick
                        }]}
                    />
                </Space>
            </Container>
        </div>

    );
}

NoProjects.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default NoProjects;
