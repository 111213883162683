import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'changeOwner.title',
        defaultMessage: 'Change owner',
    },
    subtitle: {
        id: 'changeOwner.subtitle',
        defaultMessage: 'Are you sure you want to transfer ownership of this workspace to the new owner? Once approved, you will lose the ownership rights.',
    },
    cancel: {
        id: 'changeOwner.cancel',
        defaultMessage: 'Cancel',
    },
    approve: {
        id: 'changeOwner.approve',
        defaultMessage: 'Approve',
    },
    alt: {
        id: 'changeOwner.alt',
        defaultMessage: 'Man and woman handshaking',
    },
});