import { defineMessages } from 'react-intl';

export default defineMessages({
    user: {
        id: 'connectSlack.user',
        defaultMessage: 'Dear user'
    },
    message: {
        id: 'connectSlack.message',
        defaultMessage: 'Your account will be connected with Slack'
    },
    ok: {
        id: 'connectSlack.ok',
        defaultMessage: 'Ok'
    },
    alreadyExists: {
        id: 'connectSlack.alreadyExists',
        defaultMessage: 'Chatbot already exists'
    },
    success: {
        id: 'connectSlack.success',
        defaultMessage: 'Slack bot configured successfully'
    }
});