import React from 'react';
import cx from "classnames";
import PropTypes from 'prop-types';

import COLORS from '@quidlo/common/constants/colors';
import { Context } from "@quidlo/ui";

import style from "./InputColor.module.scss";

const InputColor = ({
    value,
    onChange,
    disabled
}) => {
    const onChangeHandler = val => {
        if (value !== val) {
            onChange(val);
        }
    };

    const colorElements = COLORS.PREDEFINED_COLORS.map(c => (
        <a
            className={cx(c.color === value && style.active)}
            onClick={() => {
                onChangeHandler(c.color);
            }}
            key={c.color}>
            <div style={{ backgroundColor: c.color }} />
        </a>
    ));

    return (
        <Context
            context={(
                <div className={style.dropdown}>
                    {colorElements}
                </div>
            )}
            position="bottom-center"
            disabled={disabled}
        >
            <div
                className={style.colorpicker}
            >
                <div className={style.value}>
                    <div style={{ backgroundColor: value }} />
                </div>
                <div className={style.colorName}>
                    {COLORS.PREDEFINED_COLORS.find(c => c.color === value)
                        ? COLORS.PREDEFINED_COLORS.find(c => c.color === value).name
                        : 'None'}
                </div>
            </div>
        </Context>
    );
};

InputColor.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool
};

InputColor.defaultProps = {
    value: undefined,
    disabled: false
};

export default InputColor;