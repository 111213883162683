import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import useForm from '@quidlo/common/hooks/useForm2';
import useUI from "@quidlo/common/hooks/useUI";
import validators from '@quidlo/common/utils/validators';
import { Button, Checkbox, Space } from '@quidlo/ui';
import Icon from '@quidlo/ui/src/Icon';

import illustration from "../../../../../assets/modals/trash.png";
import {
    deleteProjectMutation,
} from '../../../../../queries/projects.queries';
import ModalHOC, { ModalActions, ModalContent, ModalHeader } from "../../../../../ui/Modal/Modal";

import style from "./DeleteProject.module.scss";

import messages from './DeleteProject.i18n';

const DeleteProject = () => {
    const
        navigate = useNavigate(),
        intl = useIntl(),
        { modal, modalClose } = useUI(),
        { mutate: deleteProject } = deleteProjectMutation(),
        [form, { isFormValid, touchForm }] = useForm({
            declaration: {
                value: false,
                validators: [validators.isTrue()]
            }
        }),
        removeProject = () => {
            if(isFormValid) {
                const { id } = modal.data;
                deleteProject(id)
                navigate('/projects');
                modalClose();
            } else {
                form.declaration.setError(intl.formatMessage(messages.makeSure))
                touchForm()
            }
        };

    return (
        <>
            <ModalHeader>
                {intl.formatMessage(messages.deleteProject)}
            </ModalHeader>
            <ModalContent>
                <Space mb="m">
                    <span>
                        <FormattedMessage
                            {...messages.areYouSure}
                            values={{
                                b: chunks => <strong> {chunks} </strong>,
                                name: modal.data.name,
                            }}
                        />
                    </span>
                </Space>
                <div className={style.info}>
                    <Space mr={"xs"}>
                        <Icon icon="info" size="small" color={"blue"}  />
                    </Space>
                    <div>
                        {intl.formatMessage(messages.allTimeEntries)}
                    </div>
                </div>
                <Space mt="m">
                    <div className={style.declaration}>
                        <Checkbox
                            {...form.declaration}
                            fieldId={messages.declaration.id}
                            label={intl.formatMessage(messages.declaration)}
                            tickBorder
                            required
                        />
                    </div>
                </Space>
            </ModalContent>
            <ModalActions>
                <Space mr="m">
                    <Button
                        color="white"
                        text={intl.formatMessage(messages.cancel)}
                        onClick={modalClose}
                    />
                </Space>
                <Button
                    color="blue"
                    text={intl.formatMessage(messages.delete)}
                    onClick={removeProject}
                    shadow
                />
            </ModalActions>
        </>
    );
};

export default ModalHOC(
    DeleteProject,
    {
        id: 'deleteProject',
        size: 'small',
        illustration,
    }
);
