import React from 'react';
import { useIntl } from 'react-intl';

import useUI from "@quidlo/common/hooks/useUI";
import { Button } from "@quidlo/ui";

import illustration from "../../../../assets/modals/woman-time.png";
import ModalHOC, { ModalActions, ModalContent, ModalHeader } from "../../../../ui/Modal/Modal";

import messages from './ConfirmDurationExceeded.i18n';

const
    ConfirmDurationExceeded = () => {
        const
            intl = useIntl(),
            { modalClose } = useUI();

        return (
            <>
                <ModalHeader>
                    {intl.formatMessage(messages.title)}
                </ModalHeader>
                <ModalContent>
                    {intl.formatMessage(messages.text)}
                </ModalContent>
                <ModalActions>
                    <Button
                        color="blue"
                        text={intl.formatMessage(messages.ok)}
                        onClick={modalClose}
                        shadow
                    />
                </ModalActions>
            </>
        )
    };

export default ModalHOC(
    ConfirmDurationExceeded,
    {
        id: 'confirmDurationExceeded',
        size: 'small',
        illustration,
    }
);