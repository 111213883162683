const
    HOURS_MINUTES_24 = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/,
    HOURS_MINUTES_12 = /^(1[0-2]|0?[1-9]):([0-5][0-9]) ?(am|pm|AM|PM)$/,
    HOURS_24 = /^([0-9]|0[0-9]|1[0-9]|2[0-3])$/,
    HOURS_12 = /^(1[0-2]|0?[1-9]) ?(am|pm|AM|PM)$/,
    ALL = [HOURS_MINUTES_24, HOURS_24, HOURS_MINUTES_12, HOURS_12];

export default {
    HOURS_MINUTES_24,
    HOURS_MINUTES_12,
    HOURS_24,
    HOURS_12,
    ALL
};