import { useIntl } from "react-intl";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import TagsService from "@quidlo/common/api/TagsService";
import useAuthentication from "@quidlo/common/hooks/useAuthentication";
import useUI from "@quidlo/common/hooks/useUI";
import useWorkspace from "@quidlo/common/hooks/useWorkspace";

import { messagesToasts } from "../messages";

export const getTagsListQuery = () => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    return useQuery(
        [
            { workspace: workspace?.code, user: user?.id },
            'tags', 'list-all'
        ],
        async () => (await TagsService.getAll(workspace.code))?.data,
        {
            // enabled: !!workspace?.code && !!user?.id,
            initialData: []
        })
};

export const createTagMutation = () => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    const queryClient = useQueryClient();
    const { toastPush } = useUI();
    const intl = useIntl();

    return useMutation(tag => TagsService.post(workspace.code, tag), {
        onSuccess: () => {
            toastPush({
                text: intl.formatMessage(messagesToasts.workspaceTagAdded)
            });
            queryClient.invalidateQueries([
                { workspace: workspace.code, user: user.id },
                'tags', 'list-all'
            ])
        },
        onError: e => {
            toastPush({
                text: e?.response?.data?.message || intl.formatMessage(messagesToasts.unexpectedError),
                color: 'red'
            });
        }
    })
}

export const deleteTagMutation = () => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    const queryClient = useQueryClient();
    const { toastPush } = useUI();
    const intl = useIntl();

    return useMutation(tag => TagsService.delete(workspace.code, tag), {
        onSuccess: () => {
            toastPush({
                text: intl.formatMessage(messagesToasts.workspaceTagDeleted)
            });
            queryClient.invalidateQueries([
                { workspace: workspace.code, user: user.id },
                'tags', 'list-all'
            ])
        },
        onError: e => {
            toastPush({
                text: e?.response?.data?.message || intl.formatMessage(messagesToasts.unexpectedError),
                color: 'red'
            });
        }
    })
}