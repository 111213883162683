import React from 'react';

import { Space } from "@quidlo/ui";

import ProFeatures from './ProFeatures';
// import StarterFeatures from './StarterFeatures';

const
    Features = () => {
        return (
            <Space pv="m">
                {/*<Space mb="s">*/}
                {/*    <StarterFeatures />*/}
                {/*</Space>*/}
                <ProFeatures />
            </Space>
        );
    };

export default Features;
