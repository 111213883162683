import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import useUI from "@quidlo/common/hooks/useUI";
import { Button, Space } from '@quidlo/ui';

import illustration from "../../../../../../assets/modals/padlock.png";
import ModalHOC, { ModalActions, ModalContent, ModalHeader } from "../../../../../../ui/Modal/Modal";

import messages from './CannotLeaveWorkspace.i18n';

const CannotLeaveWorkspace = () => {
    const navigate = useNavigate();
    const intl = useIntl();
    const { modalClose } = useUI();
    return (
        <>
            <ModalHeader>
                {intl.formatMessage(messages.title)}
            </ModalHeader>
            <ModalContent>
                {intl.formatMessage(messages.subtitle)}
            </ModalContent>
            <ModalActions>
                <Space mr="m">
                    <Button
                        onClick={modalClose}
                        text={intl.formatMessage(messages.cancel)}
                        color="white"
                    />
                </Space>
                <Button
                    onClick={
                        () => {
                            modalClose();
                            navigate('/users');
                        }
                    }
                    text={intl.formatMessage(messages.manageUsers)}
                    shadow
                    color="blue"
                />
            </ModalActions>
        </>
    );
};


export default ModalHOC(
    CannotLeaveWorkspace,
    {
        id: 'cannotLeaveWorkspace',
        size: 'small',
        illustration,
    }
);