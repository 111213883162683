import { defineMessages } from 'react-intl';

export default defineMessages({
    createTag: {
        id: 'settings.createTag',
        defaultMessage: 'Create tag',
    },
    globalTagName: {
        id: 'settings.globalTagName',
        defaultMessage: 'Global tag name',
    },
    save: {
        id: 'settings.save',
        defaultMessage: 'Add tag',
    },
    cancel: {
        id: 'settings.cancel',
        defaultMessage: 'Cancel',
    },
});