import { useIntl } from 'react-intl';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import ApprovalsService from '@quidlo/common/apiV2/ApprovalsService';
import useAuthentication from "@quidlo/common/hooks/useAuthentication";
import useUI from '@quidlo/common/hooks/useUI';
import useWorkspace from '@quidlo/common/hooks/useWorkspace';

import { messagesToasts } from '../messages';

export const getPotentialApprovalsQuery = params => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();

    return useQuery(
        [{ workspace: workspace.code, user: user.id }, 'approvals', 'potential-list', params],
        async () => (await ApprovalsService.getPotentialApprovals(workspace.code, user.id, params))?.data,
        {
            enabled: !!params?.projects?.length,
            // enabled: !!workspace?.code && !!user?.id && !!params?.projects?.length,
            initialData: {
                projects: []
            },
            refetchOnWindowFocus: false
        }
    )
};

export const getApprovalsQuery = filters => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    return useQuery(
        [{ workspace: workspace.code, user: user.id }, 'approvals', 'my-list', filters],
        async () => (await ApprovalsService.getApprovals(workspace.code, user.id, filters))?.data,
        {
            // enabled: !!workspace?.code && !!user?.id,
            initialData: {
                view: 'list',
                list: []
            },
            refetchOnWindowFocus: false
        }
    )
};

export const getRequestedApprovalsQuery = filters => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    return useQuery(
        [{ workspace: workspace.code, user: user.id }, 'approvals', 'requested-list', filters],
        async () => {
            return (await ApprovalsService.getRequestedApprovals(workspace.code, user.id, filters))?.data
        },
        {
            // enabled: !!workspace?.code && !!user?.id,
            initialData: {
                list: []
            },
            refetchOnWindowFocus: false
        }
    )
};

export const getPotentialApprovalDetailsQuery = filters => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    return useQuery(
        [{ workspace: workspace.code, user: user.id }, 'approvals', 'potential', filters],
        async () => (await ApprovalsService.getPotentialApproval(workspace.code, user.id, filters))?.data,
        {
            // enabled: !!workspace?.code && !!user?.id,
            initialData: {
                tasks: []
            }
        }
    )
};

export const getApprovalDetailsQuery = id => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    return useQuery(
        [{ workspace: workspace.code, user: user.id }, 'approvals','approval', id],
        async () => (await ApprovalsService.getApproval(workspace.code, user.id, id))?.data,
        {
            // enabled: !!workspace?.code && !!user?.id,
            initialData: {
                tasks: [],
                managers: []
            },
        }
    )
};

export const getApprovalRequestDetailsQuery = id => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    return useQuery(
        [{ workspace: workspace.code, user: user.id }, 'approvals', 'request', id],
        async () => (await ApprovalsService.getRequestedApproval(workspace.code, user.id, id))?.data,
        {
            // enabled: !!workspace?.code && !!user?.id,
            initialData: {
                tasks: [],
                managers: []
            },
        }
    )
};

export const createApprovalMutation = () => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    const queryClient = useQueryClient();
    const { toastPush } = useUI();
    const intl = useIntl();
    return useMutation(async params => (ApprovalsService.postApproval(workspace.code, user.id, params)), {
        onSuccess: () => {
            queryClient.invalidateQueries([{ workspace: workspace.code, user: user.id }, 'approvals', 'potential-list'])
            queryClient.invalidateQueries([{ workspace: workspace.code, user: user.id }, 'approvals', 'my-list'])
            toastPush({
                text: intl.formatMessage(messagesToasts.approvalRequestSent)
            });
        },
        onError: e => {
            toastPush({
                text: e?.response?.data?.message || intl.formatMessage(messagesToasts.approvalsSwitchedOff),
                color: 'red'
            });
        }
    })
};

export const approveRequestMutation = () => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    const queryClient = useQueryClient();
    const { toastPush } = useUI();
    const intl = useIntl();
    return useMutation(async ids => Promise.all(ids.map(id => ApprovalsService.approveRequest(workspace.code, user.id, id))), {
        onSuccess: (_, ids) => {
            queryClient.invalidateQueries(
                [{ workspace: workspace.code, user: user.id }, 'approvals', 'requested-list'],
            );
            ids.forEach(id => {
                queryClient.invalidateQueries(
                    [{ workspace: workspace.code, user: user.id }, 'approvals', 'request', id]
                );
                queryClient.invalidateQueries(
                    [{ workspace: workspace.code, user: user.id }, 'approvals', 'approval', id]
                );
            })
            toastPush({
                text: intl.formatMessage(messagesToasts.requestApproved)
            });
        },
        onError: e => {
            toastPush({
                text: e?.response?.data?.message,
                color: 'red'
            });
        }
    })
};

export const rejectRequestMutation = () => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    const queryClient = useQueryClient();
    const { toastPush } = useUI();
    const intl = useIntl();
    return useMutation(async rejectedApprovals => Promise.all(rejectedApprovals.map(approval => ApprovalsService.rejectRequest(workspace.code, user.id, approval))), {
        onSuccess: (_, requests) => {
            queryClient.invalidateQueries(
                [{ workspace: workspace.code, user: user.id }, 'approvals', 'requested-list'],
            );
            requests.forEach(({ id }) => {
                queryClient.invalidateQueries(
                    [{ workspace: workspace.code, user: user.id }, 'approvals', 'request', id]
                );
                queryClient.invalidateQueries(
                    [{ workspace: workspace.code, user: user.id }, 'approvals', 'approval', id]
                );
            })
            toastPush({
                text: intl.formatMessage(messagesToasts.requestRejected),
            });
        },
        onError: e => {
            toastPush({
                text: e?.response?.data?.message,
                color: 'red'
            });
        }
    })
};

export const withdrawApprovalMutation = () => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    const queryClient = useQueryClient();
    const { toastPush } = useUI();
    const intl = useIntl();
    return useMutation(async params => ApprovalsService.withdrawRequest(workspace.code, user.id, params), {
        onSuccess: (_,params) => {
            queryClient.invalidateQueries([{ workspace: workspace.code, user: user.id }, 'approvals', 'requested-list']),
            queryClient.invalidateQueries(
                [{ workspace: workspace.code, user: user.id }, 'approvals', 'request', params.id]
            );
            queryClient.invalidateQueries(
                [{ workspace: workspace.code, user: user.id }, 'approvals', 'approval', params.id]
            );

            toastPush({
                text: intl.formatMessage(messagesToasts.approvalWithdrawn),
            });
        },
        onError: e => {
            toastPush({
                text: e?.response?.data?.message,
                color: 'red'
            });
        }
    })
};

export const getRequestedApprovalsCountQuery = params => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    return useQuery(
        [{ workspace: workspace.code, user: user.id }, 'approvals', 'requested-count', params],
        async () => (await ApprovalsService.getRequestedApprovalsCount(workspace.code, user.id, params))?.data,
        {
            // enabled: !!workspace?.code && !!user?.id,
        }
    )
};
