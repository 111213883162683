import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import useForm from '@quidlo/common/hooks/useForm2';
import validators from "@quidlo/common/utils/validators";
import { Card, Input, Label, Space, Tag } from '@quidlo/ui';

import {
    addProjectTagMutation,
    deleteProjectTagMutation, getProjectsProjectQuery,
    getProjectsProjectTagsQuery
} from "../../../../queries/projects.queries";

import style from './AddTagsForm.module.scss';

import messages from './AddTagsForm.i18n';

const FORM = {
    name: {
        value: '',
        validators: [validators.isRequired(), validators.minLength(2)]
    }
}

const AddTagsForm = ({
    projectId,
}) => {
    const { data: project } = getProjectsProjectQuery(projectId);
    const { data: projectTags } = getProjectsProjectTagsQuery(projectId);
    const { mutate: deleteProjectTag } = deleteProjectTagMutation(projectId);
    const { mutate: addProjectTag } = addProjectTagMutation(projectId);
    const [form, { isFormValid, cleanForm }] = useForm(FORM);
    const addTag = tag => {
        addProjectTag({ tag })
    };
    const removeTag = tagId => {
        deleteProjectTag({ tagId })
    };
    const tagsMap = projectTags.map(tag =>
            (
                <div className={style.tagContainer} key={tag.id}>
                    <Tag type={tag.isGlobal ? 'highlighted' : 'normal'} text={tag.name} onRemove={!project.isArchived ? () => {
                        removeTag(tag.id);
                    } : null} />
                </div>
            )
        ),
        intl = useIntl();

    const keydownHandler = useCallback(event => {
        if (
            event.keyCode === 13 && isFormValid
        ) {
            addTag({ name: form.name.value });
            cleanForm();
        }
    });

    return (
        <Card>
            <div className={style.card}>
                <Space mr="m" pb="s">
                    <div className={style.addTag}>
                        <Space mv="xxs">
                            <Label icon="tag" text={intl.formatMessage(messages.tags)} size="small" />
                        </Space>
                        <div className={style.tagSelect}>
                            <Input
                                {...form.name}
                                onKeyDown={keydownHandler}
                                placeholder={intl.formatMessage(messages.addNewTag)}
                                isRequired
                                disabled={project.isArchived}
                            />
                        </div>
                    </div>
                </Space>
                <div className={style.tagsGroup}>
                    <Space mv="xxs">
                        <Label icon="tag" text={intl.formatMessage(messages.tagsInUse)} size="small" />
                    </Space>
                    <div className={style.tags}>
                        {tagsMap}
                    </div>
                </div>
            </div>
        </Card>
    );
};

AddTagsForm.propTypes = {
    projectId: PropTypes.string.isRequired,
};

export default AddTagsForm;
