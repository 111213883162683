import React from 'react';
import { useIntl } from 'react-intl';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Autocomplete, Avatar, Button, Select, Space, Tag } from '@quidlo/ui';

import useSuggestions from '../../../../hooks/useSuggestions';
import { Suggestions } from '../../../../ui';
import ProjectPill from '../../../../ui/ProjectPill/ProjectPill';

import style from './Filters.module.scss';

import messages from './Filters.i18n';

const Filters = ({
    options,
    filters,
    confirm,
    touchFilters,
    disabled,
}) => {
    const
        intl = useIntl(),
        {
            projects: projectsSuggestions,
            tags: tagsSuggestions,
            users: usersSuggestions,
            feedSuggestions
        } = useSuggestions();

    const open = () => {
        if (filters.tags.value.length < 3) {
            feedSuggestions('tags', filters.tags.value);
        }
        if (filters.users.value.length < 3) {
            feedSuggestions('users', filters.users.value);
        }
        if (filters.projects.value.length < 3) {
            feedSuggestions('projects', filters.projects.value);
        }
        if (filters?.statuses?.value?.length < 3) {
            feedSuggestions('statuses', filters.statuses.value);
        }
        confirm();
    }

    return (
        <Space p="s" direction="row" className={style.filters}>
            <Space mr="s">
                <div className={style.select}>
                    <Autocomplete
                        { ...filters.projects }
                        label={intl.formatMessage(messages.projects)}
                        fieldId="reports-project"
                        options={options.projects.map(p => ({
                            label: <ProjectPill {...p} />,
                            labelText: p.name,
                            value: p.id
                        }))}
                        onlyselect
                        allselect
                        multiselect
                        analyticsID="Reports - Projects"
                    />
                </div>
                <div className={cx([style.suggestions, (filters.projects.error) && style.hidden])}>
                    <Suggestions
                        options={options.projects.map(o => ({
                            label: o.name,
                            value: o.id
                        }))}
                        suggestions={projectsSuggestions}
                        onClick={e => filters.projects.onChange([e])}
                        maxNumber={3}
                        analyticsID="Reports - Projects"
                    />
                </div>
            </Space>
            <Space mr="s">
                <div className={style.select}>
                    <Autocomplete
                        { ...filters.tags }
                        label={intl.formatMessage(messages.tags)}
                        fieldId="reports-tags"
                        options={options.tags.map(t => ({
                            label: <Tag text={t.name} />,
                            labelText: t.name,
                            value: t.id,
                        }))}
                        noOptionsText={
                            filters.projects.value.length
                                ? undefined
                                : intl.formatMessage(messages.pleaseSelect)
                        }
                        onlyselect
                        allselect
                        multiselect
                        analyticsID="Reports - Tags"
                    />
                </div>
                <div className={cx([style.suggestions, (filters.tags.error) && style.hidden])}>
                    <Suggestions
                        options={options.tags.map(o => ({
                            label: o.name,
                            value: o.id
                        }))}
                        suggestions={tagsSuggestions}
                        onClick={e => filters.tags.onChange([e])}
                        maxNumber={2}
                        analyticsID="Reports - Tags"
                    />
                </div>
            </Space>
            <Space mr="s">
                <div className={style.select}>
                    <Autocomplete
                        { ...filters.users }
                        label={intl.formatMessage(messages.people)}
                        fieldId="reports-users"
                        options={options.users.map(t => ({
                            label: (
                                <Tag
                                    avatar={
                                        <Avatar
                                            picture={t.avatarUrl}
                                            firstName={t.firstName}
                                            lastName={t.lastName}
                                        />
                                    }
                                    text={`${t.firstName} ${t.lastName}`}
                                />
                            ),
                            labelText: `${t.firstName} ${t.lastName}`,
                            value: t.id,
                        }))}
                        noOptionsText={
                            filters.projects.value.length
                                ? undefined
                                : intl.formatMessage(messages.pleaseSelect)
                        }
                        onlyselect
                        allselect
                        multiselect
                        analyticsID="Reports - People"
                    />
                </div>
                <div className={cx([style.suggestions, (filters.users.error) && style.hidden])}>
                    <Suggestions
                        options={options.users.map(o => ({
                            label: `${o.firstName[0]}. ${o.lastName}`,
                            value: o.id
                        }))}
                        suggestions={usersSuggestions}
                        onClick={e => filters.users.onChange([e])}
                        maxNumber={3}
                        analyticsID="Reports - People"
                    />
                </div>
            </Space>
            <Space mr="s">
                <div className={style.select}>
                    <Select
                        { ...filters.billable}
                        label={intl.formatMessage(messages.billable)}
                        options={options.billable.map(v => ({
                            label: intl.formatMessage(
                                messages[`billable${v}`],
                            ),
                            labelText: intl.formatMessage(
                                messages[`billable${v}`],
                            ),
                            value: v,
                        }))}
                        size="large"
                    />
                </div>
                <div style={{ height: 30 }} />
            </Space>
            {filters.statuses &&
                <Space mr="s">
                    <div className={style.select}>
                        <Autocomplete
                            onlyselect
                            {...filters.statuses}
                            label={intl.formatMessage(messages.status)}
                            fieldId="reports-statuses"
                            options={options.statuses.map(t => ({
                                label: t[0].toUpperCase() + t.slice(1),
                                labelText: t[0].toUpperCase() + t.slice(1),
                                value: t,
                            }))}
                            allselect
                            multiselect
                            analyticsID="Reports - Statuses"
                        />
                    </div>
                    <div style={{ height: 30 }} />
                </Space>
            }
            <span>
                <Button
                    text={intl.formatMessage(messages.apply)}
                    shadow
                    color="white"
                    size="small"
                    onClick={open}
                    onDisabledClick={touchFilters}
                    disabled={disabled}
                />
            </span>
        </Space>
    );
};

Filters.propTypes = {
    options: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    confirm: PropTypes.func.isRequired,
    touchFilters: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
}

Filters.defaultProps = {
    disabled: false
}

export default Filters;
