import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import useUI from "@quidlo/common/hooks/useUI";
import { Button, Card, Header, InfoCard, Space } from '@quidlo/ui';

import {
    addWorkspaceAPIKeyMutation,
    deleteWorkspaceAPIKeyMutation,
    getWorkspaceAPIKeysQuery
} from "../../../queries/workspace.queries";
import { Container, List, ListActions, ListCell } from '../../../ui';
import ViewHOC from '../../../ui/View/View';
import CreateNewApiKey from '../modals/CreateNewApiKey';

import YouDontHavePNG from './assets/YouDontHave.png';

import style from './ApiKeys.module.scss';

import messages from './ApiKeys.i18n';

const ApiKeys = () => {
    const
        intl = useIntl(),
        { modalOpen } = useUI(),
        { data: apiKeys, isFetching } = getWorkspaceAPIKeysQuery(),
        { mutate: deleteApiKey } = deleteWorkspaceAPIKeyMutation(),
        postAPIKey = addWorkspaceAPIKeyMutation();

    const createApiKey = async () => {
        await postAPIKey.mutate();
    }

    useEffect(() => {
        if (postAPIKey.isSuccess && postAPIKey.data) {
            modalOpen('createNewApiKey', postAPIKey.data)
        }
    }, [postAPIKey.isSuccess, postAPIKey.data])

    return (
        <Container>
            <Header
                title={intl.formatMessage(messages.apiKeys)}
                size="large"
                underline
                goBack
                rightChildren={(
                    <Button
                        shadow
                        size="small"
                        color="blue"
                        onClick={createApiKey}
                        text={intl.formatMessage(messages.createNewApiKey)}
                    />
                )}
            />
            <Space pv="m">
                {(!isFetching && apiKeys?.length) ? (
                    <Card title={intl.formatMessage(messages.listHeader)} noPadding>
                        <List
                            icon=""
                            data={apiKeys.map(key => ({ ...key, id: key.name }))}
                            autostatus={false}
                            noListShadow
                            renderRow={({
                                name
                            }) => (
                                <>
                                    <ListCell align="left">
                                        <div>{name}</div>
                                    </ListCell>
                                    <ListActions
                                        actions={[
                                            {
                                                title: 'Delete',
                                                icon: 'trash',
                                                onClick: () => {
                                                    deleteApiKey(name);
                                                }
                                            },
                                        ]}
                                    />
                                </>
                            )}
                        />
                    </Card>
                ) : (
                    <div className={style.infoContainer}>
                        <InfoCard
                            illustration={<img src={YouDontHavePNG} alt={'laptop'} />}
                            header={intl.formatMessage(messages.youDontHave)}
                            text={intl.formatMessage(messages.apiKeysWillBeListed)}
                        />
                    </div>
                )}
            </Space>
            <CreateNewApiKey />
        </Container>
    );
};

export default ViewHOC((ApiKeys),
    {
        title: 'API Keys',
        menu: true,
        topbar: true,
        auth: {},
        subscription: true
    }
);

