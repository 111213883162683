import React from 'react';
import { useIntl } from 'react-intl';
import cx from "classnames";

import { APPROVAL_STATUSES, FEATURE } from "@quidlo/common/constants/main";
import useForm from '@quidlo/common/hooks/useForm2';
import useWorkspace from '@quidlo/common/hooks/useWorkspace';
import analytics from "@quidlo/common/utils/analytics";
import validators from "@quidlo/common/utils/validators";
import { Autocomplete, Button, Space, Spinner } from '@quidlo/ui';

import useSuggestions from '../../../hooks/useSuggestions';
import { getApprovalsQuery } from '../../../queries/approvals.queries';
import { getProjectsListQuery } from '../../../queries/projects.queries';
import { DateRangePicker, Suggestions } from '../../../ui';
import ProjectPill from "../../../ui/ProjectPill/ProjectPill";
import ViewHOC from '../../../ui/View/View';
import { dateToString, setWeekday } from '../../../utils/dateHelpers';

import MyRequestsList from './MyRequestsList/MyRequestsList';

import style from './MyRequests.module.scss';

import messages from './MyRequests.i18n';

const MyRequests = () => {
    const
        intl = useIntl(),
        { workspace } = useWorkspace(),
        [form, { isFormDirty, isFormValid, touchForm }] = useForm({
            date: {
                value: {
                    gte: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
                    lte: setWeekday(new Date(), 6, workspace?.weekStart),
                }
            },
            projects: {
                value: [],
                validators: [validators.isRequired()],
            },
            statuses: {
                value: APPROVAL_STATUSES,
                validators: [validators.isRequired()]
            }
        },
        {
            id: 'my-requests.form',
            persistent: true
        }),
        [filters, { formValue: filtersValue }] = useForm({
            date: {
                value: {
                    gte: dateToString(new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)),
                    lte: dateToString(setWeekday(new Date(), 6, workspace?.weekStart)),
                }
            },
            projects: {
                value: [],
            },
            statuses: {
                value: APPROVAL_STATUSES,
            }
        },  {
            id: 'my-requests.filters',
            persistent: true
        }),
        { data, isFetched, isFetching } = getApprovalsQuery(filtersValue),
        { data: projectsOptions } = getProjectsListQuery(),
        {
            projects: projectsSuggestions,
            statuses: statusesSuggestions,
            feedSuggestions
        } = useSuggestions(),
        apply = (
            date= {
                gte: dateToString(form.date.value.gte),
                lte: dateToString(form.date.value.lte)
            },
            projects = form.projects.value,
            statuses = form.statuses.value
        ) => {
            filters.date.setValue({
                gte: date.gte,
                lte: date.lte,
            });
            filters.projects.setValue(projects);
            filters.statuses.setValue(statuses);
            feedSuggestions('projects', projects)
            feedSuggestions('statuses', statuses)
            analytics.logTimesheetsFilters({
                screenName: "Approvals - My Requests",
                date: {
                    gte: new Date(date.gte),
                    lte: new Date(date.lte)
                },
                projectsNo: projects.length,
                statusesNo: statuses.length
            })
        };

    return (
        <>
            <Space mt="m" direction="row">
                <DateRangePicker
                    date={form.date.value}
                    onChange={form.date.onChange}
                    analyticsID="Approvals - My Requests"
                />
            </Space>
            <Space mt="m" p="s" direction="row" className={style.filters}>
                <div className={style.leftSide}>
                    <div className={style.select}>
                        <Autocomplete
                            {...form.projects}
                            label={intl.formatMessage(messages.projects)}
                            multiselect
                            allselect
                            options={projectsOptions.map(p => ({
                                label: <ProjectPill {...p} />,
                                labelText: p.name,
                                value: p.id,
                            }))}
                            size="large"
                            onlyselect
                            analyticsID="Approvals - My Requests - Projects"
                        />
                        <div className={cx([style.suggestions, (form.projects.error) && style.hidden])}>
                            <Suggestions
                                options={projectsOptions.map(o => ({
                                    label: o.name,
                                    value: o.id
                                }))}
                                suggestions={projectsSuggestions}
                                onClick={e => form.projects.onChange([e])}
                                maxNumber={3}
                                analyticsID="Approvals - My Requests - Projects"
                            />
                        </div>
                    </div>
                    <div className={style.select}>
                        <Autocomplete
                            {...form.statuses}
                            label={intl.formatMessage(messages.status)}
                            multiselect
                            allselect
                            options={APPROVAL_STATUSES.map(t => ({
                                label: intl.formatMessage(messages[t]),
                                labelText: intl.formatMessage(messages[t]),
                                value: t,
                            }))}
                            size="large"
                            onlyselect
                            analyticsID="Approvals - My Requests - Statuses"
                        />
                        <div className={cx([style.suggestions, (form.statuses.error) && style.hidden])}>
                            <Suggestions
                                options={APPROVAL_STATUSES.map(t => ({
                                    label: intl.formatMessage(messages[t]),
                                    value: t
                                }))}
                                suggestions={statusesSuggestions}
                                onClick={e => form.statuses.onChange([e])}
                                maxNumber={3}
                                analyticsID="Approvals - My Requests - Statuses"
                            />
                        </div>
                    </div>
                </div>
                <Button
                    size={'small'}
                    color={'white'}
                    onClick={() => apply()}
                    onDisabledClick={touchForm}
                    disabled={!isFormDirty || !isFormValid}
                    text={intl.formatMessage(messages.apply)}
                    shadow
                />
            </Space>
            <Space mt="m">
                {
                    (!isFetched && isFetching) ? (
                        <Space p="l">
                            <Spinner active />
                        </Space>
                    ) : (
                        <MyRequestsList filters={filters} data={data} />
                    )
                }
            </Space>
        </>
    );
};

export default ViewHOC((MyRequests),
    {
        title: 'Approvals - My Requests',
        menu: true,
        topbar: true,
        auth: {},
        subscription: true,
        feature: FEATURE.APPROVALS
    }
);

