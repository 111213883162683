import { defineMessages } from 'react-intl';

export default defineMessages({
    users: {
        id: 'users.users',
        defaultMessage: 'Users ({total})'
    },
    name: {
        id: 'users.name',
        defaultMessage: 'Name'
    },
    email: {
        id: 'users.email',
        defaultMessage: 'Email'
    },
    projects: {
        id: 'users.projects',
        defaultMessage: 'Projects'
    },
    role: {
        id: 'users.role',
        defaultMessage: 'Role'
    },
    owner: {
        id: 'users.role.owner',
        defaultMessage: 'Owner'
    },
    admin: {
        id: 'users.role.admin',
        defaultMessage: 'Administrator'
    },
    employee: {
        id: 'users.role.employee',
        defaultMessage: 'Employee'
    },
    loadMore: {
        id: 'users.loadMore',
        defaultMessage: 'Load more'
    },
    toastRoleEdited: {
        id: 'users.toast.roleEdited',
        defaultMessage: 'User role edited'
    },
    noProjects: {
        id: 'users.toast.noProjects',
        defaultMessage: 'No projects'
    },
    invitationHasBeenSent: {
        id: 'users.toast.invitationHasBeenSent',
        defaultMessage: 'Invitation has been sent to {email}'
    },
    toastUserRemoved: {
        id: 'users.toast.toastUserRemoved',
        defaultMessage: 'User removed'
    },
    pending: {
        id: 'users.pending',
        defaultMessage: 'pending'
    },
    resendInvitation: {
        id: 'users.resendInvitation',
        defaultMessage: 'Resend Invitation'
    },
    inviteUsers: {
        id: 'users.inviteUsers',
        defaultMessage: 'Invite users'
    },
    deleteUser: {
        id: 'users.deleteUser',
        defaultMessage: 'Delete user'
    },
    searchPlaceholder: {
        id: 'users.searchPlaceholder',
        defaultMessage: 'Who are you looking for?'
    }
});
