import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import AutotrackerService from "../../api/AutotrackerService";
import useAuthentication from "../../hooks/useAuthentication";
import useWorkspace from "../../hooks/useWorkspace";

export const AutoTrackerContext = React.createContext({});

const AutoTrackerProvider = ({ children }) => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    const [task, setTask] = useState(null);
    const tickRef = useRef();
    const faviconTickRef = useRef();

    const tick = () => {
        setTask(task => task ? ({
            ...task,
            duration: task.duration + 1
        }) : null);
        tickRef.ref = setTimeout(tick, 60000);
    }

    const tickFavicon = () => {
        const favicon = document.getElementById('favicon');
        if (favicon.href.includes('red_circle')) {
            document.getElementById('favicon').href = `/img/fav_196_${process.env.REACT_APP_INSTANCE_NAME}.png`;
        } else {
            document.getElementById('favicon').href = `/img/fav_196_red_circle.png`;
        }
    }

    const restoreTask = async () => {
        const restoredTask = (await AutotrackerService.restore(workspace.code, user.id))?.data;
        if (restoredTask?.id) {

            const serverTime = new Date(restoredTask.serverTime).getTime()
            const creationTime = Math.floor(new Date(restoredTask.creationTime).getTime() / 1000) * 1000;

            const duration = (serverTime - creationTime) / 60000;
            const milisecondsToTick = 60000 - ((serverTime - creationTime) % 60000);

            tickRef.current = setTimeout(tick, milisecondsToTick)
            faviconTickRef.current = setInterval(tickFavicon, 1000);

            setTask({
                ...restoredTask,
                duration
            });
        } else {
            setTask(null)
        }
    }

    useEffect(() => {
        if (workspace && user) {
            restoreTask();
        }
    }, [workspace, user])

    useEffect(() => {
        if (!task) {
            if (tickRef.current) {
                clearTimeout(tickRef.current);
            }
            if (faviconTickRef.current) {
                clearInterval(faviconTickRef.current);
                document.getElementById('favicon').href = `/img/fav_196_${process.env.REACT_APP_INSTANCE_NAME}.png`;
            }
        }
    }, [task])

    const contextValue = useMemo(() => {
        return {
            task,
            restoreTask,
            setTask
        };
    }, [task, workspace, user]);

    return (
        <AutoTrackerContext.Provider value={contextValue}>
            {children}
        </AutoTrackerContext.Provider>
    );
};

AutoTrackerProvider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

export default AutoTrackerProvider;
