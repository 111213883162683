import { useIntl } from "react-intl";
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import ProjectsService from "@quidlo/common/api/ProjectsService";
import useAuthentication from "@quidlo/common/hooks/useAuthentication";
import useUI from "@quidlo/common/hooks/useUI";
import useWorkspace from "@quidlo/common/hooks/useWorkspace";

import { messagesToasts } from "../messages";

export const getManagedProjectsListQuery = () => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    return useQuery(
        [
            { workspace: workspace?.code, user: user.id },
            'managed-projects', 'list-user',
        ],
        async () => (await ProjectsService.getAllManaged(workspace.code, user.id, { limit: 0 }))?.data,
        {
            // enabled: !!workspace?.code && !!user?.id,
            initialData: []
        })
};

export const getProjectsListQuery = () => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    return useQuery(
        [
            { workspace: workspace?.code, user: user.id },
            'projects', 'list-user',
        ],
        async () => (await ProjectsService.getAll(workspace.code, user.id, { limit: 0 }))?.data,
        {
            // enabled: !!workspace?.code && !!user?.id,
            initialData: []
        })
};

export const getProjectsListAllInfiniteQuery = filters => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    return useInfiniteQuery(
        [
            { workspace: workspace.code, user: user.id },
            'projects', 'list-all', filters
        ],
        async ({ pageParam = 0 }) => (await ProjectsService.getAdministrableAll(user.id, workspace.code, { limit: 15, offset: pageParam, ...filters }))?.data,
        {
            getNextPageParam: (lastPage, pages) => {
                if (lastPage.length < 15) return null;
                return pages.length * 15
            },
            // enabled: !!workspace?.code && !!user?.id,
            initialData: []
        })
};

export const getProjectsProjectQuery = id => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    return useQuery(
        [
            { workspace: workspace.code, user: user.id },
            'projects', 'project', id
        ],
        async () => (await ProjectsService.get(workspace.code, user.id, id ))?.data,
        {
            // enabled: !!workspace?.code && !!user?.id && !!id,
            enabled: !!id,
            keepPreviousData: true,
            initialData: {
                id: Number(id),
                color: undefined,
                name: undefined,
                freezeDate: undefined,
                projectTags: [],
                isArchived: false,
                isBillable: false
            }
        },
    )
};

export const getProjectsProjectTagsQuery = id => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    return useQuery(
        [
            { workspace: workspace.code, user: user.id },
            'projects', 'project', id, 'tags'
        ],
        async () => (await ProjectsService.getProjectTags(workspace.code, user.id, id))?.data,
        {
            // enabled: !!workspace?.code && !!user?.id && !!id,
            enabled: !!id,
            initialData: []
        }
    );
};

export const addProjectMutation = () => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    const queryClient = useQueryClient();
    const { toastPush } = useUI();
    const intl = useIntl();
    return useMutation(async project => (await ProjectsService.post(workspace.code, project)), {
        onSuccess: () => {
            toastPush({
                text: intl.formatMessage(messagesToasts.projectCreated)
            });
            queryClient.invalidateQueries(
                { workspace: workspace.code, user: user.id },
                'projects', 'list-all',
            )
        },
        onError: e => {
            toastPush({
                text: e?.response?.data?.message || intl.formatMessage(messagesToasts.unexpectedError),
                color: 'red'
            });
        }
    })};

export const editProjectMutation = projectId => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    const queryClient = useQueryClient();
    const { toastPush } = useUI();
    const intl = useIntl();
    return useMutation(async ( { id, delta }) => (await ProjectsService.patch(workspace.code, user.id, id, delta)), {
        onSuccess: (a,b) => {
            if (typeof b?.delta?.isArchived !== 'undefined') {
                toastPush({
                    text: intl.formatMessage(b?.delta?.isArchived ? messagesToasts.projectArchived : messagesToasts.projectActivated)
                });
            } else {
                toastPush({
                    text: intl.formatMessage(messagesToasts.projectEdited)
                });
            }
            queryClient.invalidateQueries([
                { workspace: workspace.code, user: user.id },
                'projects', 'list-all'
            ])
            queryClient.invalidateQueries([
                { workspace: workspace.code, user: user.id },
                'projects', 'project', projectId
            ])
        },
        onError: e => {
            toastPush({
                text: e?.response?.data?.message || intl.formatMessage(messagesToasts.unexpectedError),
                color: 'red'
            });
        }
    })};

export const deleteProjectMutation = () => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    const queryClient = useQueryClient();
    const { toastPush } = useUI();
    const intl = useIntl();
    return useMutation(async projectId => (await ProjectsService.delete(workspace.code, user.id, projectId)), {
        onSuccess: () => {
            toastPush({
                text: intl.formatMessage(messagesToasts.projectDeleted)
            });
            queryClient.invalidateQueries(
                { workspace: workspace.code, user: user.id },
                'projects', 'list-all'
            );
        },
        onError: e => {
            toastPush({
                text: e?.response?.data?.message || intl.formatMessage(messagesToasts.unexpectedError),
                color: 'red'
            });
        }
    })};

export const addProjectTagMutation = projectId => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    const queryClient = useQueryClient();
    const { toastPush } = useUI();
    const intl = useIntl();

    return useMutation(({ tag }) => ProjectsService.postProjectTag(workspace.code, user.id, projectId, tag), {
        onSuccess: () => {
            toastPush({
                text: intl.formatMessage(messagesToasts.tagAddedToProject)
            });
            setTimeout(() => {
                queryClient.invalidateQueries([
                    { workspace: workspace.code, user: user.id },
                    'projects', 'project', projectId, 'tags'
                ])
            }, 1000)
        },
        onError: e => {
            toastPush({
                text: e?.response?.data?.message || intl.formatMessage(messagesToasts.unexpectedError),
                color: 'red'
            });
        }
    })
}

export const deleteProjectTagMutation = projectId => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    const queryClient = useQueryClient();
    const { toastPush } = useUI();
    const intl = useIntl();

    return useMutation(({ tagId }) => ProjectsService.deleteProjectTag(workspace.code, user.id, projectId, tagId), {
        onSuccess: () => {
            toastPush({
                text: intl.formatMessage(messagesToasts.tagDeletedFromProject)
            });
            queryClient.invalidateQueries([
                { workspace: workspace.code, user: user.id },
                'projects', 'project', projectId, 'tags'
            ])
        },
        onError: e => {
            toastPush({
                text: e?.response?.data?.message || intl.formatMessage(messagesToasts.unexpectedError),
                color: 'red'
            });
        }
    })
}

export const getProjectsProjectUsersQuery = projectId => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    return useQuery(
        [
            { workspace: workspace.code, user: user.id },
            'projects', 'project', projectId, 'users'
        ],
        async () => (await ProjectsService.getProjectUsers(workspace.code, user.id, projectId))?.data,
        {
            // enabled: !!workspace?.code && !!user?.id && !!projectId,
            enabled: !!projectId,
            initialData: []
        })
};

export const addProjectUserMutation = projectId => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    const queryClient = useQueryClient();
    const { toastPush } = useUI();
    const intl = useIntl();

    return useMutation(async delta => (await ProjectsService.postProjectUser(workspace.code, user.id, projectId, delta)), {
        onSuccess: () => {
            toastPush({
                text: intl.formatMessage(messagesToasts.userAddedToProject)
            });
            queryClient.invalidateQueries([
                { workspace: workspace.code, user: user.id },
                'projects', 'project', projectId, 'users'
            ])
        },
        onError: e => {
            toastPush({
                text: e?.response?.data?.message || intl.formatMessage(messagesToasts.unexpectedError),
                color: 'red'
            });
        }
    })};

export const editProjectUserMutation = projectId => {
    const { user, fetchUser } = useAuthentication();
    const { workspace } = useWorkspace();
    const queryClient = useQueryClient();
    const { toastPush } = useUI();
    const intl = useIntl();

    return useMutation(async ({ userId, delta }) => (await ProjectsService.patchProjectUser(workspace.code, user.id, projectId, userId, delta)), {
        onSuccess: () => {
            toastPush({
                text: intl.formatMessage(messagesToasts.userProjectRoleChanged)
            });
            queryClient.invalidateQueries([
                { workspace: workspace.code, user: user.id },
                'projects', 'project', projectId, 'users'
            ]);
            fetchUser();
        },
        onError: e => {
            toastPush({
                text: e?.response?.data?.message || intl.formatMessage(messagesToasts.unexpectedError),
                color: 'red'
            });
        }
    })};

export const deleteProjectUserMutation = projectId => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    const queryClient = useQueryClient();
    const { toastPush } = useUI();
    const intl = useIntl();

    return useMutation(async ({ userId }) => (await ProjectsService.deleteProjectUser(workspace.code, user.id, projectId, userId)), {
        onSuccess: () => {
            toastPush({
                text: intl.formatMessage(messagesToasts.userDeletedFromProject)
            });
            queryClient.invalidateQueries([
                { workspace: workspace.code, user: user.id },
                'projects', 'project', projectId, 'users'
            ])
        },
        onError: e => {
            toastPush({
                text: e?.response?.data?.message || intl.formatMessage(messagesToasts.unexpectedError),
                color: 'red'
            });
        }
    })};
