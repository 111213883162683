import React from 'react';
import { useIntl } from 'react-intl';

import useUI from "@quidlo/common/hooks/useUI";
import useWorkspace from "@quidlo/common/hooks/useWorkspace";
import { Button, Space } from "@quidlo/ui";

import illustration from "../../../../../../assets/modals/trash.png";
import ModalHOC, { ModalActions, ModalContent, ModalHeader } from "../../../../../../ui/Modal/Modal";

import messages from "./DeleteWorkspace.i18n";

const DeleteWorkspace = () => {
    const intl = useIntl();
    const { modalClose } = useUI();
    const { workspace, deleteWorkspace } = useWorkspace();

    const deleteW = async () =>  {
        await deleteWorkspace(workspace.code);
        modalClose();
    }

    return (
        <>
            <ModalHeader>
                {intl.formatMessage(messages.title)}
            </ModalHeader>
            <ModalContent>
                {intl.formatMessage(messages.subtitle)}
            </ModalContent>
            <ModalActions>
                <Space mr="m">
                    <Button
                        onClick={deleteW}
                        text={intl.formatMessage(messages.delete)}
                        color="white"
                    />
                </Space>
                <Button
                    onClick={modalClose}
                    text={intl.formatMessage(messages.cancel)}
                    shadow
                    color="blue"
                />
            </ModalActions>
        </>
    )
}

export default ModalHOC(
    DeleteWorkspace,
    {
        id: 'deleteWorkspace',
        size: 'small',
        illustration,
    }
);
