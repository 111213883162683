import { defineMessages } from 'react-intl';

export default defineMessages({
    globalTags: {
        id: 'settings.globalTags',
        defaultMessage: 'Global tags',
    },
    createTag: {
        id: 'settings.createTag',
        defaultMessage: 'Create tag',
    },
});