import React, { useMemo, useRef } from 'react';
import Dropzone from "react-dropzone";
import { FormattedMessage, useIntl } from 'react-intl';

import useForm from "@quidlo/common/hooks/useForm2";
import useUI from "@quidlo/common/hooks/useUI";
import useWorkspace from "@quidlo/common/hooks/useWorkspace";
import validators from "@quidlo/common/utils/validators";
import { Button, Card, Input, Select, Space } from "@quidlo/ui";

import { messagesToasts } from "../../../../messages";

import style from "./WorkspaceSettings.module.scss";

import messages from './WorkspaceSettings.i18n';

const avatarValidationRules = {
        MAX_SIZE: 800000,
        MIN_SIZE: 10,
        TYPES: ['image/jpg', 'image/jpeg', 'image/png']
    },
    WorkspaceSettings = () => {
        const
            intl = useIntl(),
            dropzoneRef = useRef(),
            { workspace, patchWorkspace, patchWorkspaceAvatar } = useWorkspace(),
            { toastPush } = useUI(),
            [form, { isFormValid, isFormDirty, touchForm }] = useForm(useMemo(() => (
                {
                    name: {
                        value: workspace.name,
                        validators: [validators.isRequired()]
                    },
                    avatar: {
                        value: {
                            preview: workspace.logo
                        }
                    },
                    weekStart: {
                        value: workspace.weekStart
                    }
                }), [workspace])),

            saveWorkspaceForm = () => {
                const { name, avatar, weekStart } = form;
                if ((name.value && (workspace.name !== name.value)) || (weekStart.value && (workspace.weekStart !== weekStart.value))) {
                    patchWorkspace({
                        name: name.value,
                        weekStart: weekStart.value
                    }, intl);
                }
                if (avatar.value && avatar.value.name) {
                    patchWorkspaceAvatar(avatar.value);
                }
            },
            fileUpload = ([file], [rejected]) => {
                if (rejected) {
                    if (rejected.size > avatarValidationRules.MAX_SIZE) {
                        toastPush({
                            text: intl.formatMessage(messages.maxSizeError),
                            color: 'red'
                        });
                    } else if (rejected.size < avatarValidationRules.MIN_SIZE) {
                        toastPush({
                            text: intl.formatMessage(messages.minSizeError),
                            color: 'red'
                        });
                    } else if (!avatarValidationRules.TYPES.find(type => rejected.type === type)) {
                        toastPush({
                            text: intl.formatMessage(messages.wrongTypeError, { types: avatarValidationRules.TYPES.join(', ') }),
                            color: 'red'
                        });
                    } else {
                        toastPush({
                            text: intl.formatMessage(messagesToasts.unexpectedError),
                            color: 'red'
                        });
                    }
                } else {
                    form.avatar.onChange(file)
                }
            };

        return (
            <Card
                title={intl.formatMessage(messages.basicInformation)}
            >
                <Space className={style.workspaceSettings} direction="column">
                    <Space direction="row" mb="m" >
                        <Space pr="m" className={style.upload}>
                            <Space direction="row">
                                <Space mr="m" className={style.dropzone}>
                                    <Dropzone
                                        className={style.avatar}
                                        activeClassName={style.active}
                                        ref={dropzoneRef}
                                        onDrop={fileUpload}
                                        style={{
                                            background: `url(${form.avatar.value.preview})`,
                                        }}
                                        accept={avatarValidationRules.TYPES}
                                        multiple={false}
                                        maxSize={avatarValidationRules.MAX_SIZE}
                                        minSize={avatarValidationRules.MIN_SIZE}
                                    />
                                </Space>
                                <div className={style.avatarText}>
                                    <p>
                                        <FormattedMessage {...messages.uploadYour} />
                                    </p>
                                    <p className={style.small}>
                                        <FormattedMessage {...messages.minSize} />
                                    </p>
                                    <Button
                                        type="bordered"
                                        color="white"
                                        onClick={() => {
                                            dropzoneRef.current.open();
                                        }}
                                        text="Upload"
                                    />
                                </div>
                            </Space>
                        </Space>
                        <Space pr="m" className={style.name}>
                            <div className={style.input}>
                                <Input
                                    {...form.name}
                                    type="text"
                                    label={intl.formatMessage(messages.typename)}
                                    isRequired
                                />
                            </div>
                        </Space>
                        <div className={style.week}>
                            <div className={style.input}>
                                <Select
                                    { ...form.weekStart }
                                    label={intl.formatMessage(messages.weekStart)}
                                    options={[{
                                        label: intl.formatMessage(messages.monday),
                                        labelText: intl.formatMessage(messages.monday),
                                        value: 'monday'
                                    }, {
                                        label: intl.formatMessage(messages.sunday),
                                        labelText: intl.formatMessage(messages.sunday),
                                        value: 'sunday'
                                    }]}
                                    size="large"
                                />
                            </div>
                        </div>
                    </Space>
                    <Space direction="row" className={style.button}>
                        <Button
                            type="bordered"
                            color="blue"
                            onClick={saveWorkspaceForm}
                            onDisabledClick={touchForm}
                            disabled={!isFormValid || !isFormDirty}
                            text={intl.formatMessage(messages.save)}
                        />
                    </Space>
                </Space>
            </Card>
        );
    };

export default WorkspaceSettings;
