import React from 'react';
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { PROJECT_ROLES } from '@quidlo/common/constants/main';
import useUI from "@quidlo/common/hooks/useUI";
import { Avatar, Select } from "@quidlo/ui";

import { editProjectUserMutation, getProjectsProjectQuery } from "../../../../queries/projects.queries";
import { resendInvitationMutation } from "../../../../queries/users.queries";
import { ListActions, ListCell } from "../../../../ui";

import style from "./UsersList.module.scss";

import messages from "./UsersList.i18n";

const UserRow = ({ user, status, projectId }) => {
    const intl = useIntl();
    const { modalOpen } = useUI();
    const { data: project } = getProjectsProjectQuery(projectId);

    const { mutate: editUser } = editProjectUserMutation(projectId);
    const { mutate: resendInvitation } = resendInvitationMutation();
    const editUserRole = (userId, val) => {
        editUser({ userId, delta: { projectRole: val } })
    };

    return (
        <>
            <ListCell>
                {user.firstName && user.lastName ? (
                    <div className={style.credentialsAndAvatar}>
                        <Avatar
                            firstName={user.firstName}
                            lastName={user.lastName}
                            picture={user.avatarUrl}
                        />
                        <span className={style.credentials}>
                            {user.firstName} {user.lastName}
                        </span>
                    </div>
                ) : (
                    <div className={style.pending}>
                        {intl.formatMessage(messages.pending)}
                    </div>
                )}
            </ListCell>
            <ListCell width="400px">{user.email}</ListCell>
            <ListCell width="250px">
                <div className={style.roleSelect}>
                    {status === 'edit' ? (
                        <Select
                            options={PROJECT_ROLES.map(
                                rl => ({
                                    label: intl.formatMessage(
                                        messages[`${rl}Role`],
                                    ),
                                    labelText:
                                        intl.formatMessage(
                                            messages[
                                                `${rl}Role`
                                            ],
                                        ),
                                    value: rl,
                                }),
                            )}
                            value={user.projectRole}
                            onChange={val => {
                                if (user.projectRole !== val)
                                    editUserRole(
                                        user.userId,
                                        val,
                                    );
                            }}
                        />
                    ) : (
                        intl.formatMessage(
                            messages[`${user.projectRole}Role`],
                        )
                    )}
                </div>
            </ListCell>
            {
                !project.isArchived && (
                    <ListActions
                        actions={
                            user.firstName && user.lastName
                                ? [
                                    {
                                        title: intl.formatMessage(
                                            messages.delete,
                                        ),
                                        icon: 'trash',
                                        onClick: () => {
                                            modalOpen(
                                                'projectRemoveUser',
                                                { projectId, userId: user.userId },
                                            );
                                        },
                                    },
                                ]
                                : [
                                    {
                                        title: intl.formatMessage(
                                            messages.delete,
                                        ),
                                        icon: 'trash',
                                        onClick: () => {
                                            modalOpen(
                                                'projectRemoveUser',
                                                { projectId, userId: user.userId },
                                            );
                                        },
                                    },
                                    {
                                        title: intl.formatMessage(
                                            messages.resendInvitation,
                                        ),
                                        icon: 'resend',
                                        onClick: () => {
                                            resendInvitation(
                                                user.userId,
                                            );
                                        },
                                    },
                                ]
                        }
                    />
                )
            }
        </>
    )
};

UserRow.propTypes = {
    user: PropTypes.object.isRequired,
    status: PropTypes.string.isRequired,
    projectId: PropTypes.string.isRequired,
};

UserRow.defaultProps = {
};

export default UserRow;
