import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Outlet } from 'react-router-dom';

import { WORKSPACE_ROLE } from "@quidlo/common/constants/main";
import useWorkspace from "@quidlo/common/hooks/useWorkspace";
import { Header } from '@quidlo/ui';

import { Container } from '../../ui';
import ViewHOC from '../../ui/View/View';

import messages from './Settings.i18n';

const Settings = () => {
    const
        intl = useIntl(),
        { workspace } = useWorkspace();

    const tabs = useMemo(() => {
        const t = [
            {
                to: '/settings/workspace',
                label: `${intl.formatMessage(messages.workspace)}`,
            },
            {
                to: '/settings/features',
                label: intl.formatMessage(messages.features),
            }
        ]
        if (workspace?.organizationRole === WORKSPACE_ROLE.OWNER) {
            t.push({
                to: '/settings/subscription',
                label: `${intl.formatMessage(messages.subscription)}`,
            })
        }
        return t;
    }, [workspace])

    return (
        <Container>
            <Header
                title={intl.formatMessage(messages.settings)}
                icon="settings"
                size="large"
                tabs={tabs}
                underline
            />
            <Outlet />
        </Container>
    );
};

export default ViewHOC((Settings),
    {
        title: 'Settings',
        menu: true,
        topbar: true,
        auth: {}
    }
);

