import { defineMessages } from 'react-intl';

export default defineMessages({
    minSizeError: {
        id: 'personalContainer.minSizeError',
        defaultMessage: 'Min. weight should be 0,01KB',
    },
    maxSizeError: {
        id: 'personalContainer.maxSizeError',
        defaultMessage: 'Max. weight should be 800KB',
    },
    wrongTypeError: {
        id: 'personalContainer.wrongTypeError',
        defaultMessage: 'You can upload file with one of types: {types}',
    },
    unexpectedError: {
        id: 'personalContainer.unexpectedError',
        defaultMessage: 'Unexpected error',
    },
    userEdited: {
        id: 'personalContainer.userEdited',
        defaultMessage: 'Changes saved',
    },
    personalInfo: {
        id: 'profile.personal.personalInfo',
        defaultMessage: 'Personal information',
    },
    firstName: {
        id: 'profile.personal.firstName',
        defaultMessage: 'First name',
    },
    lastName: {
        id: 'profile.personal.lastName',
        defaultMessage: 'Surname',
    },
    email: {
        id: 'profile.personal.email',
        defaultMessage: 'E-mail',
    },
    password: {
        id: 'profile.personal.password',
        defaultMessage: 'Password',
    },
    timeZone: {
        id: 'profile.personal.timeZone',
        defaultMessage: 'Time zone',
    },
    repeatedPassword: {
        id: 'profile.personal.repeatedPassword',
        defaultMessage: 'Repeat password',
    },
    newPassword: {
        id: 'profile.personal.newPassword',
        defaultMessage: 'New password',
    },
    passwordsDoNotMatch: {
        id: 'profile.personal.passwordsDoNotMatch',
        defaultMessage: 'Passwords do not match',
    },
    workspaceSettings: {
        id: 'profile.personal.workspaceSettings',
        defaultMessage: 'Workspace settings',
    },
    uploadYour: {
        id: 'profile.personal.uploadYour',
        defaultMessage: 'Upload your avatar',
    },
    minSize: {
        id: 'profile.personal.minSize',
        defaultMessage: 'Min. size 150px x 150px, max. weight 800KB',
    },
    typename: {
        id: 'profile.personal.typename',
        defaultMessage: 'Workspace name',
    },
    save: {
        id: 'profile.personal.save',
        defaultMessage: 'Save',
    },
    changePassword: {
        id: 'profile.personal.changePassword',
        defaultMessage: 'Change password',
    },
    nodigit: {
        id: 'profile.personal.nodigit',
        defaultMessage: 'Value contains digit',
    },
    name: {
        id: 'profile.name',
        defaultMessage: 'The length of first name must be between 2 and 32',
    },
    surname: {
        id: 'profile.surname',
        defaultMessage: 'The length of surname name must be between 2 and 32',
    }
});