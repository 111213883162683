export const filterStatuses = statuses => {
    const orderOfStatuses = ['logged', 'pending', 'accepted']
    const statusesInPeriods = {};

    if (statuses?.length) {
        statuses.forEach(status => {
            if(status) {
                statusesInPeriods[status] = true;
            }
        })
    }

    return orderOfStatuses.filter(s => statusesInPeriods[s])
}