import { defineMessages } from 'react-intl';

export default defineMessages({
    alertMsg: {
        id: 'projectsList.alertMsg',
        defaultMessage: 'Only Project Managers and Admin are able to configure Hangouts Room'
    },
    projects: {
        id: 'projectsList.projects',
        defaultMessage: 'Projects'
    },
    intents: {
        id: 'projectsList.intents',
        defaultMessage: 'Intents'
    },
    infocardHeader: {
        id: 'projectsList.infocardHeader',
        defaultMessage: 'Select projects and intents'
    },
    header: {
        id: 'projectsList.header',
        defaultMessage: 'Configure Google Chatbot'
    },
});
