import { defineMessages } from 'react-intl';

export default defineMessages({
    deleteTask: {
        id: 'deleteTaskDialog.deleteTask',
        defaultMessage: 'Delete task',
    },
    deleteTaskMsg: {
        id: 'deleteTaskDialog.deleteTaskMsg',
        defaultMessage: 'This task has been already reported. Are you sure you want to delete it?',
    },
    save: {
        id: 'deleteTaskDialog.delete',
        defaultMessage: 'Delete',
    },
    cancel: {
        id: 'deleteTaskDialog.project',
        defaultMessage: 'Cancel',
    }
});