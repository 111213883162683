import React, { useEffect, useRef, useState } from 'react';
import { FormattedDate } from "react-intl";
import cx from "classnames";
import PropTypes from 'prop-types';

import useWorkspace from "@quidlo/common/hooks/useWorkspace";
import analytics from "@quidlo/common/utils/analytics";
import { durationFormatter } from "@quidlo/common/utils/duration";
import { Pill } from "@quidlo/ui";

import { getTrackerSummaryQuery } from "../../../queries/tracker.queries";
import { addDays, dateToString, daysArray } from '../../../utils/dateHelpers';

import styles from "./TimeBar.module.scss";

const TimeBar = ({
    dateStart,
    dateEnd,
    date,
    setDate,
    nextWeek,
    prevWeek,
    mode
}) => {
    const { workspace: { weekStart } } = useWorkspace(),
        { data: worklog } = getTrackerSummaryQuery({ startDate: dateStart, endDate: dateEnd, selection: mode }),
        timeoutRef = useRef(null),
        dateStartRef = useRef(null),
        [days, setDays] = useState([]),
        [dragging, setDragging] = useState({
            isActive: 0,
            start: 0,
            position: 0,
        }),
        [animating, setAnimating] = useState(false),
        [position, setPosition] = useState('center'),
        calculateMarkerPosition = () => {
            let weekPosition;
            if (weekStart === 'sunday') {
                weekPosition = date.getDay() * 100;
            } else {
                weekPosition = date.getDay()
                    ? (date.getDay() - 1) * 100
                    : 600;
            }
            if (position === 'left') {
                return -700 + weekPosition;
            } else if (position === 'right') {
                return 700 + weekPosition;
            }
            return weekPosition;
        },
        [markerPosition, setMarkerPosition] = useState(
            calculateMarkerPosition(),
        ),
        draggingHandler = (type, x) => {
            switch (type) {
                case 'start': {
                    setDragging({
                        isActive: true,
                        start: x,
                        position: 0,
                    });
                    break;
                }
                case 'end': {
                    if (dragging.isActive && dragging.position !== 0) {
                        if (dragging.position > 200) {
                            analytics.logTimesheetsTrackerTimebarSwipe('Prev');
                            prevWeek();
                        } else if (dragging.position < -200) {
                            analytics.logTimesheetsTrackerTimebarSwipe('Next');
                            nextWeek();
                        } else {
                            analytics.logTimesheetsTrackerTimebarSwipe('None');
                        }
                    }


                    setDragging({
                        isActive: false,
                        start: 0,
                        position: 0,
                    });
                    break;
                }
                default: {
                    if (dragging.isActive) {
                        setDragging(prevState => ({
                            ...prevState,
                            position: x - dragging.start,
                        }));
                    }
                }
            }
        };

    useEffect(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        }
        timeoutRef.current = setTimeout(() => {
            setPosition('center');
            setAnimating(false);
            setDays(
                daysArray(addDays(dateStart, -7), addDays(dateEnd, 7)).map(
                    d => ({
                        ...d,
                        worklog: worklog[dateToString(d.date)],
                        isActive:
                                d.date.toDateString() === date.toDateString(),
                    }),
                ),
            );
            timeoutRef.current = null;
        }, 500);
        if (dateStartRef.current < dateStart) {
            setPosition('right');
            setAnimating(true);
            setDays(prevState =>
                prevState.map(d => ({
                    ...d,
                    isActive: d.date.toDateString() === date.toDateString(),
                })),
            );
        } else if (dateStartRef.current > dateStart) {
            setPosition('left');
            setAnimating(true);
            setDays(prevState =>
                prevState.map(d => ({
                    ...d,
                    isActive: d.date.toDateString() === date.toDateString(),
                })),
            );
        } else {
            setAnimating(true);
            setMarkerPosition(calculateMarkerPosition());
            setDays(prevState =>
                prevState.map(d => ({
                    ...d,
                    isActive: d.date.toDateString() === date.toDateString(),
                })),
            );
        }
        dateStartRef.current = dateStart;
    }, [dateStart, dateEnd, date, worklog]);

    useEffect(() => {
        setMarkerPosition(calculateMarkerPosition());
    }, [position]);

    // useEffect(() => {
    //     setDays(prevState =>
    //         prevState.map(d => ({
    //             ...d,
    //             worklog: worklog[dateToString(d.date)],
    //         })),
    //     );
    // }, []);

    return (
        <div
            className={styles.timeBar}
            onMouseDown={e => {
                e.stopPropagation();
                draggingHandler('start', e.pageX);
            }}
            onMouseMove={e => {
                e.stopPropagation();
                draggingHandler('move', e.pageX);
            }}
            onMouseUp={e => {
                e.stopPropagation();
                draggingHandler('end', e.pageX);
            }}
            onMouseLeave={e => {
                e.stopPropagation();
                draggingHandler('end', e.pageX);
            }}
        >
            <div className={styles.daysBar}>
                <div
                    className={cx(styles.draggingwrapper, dragging.position && styles.dragging, !dragging.position && styles.animation)}
                    style={{ opacity: days.length ? 1 : 0, transform: `translateX(${dragging.position}px)` }}
                >
                    <div
                        className={cx(styles.wrapper, (animating && styles.animating), styles[`position-${position}`])}>
                        {days.map(({
                            id,
                            date,
                            isToday,
                            isActive,
                            worklog
                        }, i) => {
                            return (
                                <div
                                    className={cx(styles.day, (isToday && styles.today), (isActive && styles.active))}
                                    key={id}
                                    onClick={() => {
                                        setDate({ date });
                                    }}
                                >
                                    {(i > 0 && date.getMonth() !== days[i - 1].date.getMonth()) &&
                                        <div className={styles.month}>
                                            <FormattedDate value={date} month="short" />
                                        </div>
                                    }
                                    <div className={styles.name}>
                                        <FormattedDate value={date} day="2-digit" weekday="short" />
                                    </div>
                                    {worklog > 0 &&
                                        <div className={styles.log}>
                                            <Pill size="small" text={durationFormatter(worklog)} textColor="blue" color={(isActive ? 'white' : 'grey')} />
                                        </div>
                                    }
                                </div>
                            )
                        })}
                        <div className={styles.backgrounds}>
                            {
                                days.map(({ id }) => (
                                    <div
                                        key={id}
                                    />
                                ))
                            }
                        </div>
                        <div className={styles.markerContainer}>
                            <div
                                className={cx(styles.marker, (animating && styles.animating))}
                                style={{ transform: `translateX(${markerPosition}%)` }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

TimeBar.propTypes = {
    dateStart: PropTypes.instanceOf(Date).isRequired,
    dateEnd: PropTypes.instanceOf(Date).isRequired,
    date: PropTypes.instanceOf(Date).isRequired,
    setDate: PropTypes.func.isRequired,
    nextWeek: PropTypes.func.isRequired,
    prevWeek: PropTypes.func.isRequired,
    mode: PropTypes.string.isRequired,
};

export default TimeBar;
