import { defineMessages } from 'react-intl';

export default defineMessages({
    projects: {
        id: 'myApprovals.projects',
        defaultMessage: 'Projects',
    },
    status: {
        id: 'myApprovals.status',
        defaultMessage: 'Status',
    },
    apply: {
        id: 'myApprovals.apply',
        defaultMessage: 'Apply',
    },
    accepted: {
        id: 'myApprovals.accepted',
        defaultMessage: 'Accepted'
    },
    rejected: {
        id: 'myApprovals.rejected',
        defaultMessage: 'Rejected'
    },
    pending: {
        id: 'myApprovals.pending',
        defaultMessage: 'Pending'
    },
    withdrawn: {
        id: 'myApprovals.withdrawn',
        defaultMessage: 'Withdrawn'
    },
});