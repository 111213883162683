import React from 'react';
import { useIntl } from 'react-intl';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Button } from '@quidlo/ui';

import { ReactComponent as ArrowSVG } from './assets/best-for-teams-arrow.svg';
import cardOptions from './CardOptions';

import style from './Card.module.scss';

import messages from './Card.i18n';

const Card = ({
    subscription,
    best,
    recurrence,
    action
}) => {
    const view = cardOptions[subscription],
        intl = useIntl(),
        pricePerMonthMonthly = 39,
        pricePerMonthAnnually = 29;

    return (
        <div className={best ? style.bestWrapper : undefined}>
            {best &&
                <span>{intl.formatMessage(messages.bestForTeams)}
                    <ArrowSVG />
                </span>}
            <div className={cx(style.card, style.best)}>
                <div className={style.top}>
                    <img src={view.image} alt={intl.formatMessage(messages[view.alt])} className={style.icon} />
                    <div className={style.title}>
                        {intl.formatMessage(messages[view.title])}
                    </div>
                    <div className={style.priceWrapper}>
                        <div className={style.price}>
                            {subscription === 'pro' ?
                                <div>
                                    {intl.formatMessage(messages.proPrice, { price: recurrence === 'annually' ? pricePerMonthAnnually : pricePerMonthMonthly })}
                                </div>
                                :
                                <div>{intl.formatMessage(messages[view.price])}</div>
                            }
                        </div>
                        {recurrence &&
                            <div className={style.billed}>
                                {recurrence === 'monthly' ?
                                    intl.formatMessage(messages[view.billedMonthly])
                                    :
                                    intl.formatMessage(messages[view.billedAnnually])
                                }
                            </div>
                        }
                    </div>
                    <div className={style.subtitle}>
                        {intl.formatMessage(messages[view.subtitle])}
                    </div>
                    <ul className={style.bottom}>
                        <li>
                            <div className={style.tick} />
                            <div className={style.text}>
                                {intl.formatMessage(messages[view.perk1])}
                            </div>
                        </li>
                        {view.perk2 ?
                            <li>
                                <div className={style.tick} />
                                <div className={style.text}>
                                    {intl.formatMessage(messages[view.perk2])}
                                </div>
                            </li>
                            :
                            null
                        }
                    </ul>
                </div>
                <Button
                    color={subscription === 'starter' ? 'white' : 'blue'}
                    shadow
                    onClick={action}
                    text={intl.formatMessage(messages[view.button])}
                />
            </div>
        </div>
    );
};

Card.propTypes = {
    subscription: PropTypes.string.isRequired,
    recurrence: PropTypes.oneOf(['monthly', 'annually']),
    best: PropTypes.bool,
    action: PropTypes.func.isRequired
};

Card.defaultProps = {
    best: false,
    recurrence: null,
};

export default Card;