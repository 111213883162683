import React from 'react';
import { useIntl } from 'react-intl';

import useUI from "@quidlo/common/hooks/useUI";
import { Button, Space } from "@quidlo/ui";

import illustration from "../../../../../assets/modals/trash.png";
import {
    deleteProjectUserMutation,
} from '../../../../../queries/projects.queries';
import ModalHOC, { ModalActions, ModalContent, ModalHeader } from "../../../../../ui/Modal/Modal";

import messages from './ProjectRemoveUser.i18n';

const ProjectRemoveUser = () => {
    const intl = useIntl();
    const { modal, modalClose } = useUI();
    const { mutate: deleteUser } = deleteProjectUserMutation(modal?.data?.projectId);

    const delUser = () => {
        deleteUser({
            userId: modal.data.userId
        });
        modalClose();
    }

    return (
        <>
            <ModalHeader>
                {intl.formatMessage(messages.deleteUser)}
            </ModalHeader>
            <ModalContent>
                {intl.formatMessage(messages.areYouSure)}
            </ModalContent>
            <ModalActions>
                <Space mr="m">
                    <Button
                        color="white"
                        text={intl.formatMessage(messages.cancel)}
                        onClick={modalClose}
                    />
                </Space>
                <Button
                    color="blue"
                    text={intl.formatMessage(messages.remove)}
                    onClick={delUser}
                    shadow
                />
            </ModalActions>
        </>
    );
};

export default ModalHOC(
    ProjectRemoveUser,
    {
        id: 'projectRemoveUser',
        size: 'small',
        illustration,
    }
);
