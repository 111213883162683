import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import InvitationsService from '../../api/InvitationsService';
import ProjectsService from '../../api/ProjectsService';
import SessionService from '../../api/SessionService';
import UsersService from '../../api/UsersService';
import WorkspaceService2 from "../../apiV2/WorkspaceService";
import { PROJECT_ROLE,WORKSPACE_STATUS } from "../../constants/main";
import useUI from "../../hooks/useUI";
import analytics from "../../utils/analytics";
import Bugsnag from '../../utils/bugsnag';
import cookies from '../../utils/cookies';
import localDB from '../../utils/localDB'
import { getTimezone } from "../../utils/timezone";

import messages from './AuthProvider.i18n';

export const AuthContext = React.createContext({});

const AuthProvider = ({ children }) => {
    const intl = useIntl();
    const { toastPush } = useUI();
    const location = useLocation();

    const [isInitialized, setIsInitialized] = useState(null);

    const [quidloToken, setQuidloToken] = useState(null);
    const [userId, setUserId] = useState(null);
    const [user, setUser] = useState(null);
    const [workspace, setWorkspace] = useState(null);
    const [workspaceFeatures, setWorkspaceFeatures] = useState(null);
    const [invitations, setInvitations] = useState(null);

    const restoreSession = async () => {
        const token = cookies.get(process.env.REACT_APP_AUTHTOKEN_NAME);

        if (token) {
            try {
                const { data } = await SessionService.get();
                if (data?.userId) {
                    setQuidloToken(token);
                    setUserId(data.userId);
                }
            } catch (e) {
                analytics.logError(e?.response?.data?.message)
                setQuidloToken(null);
                setUserId(null);
                setIsInitialized(true);
            }
        } else {
            setQuidloToken(null);
            setUserId(null);
            setIsInitialized(true);
        }
    };

    const selectWorkspace = async (code, redirectUrl) => {
        const selectedWorkspace = user.organizations.find(o => o.code === code);
        if (selectedWorkspace) {
            const { data: projects } = await ProjectsService.getAll(code, userId);
            const projectsRole = projects.find(p => p.projectRole === PROJECT_ROLE.MANAGER) ? PROJECT_ROLE.MANAGER : PROJECT_ROLE.MEMBER

            localDB.LSset('workspace', code);

            setWorkspace({
                ...selectedWorkspace,
                projectsRole
            });
            window.location.replace(redirectUrl || '/');

            toastPush({
                text: intl.formatMessage(messages.workspaceSwitched)
            });
        }

    }

    const fetchUser = async () => {
        try {
            const { data } = await UsersService.get(userId);

            const queryParamsString = location.search.substring(1),
                searchParams = new URLSearchParams(queryParamsString),
                urlParamWorkspaceCode = searchParams.get('orgCode'),
                localStorageWorkspaceCode = localDB.LSget('workspace');

            let workspace;
            if (!data?.organizations?.filter(o => o.status === WORKSPACE_STATUS.APPROVED).length) {
                setUser(data);
                setWorkspace(null);
                setIsInitialized(true);
                if (process.env.REACT_APP_AUTH_URL && !window.location.href.includes(process.env.REACT_APP_AUTH_URL)) {
                    setQuidloToken(null);
                    cookies.set(process.env.REACT_APP_AUTHTOKEN_NAME, '');
                    window.location.replace(process.env.REACT_APP_AUTH_URL);
                }
                return;
            }

            if (urlParamWorkspaceCode && data.organizations.find(o => o.code === urlParamWorkspaceCode).status === 'approved') {
                workspace = data.organizations.find(o => o.code === urlParamWorkspaceCode)
            } else if (localStorageWorkspaceCode && data.organizations.find(o => o.code === localStorageWorkspaceCode)?.status === 'approved') {
                workspace = data.organizations.find(o => o.code === localStorageWorkspaceCode)
            } else {
                workspace = data.organizations.find(o => o.status === 'approved');
            }

            const { data: projects } = await ProjectsService.getAll(workspace.code, userId);
            const projectsRole = projects.find(p => p.projectRole === PROJECT_ROLE.MANAGER) ? PROJECT_ROLE.MANAGER : PROJECT_ROLE.MEMBER
            setUser(data);
            setWorkspace({
                ...workspace,
                projectsRole
            });
        } catch (e) {
            Bugsnag.notify(e);
            analytics.logError(e?.response?.data?.message)
            setUser(null);
        }
    };

    const fetchInvitations = async () => {
        try {
            const { data } = await InvitationsService.query(userId);
            setInvitations(data);
        } catch (e) {
            Bugsnag.notify(e);
            analytics.logError(e?.response?.data?.message)
            setInvitations([]);
        }
    };

    const fetchWorkspaceFeatures = async () => {

        try {
            const features = (await WorkspaceService2.getFeatures(workspace.code))?.data?.data || [];
            const tmp = {};
            features.forEach(f => {
                tmp[f.name] = f.enabled
            })
            setWorkspaceFeatures(tmp);
        } catch (e) {
            Bugsnag.notify(e);
            analytics.logError(e?.response?.data?.message)
            setWorkspaceFeatures({});
        }
    }

    const patchWorkspaceFeatures = async feat => {
        try {
            WorkspaceService2.patchFeatures(workspace.code, feat).then(() => {
                setWorkspaceFeatures(prevFeatures => ({
                    ...prevFeatures,
                    ...feat
                }))
            })
        } catch (e) {
            analytics.logError(e?.response?.data?.message)
            Bugsnag.notify(e);
        }
    }

    useEffect(() => {
        const elemScript = document.createElement('script');
        elemScript.src = "https://accounts.google.com/gsi/client";
        elemScript.async = true;
        elemScript.defer = true;
        elemScript.onload = () => {
            window.google.accounts.id.initialize({
                client_id: process.env.REACT_APP_GOOGLE_CLIENTID,
                callback: async res => {
                    const timezone = getTimezone();
                    const { data } = await SessionService.postGoogle(res.credential, timezone);
                    if (data && data.token && data.userId) {
                        // TODO: Hacky way to check if user signs up
                        cookies.set(process.env.REACT_APP_AUTHTOKEN_NAME, data.token, { expires: 7 });
                        const user = (await UsersService.get(data.userId))?.data;
                        if (!user.organizations.length) {
                            analytics.logAuthSignUpSuccess('google');
                        }
                        // TODO: End
                        analytics.logAuthSignInSuccess('google');
                        setQuidloToken(data.token);
                        setUserId(data.userId);
                    }
                }
            });
            restoreSession();
        }
        document.body.append(elemScript);
    }, []);

    useEffect(() => {
        if (quidloToken) {
            cookies.set(process.env.REACT_APP_AUTHTOKEN_NAME, quidloToken, { expires: 7 });
        }
    }, [quidloToken]);

    useEffect(() => {
        if (userId) {
            fetchUser();
            fetchInvitations();
        } else {
            setUser(null);
        }
    }, [userId]);

    useEffect(() => {
        if (user?.id) {
            localDB.LSset('user', {
                id: user.id,
                name: `${user.firstName} ${user.lastName}`,
                email: user.email,
            });
        } else {
            localDB.LSset('user', null);
        }

        if (user?.id && workspace?.code) {
            analytics.identifyUser(`${user?.id}-${workspace.code}`, user, workspace);
        }
    }, [user, workspace])

    useEffect(() => {
        if (!workspace?.code) {
            setWorkspaceFeatures({})
        } else {
            Promise.all([
                fetchWorkspaceFeatures(),
            ]).then(() => {
                setIsInitialized(true);
            })

        }
    }, [workspace]);

    const contextValue = useMemo(() => {
        return {
            isInitialized,
            setQuidloToken,
            user,
            userId,
            setUserId,
            fetchUser,
            workspace,
            fetchWorkspaceFeatures,
            workspaceFeatures,
            patchWorkspaceFeatures,
            selectWorkspace,
            invitations,
            fetchInvitations,
        };
    }, [user, userId, invitations, workspace, workspaceFeatures, isInitialized]);

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

export default AuthProvider;
