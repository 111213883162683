import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import cx from "classnames";
import PropTypes from 'prop-types';

import analytics from "@quidlo/common/utils/analytics";

import AutocompleteDropdown from './AutocompleteDropdown';
import AutocompleteView from './AutocompleteView';

import style from "./Autocomplete.module.scss";

import messages from './Autocomplete.i18n';

const sortOpts = opts => opts.sort((a, b) => {
    if (a.selected !== b.selected) {
        return b.selected - a.selected;
    }

    return a.labelText.localeCompare(b.labelText);
});

const Autocomplete = ({
    fieldId,
    label,
    placeholder,
    value,
    error,
    options,
    optionsLength,
    fetch,
    disabled,
    multiselect,
    allselect,
    onlyselect,
    noOptionsText,
    isTouched,
    isRequired,
    onAdd,
    onChange,
    onBlur,
    onFocus,
    analyticsID,
    type,
}) => {
    const
        intl = useIntl(),
        [input, setInput] = useState({
            value: '',
            focused: false
        }),
        [isFocused, setIsFocused] = useState(false),
        inputRef = useRef(input.value),
        [opt, setOpt] = useState([]),
        [activeOption, setActiveOption] = useState(undefined),
        [showAll, setShowAll] = useState(false),
        ref = useRef(null),
        optionsContainerRef = useRef(null),
        optionRef = useRef(null),
        inputHandler = val => {
            if (analyticsID && val) {
                analytics.logTimesheetsAutocompleteFilter({ id: analyticsID, phraseLength: val.length })
            }
            if (fetch) {
                setInput(prevInput => ({
                    ...prevInput,
                    value: val
                }));

                fetch(val).then(fetchOptions => {
                    setActiveOption(0);
                    setOpt(sortOpts(fetchOptions.map(o => ({
                        ...o,
                        selected: multiselect ? !!value.find(v => v === o.value) : value === o.value
                    }))));
                });
            } else {
                let autocompleteOptions = [];
                if (val.length > 0) {
                    autocompleteOptions = options.filter(o => o.labelText.toLowerCase().includes(input.value.toLowerCase())).map(o => ({
                        ...o,
                        selected: multiselect ? !!value.find(v => v === o.value) : value === o.value
                    }));
                } else {
                    autocompleteOptions = options.map(o => ({
                        ...o,
                        selected: multiselect ? !!value.find(v => v === o.value) : value === o.value
                    }));
                }

                setInput(prevInput => ({
                    ...prevInput,
                    value: val
                }));
                setOpt(sortOpts(autocompleteOptions));
                setActiveOption(0);
            }
        },
        focusHandler = () => {
            if (onFocus) {
                onFocus();
                setIsFocused(true);
            }

            inputHandler(input.value);
            setInput(prevInput => ({
                ...prevInput,
                focused: true
            }));
            setActiveOption(allselect ? 'all' : 0);
            if (analyticsID) {
                analytics.logTimesheetsAutocompleteOpen({ id: analyticsID })
            }
            if (fetch) {
                fetch().then(fetchOptions => {
                    setOpt(sortOpts(fetchOptions.map(o => ({
                        ...o,
                        selected: multiselect ? !!value.find(v => v === o.value) : value === o.value
                    }))));
                });
            }
        },
        keydownHandler = useCallback(event => {

            if (event.keyCode === 40) {
                setActiveOption(val => {
                    if (val === 'all') {
                        return 0;
                    } else if (opt.length > val + 1) {
                        return val + 1;
                    }
                    return val;
                });
            }

            if (event.keyCode === 38) {
                setActiveOption(val => {
                    if (val === 0 && allselect) {
                        return 'all';
                    } else if (val > 0) {
                        return val - 1;
                    }
                    return val;
                });
            }

            if (event.keyCode === 13) {

                if (inputRef.current && onAdd) {
                    onAdd(inputRef.current);
                    setInput(prevInput => ({
                        ...prevInput,
                        value: ''
                    }));
                } else {
                    setActiveOption(val => {
                        if (opt.length > 0) {
                            if (val === 'all') {
                            // eslint-disable-next-line no-use-before-define
                                selectAllHandler(true);
                            } else if (opt[val].value) {
                            // eslint-disable-next-line no-use-before-define
                                selectHandler(opt[val].value, true);
                            }
                        }
                        return val;
                    });
                }
            }

            if (event.keyCode === 9) {
                blurHandler();
            }
        }),
        clickHandler = event => {
            if (event.type === 'click' && ref.current && !ref.current.contains(event.target)) {
                blurHandler();
                setIsFocused(false);
            }
        },
        blurHandler = () => {
            if (onBlur) {
                onBlur(value);
                setIsFocused(false);
            }

            setInput({
                value: '',
                focused: false
            });

            setActiveOption(undefined);
            setOpt(sortOpts(opt));

            if (analyticsID) {
                analytics.logTimesheetsAutocompleteClose({ id: analyticsID })
            }
        },
        selectHandler = (val, isKeyboard) => {
            let newVal;

            if (multiselect) {
                if (value.find(v => v === val)) {
                    newVal = value.filter(v => v !== val);
                } else {
                    newVal = [...value, val];
                }

                setInput({
                    value: '',
                    focused: false
                });
            } else {
                newVal = val;
            }

            setActiveOption(opt.map(o => o.value).indexOf(val));

            // eslint-disable-next-line no-use-before-define
            changeHandler(newVal);

            if (analyticsID) {
                analytics.logTimesheetsAutocompleteSelect({
                    id: analyticsID,
                    number: newVal?.length || 1,
                    trigger: isKeyboard ? 'Keyboard' : 'Click',
                })
            }

            if (!multiselect) {
                blurHandler();
            }
        },
        selectAllHandler = isKeyboard => {
            const isAllSelected = value?.length === options.length,
                v = isAllSelected ? [] : options.map(o => o.value);

            if (analyticsID) {
                analytics.logTimesheetsAutocompleteSelect({
                    id: analyticsID,
                    number: v?.length || 1,
                    trigger: isKeyboard ? 'Keyboard' : 'Click',
                })
            }

            // eslint-disable-next-line no-use-before-define
            changeHandler(v);

            setActiveOption('all');
        },
        changeHandler = v => {
            if (JSON.stringify(v) !== JSON.stringify(value)) {
                onChange(v);
            }
        },
        selectedOptions = useMemo(() => {
            if (!value) {
                return [];
            }
            if (multiselect) {
                if (fetch && !options.length) {
                    return opt.filter(o => value.includes(o.value));
                }
                return options.filter(o => value.includes(o.value));
            }

            if (fetch && !options.length) {
                return [opt.find(o => value === o.value)];
            }
            return [options.find(o => value === o.value)];
        }, [value, options, opt]),
        clickShowAll = () => {
            setTimeout(() => {
                setShowAll(true);
            }, 100);
        },
        selectOnly = id => {
            if (analyticsID) {
                analytics.logTimesheetsAutocompleteOnlyClick({ id: analyticsID })
            }
            changeHandler([id]);
        };

    useEffect(() => {
        const autocompleteOptions = !input.value ? options : options.filter(o => o.labelText.toLowerCase().includes(input.value.toLowerCase()));
        setOpt(sortOpts(autocompleteOptions.map(o => ({
            ...o,
            selected: multiselect ? !!value.find(v => v === o.value) : value === o.value
        }))));

        if (multiselect) {
            const
                newValue = options.filter(o => value.indexOf(o.value) > -1).map(o => o.value);

            changeHandler(newValue);
        } else {
            const
                newValue = options.filter(o => value === o.value).map(o => o.value)[0];

            changeHandler(newValue);
        }
    }, [options.length]);

    useEffect(() => {
        setOpt(opt.map(o => ({
            ...o,
            selected: multiselect ? !!value.find(v => v === o.value) : value === o.value
        })));
        changeHandler(value);
    }, [value]);

    useEffect(() => {
        inputRef.current = input.value;
    }, [input.value])

    useEffect(() => {
        if (isFocused) {
            document.removeEventListener('click', clickHandler);
            document.removeEventListener('keydown', keydownHandler);
            document.addEventListener('click', clickHandler);
            document.addEventListener('keydown', keydownHandler);
        } else {
            document.removeEventListener('click', clickHandler);
            document.removeEventListener('keydown', keydownHandler);
        }
        return () => {
            document.removeEventListener('click', clickHandler);
            document.removeEventListener('keydown', keydownHandler);
        }
    }, [isFocused, value, opt]);

    useEffect(() => {
        if (optionsContainerRef.current && optionRef.current) {
            const
                scrollMenuTop = optionsContainerRef.current.scrollTop,
                scrollBottom = scrollMenuTop + optionsContainerRef.current.offsetHeight,
                optionTop = optionRef.current.offsetTop,
                optionBottom = optionTop + optionRef.current.offsetHeight;

            if (scrollMenuTop > optionTop) {
                optionsContainerRef.current.scrollTop = optionRef.current.offsetTop;
            } else if (scrollBottom < optionBottom) {
                optionsContainerRef.current.scrollTop = optionsContainerRef.current.scrollTop + optionRef.current.offsetHeight;
            }
        }
    }, [optionRef.current])

    useEffect(() => {
        if (analyticsID && error && isTouched) {
            analytics.logTimesheetsAutocompleteValidationError({ id: analyticsID, message: error })
        }
    }, [error, isTouched]);


    return (
        <div ref={ref}>
            <div
                className={cx(style.autocomplete, disabled && style.disabled)}
            >
                <AutocompleteView
                    fieldId={fieldId}
                    label={label}
                    placeholder={placeholder}
                    error={error}
                    inputValue={input.value}
                    isInputFocused={input.focused}
                    selectedOptions={selectedOptions}
                    onInput={inputHandler}
                    onFocus={focusHandler}
                    isMulti={multiselect}
                    allSelected={multiselect && value?.length === options.length}
                    type={type}
                    isRequired={isRequired}
                />
                <AutocompleteDropdown
                    inputValue={input.value}
                    options={opt}
                    optionsLength={optionsLength}
                    activeOption={activeOption}
                    noOptionsText={noOptionsText || intl.formatMessage(messages.noOptions)}
                    onSelect={selectHandler}
                    onSelectAll={((allselect && multiselect) ? selectAllHandler : undefined)}
                    optionRef={optionRef}
                    optionsContainerRef={optionsContainerRef}
                    isFocused={isFocused}
                    allSelected={multiselect && value?.length === options.length}
                    showAll={showAll}
                    clickShowAll={clickShowAll}
                    selectOnly={onlyselect ? selectOnly : undefined}
                    setActiveOption={setActiveOption}
                />
            </div>
        </div>
    );
};

Autocomplete.propTypes = {
    fieldId: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    error: PropTypes.node,
    options: PropTypes.array,
    optionsLength: PropTypes.number,
    fetch: PropTypes.func,
    disabled: PropTypes.bool,
    multiselect: PropTypes.bool,
    allselect: PropTypes.bool,
    noOptionsText: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onAdd: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onKeyDown: PropTypes.func,
    isFocused: PropTypes.bool,
    isTouched: PropTypes.bool,
    isRequired: PropTypes.bool,
    type: PropTypes.oneOf(['text', 'chips']),
    onlyselect: PropTypes.bool,
    analyticsID: PropTypes.string,
};

Autocomplete.defaultProps = {
    fieldId: 'autocomplete',
    label: undefined,
    placeholder: '',
    value: undefined,
    error: undefined,
    options: [],
    fetch: undefined,
    disabled: false,
    multiselect: false,
    allselect: false,
    noOptionsText: undefined,
    onAdd: undefined,
    onFocus: undefined,
    onBlur: undefined,
    onKeyDown: undefined,
    optionsLength: 5,
    type: 'text',
    onlyselect: false,
    analyticsID: null,
    isTouched: false,
    isRequired: false,
};

export default Autocomplete;