import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'qs';

import IntegrationsService from "@quidlo/common/api/IntegrationsService";
import useAuthentication from "@quidlo/common/hooks/useAuthentication";
import useUI from "@quidlo/common/hooks/useUI";
import useWorkspace from "@quidlo/common/hooks/useWorkspace";
import { InfoCard, Space, Spinner } from "@quidlo/ui";

import { Container } from "../../../ui";
import ViewHOC from '../../../ui/View/View';

import { ReactComponent as ChatbotIllu } from "./svg/chatbot-big.svg";

import messages from './SlackConnect.i18n';

const SlackConnect = () => {
    const
        intl = useIntl(),
        navigate = useNavigate(),
        location = useLocation(),
        { user } = useAuthentication(),
        { workspace, selectWorkspace } = useWorkspace(),
        { toastPush } = useUI(),
        [state, setState] = useState({
            userId: '',
            teamId: '',
            spaceId: '',
            token: '',
            isLoading: true
        }),
        connectSlackHandler = async () => {
            await IntegrationsService.slackConnect(
                workspace.code,
                user.id,
                {
                    slackUserId: state.userId,
                    slackTeamId: state.teamId,
                    slackChannelId: state.spaceId,
                    token: state.token
                })
            toastPush({
                text: intl.formatMessage(messages.success)
            });
            navigate('/tracker');
        };
    useEffect(() => {
        const { search } = location;

        if (search) {
            const
                parsedSearch = search.substring(1).replace('+', '%2b'),
                {
                    userId,
                    teamId,
                    spaceId,
                    token
                } = qs.parse(parsedSearch);
            setState({
                userId,
                teamId,
                spaceId,
                token
            });
            IntegrationsService.slackGetConnection(
                user.id,
                {
                    userId,
                    teamId,
                    spaceId
                }).then(data => {
                if (data && Object.keys(data.data).length > 0) {
                    toastPush({
                        text: intl.formatMessage(messages.alreadyExists)
                    });

                    if (data.data.organizationCode && (workspace.code !== data.data.organizationCode)) {
                        selectWorkspace(data.data.organizationCode);
                    }

                    navigate('/integrations/chatbots/list');
                }

                setTimeout(() => {
                    setState(prevState => ({
                        ...prevState,
                        isLoading: false
                    }));
                }, 1000);
            })
        } else {
            navigate('/integrations/chatbots/list');
        }
    }, []);
    return (
        <div>
            <Container centered>
                {!state.isLoading ? (
                    <Space>
                        <InfoCard
                            header={intl.formatMessage(messages.user)}
                            text={intl.formatMessage(messages.message)}
                            actions={[{
                                text: intl.formatMessage(messages.ok),
                                onClick: connectSlackHandler
                            }]}
                        >
                            <ChatbotIllu />
                        </InfoCard>
                    </Space>
                ) : (
                    <Space>
                        <Spinner active={state.isLoading} intl={intl} />
                    </Space>
                )}
            </Container>
        </div>
    );
};


export default ViewHOC(
    SlackConnect,
    {
        title: "Slack Connect",
        menu: true,
        topbar: true,
        auth: {
            roles: ['employee', 'manager', 'admin', 'owner']
        },
        subscription: true
    }
);
