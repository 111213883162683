import React from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import useForm from '@quidlo/common/hooks/useForm2';
import useUI from "@quidlo/common/hooks/useUI";
import { Button, Space } from '@quidlo/ui';

import illustration from "../../../../assets/modals/man-confused.png";
import { withdrawApprovalMutation } from '../../../../queries/approvals.queries';
import { Textarea } from '../../../../ui';
import ModalHOC, { ModalActions, ModalContent, ModalHeader } from "../../../../ui/Modal/Modal";
import SummaryTable from "../../components/SummaryTable";

import style from "./WithdrawAcceptedApprovalModal.module.scss";

import messages from "./WithdrawAcceptedApprovalModal.i18n";

const WithdrawAcceptedApprovalModal = () => {
    const { modal, modalClose } = useUI(),
        params = useParams(),
        { projectsToWithdraw, dateRange } = modal.data || {},
        { mutate: withdrawApproval } = withdrawApprovalMutation(),
        [form] = useForm({ message: { value: '' } }),
        withdraw = () => {
            withdrawApproval({ id: params.id, note: form.message.value });
            modal.callback()
            modalClose()
        },
        cancel = () => {
            modal.callback()
            modalClose()
        },
        intl = useIntl();

    return (
        <>
            <ModalHeader>
                {intl.formatMessage(messages.title)}
            </ModalHeader>
            <ModalContent>
                <Space mb="m" className={style.description}>{intl.formatMessage(messages.description1)}</Space>
                <Space mb="m">
                    <SummaryTable dateRange={dateRange} list={projectsToWithdraw} />
                </Space>
                <Space mb="m" className={style.description}>{intl.formatMessage(messages.ifYouWouldLike)}</Space>
                <Space mb="m" ph="m" pv="xs" className={style.messageWrapper}>
                    <Textarea
                        placeholder={intl.formatMessage(messages.message)}
                        {...form.message}
                        rows={4}
                    />
                </Space>
            </ModalContent>
            <ModalActions>
                <Space mr="m">
                    <Button
                        onClick={cancel}
                        text={intl.formatMessage(messages.cancel)}
                        color="white"
                    />
                </Space>

                <Button
                    onClick={withdraw}
                    text={intl.formatMessage(messages.withdraw)}
                    color="blue"
                    shadow
                />
            </ModalActions>
        </>
    );
};

export default ModalHOC(
    WithdrawAcceptedApprovalModal,
    {
        id: 'withdrawAcceptedApproval',
        size: 'small',
        illustration,
    }
);