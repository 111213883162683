import React from 'react';
import { createRoot } from "react-dom/client";
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import AuthProvider from "@quidlo/common/providers/AuthProvider";
import AutoTrackerProvider from "@quidlo/common/providers/AutoTrackerProvider";
import UIProvider from "@quidlo/common/providers/UIProvider";
import analytics from "@quidlo/common/utils/analytics";
import fontfix from "@quidlo/common/utils/fontfix";

import packageJson from '../package.json';

import App from './components/App';
import ErrorBoundary from './components/App/ErrorBoundary';
import localeData from './locales/data.json';

const
    language = (navigator.languages && navigator.languages[0]) || navigator.language,
    languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0],
    messages = localeData[languageWithoutRegionCode] || localeData[language] || localeData.en,
    queryClient = new QueryClient(),
    container = document.getElementById('root'),
    root = createRoot(container);

window.version = packageJson.version;
window.env = process.env.REACT_APP_INSTANCE_NAME;

analytics.init();

fontfix();

root.render(
    <ErrorBoundary>
        <IntlProvider locale="en" defaultLocale="en" messages={messages}>
            <BrowserRouter>
                <UIProvider>
                    <AuthProvider>
                        <AutoTrackerProvider>
                            <QueryClientProvider client={queryClient}>
                                <App />
                                <ReactQueryDevtools />
                            </QueryClientProvider>
                        </AutoTrackerProvider>
                    </AuthProvider>
                </UIProvider>
            </BrowserRouter>
        </IntlProvider>
    </ErrorBoundary>
);