import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { APPROVAL_STATUS, APPROVAL_STATUSES, TASK_STATUSES } from "@quidlo/common/constants/main";
import { Dot } from '@quidlo/ui';

import style from './ApprovalStatus.module.scss';

const statusToColor = {
        [APPROVAL_STATUS.ACCEPTED]: 'green',
        [APPROVAL_STATUS.PENDING]: 'yellow',
        [APPROVAL_STATUS.REJECTED]: 'red',
        [APPROVAL_STATUS.LOGGED]: 'blue',
        [APPROVAL_STATUS.WITHDRAWN]: 'grey'
    },
    Status = ({ status }) => (
        <div className={cx(style.status, status === 'rejected' && style.rejected)}>
            <Dot color={statusToColor[status]} />
            {status}
        </div>
    );

Status.propTypes = {
    status: PropTypes.oneOf([...APPROVAL_STATUSES, ...TASK_STATUSES])
};

export default Status;
