import { defineMessages } from 'react-intl';

export default defineMessages({
    noReports: {
        id: 'reportTable.noReports',
        defaultMessage: 'No reports found with these filters'
    },
    checkYourFilters: {
        id: 'reportTable.checkYourFilters',
        defaultMessage: 'Check your filters to generate another report'
    },
    noTimeEntries: {
        id: 'reportTable.noTimeEntries',
        defaultMessage: 'No time entries found'
    },
    matchSelectedFilters: {
        id: 'reportTable.matchSelectedFilters',
        defaultMessage: 'No time entries found that match the selected filters. Try changing the filters.'
    },
    thereAreNo: {
        id: 'reportTable.thereAreNo',
        defaultMessage: 'There are no reports to display'
    },
    useTheFilters: {
        id: 'reportTable.useTheFilters',
        defaultMessage: 'Use the filters above to generate reports'
    },
});