import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { dateToString } from "@quidlo/common/utils/dateHelpers";
import { InfoCard } from '@quidlo/ui';

import { getTrackerDataQuery } from "../../queries/tracker.queries";

import noLogsPNG from './assets/no-logs.png';
import Project from './Project';

import messages from './Tracker.i18n';

const ProjectsList = ({
    mode,
    date,
}) => {
    const
        intl = useIntl(),
        { data } = getTrackerDataQuery({ date: dateToString(date), selection: mode });

    if (!data?.projects?.length) {
        return (
            <InfoCard
                header={intl.formatMessage(messages.noDataHeader)}
                text={mode === 'all' ? intl.formatMessage(messages.noDataBodyAll) : intl.formatMessage(messages.noDataBody)}
                illustration={ <img src={noLogsPNG} alt="a man at a desk" />}
            />);
    }

    return data?.projects.map(project => {
        if (project.tasks.length) {
            return <Project
                key={`${project.id}-${data.date}-${data.selection}`}
                project={project}
            />;
        }

        return <div key={`${date}${project.id}`} />;
    });
};

ProjectsList.propTypes = {
    mode: PropTypes.string.isRequired,
    date: PropTypes.instanceOf(Date).isRequired,
};

export default ProjectsList;
