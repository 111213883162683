import React from 'react';
import { FormattedMessage } from 'react-intl';

import AppStoreImage from './img/appstore@3x.png';
import GooglePlayImage from './img/googleplay@3x.png';
import { ReactComponent as SetupImage } from './img/setup.svg';

import style from './MobileScreen.module.scss';

import messages from './MobileScreen.i18n';

const MobileScreen = () => ((
    <div className={style.mobile_screen}>
        <div className={style.card}>
            <SetupImage />
            <div className={style.text}>
                <FormattedMessage {...messages.text} />
            </div>
            <a href={process.env.REACT_APP_GOOGLEPLAY_URL} className={style.storeLink}>
                <img src={GooglePlayImage} alt="Google Play" />
            </a>
            <a href={process.env.REACT_APP_APPSTORE_URL} className={style.storeLink}>
                <img src={AppStoreImage} alt="App Store" />
            </a>
        </div>
    </div>
));

export default MobileScreen;
