import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Icon } from '@quidlo/ui';

import style from './List.module.scss';

const
    List = ({
        rightChildren,
        title,
        color,
        active,
        icon,
        rightIcon,
        children,
        noListShadow
    }) => (
        <div
            className={cx(style.list, active && style.active, noListShadow && style.noListShadow)}>
            {title &&
                <div className={cx(style.title)}>
                    <div className={style.left} style={{ color }}>
                        {rightIcon && (
                            <div className={style.rightIcon}>
                                {rightIcon}
                            </div>
                        )}
                        {title}
                        {icon && (
                            <div className={style.icon}>
                                <Icon icon={icon} color={color} size="medium" />
                            </div>
                        )}
                    </div>
                    <div className={style.right}>
                        {rightChildren}
                        {color &&
                            <div className={style.label} style={{ backgroundColor: color }} />
                        }
                    </div>
                </div>
            }
            <div className={cx(style.content)}>
                {children}
            </div>
        </div>
    );

List.propTypes = {
    title: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    color: PropTypes.string,
    icon: PropTypes.string,
    rightIcon: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    active: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    rightChildren: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    noListShadow: PropTypes.bool,
};

List.defaultProps = {
    title: '',
    color: undefined,
    icon: undefined,
    rightIcon: undefined,
    active: false,
    rightChildren: undefined,
    noListShadow: false,
};

export default List;