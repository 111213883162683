import { defineMessages } from 'react-intl';

export default defineMessages({
    apiKeys: {
        id: 'settings.apiKeys',
        defaultMessage: 'API Keys',
    },
    listHeader: {
        id: 'settings.listHeader',
        defaultMessage: 'API Keys',
    },
    noResults: {
        id: 'settings.noResults',
        defaultMessage: 'No results',
    },
    createNewApiKey: {
        id: 'settings.createNewApiKey',
        defaultMessage: 'Create api key',
    },
    youDontHave: {
        id: 'settings.youDontHave',
        defaultMessage: 'You don’t have any API Keys created yet',
    },
    apiKeysWillBeListed: {
        id: 'settings.apiKeysWillBeListed',
        defaultMessage: 'API keys will be listed here after you create them',
    }
});