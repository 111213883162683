import { useIntl } from "react-intl";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import UsersService from "@quidlo/common/api/UsersService";
import useAuthentication from "@quidlo/common/hooks/useAuthentication";
import useUI from "@quidlo/common/hooks/useUI";

import { messagesToasts } from "../messages";

export const getSettingsQuery = () => {
    const { user } = useAuthentication();
    return useQuery(
        ['settings'],
        async () => (await UsersService.getSettings(user.id)).data,
        {
            // enabled: !!user?.id,
        }
    );
};

export const editSettingsMutation = () => {
    const { user } = useAuthentication();
    const queryClient = useQueryClient();
    const { toastPush } = useUI();
    const intl = useIntl();

    return useMutation(settings => UsersService.patchSettings(user.id, settings), {
        onSuccess: () => {
            toastPush({
                text: intl.formatMessage(messagesToasts.settingsSaved)
            });
            queryClient.invalidateQueries(['settings'])
        },
        onError: e => {
            toastPush({
                text: e?.response?.data?.message || intl.formatMessage(messagesToasts.unexpectedError),
                color: 'red'
            });
        }
    })
}