import DURATION_FORMATS from "../constants/durationFormats";
import TIME_FORMATS from "../constants/timeFormats";

import { durationParser } from './duration';

export default {
    isRequired: error => val => {
        if (Array.isArray(val) && !val.length) {
            return error || "Value is required"
        }
        if (!val) {
            return error || "Value is required"
        }
        return null;
    },

    minLength: (min, error) => val => {
        if (val.length < min) {
            return error || `Value should contain at least ${min} characters`
        }
        return null;
    },

    maxLength: (max, error) => val => {
        if (val.length > max) {
            return error || `Value should contain at most ${max} characters`
        }
        return null;
    },
    isNumber: error =>val => {
        if (!isNaN(val)) {
            return error || `Value should be a number`
        }
        return null;
    },
    minNumber: (min, error) => val => {
        if (val < min) {
            return error || `Value can't be lower than ${min}`
        }
        return null;
    },
    maxNumber: (max, error) => val => {
        if (val < max) {
            return error || `Value can't be higher than ${max}`
        }
        return null;
    },
    isDuration: error => val => {
        if (!val) {
            return null;
        }
        const string = val.trim();
        if (!DURATION_FORMATS.ALL.filter(durationString => durationString.test(string)).length) {
            return error || 'Not valid duration format'
        }
        return null;
    },
    minDuration: (min, error) => val => {
        if (durationParser(val) < durationParser(min)) {
            return error || `Value can't be lower than ${min}`
        }
        return null
    },
    maxDuration: (max, error) => val => {
        if (durationParser(val) > durationParser(max)) {
            return error || `Value can't be higher than ${max}`
        }
        return null
    },
    isTime: error => val => {
        if (!val) {
            return null;
        }
        const string = val.trim();
        if (!TIME_FORMATS.ALL.filter(timeString => timeString.test(string)).length) {
            return error || 'Not valid time format'
        }
        return null;
    },
    isEmail: error => value => {
        // eslint-disable-next-line
        const emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (Array.isArray(value)) {
            return value.find(v => !emailRegEx.test(v)) ? 'Not valid email address' : null
        } else  {
            if (!emailRegEx.test(value)) {
                return error || 'Not valid email address'
            }
        }

        return null
    },
    isPassword: error => val => {
        if (val.length < 8) {
            return error || 'Password should consist of at least eight characters'
        }

        if (!/\d/.test(val)) {
            return error || 'Password should contain at least one digit'
        }

        if (!/[A-Z]/.test(val)) {
            return error || 'Password should contain an uppercase character'
        }
    },
    isHour: error => val => {
        if (/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(val)) {
            return error || 'Not valid HH:MM format'
        }
        return null;
    },
    isName: error => val => /\d/.test(val) ? error || 'Value contains digit' : null,
    isTrue: error => val => !val ? error || 'Value contains digit' : null
}