import { defineMessages } from 'react-intl';

export default defineMessages({
    header: {
        id: 'noPermissionToConfigure.header',
        defaultMessage: 'Permission denied…'
    },
    message: {
        id: 'noPermissionToConfigure.message',
        defaultMessage: 'We are really sorry, however in order to configure the chatbot in a room, your require the Project Manager or the Admin rights.'
    }
});