import React from 'react';

import style from './Error500.module.scss';

const Error500 = () => {
    return (
        <div className={style.error}>
            <div className={style.center}>
                <div className={style.number}>
                    <span>500</span>
                </div>
                <div className={style.title}>
                    Sorry, we’re having server problems.
                </div>
                <div className={style.subtitle}>
                    You can try to
                    <a onClick={window.reload}>
                        reload
                    </a>
                    the page
                </div>
            </div>
        </div>
    );
};

export default Error500;
