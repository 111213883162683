import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'deleteAccount.title',
        defaultMessage: 'Delete account',
    },
    subtitle: {
        id: 'deleteAccount.subtitle',
        defaultMessage: 'Are you sure you want to delete your account?',
    },
    deleteWorkspace: {
        id: 'deleteAccount.deleteWorkspace',
        defaultMessage: 'Your workspace will be also deleted.',
    },
    typeInBelow: {
        id: 'deleteAccount.typeInBelow',
        defaultMessage: 'To delete it, please type in below:',
    },
    deleteAccount: {
        id: 'deleteAccount.deleteAccount',
        defaultMessage: 'DELETE',
    },
    typeInDeleteAccount: {
        id: 'deleteAccount.typeInDeleteAccount',
        defaultMessage: 'Type in DELETE',
    },
    cancel: {
        id: 'deleteAccount.cancel',
        defaultMessage: 'Cancel',
    },
    delete: {
        id: 'deleteAccount.delete',
        defaultMessage: 'Delete Account',
    },
    alt: {
        id: 'deleteAccount.alt',
        defaultMessage: 'A site',
    },
    invalidValue: {
        id: 'deleteAccount.invalidValue',
        defaultMessage: 'Invalid value',
    },
});