import React from 'react';
import cx from "classnames";
import { AnimatePresence, motion } from "framer-motion"
import PropTypes from "prop-types";

import useUI from "@quidlo/common/hooks/useUI";
import { Portal, Space } from "@quidlo/ui";

import style from './Modal.module.scss';

export default function ModalHOC(ModalComponent, config) {
    const ModalHOCClass = props => {
        const { modal, modalClose } = useUI();
        return (
            <AnimatePresence>
                {
                    modal.id === config.id ? (
                        <Portal>
                            <div className={style.root}>
                                <motion.div
                                    className={cx(style.card, style[`size-${config.size}`])}
                                    initial={{ opacity: 0, scale: 0.8 }}
                                    animate={{ opacity: 1, scale: 1, transition: {
                                        duration: 0.3,
                                        delay: 0.2,
                                        ease: [0, 0.71, 0.2, 1.01]
                                    } }}
                                    exit={{ opacity: 0, transition: { duration: 0.3 } }}
                                >
                                    <div className={style.body}>
                                        {
                                            config.illustration && (
                                                <div className={style.left}>
                                                    <img className={style.illustration} src={config.illustration}/>
                                                </div>
                                            )
                                        }
                                        <div className={style.right}>
                                            <ModalComponent {...props} />
                                        </div>
                                    </div>
                                </motion.div>
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1, transition: { duration: 0.3 } }}
                                    exit={{ opacity: 0, transition: { duration: 0.3 } }}
                                    className={style.overlay}
                                    onClick={modalClose}
                                />
                            </div>
                        </Portal>
                    ) : null
                }
            </AnimatePresence>
        )
    };

    return ModalHOCClass;
}

export const ModalHeader = ({ children }) => (
    <Space className={style.header} mb="l">
        {children}
    </Space>
);

ModalHeader.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string,
    ]).isRequired,
}

export const ModalContent = ({ children }) => (
    <Space className={style.content} mb="l">
        {children}
    </Space>
);

ModalContent.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
}

export const ModalActions = ({ children }) => (
    <Space className={style.actions} direction="row">
        {children}
    </Space>
);

ModalActions.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
}