import React from 'react';
import { useIntl } from 'react-intl';
import { Navigate } from 'react-router-dom';
import cx from "classnames";

import { APPROVAL_STATUSES, FEATURE, PROJECT_ROLE, WORKSPACE_ROLE } from "@quidlo/common/constants/main";
import useForm from '@quidlo/common/hooks/useForm2';
import useWorkspace from '@quidlo/common/hooks/useWorkspace';
import analytics from "@quidlo/common/utils/analytics";
import validators from "@quidlo/common/utils/validators";
import { Autocomplete, Avatar, Button, Space, Spinner, Tag } from '@quidlo/ui';

import useSuggestions from '../../../hooks/useSuggestions';
import { getRequestedApprovalsQuery } from '../../../queries/approvals.queries';
import { getManagedProjectsListQuery } from '../../../queries/projects.queries';
import { getReportsFiltersUsersByProjects } from '../../../queries/reports.queries';
import { DateRangePicker, Suggestions } from '../../../ui';
import ProjectPill from "../../../ui/ProjectPill/ProjectPill";
import ViewHOC from '../../../ui/View/View';
import { dateToString, setWeekday } from '../../../utils/dateHelpers';
import ApproveRequestsModal from '../modals/ApproveRequestsModal';
import RejectRequestsModal from '../modals/RejectRequestsModal';

import ToApproveList from './ToApproveList/ToApproveList';

import style from './ToApprove.module.scss';

import messages from './ToApprove.i18n';

const ToApprove = () => {
    const
        { workspace } = useWorkspace(),
        intl = useIntl(),
        [form, { isFormDirty, isFormValid, touchForm }] = useForm({
            date: {
                value: {
                    gte: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
                    lte: setWeekday(new Date(), 6, workspace?.weekStart),
                }
            },
            projects: {
                value: [],
                validators: [validators.isRequired()]
            },
            statuses: {
                value: APPROVAL_STATUSES,
                validators: [validators.isRequired()]
            },
            people: {
                value: [],
                validators: [validators.isRequired()]
            }
        }, {
            id: 'to-approve.form',
            persistent: true
        }),
        [filters, { formValue: filtersValue }] = useForm({
            date: {
                value: {
                    gte: dateToString(new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)),
                    lte: dateToString(setWeekday(new Date(), 6, workspace?.weekStart)),
                }
            },
            projects: {
                value: [],
            },
            statuses: {
                value: APPROVAL_STATUSES,
            },
            people: {
                value: [],
            }
        }, {
            id: 'to-approve.filters',
            persistent: true
        }),
        { data, isFetched } = getRequestedApprovalsQuery(filtersValue),
        { data: projectsOptions } = getManagedProjectsListQuery(),
        { data: usersOptions } = getReportsFiltersUsersByProjects(form.projects.value),
        {
            projects: projectsSuggestions,
            people: peopleSuggestions,
            statuses: statusesSuggestions,
            feedSuggestions
        } = useSuggestions(),
        apply = (
            date= {
                gte: dateToString(form.date.value.gte),
                lte: dateToString(form.date.value.lte)
            },
            projects = form.projects.value,
            statuses = form.statuses.value,
            people = form.people.value,
        ) => {
            filters.date.setValue({
                gte: date.gte,
                lte: date.lte,
            });
            filters.projects.setValue(projects);
            filters.statuses.setValue(statuses);
            filters.people.setValue(people);
            feedSuggestions('projects', form.projects.value)
            feedSuggestions('statuses', form.statuses.value)
            feedSuggestions('people', form.people.value)
            analytics.logTimesheetsFilters({
                screenName: "Approvals - To Approve",
                date: {
                    gte: new Date(date.gte),
                    lte: new Date(date.lte)
                },
                projectsNo: projects.length,
                statusesNo: statuses.length,
                peopleNo: people.length
            })
        };

    if (![WORKSPACE_ROLE.OWNER, WORKSPACE_ROLE.ADMIN].includes(workspace?.organizationRole) && workspace.projectsRole !== PROJECT_ROLE.MANAGER) {
        return (
            <Navigate to="/approvals/list/my" replace={true} />
        )
    }

    return (
        <>
            <Space mt="m" direction="row">
                <DateRangePicker
                    date={form.date.value}
                    onChange={form.date.onChange}
                    analyticsID="Approvals - To Approve"
                />
            </Space>
            <Space mt="m" p="s" direction="row" className={style.filters}>
                <Space direction="row">
                    <div className={style.select}>
                        <Autocomplete
                            {...form.projects}
                            label={intl.formatMessage(messages.projects)}
                            multiselect
                            allselect
                            options={projectsOptions.map(p => ({
                                label: <ProjectPill {...p} />,
                                labelText: p.name,
                                value: p.id,
                            }))}
                            size="large"
                            onlyselect
                            analyticsID="Approvals - To Approve - Projects"
                        />
                        <div className={cx([style.suggestions, (form.projects.error) && style.hidden])}>
                            <Suggestions
                                options={projectsOptions.map(o => ({
                                    label: o.name,
                                    value: o.id
                                }))}
                                suggestions={projectsSuggestions}
                                onClick={e => form.projects.onChange([e])}
                                maxNumber={3}
                                analyticsID="Approvals - To Approve - Projects"
                            />
                        </div>
                    </div>
                    <div className={style.select}>
                        <Autocomplete
                            {...form.people}
                            label={intl.formatMessage(messages.people)}
                            multiselect
                            allselect
                            options={usersOptions.map(t => ({
                                label: (
                                    <Tag
                                        avatar={
                                            <Avatar
                                                picture={t.avatarUrl}
                                                firstName={t.firstName}
                                                lastName={t.lastName}
                                            />
                                        }
                                        text={`${t.firstName} ${t.lastName}`}
                                    />
                                ),
                                labelText: `${t.firstName} ${t.lastName}`,
                                value: t.id,
                            }))}
                            size="large"
                            onlyselect
                            analyticsID="Approvals - To Approve - People"
                        />
                        <div className={cx([style.suggestions, (form.people.error) && style.hidden])}>
                            <Suggestions
                                options={usersOptions.map(o => ({
                                    label: `${o.firstName[0], o.lastName}`,
                                    value: o.id
                                }))}
                                suggestions={peopleSuggestions}
                                onClick={e => form.people.onChange([e])}
                                maxNumber={3}
                                analyticsID="Approvals - To Approve - People"
                            />
                        </div>
                    </div>
                    <div className={style.select}>
                        <Autocomplete
                            {...form.statuses}
                            label={intl.formatMessage(messages.status)}
                            multiselect
                            allselect
                            options={APPROVAL_STATUSES.map(t => ({
                                label: intl.formatMessage(messages[t]),
                                labelText: intl.formatMessage(messages[t]),
                                value: t,
                            }))}
                            size="large"
                            onlyselect
                            analyticsID="Approvals - To Approve - Statuses"
                        />
                        <div className={cx([style.suggestions, (form.statuses.error) && style.hidden])}>
                            <Suggestions
                                options={APPROVAL_STATUSES.map(t => ({
                                    label: intl.formatMessage(messages[t]),
                                    value: t
                                }))}
                                suggestions={statusesSuggestions}
                                onClick={e => form.statuses.onChange([e])}
                                maxNumber={3}
                                analyticsID="Approvals - To Approve - Statuses"
                            />
                        </div>
                    </div>
                </Space>
                <span>
                    <Button
                        size={'small'}
                        color={'white'}
                        onDisabledClick={touchForm}
                        disabled={!isFormDirty || !isFormValid}
                        onClick={() => apply()}
                        text={intl.formatMessage(messages.apply)}
                        shadow
                    />
                </span>
            </Space>
            <Space mt="m">
                {
                    !isFetched ? (
                        <Space p="l">
                            <Spinner active />
                        </Space>
                    ) : (
                        <ToApproveList
                            data={data}
                            dateRange={form.date.value}
                        />
                    )
                }
            </Space>
            <ApproveRequestsModal />
            <RejectRequestsModal />
        </>
    );
};

export default ViewHOC((ToApprove),
    {
        title: 'Approvals - To Approve',
        menu: true,
        topbar: true,
        auth: {},
        subscription: true,
        feature: FEATURE.APPROVALS
    }
);

