import React from 'react';
import PropTypes from 'prop-types';

import { Avatar, Tooltip } from '../index';

import style from './AvatarStack.module.scss';

const AvatarStack = ({ avatars }) => {
    const firstFivePeople = avatars.slice(0, 5);
    let tooltipText = '';

    avatars.slice(5).map(person => {
        tooltipText = tooltipText + `${person.first_name} ${person.last_name}\n`
    })

    return (
        <div className={style.wrapper}>
            {firstFivePeople.map(({ avatar_url, first_name, last_name }) => (
                <Tooltip key={avatar_url} text={`${first_name} ${last_name}`} position={'bottom'}>
                    <div className={style.avatar} key={avatar_url}>
                        <Avatar picture={avatar_url} firstName={first_name} lastName={last_name} />
                    </div>
                </Tooltip>
            ))}
            {avatars.length > 5 &&
                <Tooltip text={tooltipText} position={'bottom'}>
                    <div className={style.moreAvatars}>
                        <span>+{avatars.length - 5}</span>
                    </div>
                </Tooltip>
            }
        </div>
    )
};

AvatarStack.propTypes = {
    avatars: PropTypes.array.isRequired
};

export default AvatarStack;