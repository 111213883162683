import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import style from './Card.module.scss';

const Card = ({ title, color, children, headerRightChildren, noPadding }) => (
    <div className={cx(style.card)}>
        {title && (
            <div className={cx(style.title)}>
                <div className={style.left} style={{ color }}>
                    {title}
                </div>
                <div className={style.right}>{headerRightChildren}</div>
                {color && (
                    <div
                        className={style.label}
                        style={{ backgroundColor: color }}
                    />
                )}
            </div>
        )}
        <div className={cx(style.content, noPadding && style.noPadding)}>
            {children}
        </div>
    </div>
);

Card.propTypes = {
    title: PropTypes.string,
    color: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    headerRightChildren: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    noPadding: PropTypes.bool,
};

Card.defaultProps = {
    title: undefined,
    color: undefined,
    headerRightChildren: undefined,
    noPadding: false,
};

export default Card;
