import { useContext } from 'react';

import { AuthContext } from '../providers/AuthProvider';

const useWorkspaceFeatures = () => {
    const {  workspaceFeatures, patchWorkspaceFeatures } = useContext(AuthContext);

    return {
        features: workspaceFeatures,
        patchFeatures: patchWorkspaceFeatures,
    };
};

export default useWorkspaceFeatures;
