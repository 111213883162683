import React from 'react';
import PropTypes from 'prop-types';

import { Context, Icon, Space } from "@quidlo/ui";

import style from "./DropdownMenu.module.scss";

const DropdownMenu = ({
    options,
    children,
    position,
    disabled
}) => {
    return (
        <Context
            fixed={position === 'fixed'}
            disabled={disabled}
            dismiss='click'
            context={(
                <div className={style.menu}>
                    <div className={style.content}>
                        {
                            options.map(section => (
                                <div className={style.section} key={section[0].label}>
                                    {section.map(opt => (
                                        <div
                                            onClick={opt.onClick}
                                            key={opt.label}
                                        >
                                            <Space
                                                className={style.menuItem}
                                                direction="row"
                                                ph="s"
                                            >
                                                {opt.icon && (
                                                    <Space mr="xs">
                                                        <Icon icon={opt.icon} color="blue" />
                                                    </Space>
                                                )}
                                                <span>{opt.label}</span>
                                            </Space>
                                        </div>
                                    ))}
                                </div>
                            ))
                        }
                    </div>
                </div>
            )}
        >
            <div className={style.dropdown}>
                {children}
            </div>
        </Context>
    );
};

DropdownMenu.propTypes = {
    options: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.string,
        onClick: PropTypes.func,
        label: PropTypes.string.isRequired,
    }))).isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    position: PropTypes.oneOf(['absolute', 'fixed']),
    disabled: PropTypes.bool,
};

DropdownMenu.defaultProps = {
    position: 'absolute',
    disabled: false,
};

export default DropdownMenu;