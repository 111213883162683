import React from 'react';
import { useIntl } from 'react-intl';

import UsersService from "@quidlo/common/api/UsersService";
import useAuthentication from "@quidlo/common/hooks/useAuthentication";
import useForm from "@quidlo/common/hooks/useForm2";
import useUI from "@quidlo/common/hooks/useUI";
import validators from "@quidlo/common/utils/validators";
import { Button, Input, Space } from "@quidlo/ui";

import illustration from "../../../../../assets/modals/pencil.png";
import ModalHOC, { ModalActions, ModalContent, ModalHeader } from "../../../../../ui/Modal/Modal";

import messages from './ChangePassword.i18n';

const ChangePassword = () => {
    const
        { user } = useAuthentication(),
        { modal, modalClose, toastPush } = useUI(),
        intl = useIntl(),
        [form, { isFormValid, touchForm, cleanForm, formError }] = useForm({
            oldPassword: {
                value: ''
            },
            newPassword: {
                value: '',
                validators: [validators.isPassword()]
            },
            repeatedPassword: {
                value: '',
                validators: [validators.isPassword()]
            }
        }, {
            validators: [
                form => form.repeatedPassword.value !== form.newPassword.value ? intl.formatMessage(messages.passwordsDoNotMatch) : null
            ]
        }),
        close = () => {
            cleanForm();
            modalClose();
        },
        save = async () => {
            try {
                await UsersService.patchPassword(
                    user.id,
                    {
                        oldPassword: form.oldPassword.value,
                        newPassword: form.newPassword.value,
                    }
                )
                if (modal.callback) {
                    modal.callback();
                }
                close();
            } catch (e) {
                toastPush({
                    text: e.response.data.message,
                    color: 'red'
                });
            }
        };

    return (
        <>
            <ModalHeader>
                {intl.formatMessage(messages.editPassword)}
            </ModalHeader>
            <ModalContent>
                <Space mb="l">
                    <Input
                        {...form.oldPassword}
                        type="password"
                        label={intl.formatMessage(messages.oldPassword)}
                    />
                </Space>
                <Space mb="l">
                    <Input
                        {...form.newPassword}
                        type="password"
                        label={intl.formatMessage(messages.newPassword)}
                    />
                </Space>
                <Space>
                    <Input
                        {...form.repeatedPassword}
                        error={form.repeatedPassword.error || formError}
                        type="password"
                        label={intl.formatMessage(messages.repeatedPassword)}
                    />
                </Space>
            </ModalContent>
            <ModalActions>
                <Space mr="m">
                    <Button
                        color="white"
                        text={intl.formatMessage(messages.cancel)}
                        onClick={modalClose}
                    />
                </Space>
                <Button
                    color="blue"
                    text={intl.formatMessage(messages.save)}
                    onClick={save}
                    disabled={!isFormValid}
                    onDisabledClick={touchForm}
                    shadow
                />
            </ModalActions>
        </>
    );
};

export default ModalHOC(
    ChangePassword,
    {
        id: 'changePassword',
        size: 'small',
        illustration,
    }
);