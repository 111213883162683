import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import analytics from "../../utils/analytics";

export const UIContext = React.createContext({
    toasts: [],
    toastPush: () => {},
    toastPop: () => {},
    isSpinner: false,
    spinnerPush: () => {},
    spinnerPop: () => {},
    modal: null,
    modalOpen: () => {},
    modalClose: () => {},
    isMenu: false,
    setIsMenu: () => {},
    isTopbar: false,
    setIsTopbar: () => {},
    egg: null,
    displayEgg: () => {},
    isMobile: false,
});

const UIProvider = ({ children }) => {
    const [spinner, setSpinner] = useState([]);
    const [toasts, setToasts] = useState([]);
    const [modal, setModal] = useState({ id: null, data: {}, callback: null });
    const [isMenu, setIsMenu] = useState(false);
    const [isTopbar, setIsTopbar] = useState(false);
    const [egg, setEgg] = useState(null);
    const [isMobile, setIsMobile] = useState(
        window.matchMedia('only screen and (max-width: 1100px)').matches,
    );

    const spinnerPush = () => {
        setSpinner(s => {
            const tmp = [...s];
            tmp.push(true);
            return tmp;
        });
    };

    const spinnerPop = () => {
        setSpinner(s => {
            const tmp = [...s];
            tmp.pop(true);
            return tmp;
        });
    };

    const toastPush = toast => {
        analytics.logToastDisplay(toast.color || 'green', toast.text)
        setToasts(prevState => {
            const tmp = [...prevState];
            const id = +new Date();
            tmp.push({ ...toast, id, close: () => toastPop(id) });
            return tmp;
        });
    };

    const toastPop = id => {
        setToasts(prevState => [...prevState].filter(t => t.id !== id));
    };

    const modalOpen = (id, data, callback = () => {}) => {
        analytics.logModalDisplay(id)
        setModal({
            id: id || null,
            data: data || null,
            callback: callback || null
        });
    };

    const modalClose = () => {
        setModal({ id: null, data: {}, callback: null });
    };

    const displayEgg = type => {
        setEgg(type)
        setTimeout(() => setEgg(null), 3000);
    }

    const resizeHandler = useCallback(() => {
        if (window.matchMedia('only screen and (max-width: 1100px)').matches) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, []);

    useEffect(() => {
        window.addEventListener('resize', resizeHandler);
        return () => window.removeEventListener('resize', resizeHandler);
    });

    useEffect(() => {
        if (modal.id) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [modal.id]);

    const contextValue = useMemo(() => {
        return {
            toasts,
            toastPush,
            toastPop,
            isSpinner: !!spinner.length,
            spinnerPush,
            spinnerPop,
            modal,
            modalOpen,
            modalClose,
            isMenu,
            setIsMenu,
            isTopbar,
            setIsTopbar,
            egg,
            displayEgg,
            isMobile,
        };
    }, [spinner, toasts, modal, isMobile, isMenu, isTopbar, egg]);
    return (
        <UIContext.Provider value={contextValue}>{children}</UIContext.Provider>
    );
};

UIProvider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

export default UIProvider;
