import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'youHaveReached.title',
        defaultMessage: 'You have reached the number of 10 users per workspace!',
    },
    description1: {
        id: 'youHaveReached.description1',
        defaultMessage: 'In the Starter Plan you are allowed to keep',
    },
    upTo10users: {
        id: 'youHaveReached.upTo10users',
        defaultMessage: 'up to 10 users',
    },
    description2: {
        id: 'youHaveReached.description2',
        defaultMessage: 'per Workspace. Reduce users to continue with Free Plan or upgrade!',
    },
    alt: {
        id: 'youHaveReached.alt',
        defaultMessage: 'A woman and man shaking hands',
    },
    cancel: {
        id: 'youHaveReached.cancel',
        defaultMessage: 'Cancel',
    },
    upgrade: {
        id: 'youHaveReached.upgrade',
        defaultMessage: 'Upgrade',
    },
});
