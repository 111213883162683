import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'addTaxInformation.title',
        defaultMessage: 'Invoice information',
    },
    subtitle: {
        id: 'addTaxInformation.subtitle',
        defaultMessage: 'Unfortunately, at this moment only registered companies are allowed to upgrade to the PRO plan. You need to add your company’s tax information to complete the payment via Stripe.',
    },
    alt: {
        id: 'addTaxInformation.alt',
        defaultMessage: 'Woman in front of a notebook',
    },
    weAreVerifying: {
        id: 'addTaxInformation.weAreVerifying',
        defaultMessage: 'We are verifying your invoice information...',
    },
    thatMightTake: {
        id: 'addTaxInformation.thatMightTake',
        defaultMessage: '...that might take a few seconds.',
    },
    cancel: {
        id: 'addTaxInformation.cancel',
        defaultMessage: 'Cancel',
    },
});

