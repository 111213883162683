import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import UsersService from "@quidlo/common/api/UsersService";
import COLORS from '@quidlo/common/constants/colors';
import useForm from "@quidlo/common/hooks/useForm2";
import useUI from "@quidlo/common/hooks/useUI";
import useWorkspace from "@quidlo/common/hooks/useWorkspace";
import validators from "@quidlo/common/utils/validators";
import { Autocomplete, Button, Icon, Input, Space, Switch, Tag } from "@quidlo/ui";

import illustration from "../../../../assets/modals/project.png";
import { addProjectMutation } from "../../../../queries/projects.queries";
import { InputColor } from "../../../../ui";
import ModalHOC, { ModalActions, ModalContent, ModalHeader } from "../../../../ui/Modal";

import style from "./CreateProject.module.scss";

import messages from './CreateProject.i18n';

const randomColor = () => COLORS.PREDEFINED_COLORS[Math.floor(Math.random() * COLORS.PREDEFINED_COLORS.length)].color;

const fetchUsers = async (workspaceCode, str) => {
    const { data } = await UsersService.getAll(workspaceCode, { 'suggestions-for': str })
    return data.map(o => ({
        label: (
            <div>
                {o.firstName && o.lastName ? (
                    <span className={style.userName}>{o.firstName} {o.lastName}</span>
                ) : (<Tag text={o.email} />)}
            </div>
        ),
        labelText: o.email,
        value: o.id
    }))
};

const FORM = {
    color: {
        value: '',
    },
    name: {
        value: '',
        validators: [validators.isRequired(), validators.minLength(3), validators.maxLength(40)]
    },
    managers: {
        value: [],
    },
    isBillable: {
        value: false,
    },
}

const CreateProject = () => {
    const
        { modalClose } = useUI(),
        intl = useIntl(),
        navigate = useNavigate(),
        [form, { isFormValid, cleanForm, touchForm }] = useForm(FORM),
        { mutate: addProject } = addProjectMutation(),
        { workspace } = useWorkspace(),

        close = () => {
            cleanForm();
            modalClose();
        },
        save = () => {
            if (!isFormValid) return;

            addProject({
                name: form.name.value,
                color: form.color.value,
                managerUserIds: form.managers.value,
                isBillable: form.isBillable.value
            }, {
                onSuccess: ({ data: { id } }) => {
                    close();
                    navigate(`/projects/${id}`);
                }
            });
        };

    useEffect(() => {
        form.color.setValue(randomColor())
    },[])

    return (
        <>
            <ModalHeader>
                <FormattedMessage {...messages.createProject} />
            </ModalHeader>
            <ModalContent>
                <Space mb="l">
                    <Input
                        {...form.name}
                        label={intl.formatMessage(messages.typeProjectsName)}
                        isRequired
                        name="project-create-name"
                        isNarrow
                    />
                </Space>
                <Space mb="l">
                    <Autocomplete
                        { ...form.managers }
                        label={intl.formatMessage(messages.projectManagers)}
                        fetch={phrase => fetchUsers(workspace.code, phrase)}
                        multiselect
                        optionsLength={3}
                    />
                </Space>
                <Space mb="l" direction="row" className={style.row}>
                    {intl.formatMessage(messages.projectColor)}
                    <InputColor
                        {...form.color}
                    />
                </Space>
                <Space mb="l" direction="row" className={style.row}>
                    <div className={style.center}>
                        <Space mr="xxs">
                            <Icon icon="dollar" />
                        </Space>
                        {intl.formatMessage(messages.billable)}
                    </div>
                    <Switch
                        { ...form.isBillable }
                        options={[
                            {
                                value: false,
                                label: ''
                            },
                            {
                                value: true,
                                label: ''
                            }
                        ]}
                        size="small"
                        fieldId="create-project-billable-switch"
                    />
                </Space>
            </ModalContent>
            <ModalActions>
                <Space mr="m">
                    <Button
                        color="white"
                        text={intl.formatMessage(messages.cancel)}
                        onClick={close}
                    />
                </Space>
                <Button
                    color="blue"
                    text={intl.formatMessage(messages.save)}
                    onClick={save}
                    onDisabledClick={touchForm}
                    disabled={!isFormValid}
                    shadow
                />
            </ModalActions>
        </>
    );
};

export default ModalHOC(
    CreateProject,
    {
        id: 'createProject',
        size: 'small',
        illustration
    }
);