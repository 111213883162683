import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Icon } from '@quidlo/ui';

import style from './Textarea.module.scss';

let timeout = null;

const timeoutDelay = 800,
    Textarea = ({
        fieldId,
        label,
        placeholder,
        icon,
        size,
        value,
        error,
        disabled,
        errorHidden,
        onChange,
        onBlur,
        onDelayedChange,
        rows,
        maxLength
    }) => {
        const
            elementRef = useRef(null),
            [focused, setFocused] = useState(false),
            [touched, setTouched] = useState(false),
            initValue = value,
            focusHandler = () => {
                setFocused(true);
                setTouched(true);
            },
            blurHandler = () => {
                if (onBlur) {
                    onBlur(value);
                }
                setFocused(false);
                setTouched(true);
            },
            changeHandler = event => {
                const
                // eslint-disable-next-line no-shadow
                    { target: { value } } = event;
                elementRef.current.style.height = 'auto';
                elementRef.current.style.height = `${elementRef.current.scrollHeight}px`;
                if (onChange) {
                    onChange(value);
                } else if (onDelayedChange) {
                    clearTimeout(timeout);
                    timeout = setTimeout(() => {
                        onDelayedChange(value);
                    }, timeoutDelay);
                }
            },
            errorVisibility = !errorHidden && touched && !focused && (initValue !== value || (!initValue && !value));

        useEffect(() => {
            elementRef.current.setAttribute('style', `height:${23 * rows}px;overflow-y:hidden;`);
        }, [elementRef.current]);

        return (
            <div
                className={cx(style.textarea, (error && errorVisibility) && style.error, disabled && style.disabled, style[`size-${size}`])}>
                <textarea
                    rows={String(rows)}
                    name={fieldId}
                    value={value}
                    onChange={changeHandler}
                    onBlur={blurHandler}
                    onFocus={focusHandler}
                    ref={elementRef}
                    className={cx(
                        value && style.filled,
                        icon && style.iconInput
                    )}
                    maxLength={maxLength}
                />
                <span className={style.bar} />
                {icon && (
                    <div className={style.icon}>
                        <Icon icon={icon} size="small" color="grey" />
                    </div>
                )}
                {label ? <label htmlFor={name}>{label}</label> : null}
                {placeholder ? <span className={style.hint}>{placeholder}</span> : null}
                {errorVisibility && <span className={style.errormessage}>{error}</span>}
            </div>
        )
    };

Textarea.propTypes = {
    fieldId: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    icon: PropTypes.string,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    error: PropTypes.string,
    validators: PropTypes.object,
    customValidators: PropTypes.arrayOf(PropTypes.func),
    disabled: PropTypes.bool,
    errorHidden: PropTypes.bool,
    persistent: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onDelayedChange: PropTypes.func,
    rows: PropTypes.number,
    maxLength: PropTypes.number
};

Textarea.defaultProps = {
    fieldId: 'textarea',
    label: '',
    placeholder: '',
    icon: undefined,
    size: 'medium',
    value: '',
    error: undefined,
    validators: {},
    customValidators: [],
    disabled: false,
    errorHidden: undefined,
    persistent: false,
    onChange: undefined,
    onBlur: undefined,
    onDelayedChange: undefined,
    rows: 1,
    maxLength: undefined
};

export default Textarea;
