import React from 'react';
import PropTypes from "prop-types";

import COLORS from '@quidlo/common/constants/colors';
import { Tag } from "@quidlo/ui";

const ProjectPill = ({ name, isBillable, isArchived, color }) => (
    isArchived ? (
        <Tag
            text={name}
            color={COLORS.PROJECT_ARCHIVED_COLOR}
            icon={isBillable ? 'dollar' : ''}
            type="dashed"
        />
    ) : (
        <Tag
            text={name}
            color={color}
            icon={isBillable ? 'dollar' : ''}
            type="stroked"
        />
    )
)


ProjectPill.propTypes = {
    name: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    isBillable: PropTypes.bool,
    isArchived: PropTypes.bool
};

ProjectPill.defaultProps = {
    isBillable: true,
    isArchived: false
};

export default ProjectPill;
