import { defineMessages } from 'react-intl';

export default defineMessages({
    room: {
        id: 'chatbotList.roomHeader',
        defaultMessage: 'Room'
    },
    project: {
        id: 'chatbotList.projectHeader',
        defaultMessage: 'Projects'
    },
    intent: {
        id: 'chatbotList.intentHeader',
        defaultMessage: 'Intents'
    },
    headerTitle: {
        id: 'chatbotList.mainHeader',
        defaultMessage: 'Chatbot configuration list'
    },
    listHeader: {
        id: 'chatbotList.header',
        defaultMessage: 'Hangouts chatbots'
    },
    slackListHeader: {
        id: 'chatbotList.slackListHeader',
        defaultMessage: 'Configured Slack teams'
    },
    accountNotConnected: {
        id: 'chatbotList.accountNotConnected',
        defaultMessage: 'You have not gone through the process of connecting accounts.'
    },
    privateMessageNeeded: {
        id: 'chatbotList.privateMessageNeeded',
        defaultMessage: 'You have a connected account but to fully use the bot`s capabilities write a private message to the bot.'
    },
    youCanUseBot: {
        id: 'chatbotList.youCanUseBot',
        defaultMessage: 'You have a connected account and you can use the bot`s capabilities.'
    },
    hangoutsStatus: {
        id: 'chatbotList.hangoutsStatus',
        defaultMessage: 'Hangouts configuration status'
    },
    slackStatus: {
        id: 'chatbotList.slackStatus',
        defaultMessage: 'Slack configuration status'
    },
    howToConfigure: {
        id: 'chatbotList.howToConfigure',
        defaultMessage: 'How to configure?'
    },
    configured: {
        id: 'chatbotList.configured',
        defaultMessage: 'Configured'
    },
    notConfigured: {
        id: 'chatbotList.notConfigured',
        defaultMessage: 'Not configured'
    },
    addRoom: {
        id: 'chatbotList.addRoom',
        defaultMessage: 'HOW TO ADD ROOMS?'
    },
    noRoomsConfigured: {
        id: 'chatbotList.noRoomsConfigured',
        defaultMessage: 'No rooms configured'
    },
    slackTeam: {
        id: 'chatbotList.slackTeam',
        defaultMessage: 'Slack team'
    },
    accessDenied: {
        id: 'chatbotList.accessDenied',
        defaultMessage: 'Could not complete Slack bot installation, missing required permissions.'
    },
    internalError: {
        id: 'chatbotList.internalError',
        defaultMessage: 'Error occurred while authorising team, please try again.'
    },
    botAlreadyInstalled: {
        id: 'chatbotList.botAlreadyInstalled',
        defaultMessage: 'Timesheets bot already installed in this Slack workspace.'
    },
    emptyCode: {
        id: 'chatbotList.emptyCode',
        defaultMessage: 'Missing authorisation code, please try again.'
    },
    defaultSlackError: {
        id: 'chatbotList.defaultSlackError',
        defaultMessage: 'Ups, something went wrong!'
    },
});