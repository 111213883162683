import { defineMessages } from 'react-intl';

export default defineMessages({
    workspaceCreated: {
        id: 'useWorkspace.workspaceCreated',
        defaultMessage: 'Workspace created',
    },
    workspaceEdited: {
        id: 'useWorkspace.workspaceEdited',
        defaultMessage: 'Workspace edited',
    },
    workspaceLeft: {
        id: 'useWorkspace.workspaceLeft',
        defaultMessage: 'Workspace left',
    },
    workspaceDeleted: {
        id: 'useWorkspace.workspaceDeleted',
        defaultMessage: 'Workspace deleted',
    },
    cannotLeaveWorkspace: {
        id: 'useWorkspace.cannotLeaveWorkspace',
        defaultMessage: 'Cannot leave workspace as an owner',
    },
});
