import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from "react-router-dom";

import useForm from "@quidlo/common/hooks/useForm2";
import useUI from "@quidlo/common/hooks/useUI";
import validators from "@quidlo/common/utils/validators";
import { Button, InputCollection, Space } from "@quidlo/ui";

import illustration from "../../../../assets/modals/deal.png";
import { addUsersMutation, getUsersListAll, getUsersTotalQuery } from '../../../../queries/users.queries';
import { getWorkspaceSubscriptionQuery } from "../../../../queries/workspace.queries";
import ModalHOC, { ModalActions, ModalContent, ModalHeader } from "../../../../ui/Modal/Modal";

import styles from './InviteUsers.module.scss';

import messages from './InviteUsers.i18n';

const InviteUsers = () => {
    const
        intl = useIntl(),
        navigate = useNavigate(),
        { modalClose, modalOpen } = useUI(),
        { data: total } = getUsersTotalQuery(),
        { data: subscription } = getWorkspaceSubscriptionQuery(),
        { mutate: inviteUsers } = addUsersMutation(),
        { data: allUsers } = getUsersListAll(),
        { toastPush } = useUI(),
        [form, { cleanForm, isFormValid, touchForm }] = useForm({
            users: {
                value: [],
                validators: [validators.isRequired(), validators.isEmail()]
            }
        }),
        navigateToUpgrade = () => {
            modalClose();
            navigate('/settings/subscription')
        },
        save = () => {
            const duplicatedEmails = [];
            allUsers?.pages?.[0].map(user => {
                form.users.value.includes(user.email) && duplicatedEmails.push(user.email)
            })

            if (duplicatedEmails.length) {
                toastPush({
                    text: intl.formatMessage(duplicatedEmails.length === 1 ? messages.emailAlreadyInvited : messages.emailsAlreadyInvited, {
                        emails: duplicatedEmails.join(', '),
                    }),
                    color: 'red',
                });
            } else if (total + form.users.value.length >= 10 && subscription.name === 'starter') {
                const amountOfUsersUserCanInvite = 10 - total;
                if (amountOfUsersUserCanInvite > 0) {
                    inviteUsers(form.users.value.slice(0, amountOfUsersUserCanInvite));
                }
                modalOpen('youHaveReached', {});
            } else {
                inviteUsers(form.users.value);
                modalClose();
                cleanForm();
            }
        };

    return (
        <>
            <ModalHeader>
                {intl.formatMessage(messages.inviteUsers)}
            </ModalHeader>
            <ModalContent>
                <InputCollection
                    {...form.users}
                    label={intl.formatMessage(messages.addUserEmail)}
                    name="users"
                    isRequired
                />
                {
                    subscription.name === 'starter' && (
                        <Space mt="s" className={styles.upgrade}>
                            <p >
                                <FormattedMessage
                                    {...messages.starterinfo1}
                                    values={{
                                        b: chunks => <strong> {chunks} </strong>,
                                    }}
                                />
                            </p>
                            <p className={styles.upgrade}>
                                <a onClick={navigateToUpgrade}>{intl.formatMessage(messages.upgrade)}</a>
                                {intl.formatMessage(messages.starterinfo2)}
                            </p>
                        </Space>
                    )
                }
            </ModalContent>
            <ModalActions>
                <Space mr="m">
                    <Button color="white" text={intl.formatMessage(messages.cancel)} onClick={modalClose} />
                </Space>
                <Button
                    color="blue"
                    text={intl.formatMessage(messages.invite)}
                    disabled={!isFormValid}
                    onClick={save}
                    onDisabledClick={touchForm}
                />
            </ModalActions>
        </>
    )
};


export default ModalHOC(
    InviteUsers,
    {
        id: 'inviteUsers',
        size: 'large',
        illustration,
    }
);