import fetch from '../utils/fetch';

export default {
    post: (workspaceCode, userId, { date, projects ,users ,tags, type, period, billable }, description = undefined, title = undefined, freezeDate = undefined, recipients = []) =>
        fetch.post(`/v1/${workspaceCode}/users/${userId}/shared-reports${recipients.length ? '?send-mail=on' : ''}`, {
            dateFrom: date.gte,
            dateTo: date.lte,
            projectIds: projects || [],
            tagIds: tags || [],
            userIds: users || [],
            defaultViewSettings: {
                type,
                period
            },
            recipients: recipients.map(r => ({
                email: r
            })),
            freezeDate,
            description,
            title,
            billable: billable === 'all' ? '' : billable
        }),
    get: code => fetch.get(`/v1/shared-reports/${code}`),
    getData: (code, { date, projects = [], users = [], tags = [], type, period, billable }) => {
        const params = {
            period,
            billable: billable === 'all' ? '' : billable,
            'date-from': date.gte,
            'date-to': date.lte,
            'project-ids': projects ? projects.join(',') : [],
            'user-ids': users ? users.join(',') : [],
            'tag-ids': tags ? tags.join(',') : []
        };
        return fetch.get(`/v1/shared-reports/${code}/${type}`, { params });
    },
    getCSV: (code, { date, projects = [], users = [], tags = [], type, period, billable }) => {
        const params = {
            period,
            billable: billable === 'all' ? '' : billable,
            'date-from': date.gte,
            'date-to': date.lte,
            'project-ids': projects.join(','),
            'user-ids': users.join(','),
            'tag-ids': tags.join(',')
        };
        return fetch.get(`/v1/shared-reports/${code}/${type}/generate-csv`, { params });
    },
    getPDF: (code, { date, projects = [], users = [], tags = [], type, period, billable }) => {
        const config = {
            responseType: 'arraybuffer',
            params: {
                period,
                billable: billable === 'all' ? '' : billable,
                'date-from': date.gte,
                'date-to': date.lte,
                'project-ids': projects.join(','),
                'user-ids': users.join(','),
                'tag-ids': tags.join(',')
            }
        };

        return fetch.get(`/v1/shared-reports/${code}/${type}/generate-pdf`, config);
    },
    getXLSX: (code, { date, projects = [], users = [], tags = [], type, period, billable }) => {
        const config = {
            responseType: 'arraybuffer',
            params: {
                period,
                billable: billable === 'all' ? '' : billable,
                'date-from': date.gte,
                'date-to': date.lte,
                'project-ids': projects.join(','),
                'user-ids': users.join(','),
                'tag-ids': tags.join(',')
            }
        };

        return fetch.get(`/v1/shared-reports/${code}/${type}/generate-xlsx`, config);
    },
    exportToGoogleSheets: (code, { date, projects = [], users = [], tags = [], type, period, billable }, googleAccessToken) => {
        const params = {
            period,
            billable: billable === 'all' ? '' : billable,
            'date-from': date.gte,
            'date-to': date.lte,
            'project-ids': projects.join(','),
            'user-ids': users.join(','),
            'tag-ids': tags.join(',')
        };

        return fetch.post(`/v1/shared-reports/${code}/${type}/export-to-google-spreadsheets?period=${params.period}&billable=${params.billable}&date-from=${params['date-from']}&date-to=${params['date-to']}&project-ids=${params['project-ids']}&user-ids=${params['user-ids']}&tag-ids=${params['tag-ids']}`, { googleAccessToken });
    }
};