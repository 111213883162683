import { defineMessages } from 'react-intl';

export default defineMessages({
    deleteWorkspace: {
        id: 'workspaceDelete.deleteWorkspace',
        defaultMessage: 'Delete Workspace',
    },
    byDeleting: {
        id: 'workspaceDelete.byDeleting',
        defaultMessage: 'By deleting <b>{workspace}</b>  Workspace, you will permanently erase all tasks, reports and projects. This action is not reversible.',
    },
    delete: {
        id: 'workspaceDelete.delete',
        defaultMessage: 'Delete',
    },
});