import React, { Fragment } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { durationFormatter } from '@quidlo/common/utils/duration';
import { Avatar, Checkbox, Icon, InfoCard, Pill, Tag, Tooltip } from '@quidlo/ui';

import { List, ListCell } from '../../../../ui';
import { dateToString } from '../../../../utils/dateHelpers';

import noApprovalsPNG from './assets/noApprovals.png';

import style from './PotentialApprovalsList.module.scss';

import messages from './PotentialApprovalsList.i18n';

const PotentialApprovalsList = ({
    data,
    chosenProjects,
    setChosenProjects,
    totalTimeInAllProjects,
    totalTimeOfChosenTasks,
    dateRange
}) => {
    const
        intl = useIntl(),
        projectsWithIsSelectedField = data.projects.map(proj => ({ ...proj, isSelected: chosenProjects.includes(proj.project.id), id: proj.project.id })),
        selectProject = id => {
            setChosenProjects(prevChosenProjects => {
                if(prevChosenProjects.includes(id)) {
                    return prevChosenProjects.filter(item => {
                        return item !== id
                    })
                } else {
                    return [...prevChosenProjects, id]
                }
            })
        },
        selectAll = () => {
            if(chosenProjects.length === projectsWithIsSelectedField.length) {
                setChosenProjects([])
            } else {
                setChosenProjects(projectsWithIsSelectedField.map(project => project.project.id))
            }
        };


    if (!data?.projects?.length) {
        return (
            <div className={style.infoCardWrapper}>
                <InfoCard
                    illustration={<img src={noApprovalsPNG} alt="a man at a desk" />}
                    header={intl.formatMessage(messages.noDataHeader)}
                    text={intl.formatMessage(messages.noDataBody)}
                />
            </div>
        )
    }

    const renderRow = ({
        duration_mins,
        project: {
            id,
            name,
            color,
            is_billable
        },
        from,
        to,
        tags,
    }) => {

        return (
            <Fragment>
                <ListCell width='64px'>
                    <Checkbox
                        value={chosenProjects.includes(id)}
                        onChange={() => selectProject(id)}
                        tickBorder
                    />
                </ListCell>
                <ListCell width="224px" align="left">
                    <Tag text={name} color={color} type={'stroked'} icon={is_billable ? 'dollar' : ''}  />
                </ListCell>
                <ListCell width="72px" align='center'>
                    <Tooltip text={`${data.user.first_name} ${data.user.last_name}`}>
                        <Avatar picture={data.user.avatar_url} firstName={data.user.first_name} lastName={data.user.last_name} />
                    </Tooltip>
                </ListCell>
                <ListCell width="144px" align='center'>
                    <FormattedDate value={new Date(from)} month="short" />{' '}
                    <FormattedDate value={new Date(from)} day="2-digit" /> -{' '}
                    <FormattedDate value={new Date(to)} month="short" />{' '}
                    <FormattedDate value={new Date(to)} day="2-digit" />
                </ListCell>
                <ListCell align="left">
                    <span>
                        {tags && (
                            tags.length === 2 && tags[0].name.length <= 6 && tags[1].name.length <= 6 ?
                                <span>
                                    <Tooltip text={tags[0].name} key={tags[0].id}>
                                        <Tag text={tags[0].name} style={{ maxWidth: '94px' }} />
                                    </Tooltip>
                                    <Tooltip text={tags[1].name} key={tags[1].id}>
                                        <Tag text={tags[1].name} style={{ maxWidth: '94px' }} />
                                    </Tooltip>
                                </span>
                                : tags.length > 1 && tags[0].name.length <= 3 && tags[1].name.length <= 3 ?
                                    <span>
                                        <Tooltip text={tags[0].name} key={tags[0].id}>
                                            <Tag text={tags[0].name} style={{ maxWidth: '94px' }} />
                                        </Tooltip>
                                        <Tooltip text={tags[1].name} key={tags[1].id}>
                                            <Tag text={tags[1].name} style={{ maxWidth: '94px' }} />
                                        </Tooltip>
                                        <Tooltip text={tags.slice(2)
                                            .map(tag => tag.name)
                                            .join(', ')}
                                        >
                                            <Tag text={`+${tags.length - 2}`} style={{ maxWidth: '94px' }} />
                                        </Tooltip>
                                    </span>
                                    : tags.length > 1 && tags[0].name.length > 5 ?
                                        <span>
                                            <Tooltip text={tags[0].name} key={tags[0].id}>
                                                <Tag text={tags[0].name} style={{ maxWidth: '94px' }} />
                                            </Tooltip>
                                            <Tooltip text={tags.slice(1)
                                                .map(tag => tag.name)
                                                .join(', ')}
                                            >
                                                <Tag text={`+${tags.length - 1}`} style={{ maxWidth: '94px' }} />
                                            </Tooltip>
                                        </span>
                                        : tags.length === 1 && tags[0].name.length > 15 ?
                                            <Tooltip text={tags[0].name} key={tags[0].id}>
                                                <Tag text={tags[0].name} style={{ maxWidth: '140px' }} />
                                            </Tooltip>
                                            : ((tags.length === 1 && tags[0].name.length <= 15) || (tags.length > 1 && tags[0].name.length < 5)) ?
                                                <span>
                                                    {tags.length > 0 && <Tag key={tags[0].id} text={tags[0].name} />}
                                                    {tags.length > 1 &&
                                                        <Tooltip text={tags.slice(1)
                                                            .map(tag => tag.name)
                                                            .join(', ')}
                                                        >
                                                            <Tag text={`+${tags.length - 1}`} style={{ maxWidth: '94px' }} />
                                                        </Tooltip>
                                                    }
                                                </span>
                                                : <span>
                                                    {tags.length > 0 && <Tag key={tags[0].id} text={tags[0].name} style={{ maxWidth: '90px' }} />}
                                                    {tags.length > 1 &&
                                                        <Tooltip text={tags.slice(1)
                                                            .map(tag => tag.name)
                                                            .join(', ')}
                                                        >
                                                            <Tag text={`+${tags.length - 1}`} style={{ maxWidth: '94px' }} />
                                                        </Tooltip>
                                                    }
                                                </span>
                        )
                        }
                    </span>
                </ListCell>
                <ListCell width="96px" align="center">
                    <span>
                        {durationFormatter(duration_mins)}
                    </span>
                </ListCell>
                <ListCell width="80px" align="center">
                    <Link to={`/approvals/create/details/${dateToString(dateRange.gte)}/${dateToString(dateRange.lte)}/${id}`}>
                        <Icon icon={'caret-right'} color={'blue'} size={'small'} />
                    </Link>
                </ListCell>
            </Fragment>
        );
    };

    return (
        <div className={style.section}>
            <List
                title={(
                    <div className={style.projectSelectWrapper}>
                        <span>
                            <FormattedMessage {...messages.timeToApproveInThisPeriod} />
                        </span>
                    </div>
                )}
                rightChildren={(<Pill size="small" text={`${intl.formatMessage(messages.total)}  ${`${durationFormatter(totalTimeOfChosenTasks)} / ${totalTimeInAllProjects}`}`} />)}
                header={(
                    <Fragment>
                        <ListCell width='64px' header>
                            <Checkbox
                                value={chosenProjects.length === projectsWithIsSelectedField.length && !!chosenProjects.length}
                                onChange={selectAll}
                                tickBorder
                            />
                        </ListCell>
                        <ListCell width='224px' align="center" header>
                            <FormattedMessage {...messages.project} />
                        </ListCell>
                        <ListCell width='72px' align="center" header>
                            <FormattedMessage {...messages.person} />
                        </ListCell>
                        <ListCell width='144px' align="center" header>
                            <FormattedMessage {...messages.period} />
                        </ListCell>
                        <ListCell align="center" header>
                            <FormattedMessage {...messages.tags} />
                        </ListCell>
                        <ListCell width="96px" align="center" header>
                            <FormattedMessage {...messages.duration} />
                        </ListCell>
                        <ListCell width="80px" align="center" header>
                            <FormattedMessage {...messages.details} />
                        </ListCell>
                    </Fragment>
                )}
                data={projectsWithIsSelectedField}
                renderRow={renderRow}
            />
        </div>
    );

};

PotentialApprovalsList.propTypes = {
    chosenProjects: PropTypes.array.isRequired,
    setChosenProjects: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    totalTimeInAllProjects: PropTypes.string.isRequired,
    totalTimeOfChosenTasks: PropTypes.number.isRequired,
    dateRange: PropTypes.object.isRequired,
};

export default PotentialApprovalsList;
