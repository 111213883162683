import fetch from '../utils/fetch';

export default {
    get: (workspaceCode, userId, taskId) =>
        fetch.get(`/v1/${workspaceCode}/users/${userId}/tasks/${taskId}`),
    post: (workspaceCode, userId, task) =>
        fetch.post(`/v1/${workspaceCode}/users/${userId}/tasks`, task),
    patch: (workspaceCode, userId, taskId, delta) =>
        fetch.patch(`/v1/${workspaceCode}/users/${userId}/tasks/${taskId}`, delta),
    delete: (workspaceCode, userId, taskId) =>
        fetch.delete(`/v1/${workspaceCode}/users/${userId}/tasks/${taskId}`),
    getSummaryByDays: (workspaceCode, userId, { startDate, endDate, selection }) => {
        const
            params = {
                selection: selection || 'my',
                'start-date': startDate,
                'end-date': endDate
            };
        return fetch.get(`/v1/${workspaceCode}/users/${userId}/tasks/summary-by-days`, { params });
    },
    getDaySummaryByProjects: (workspaceCode, userId, { date, selection }) => {
        const
            params = {
                selection: selection || 'my',
                date,
            };
        return fetch.get(`/v1/${workspaceCode}/users/${userId}/tasks/grouped-by-projects`, { params });
    },
};