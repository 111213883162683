import { defineMessages } from 'react-intl';

export default defineMessages({
    users: {
        id: 'deleteUsers.users',
        defaultMessage: 'Users ({total})'
    },
    status: {
        id: 'deleteUsers.status',
        defaultMessage: 'Status'
    },
    name: {
        id: 'deleteUsers.name',
        defaultMessage: 'Name'
    },
    email: {
        id: 'deleteUsers.email',
        defaultMessage: 'Email'
    },
    projects: {
        id: 'deleteUsers.projects',
        defaultMessage: 'Projects'
    },
    role: {
        id: 'deleteUsers.role',
        defaultMessage: 'Role'
    },
    owner: {
        id: 'deleteUsers.role.owner',
        defaultMessage: 'Owner'
    },
    admin: {
        id: 'deleteUsers.role.admin',
        defaultMessage: 'Administrator'
    },
    employee: {
        id: 'deleteUsers.role.employee',
        defaultMessage: 'Employee'
    },
    loadMore: {
        id: 'deleteUsers.loadMore',
        defaultMessage: 'Load more'
    },
    toastRoleEdited: {
        id: 'deleteUsers.toast.roleEdited',
        defaultMessage: 'User role edited'
    },
    noProjects: {
        id: 'deleteUsers.toast.noProjects',
        defaultMessage: 'No projects'
    },
    invitationHasBeenSent: {
        id: 'deleteUsers.toast.invitationHasBeenSent',
        defaultMessage: 'Invitation has been sent to {email}'
    },
    toastUserRemoved: {
        id: 'deleteUsers.toast.toastUserRemoved',
        defaultMessage: 'User removed'
    },
    pending: {
        id: 'deleteUsers.pending',
        defaultMessage: 'pending'
    },
    resendInvitation: {
        id: 'deleteUsers.resendInvitation',
        defaultMessage: 'Resend Invitation'
    },
    continueWithFreePlan: {
        id: 'deleteUsers.continueWithFreePlan',
        defaultMessage: 'Continue with free plan'
    },
    deleteUser: {
        id: 'deleteUsers.deleteUser',
        defaultMessage: 'Delete user'
    },
    searchPlaceholder: {
        id: 'deleteUsers.searchPlaceholder',
        defaultMessage: 'Who are you looking for?'
    },
    cancel: {
        id: 'deleteUsers.cancel',
        defaultMessage: 'Cancel'
    },
    approve: {
        id: 'deleteUsers.approve',
        defaultMessage: 'Approve'
    },
    areYouSure: {
        id: 'deleteUsers.areYouSure',
        defaultMessage: 'Are you sure you want to transfer ownership of this workspace to the new owner? Once approved, you will lose the ownership rights.'
    },
    changeRoleTitle: {
        id: 'deleteUsers.changeRoleTitle',
        defaultMessage: 'Change owner'
    },
    noAccessToChangeOwnerRole: {
        id: 'deleteUsers.noAccessToChangeOwnerRole',
        defaultMessage: 'You can\'t grant owner role'
    },
    deleteUsersToContinueTitle: {
        id: 'deleteUsers.deleteUsersToContinueTitle',
        defaultMessage: 'Delete users to continue'
    },
    deleteUsersToContinueSubtitle: {
        id: 'deleteUsers.deleteUsersToContinueSubtitle',
        defaultMessage: 'In the Starter Plan you are allowed to keep up to 10 users per Workspace. Reduce users to continue with Free Plan. Want to keep all users?'
    },
    deleteUsersToContinueAlt: {
        id: 'deleteUsers.deleteUsersToContinueAlt',
        defaultMessage: 'A woman at the desk'
    },
    deactivateUser: {
        id: 'deleteUsers.deactivateUser',
        defaultMessage: 'Deactivate user'
    },
    active: {
        id: 'deleteUsers.active',
        defaultMessage: 'Active'
    },
    inactive: {
        id: 'deleteUsers.inactive',
        defaultMessage: 'Inactive'
    },
    upgrade: {
        id: 'deleteUsers.upgrade',
        defaultMessage: 'Upgrade'
    }
});
