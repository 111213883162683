import React, { useEffect, useState } from 'react';
import { FormattedDate } from 'react-intl';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Context, Icon } from "@quidlo/ui";

import style from './DateSelect.module.scss';

const generateYears = () => {
        const max = new Date().getFullYear(),
            min = max - 10,
            years = [];

        for (let i = max; i >= min; i -= 1) {
            years.push(i);
        }
        return years;
    },
    DateSelect = ({
        value,
        year,
        setDate,
        type
    }) => {
        const [val, setVal] = useState(false),
            months = [
                'January', 'February', 'March', 'April', 'May', 'June',
                'July', 'August', 'September', 'October', 'November', 'December'
            ],
            selectValue = v => {
                if (type === 'year') {
                    setVal(v);
                    setDate(v);
                } else {
                    const index = months.indexOf(v);
                    setVal(index);
                    setDate(index);
                }
            },
            renderOptions = data => (
                data.map((unit, index) => (
                    <div
                        className={style.option}
                        key={unit}
                        onClick={() => selectValue(unit)}
                        onKeyPress={() => selectValue(index)}
                    >
                        {unit}
                    </div>
                ))
            );
        useEffect(() => {
            setVal(value);
        }, [value]);

        return (
            <div className={cx(style.select, type === 'year' && style.minorWidth)}>
                <div className={style.selectField}>
                    <Context
                        context={(
                            <div className={style.optionsContainer}>
                                {type === 'month'
                                    ? renderOptions(months)
                                    : renderOptions(generateYears())}
                            </div>
                        )}
                    >
                        <div
                            className={style.selectFieldContent}
                        >
                            <div className={style.selectFieldValue}>
                                {type === 'year'
                                    ? val
                                    : <FormattedDate value={new Date(year, val, 1)} month="long" />}
                            </div>
                            <Icon icon="caret-down" size="small" color="grey" />
                        </div>
                    </Context>
                </div>
            </div>
        );
    };

DateSelect.propTypes = {
    value: PropTypes.number,
    year: PropTypes.number,
    setDate: PropTypes.func,
    type: PropTypes.string
};

DateSelect.defaultProps = {
    value: 0,
    year: 0,
    setDate: undefined,
    type: ''
};

export default DateSelect;