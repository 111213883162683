import React from 'react';
import { useIntl } from 'react-intl';
import cx from 'classnames';

import SubscriptionService from "@quidlo/common/api/SubscriptionService";
import useAuthentication from "@quidlo/common/hooks/useAuthentication";
import useWorkspace from "@quidlo/common/hooks/useWorkspace";
import { Button, Card, Space } from "@quidlo/ui";

import { getWorkspaceSubscriptionQuery } from "../../../../queries/workspace.queries";
import { dateToString, differenceInDays } from '../../../../utils/dateHelpers';

import AppSumoHeartPNG from './assets/app-sumo-heart.png';
import AppSumoLogoPNG from './assets/app-sumo-logo.png';
import subscriptionViews from './subscriptionViews';

import style from './Subscription.module.scss';

import messages from './Subscription.i18n';

const Subscription = () => {
    const
        intl = useIntl(),
        { user } = useAuthentication(),
        { workspace } = useWorkspace(),
        { data: subscription } = getWorkspaceSubscriptionQuery();

    if (!subscription?.name) return (<div />);

    const { name } = subscription;
    const view = subscriptionViews[name],
        manageSubscription = () => {
            SubscriptionService.getManageSubscriptionUrl(user.id, workspace.code).then(({ data }) => {
                window.location.replace(data.url);
            });
        },
        amountOfDaysLeft = differenceInDays(subscription.validTo);

    return (
        <Card
            title={intl.formatMessage(messages.subscriptionSettings)}
            headerRightChildren={name !== 'appSumo'
                &&
                <Button
                    size="small"
                    type="bordered"
                    href={view.href}
                    onClick={subscription.name === 'pro' ? manageSubscription : undefined}
                    text={intl.formatMessage(messages[view.button])}
                />
            }
        >
            <div className={style.subscription}>
                <Space mr="m">
                    <img src={view.image} alt={intl.formatMessage(messages[view.alt])} className={style.icon} />
                </Space>
                <Space className={cx(style.content, subscription.name === 'pro' && style.pro)}>
                    <div className={style.title}>
                        {intl.formatMessage(messages[view.title], {
                            days: amountOfDaysLeft > 0 ? amountOfDaysLeft : intl.formatMessage(messages.no),
                            dayForm: intl.formatMessage(amountOfDaysLeft === 1 ? messages.day : messages.days)
                        })}
                        {name === 'appSumo' &&
                            <>
                                <img className={style.appSumoHeart} alt="A heart" src={AppSumoHeartPNG} />
                                <img className={style.appSumoLogo} alt="AppSumo Logo" src={AppSumoLogoPNG} />
                            </>
                        }
                    </div>
                    <div className={style.subtitle}>
                        {intl.formatMessage(messages[view.subtitle])}
                    </div>
                    {name === 'pro' &&
                        <div className={style.youAreCurrentlyUsing}>
                            {intl.formatMessage(messages[view.youAreCurrentlyUsing], {
                                valid_to: subscription.validTo ? dateToString(subscription.validTo) : intl.formatMessage(messages.endOfTheWorld),
                                valid_from: dateToString(subscription.validFrom),
                                until_on: subscription.validTo ? intl.formatMessage(messages.to) : intl.formatMessage(messages.until)
                            })}
                        </div>
                    }
                    {name === 'appSumo' ?
                        <div className={style.youAreCurrentlyUsing}>
                            {intl.formatMessage(messages[view.youAreCurrentlyEnrolled])}
                        </div>
                        :
                        <div className={style.description}>
                            {intl.formatMessage(messages[view.description])}
                            <a href="https://www.quidlo.com/timesheets/pricing" target="_blank" rel="noreferrer">
                                {` ${intl.formatMessage(messages.here)}.`}
                            </a>
                        </div>
                    }
                </Space>
            </div>
        </Card>
    );
};

export default Subscription;