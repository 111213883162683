import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from '@quidlo/ui';

import styles from './Text.module.scss';

const Text = ({
    children,
    lines,
}) => {
    const [charAmount, setCharAmount] = React.useState(null);
    const [isReady, setIsReady] = React.useState(false);
    const ref = useRef();

    useEffect(() => {
        if (ref?.current) {
            const { width } = ref.current.getBoundingClientRect();
            setCharAmount(Math.floor(width / 6.5));
            setIsReady(true);
        }
    }, [ref]);

    const isOverflowed = isReady && children.length > (lines * charAmount);

    return (
        isOverflowed ? (
            <Tooltip text={children}>
                <div className={styles.text} ref={ref}>
                    {children.substr(0, lines * charAmount)}...
                </div>
            </Tooltip>
        ) : (
            <div className={styles.text} ref={ref}>
                {children}
            </div>
        )
    );
}

Text.propTypes = {
    children: PropTypes.string,
    lines: PropTypes.number,
};

Text.defaultProps = {
    children: '',
    lines: 1,
};

export default React.memo(Text);