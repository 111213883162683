import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import style from './Pill.module.scss';

const Pill = ({ text, size, color, textColor, bordered }) => (
    <div
        className={cx(
            style.pill,
            style[`size-${size}`],
            style[`color-${color}`],
            style[`textColor-${textColor}`],
            bordered && style.bordered
        )}>
        <span>{text}</span>
    </div>
);

Pill.propTypes = {
    text: PropTypes.string,
    bordered: PropTypes.bool,
    color: PropTypes.oneOf(['grey', 'white']),
    textColor: PropTypes.oneOf(['blue', 'black']),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
};

Pill.defaultProps = {
    text: null,
    color: 'grey',
    size: 'medium',
    textColor: 'black',
    bordered: true
};

export default Pill;
