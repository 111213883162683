import React from 'react';
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";

import ReportsService from "@quidlo/common/api/ReportsService";
import useAuthentication from "@quidlo/common/hooks/useAuthentication";
import useUI from "@quidlo/common/hooks/useUI";
import useWorkspace from "@quidlo/common/hooks/useWorkspace";
import analytics from "@quidlo/common/utils/analytics";
import {
    Button, Tooltip,
} from '@quidlo/ui';

import { DropdownMenu } from '../../../../ui';

import messages from './ExportMenu.i18n';
import messagesToast from 'messages/toasts.i18n';

const ExportMenu = () => {
    const intl = useIntl();
    const location = useLocation();
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    const { toastPush } = useUI();

    const downloadXLSX = async () => {
        const filters = JSON.parse(window.atob(location.hash.substr(1)));
        const resp = await ReportsService.getXLSX(workspace.code, user.id, filters);
        const blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' }),
            a = document.createElement('a'),
            url = window.URL.createObjectURL(blob),
            filename = `Timesheets-report-${(new Date().toISOString()).substr(0, 19)}.xlsx`;

        analytics.logTimesheetsReportsCreateExport('XLSX', {
            days: ((new Date(filters.date.lte)) - (new Date(filters.date.gte))) / ((1000*60*60*24)),
            people: filters.users.length,
            projects: filters.projects.length,
            statuses: filters.statuses.length,
            tags: filters.tags.length,
            period: filters.period,
            type: filters.type
        })
        if (window?.navigator?.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, filename);
            return;
        }

        a.style = 'display: none';
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    };
    const downloadCSV = async () => {
        const filters = JSON.parse(window.atob(location.hash.substr(1)));
        const resp = await ReportsService.getCSV(workspace.code, user.id, filters);
        const blob = new Blob([resp.data], { type: 'text/csv' }),
            a = document.createElement('a'),
            url = window.URL.createObjectURL(blob),
            filename = `Timesheets-report-${(new Date().toISOString()).substr(0, 19)}.csv`;
        analytics.logTimesheetsReportsCreateExport('CSV', {
            days: ((new Date(filters.date.lte)) - (new Date(filters.date.gte))) / ((1000*60*60*24)),
            people: filters.users.length,
            projects: filters.projects.length,
            statuses: filters.statuses.length,
            tags: filters.tags.length,
            period: filters.period,
            type: filters.type
        })
        if (window?.navigator?.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, filename);
            return;
        }
        a.style = 'display: none';
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    };
    const downloadPDF = async () => {
        const filters = JSON.parse(window.atob(location.hash.substr(1)));
        const resp = await ReportsService.getPDF(workspace.code, user.id, filters);
        const blob = new Blob([resp.data], { type: 'application/pdf' }),
            a = document.createElement('a'),
            url = window.URL.createObjectURL(blob),
            filename = `Timesheets-report-${(new Date().toISOString()).substr(0, 19)}.pdf`;
        analytics.logTimesheetsReportsCreateExport('PDF', {
            days: ((new Date(filters.date.lte)) - (new Date(filters.date.gte))) / ((1000*60*60*24)),
            people: filters.users.length,
            projects: filters.projects.length,
            statuses: filters.statuses.length,
            tags: filters.tags.length,
            period: filters.period,
            type: filters.type
        })
        if (window?.navigator?.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, filename);
            return;
        }

        a.style = 'display: none';
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    };
    const exportToGoogleSheets = async () => {
        try {
            const filters = JSON.parse(window.atob(location.hash.substr(1)));
            analytics.logTimesheetsReportsCreateExport('Google Sheets', {
                days: ((new Date(filters.date.lte)) - (new Date(filters.date.gte))) / ((1000*60*60*24)),
                people: filters.users.length,
                projects: filters.projects.length,
                statuses: filters.statuses.length,
                tags: filters.tags.length,
                period: filters.period,
                type: filters.type
            });
            const client = window.google.accounts.oauth2.initTokenClient({
                client_id: process.env.REACT_APP_GOOGLE_CLIENTID,
                scope: 'email https://www.googleapis.com/auth/drive.file',
                callback: async tokenResponse => {
                    const res = await ReportsService.exportToGoogleSheets(workspace.code, user.id, filters, tokenResponse.access_token)
                    window.open(res.data.url, '_blank');
                },
            });
            client.requestAccessToken();
        } catch (e) {
            console.log(e);
            toastPush({
                text: intl.formatMessage(messagesToast.unexpectedError),
                color: 'red'
            });
        }
    };



    return (
        <DropdownMenu
            options={
                [[
                    {
                        label: intl.formatMessage(messages.downloadXLSX),
                        onClick: downloadXLSX,
                    },
                    {
                        label: intl.formatMessage(messages.downloadCSV),
                        onClick: downloadCSV,
                    },
                    {
                        label: intl.formatMessage(messages.downloadPDF),
                        onClick: downloadPDF,
                    },
                    {
                        label: intl.formatMessage(messages.exportToGoogleSheets),
                        onClick: exportToGoogleSheets,
                    },
                ]]
            }
            disabled={!location.hash}>
            <Tooltip
                text={intl.formatMessage(
                    messages.exportReport,
                )}
                position="bottom">
                <Button
                    icon="download"
                    size="medium"
                    iconSize="medium"
                    color="white"
                    type="bordered"
                    disabled={!location.hash}
                    iconColor="blue"
                />
            </Tooltip>
        </DropdownMenu>
    );
};

export default ExportMenu;
