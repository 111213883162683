import React from 'react';
import { useNavigate } from "react-router-dom";
import cx from "classnames";

import useAuthentication from "@quidlo/common/hooks/useAuthentication";
import useUI from "@quidlo/common/hooks/useUI";
import useWorkspace from "@quidlo/common/hooks/useWorkspace";
import { Avatar } from "@quidlo/ui";

import ConfirmDurationExceeded from "../../components/Tracker/modals/ConfirmDurationExceeded";
import DropdownMenu from "../DropdownMenu";

import SelectWorkspace from "./modals/SelectWorkspace";
import Spinner from "./Spinner/Spinner";
import { ReactComponent as SelectIcon } from "./svg/selectArrow.svg";
import Autotracker from "./Autotracker";

import style from "./TopBar.module.scss";

const TopBar = () => {
    const
        navigate = useNavigate(),
        { isTopbar, modalOpen } = useUI(),
        { user, signOut } = useAuthentication(),
        { workspace } = useWorkspace();

    const userMenu = [
        [
            {
                label: 'Select workspace',
                icon: 'workspace',
                onClick: () => {
                    modalOpen('selectWorkspace');
                }
            },
            {
                label: 'New workspace',
                icon: 'plus',
                onClick: () => {
                    window.location.replace(`${process.env.REACT_APP_AUTH_URL}new-workspace`);
                }
            }
        ],
        [
            {
                label: 'My profile',
                icon: 'user',
                onClick: () => {
                    navigate('/profile');
                }
            },
            {
                label: 'Help center',
                icon: 'help',
                onClick: () => {
                    window.open('https://www.quidlo.com/help/');
                }
            },
            {
                label: 'Quidlo roadmap',
                icon: 'map',
                onClick: () => {
                    window.open('https://roadmap.quidlo.com/roadmap');
                }
            }
        ],
        [
            {
                label: 'Sign out',
                icon: 'logout',
                onClick: () => {
                    setTimeout(() => signOut(), 200);
                }
            },
        ]
    ]

    if (!user || !workspace) return;

    return (
        <div className={cx(style.topBar, (!isTopbar && style.hidden))}>
            <div />
            <div className={style.right}>
                <Autotracker />
                <DropdownMenu options={userMenu} position="fixed">
                    <div className={style.profile}>
                        <Avatar firstName={user.firstName} lastName={user.lastName} picture={user.avatarUrl} />
                        <div className={style.user}>
                            <div className={style.name}>
                                {user.firstName} {user.lastName}
                            </div>
                            <div className={style.workspaceName}>
                                {workspace.name}
                            </div>
                        </div>
                        <div className={style.iconContainer}>
                            <SelectIcon />
                        </div>
                    </div>
                </DropdownMenu>
            </div>
            <Spinner />
            <ConfirmDurationExceeded />
            <SelectWorkspace />
        </div>
    );
};
export default TopBar;