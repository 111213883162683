import React, { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import { REPORT_TYPES, REPORT_VIEWS,TASK_STATUSES } from '@quidlo/common/constants/main';
import useForm from "@quidlo/common/hooks/useForm2";
import analytics from "@quidlo/common/utils/analytics";
import validators from "@quidlo/common/utils/validators";
import { Header, PillBar, Space, Spinner } from '@quidlo/ui';

import {
    getReportsData,
    getReportsFiltersProjects,
    getReportsFiltersTagsByProjects,
    getReportsFiltersUsersByProjects
} from "../../../queries/reports.queries";
import { Container, DateRangePicker } from '../../../ui';
import ViewHOC from '../../../ui/View/View';
import { dateToString } from '../../../utils/dateHelpers';
import DialogDeleteTask from "../../modals/DeleteTask";
import DialogEditTask from "../../modals/EditTask";
import DrawerFilters from "../components/DrawerFilters";
import Filters from "../components/Filters";
import ReportTable from "../components/ReportTable/ReportTable";
import SendReportDialog from "../modals/SendReport";
import ShareManyDialog from "../modals/ShareMany";
import SuperRadek from "../modals/SuperRadek";

import CopyToClipboard from "./CopyToClipboard";
import ExportMenu from "./ExportMenu";
import ShareReport from "./ShareReport";

import style from "./ReportCreate.module.scss";

import messagesGlobal from '../../../messages/global.i18n';
import messages from './ReportCreate.i18n';

const ReportCreate = () => {
    const
        intl = useIntl(),
        location = useLocation(),
        navigate = useNavigate(),
        [ filters, { isFormValid: isFiltersValid, touchForm: touchFilters, isFormDirty: isFiltersDirty } ] = useForm({
            date: {
                value:  {
                    gte: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                    lte: new Date()
                }
            },
            projects : {
                value: [],
                validators: [validators.isRequired(intl.formatMessage(messages.projectsAreReq))]
            },
            tags : {
                value: [],
                validators: [validators.isRequired(intl.formatMessage(messages.tagsAreReq))]
            },
            users : {
                value: [],
                validators: [validators.isRequired(intl.formatMessage(messages.usersAreReq))]
            },
            statuses : {
                value: []
            },
            period : {
                value: REPORT_VIEWS[0]
            },
            type : {
                value: REPORT_TYPES[0]
            },
            billable : {
                value: ''
            }
        }),
        { data: projectsOptions } = getReportsFiltersProjects(),
        { data: tagsOptions } = getReportsFiltersTagsByProjects(filters.projects.value),
        { data: usersOptions } = getReportsFiltersUsersByProjects(filters.projects.value),
        { data, fetchStatus } = getReportsData(location.hash);

    const openReport = (
        date= {
            gte: dateToString(filters.date.value.gte),
            lte: dateToString(filters.date.value.lte)
        },
        projects = filters.projects.value,
        tags = filters.tags.value,
        users = filters.users.value,
        type = filters.type.value,
        period = filters.period.value,
        billable = filters.billable.value,
        statuses = filters.statuses.value
    ) => {
        if (projects) {
            const newFilters = {
                date,
                projects,
                users,
                tags,
                type,
                period,
                billable,
                statuses
            }
            const string = window.btoa(JSON.stringify(newFilters));
            navigate(`/reports#${string}`);
        }
    };

    const options = useMemo(() => {
        return ({
            projects: projectsOptions,
            tags: tagsOptions,
            users: usersOptions,
            periods: REPORT_VIEWS.map(p => ({
                label: intl.formatMessage(messagesGlobal[`reports.periods.${p}`]),
                value: p
            })),
            types: REPORT_TYPES.map(t => ({
                label: intl.formatMessage(messagesGlobal[`reports.types.${t}`]),
                value: t
            })),
            billable: ['all', 'true', 'false'],
            statuses: TASK_STATUSES
        })
    }, [projectsOptions, tagsOptions, usersOptions]);

    useEffect(() => {
        if (location.hash) {
            const newFilters = JSON.parse(window.atob(location.hash.substr(1)));
            const newDate = {
                gte: new Date(newFilters.date.gte),
                lte: new Date(newFilters.date.lte),
            }
            filters.date.setValue(newDate);
            filters.projects.setValue(newFilters.projects);
            filters.tags.setValue(newFilters.tags);
            filters.users.setValue(newFilters.users);
            filters.period.setValue(newFilters.period);
            filters.type.setValue(newFilters.type);
            filters.billable.setValue(newFilters.billable);
            filters.statuses.setValue(newFilters.statuses);
            analytics.logTimesheetsFilters({
                screenName: "Reports - Create",
                date: newDate,
                projectsNo: newFilters?.projects?.length,
                tagsNo: newFilters?.tags?.length,
                peopleNo: newFilters?.users?.length,
                statusesNo: newFilters?.statuses?.length,
                period: newFilters?.period,
                type: newFilters?.type,
                isBillable: newFilters?.billable,
            })
        }
    }, [location.hash]);

    useEffect(() => {
        if (filters.projects.value.length) {
            filters.tags.setValue(tagsOptions.map(t => t.id))
            location.hash ? filters.users.setValue(JSON.parse(window.atob(location.hash.substr(1))).users) : filters.users.setValue(usersOptions.map(u => u.id));
        }
    }, [tagsOptions])

    useEffect(() => {
        if (filters.projects.value.length) {
            filters.statuses.setValue(TASK_STATUSES)
            filters.billable.setValue('all')
            !location.hash && filters.users.setValue(usersOptions.map(u => u.id))
        }
    }, [usersOptions]);

    return (
        <div className={style.report}>
            <DrawerFilters
                options={options}
                filters={filters}
                touchFilters={touchFilters}
                confirm={openReport}
                disabled={!isFiltersValid || !isFiltersDirty}
                datepickerProps={{
                    date: filters.date.value,
                    onChange: filters.date.setValue
                }}
            />
            <Container>
                <Space mb="m">
                    <Header
                        title={intl.formatMessage(messages.reports)}
                        icon="report"
                        size="large"
                        underline
                        rightChildren={(
                            <>
                                <Space mr="s">
                                    <CopyToClipboard />
                                </Space>
                                <Space mr="s">
                                    <ShareReport />
                                </Space>
                                <Space mr="s">
                                    <ExportMenu
                                        disabled={!location.hash}
                                    />
                                </Space>
                            </>
                        )}
                    />
                </Space>
                <Space mb="m">
                    <div className={style.filtersRow}>
                        <Space mr={'s'}>
                            <DateRangePicker
                                date={filters.date.value}
                                analyticsID="Reports - Create"
                                onChange={filters.date.onChange}
                            />
                        </Space>
                        <Space mr={'s'}>
                            <PillBar
                                {...filters.period}
                                options={options.periods}
                                fieldId="period"
                            />
                        </Space>
                        <Space mr={'s'}>
                            <PillBar
                                {...filters.type}
                                options={options.types}
                                fieldId="type"
                            />
                        </Space>
                    </div>
                </Space>
                <Filters
                    options={options}
                    filters={filters}
                    touchFilters={touchFilters}
                    confirm={openReport}
                    disabled={!isFiltersValid || !isFiltersDirty}
                />
                <Space mt="m">
                    {
                        (fetchStatus !== 'idle') ? (
                            <Spinner active={true} />
                        ) : (
                            <ReportTable data={data} openReport={openReport} />
                        )
                    }
                </Space>
            </Container>
            <DialogDeleteTask />
            <DialogEditTask />
            <SendReportDialog />
            <ShareManyDialog />
            <SuperRadek />
        </div>
    );
};

export default ViewHOC(
    ReportCreate,
    {
        title: 'Reports - Create',
        menu: true,
        topbar: true,
        auth: {
            roles: ['employee', 'manager', 'admin', 'owner']
        },
        subscription: true
    }
);
