import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Icon, Space } from '..';

import style from './Button.module.scss';

const Button = ({
    text,
    type,
    href,
    onClick,
    onDisabledClick,
    shadow,
    color,
    size,
    disabled,
    icon,
    iconSize,
    iconColor,
    cypressId
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const timer = useRef(null)

    const calculatedIconColor = useMemo(() => {
        if (disabled && color !== 'blue') {
            return 'ecru';
        }
        return iconColor || 'white'
    }, [disabled, color, iconColor]);

    const click = async v => {
        if (onClick) {
            setIsLoading(true);
            try {
                await onClick(v);
                timer.current = setTimeout(() => {
                    setIsLoading(false);
                    timer.current = null;
                }, 500);
            } catch {
                setIsLoading(false);
            }
        }
    }

    useEffect(() => {
        return () => {
            if (timer.current) {
                clearTimeout(timer.current)
            }
        }
    }, [])

    if (href) {
        return (
            <Link
                className={cx(
                    style.button,
                    style[`type-${type}`],
                    style[`color-${color}`],
                    style[`size-${size}`],
                    disabled && style.disabled,
                    shadow && style.shadow,
                )}
                to={href}>
                {icon && <Icon icon={icon} size={iconSize} color={calculatedIconColor} />}
                <span>
                    {text}
                </span>
            </Link>
        );
    }

    const handleClick = useMemo(() => {
        if (isLoading) return null;

        if (disabled) {
            if (onDisabledClick) {
                return onDisabledClick;
            }
            return null;
        }

        return click;

    }, [isLoading, disabled, onDisabledClick, onClick])

    return (
        <div
            className={cx(
                style.button,
                style[`type-${type}`],
                style[`color-${color}`],
                style[`size-${size}`],
                isLoading && style.loading,
                disabled && style.disabled,
                shadow && style.shadow,
                !text && icon && style.iconOnly,
            )}
            onClick={handleClick}
            onKeyPress={handleClick}
            data-cy={cypressId}
            tabIndex={0}
        >
            {!!icon && <Icon icon={icon} size={iconSize} color={calculatedIconColor} />}
            { (!!icon && !!text) && <Space mr="xxs" />}
            {
                !!text && (
                    <span>
                        {text}
                    </span>
                )
            }

        </div>
    );
};

Button.propTypes = {
    type: PropTypes.oneOf(['filled', 'bordered', 'plain']),
    shadow: PropTypes.bool,
    color: PropTypes.string,
    size: PropTypes.oneOf(['superSmall', 'small', 'medium', 'large']),
    disabled: PropTypes.bool,
    text: PropTypes.string,
    href: PropTypes.string,
    onClick: PropTypes.func,
    onDisabledClick: PropTypes.func,
    icon: PropTypes.string,
    iconSize: PropTypes.oneOf(['small', 'medium', 'large']),
    iconColor: PropTypes.oneOf([
        'black',
        'blue',
        'white',
        'grey',
        'flint',
        'ecru',
        'transparent',
    ]),
    cypressId: PropTypes.string
};

Button.defaultProps = {
    type: 'filled',
    shadow: false,
    color: 'blue',
    size: 'medium',
    disabled: false,
    text: '',
    href: undefined,
    onClick: undefined,
    onDisabledClick: undefined,
    icon: undefined,
    iconSize: 'medium',
    iconColor: undefined,
    cypressId: undefined
};

export default Button;
