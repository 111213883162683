import React, { Fragment, useMemo, useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import useUI from '@quidlo/common/hooks/useUI';
import { durationFormatter } from '@quidlo/common/utils/duration';
import { Avatar, Button, Checkbox, Icon, InfoCard, Pill, Space, Tag, Tooltip } from '@quidlo/ui';

import { ApprovalStatus, List, ListCell } from '../../../../ui';
import noApprovalsPNG from '../assets/noApprovals.png';

import style from './ToApproveList.module.scss';

import messages from './ToApproveList.i18n';

const ToApproveList = ({
    data,
    dateRange,
}) => {
    const
        intl = useIntl(),
        { modalOpen } = useUI(),
        [chosenTasks, setChosenTasks] = useState([]),
        totalTimeInAllTasks = useMemo(() => data.list.length ? durationFormatter(data.list.reduce( (acc, obj) => acc + Number(obj.duration_mins), 0)) : '0', [data]),
        tasksWithIsSelectedField = data.list.map(task => ({ ...task, isSelected: chosenTasks.includes(task.id) })),
        totalTimeOfChosenTasks = () => {
            let total = 0;
            tasksWithIsSelectedField.map(task => {
                if(chosenTasks.includes(task.id)) {
                    total = total + task.duration_mins
                }
            })
            return total
        },
        selectProject = id => {
            setChosenTasks(prevChosenTasks => {
                if(prevChosenTasks.includes(id)) {
                    return prevChosenTasks.filter(item => {
                        return item !== id
                    })
                } else {
                    return [...prevChosenTasks, id]
                }
            })
        },
        projectsToApproveReject = useMemo(() => data.list.filter(proj => chosenTasks.includes(proj.id)), [data, chosenTasks]),
        selectAll = () => {
            if(chosenTasks.length === tasksWithIsSelectedField.length) {
                setChosenTasks([])
            } else {
                setChosenTasks(tasksWithIsSelectedField.map(task => task.status === 'pending' && task.id))
            }
        };

    if (!data?.list?.length && data?.from) {
        return (
            <div className={style.infoCardWrapper}>
                <InfoCard
                    header={intl.formatMessage( messages.noDataHeader)}
                    text={intl.formatMessage(messages.noDataBody)}
                    illustration={<img src={noApprovalsPNG} alt="a man at a desk" />}
                />
            </div>
        )
    }

    const renderRow = ({
        project: {
            name,
            color,
            is_billable
        },
        id,
        status,
        request_date,
        from,
        to,
        tags,
        duration_mins,
        user: {
            first_name,
            last_name,
            avatar_url
        }
    }) => {

        return (
            <Fragment>
                <ListCell width='64px'>
                    {status !== 'withdrawn' &&
                        <Checkbox
                            value={chosenTasks.includes(id)}
                            onChange={val => selectProject(id, val)}
                            disabled={status !== 'pending'}
                        />
                    }
                </ListCell>
                <ListCell width="224px" align="left">
                    <Tag text={name} color={color} type={'stroked'} icon={is_billable ? 'dollar' : ''} />
                </ListCell>
                <ListCell width="144px">
                    <ApprovalStatus status={status} />
                </ListCell>
                <ListCell width="112px" align='center'>
                    <FormattedDate value={request_date} weekday={"short"} />{', '}
                    <FormattedDate value={request_date} month="short" />{' '}
                    <FormattedDate value={request_date} day="2-digit" />
                </ListCell>
                <ListCell width="72px" align='center'>
                    <Tooltip text={`${first_name} ${last_name}`}>
                        <Avatar picture={avatar_url} lastName={last_name} firstName={first_name} />
                    </Tooltip>
                </ListCell>
                <ListCell width="144px" align='center'>
                    <FormattedDate value={from} month="short" />{' '}
                    <FormattedDate value={from} day="2-digit" /> -{' '}
                    <FormattedDate value={to} month="short" />{' '}
                    <FormattedDate value={to} day="2-digit" />
                </ListCell>
                <ListCell align="left">
                    <span>
                        {
                            tags.length === 2 && tags[0].name.length <= 6 && tags[1].name.length <= 6 ?
                                <span>
                                    <Tooltip text={tags[0].name} key={tags[0].id}>
                                        <Tag text={tags[0].name} style={{ maxWidth: '94px' }} />
                                    </Tooltip>
                                    <Tooltip text={tags[1].name} key={tags[1].id}>
                                        <Tag text={tags[1].name} style={{ maxWidth: '94px' }} />
                                    </Tooltip>
                                </span>
                                : tags.length > 1 && tags[0].name.length <= 3 && tags[1].name.length <= 3 ?
                                    <span>
                                        <Tooltip text={tags[0].name} key={tags[0].id}>
                                            <Tag text={tags[0].name} style={{ maxWidth: '94px' }} />
                                        </Tooltip>
                                        <Tooltip text={tags[1].name} key={tags[1].id}>
                                            <Tag text={tags[1].name} style={{ maxWidth: '94px' }} />
                                        </Tooltip>
                                        <Tooltip text={tags.slice(2)
                                            .map(tag => tag.name)
                                            .join(', ')}
                                        >
                                            <Tag text={`+${tags.length - 2}`} style={{ maxWidth: '94px' }} />
                                        </Tooltip>
                                    </span>
                                    : tags.length > 1 && tags[0].name.length > 5 ?
                                        <span>
                                            <Tooltip text={tags[0].name} key={tags[0].id}>
                                                <Tag text={tags[0].name} style={{ maxWidth: '94px' }} />
                                            </Tooltip>
                                            <Tooltip text={tags.slice(1)
                                                .map(tag => tag.name)
                                                .join(', ')}
                                            >
                                                <Tag text={`+${tags.length - 1}`} style={{ maxWidth: '94px' }} />
                                            </Tooltip>
                                        </span>
                                        : tags.length === 1 && tags[0].name.length > 15 ?
                                            <Tooltip text={tags[0].name} key={tags[0].id}>
                                                <Tag text={tags[0].name} style={{ maxWidth: '140px' }} />
                                            </Tooltip>
                                            : ((tags.length === 1 && tags[0].name.length <= 15) || (tags.length > 1 && tags[0].name.length < 5)) ?
                                                <span>
                                                    {tags.length > 0 && <Tag key={tags[0].id} text={tags[0].name} />}
                                                    {tags.length > 1 &&
                                                        <Tooltip text={tags.slice(1)
                                                            .map(tag => tag.name)
                                                            .join(', ')}
                                                        >
                                                            <Tag text={`+${tags.length - 1}`} style={{ maxWidth: '94px' }} />
                                                        </Tooltip>
                                                    }
                                                </span>
                                                : <span>
                                                    {tags.length > 0 && <Tag key={tags[0].id} text={tags[0].name}
                                                        style={{ maxWidth: '90px' }} />}
                                                    {tags.length > 1 &&
                                                        <Tooltip text={tags.slice(1)
                                                            .map(tag => tag.name)
                                                            .join(', ')}
                                                        >
                                                            <Tag text={`+${tags.length - 1}`} style={{ maxWidth: '94px' }} />
                                                        </Tooltip>
                                                    }
                                                </span>
                        }
                    </span>
                </ListCell>
                <ListCell width="96px" align="center">
                    {durationFormatter(duration_mins)}
                </ListCell>
                <ListCell width="81px" align="center" last>
                    <Link to={`/approvals/requested/details/${id}`} >
                        <Icon icon={'caret-right'} color={'blue'} size={'small'} />
                    </Link>
                </ListCell>
            </Fragment>
        );
    };

    return (
        <div className={style.row}>
            <List
                title={intl.formatMessage(messages.all)}
                rightChildren={
                    <>
                        <Pill size="small" text={`${intl.formatMessage(messages.total)}  ${`${durationFormatter(totalTimeOfChosenTasks())} / ${totalTimeInAllTasks}`}`} />
                        <Space ml="s">
                            <Button
                                type="bordered"
                                color="white"
                                shadow
                                text={intl.formatMessage(messages.reject)}
                                size="small"
                                onClick={() => modalOpen('rejectRequests', { projectsToReject: projectsToApproveReject, dateRange }, () => setChosenTasks([]))}
                                disabled={!chosenTasks.length}
                            />
                        </Space>
                        <Space ml="s">
                            <Button
                                shadow
                                text={intl.formatMessage(messages.approve)}
                                size="small"
                                onClick={() => modalOpen('approveRequests', { projectsToApprove: projectsToApproveReject, dateRange }, () => setChosenTasks([]))}
                                disabled={!chosenTasks.length}
                            />
                        </Space>
                    </>
                }
                header={(
                    <Fragment>
                        <ListCell width='64px' header>
                            <Checkbox
                                value={chosenTasks.length === tasksWithIsSelectedField.length  && !!chosenTasks.length}
                                onChange={selectAll}
                                disabled={!tasksWithIsSelectedField.some(task => task.status === 'pending')}
                            />
                        </ListCell>
                        <ListCell width='224px' align="center" header>
                            <FormattedMessage {...messages.project} />
                        </ListCell>
                        <ListCell width='144px' align="center" header>
                            <FormattedMessage {...messages.status} />
                        </ListCell>
                        <ListCell width='112px' align="center" header>
                            <FormattedMessage {...messages.requestDate} />
                        </ListCell>
                        <ListCell width='72px' align="center" header>
                            <FormattedMessage {...messages.person} />
                        </ListCell>
                        <ListCell width='144px' align="center" header>
                            <FormattedMessage {...messages.period} />
                        </ListCell>
                        <ListCell align="center" header>
                            <FormattedMessage {...messages.tags} />
                        </ListCell>
                        <ListCell width="96px" align="center" header>
                            <FormattedMessage {...messages.duration} />
                        </ListCell>
                        <ListCell width="81px" align="center" header>
                            <FormattedMessage {...messages.details} />
                        </ListCell>
                    </Fragment>
                )}
                data={tasksWithIsSelectedField}
                renderRow={renderRow}
            />
        </div>
    );

};

ToApproveList.propTypes = {
    data: PropTypes.object.isRequired,
    dateRange: PropTypes.object.isRequired
};

export default ToApproveList;
