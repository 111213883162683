import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import UsersService from '@quidlo/common/api/UsersService';
import useForm from '@quidlo/common/hooks/useForm2';
import useUI from "@quidlo/common/hooks/useUI";
import useWorkspace from '@quidlo/common/hooks/useWorkspace';
import validators from "@quidlo/common/utils/validators";
import { Autocomplete, Button, Space, Tag } from '@quidlo/ui';

import illustration from "../../../../../assets/modals/deal.png";
import { addProjectUserMutation } from '../../../../../queries/projects.queries';
import ModalHOC, { ModalActions, ModalContent, ModalHeader } from "../../../../../ui/Modal/Modal";

import style from './AddTeamMembers.module.scss';

import messages from './AddTeamMembers.i18n';

const FORM = {
    members: {
        value: [],
        validators: [validators.isRequired()]
    }
}

const AddTeamMembers = () => {
    const
        { modal, modalClose } = useUI(),
        [possibleMembers, setPossibleMembers] = useState([]),
        intl = useIntl(),
        [form, { isFormValid, cleanForm, touchForm }] = useForm(FORM),
        { workspace } = useWorkspace(),
        { mutate: addUser } = addProjectUserMutation(modal?.data?.id),
        removeMember = memberId => form.members.onChange(form.members.value.filter(id => id !== memberId)),
        addMembers = () => {
            form.members.value.map(member => {
                addUser({
                    userId: member,
                    projectRole: "member"
                })
            })
            cleanForm();
            modalClose();
        },
        usersMap = form.members.value.map(id => {
            const user = possibleMembers.find(member => member.id === id)
            if (user) {
                return (
                    <div key={id}>
                        <Tag onRemove={() => removeMember(id)} text={user.email} />
                    </div>
                )
            }
            return null;

        });

    useEffect(() => {
        UsersService.getAll(workspace.code, {
            'excluded-project-id': modal.data.id
        }).then(({ data }) => {
            setPossibleMembers(data)
        })
    }, [modal?.data?.id])

    return (
        <>
            <ModalHeader>
                {intl.formatMessage(messages.addTeamMember)}
            </ModalHeader>
            <ModalContent>
                <Space mb="l">
                    {intl.formatMessage(messages.youCanEdit)}
                </Space>
                <Space direction="row">
                    <div className={style.email}>
                        <Autocomplete
                            {...form.members}
                            label={intl.formatMessage(messages.addTeamMemberEmail)}
                            multiselect
                            options={
                                possibleMembers.map(o => ({
                                    label: (
                                        <div className={style.member}>
                                            {o.firstName && o.lastName && (
                                                <span className={style.userName}>{o.firstName} {o.lastName}</span>
                                            )}
                                            <Space mr="xs">
                                                <Tag text={o.email} />
                                            </Space>
                                        </div>
                                    ),
                                    labelText: o.email,
                                    value: o.id
                                }))
                            }
                            isRequired
                        />
                    </div>
                </Space>
                {form.members.value.length ?
                    (
                        <>
                            <Space mt="l" mb="s" className={style.membersHeader}>
                                {intl.formatMessage(messages.members)}
                            </Space>
                            <div className={style.users}>
                                {usersMap}
                            </div>
                        </>
                    ) : null
                }
            </ModalContent>
            <ModalActions>
                <Space mr="m">
                    <Button color="white" text={intl.formatMessage(messages.cancel)} onClick={modalClose} />
                </Space>
                <Button
                    color="blue"
                    text={intl.formatMessage(messages.addMembers)}
                    onClick={addMembers}
                    disabled={!isFormValid}
                    onDisabledClick={touchForm}
                />
            </ModalActions>
        </>
    )
};

export default ModalHOC(
    AddTeamMembers,
    {
        id: 'addTeamMembers',
        size: 'large',
        illustration,
    }
);
