import { defineMessages } from 'react-intl';

export default defineMessages({
    projectTeam: {
        id: 'usersList.projectTeam',
        defaultMessage: 'Project members'
    },
    managerRole: {
        id: 'usersList.managerRole',
        defaultMessage: 'Manager'
    },
    memberRole: {
        id: 'usersList.memberRole',
        defaultMessage: 'Member'
    },
    resendInvitation: {
        id: 'usersList.resendInvitation',
        defaultMessage: 'Resend Invitation'
    },
    pending: {
        id: 'usersList.pending',
        defaultMessage: 'PENDING'
    },
    person: {
        id: 'usersList.person',
        defaultMessage: 'Person'
    },
    email: {
        id: 'usersList.email',
        defaultMessage: 'Email'
    },
    projectRole: {
        id: 'usersList.projectRole',
        defaultMessage: 'Project role'
    },
    delete: {
        id: 'usersList.delete',
        defaultMessage: 'Delete'
    },
});