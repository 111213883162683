import React from 'react';
import { useIntl } from 'react-intl';

import useUI from "@quidlo/common/hooks/useUI";
import useWorkspace from '@quidlo/common/hooks/useWorkspace';
import { Button, Card, Tag } from "@quidlo/ui";

import { deleteTagMutation, getTagsListQuery } from "../../../../queries/tags.queries";

import AddTag from "./modals/AddTag";

import messages from './Tags.i18n';

const Tags = () => {
    const
        intl = useIntl(),
        { workspace } = useWorkspace(),
        { modalOpen } = useUI(),
        { data: tags } = getTagsListQuery(),
        isAdminOrManager = workspace?.organizationRole !== 'employee',
        { mutate: deleteTag } = deleteTagMutation();

    return (
        <>
            <Card
                title={intl.formatMessage(messages.globalTags)}
                headerRightChildren={isAdminOrManager && (
                    <Button
                        size="small"
                        text={intl.formatMessage(messages.createTag)}
                        color="blue"
                        shadow
                        onClick={() => {
                            modalOpen('addTag');
                        }}
                    />
                )}
            >
                {tags.map(t => (
                    <Tag
                        size="large"
                        key={t.id}
                        text={t.name}
                        type="highlighted"
                        onRemove={isAdminOrManager ? () => {
                            deleteTag(t.id);
                        } : undefined}
                    />
                ))}
            </Card>
            <AddTag />
        </>
    );
};

export default Tags;
