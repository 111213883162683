import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import useUI from "@quidlo/common/hooks/useUI";
import useWorkspace from "@quidlo/common/hooks/useWorkspace";
import { Button, Card } from "@quidlo/ui";

import LeaveWorkspace from "../../../modals/LeaveWorkspace";

import CannotLeaveWorkspace from "./modals/CannotLeaveWorkspace";

import style from "./WorkspaceLeave.module.scss";

import messages from './WorkspaceLeave.i18n';

const
    WorkspaceLeave = () => {
        const
            intl = useIntl(),
            { workspace } = useWorkspace(),
            { modalOpen } = useUI(),

            leaveWorkspaceModal = () => {
                if (workspace?.organizationRole === 'owner') {
                    modalOpen('cannotLeaveWorkspace');
                } else {
                    modalOpen('leaveWorkspace');
                }
            };
        return (
            <>
                <Card
                    title={intl.formatMessage(messages.leaveWorkspace)}
                    headerRightChildren={<Button
                        size='small'
                        shadow
                        color="white"
                        text={intl.formatMessage(messages.leave)}
                        onClick={leaveWorkspaceModal}
                    />}
                >
                    <div className={style.root}>
                        <div className={style.left}>
                            <p>
                                <FormattedMessage
                                    {...messages.byLeaving}
                                    values={{
                                        b: chunks => <strong> {chunks} </strong>,
                                        workspace: workspace?.name
                                    }}
                                />
                            </p>
                        </div>
                    </div>
                </Card>
                <LeaveWorkspace />
                <CannotLeaveWorkspace />
            </>
        );
    };

export default WorkspaceLeave;
