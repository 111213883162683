import { defineMessages } from 'react-intl';

export default defineMessages({
    me: {
        id: 'timer.me',
        defaultMessage: 'Me',
    },
    all: {
        id: 'timer.all',
        defaultMessage: 'My team',
    },
    today: {
        id: 'timer.today',
        defaultMessage: 'Today',
    },
    noDataHeader: {
        id: 'timer.noDataHeader',
        defaultMessage: 'No time entries found'
    },
    noDataBody: {
        id: 'timer.noDataBody',
        defaultMessage: 'No time entries found for the given day. Try to create a time entry.'
    },
    noDataBodyAll: {
        id: 'timer.noDataBodyAll',
        defaultMessage: 'Nothing has been logged yet'
    }
});