export const PROJECT_ROLE = {
    MEMBER: 'member',
    MANAGER: 'manager'
}

export const PROJECT_ROLES = [
    PROJECT_ROLE.MEMBER,
    PROJECT_ROLE.MANAGER
]

export const WORKSPACE_ROLE = {
    OWNER: 'owner',
    ADMIN: 'admin',
    EMPLOYEE: 'employee',
}
export const WORKSPACE_ROLES = [
    WORKSPACE_ROLE.OWNER,
    WORKSPACE_ROLE.ADMIN,
    WORKSPACE_ROLE.EMPLOYEE,
]

export const WORKSPACE_STATUS = {
    INVITED: 'invited',
    APPROVED: 'approved',
}

export const SUBSCRIPTION_STATUS = {
    EXPIRED: 'expired',
    TRIAL: 'trial',
    TRIAL_EXPIRING: 'trial_expiring',
    PRO: 'pro',
    PRO_EXPIRING: 'pro_expiring',
    PAST_DUE: 'past_due',
}

export const REPORT_TYPES = [
    'tasks',
    'time',
    'people',
]

export const REPORT_VIEWS = [
    'month',
    'week',
    'day'
]

export const FEATURE = {
    APPROVALS: 'approvals',
}

export const APPROVAL_STATUS = {
    LOGGED: 'logged',
    ACCEPTED: 'accepted',
    REJECTED: 'rejected',
    PENDING: 'pending',
    WITHDRAWN: 'withdrawn',
}

export const APPROVAL_STATUSES = [
    APPROVAL_STATUS.ACCEPTED,
    APPROVAL_STATUS.REJECTED,
    APPROVAL_STATUS.PENDING,
    APPROVAL_STATUS.WITHDRAWN,
]

export const TASK_STATUSES = [
    APPROVAL_STATUS.LOGGED,
    APPROVAL_STATUS.PENDING,
    APPROVAL_STATUS.ACCEPTED,
]