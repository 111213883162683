import React from 'react';
import { FormattedDate } from 'react-intl';
import cx from 'classnames';
import PropTypes from 'prop-types';

import analytics from "@quidlo/common/utils/analytics";
import { Context, Icon } from '@quidlo/ui';

import Calendar from './Calendar';

import style from './DateRangePicker.module.scss';

const DateRangePicker = ({
    date,
    size,
    onChange,
    range,
    contextOptions,
    analyticsID
}) => {
    const onOpen = () => {
        analytics.logTimesheetsDatePickerOpen({ id: analyticsID })
    }

    const onClose = () => {
        analytics.logTimesheetsDatePickerClose({ id: analyticsID })
    }

    return (
        <div className={cx(style.datePicker, style[`size-${size}`])}>
            <div className={style.center}>
                <span>
                    <FormattedDate value={date.gte} month="short" />{' '}
                    <FormattedDate value={date.gte} day="2-digit" /> -{' '}
                    <FormattedDate value={date.lte} month="short" />{' '}
                    <FormattedDate value={date.lte} day="2-digit" />
                </span>
            </div>
            <div className={style.right}>
                <Context
                    onOpen={onOpen}
                    onClose={onClose}
                    context={(
                        <Calendar
                            date={date}
                            onChange={onChange}
                            dateBoundary={range}
                            analyticsID={analyticsID}
                        />
                    )}
                    position="bottom-right"
                    {...contextOptions}
                >
                    <div
                        className={style.button}
                        role="button"
                        tabIndex="0">
                        <Icon color="blue" icon="calendar" size={'small'} />
                    </div>
                </Context>

            </div>
        </div>
    );
};

DateRangePicker.propTypes = {
    date: PropTypes.shape({
        gte: PropTypes.instanceOf(Date).isRequired,
        lte: PropTypes.instanceOf(Date).isRequired,
    }),
    size: PropTypes.oneOf(['small', 'medium']),
    onChange: PropTypes.func,
    range: PropTypes.shape({
        gte: PropTypes.instanceOf(Date).isRequired,
        lte: PropTypes.instanceOf(Date).isRequired,
    }),
    contextOptions: PropTypes.shape({
        zIndex: PropTypes.number,
        fixed: PropTypes.bool
    }),
    analyticsID: PropTypes.string
};

DateRangePicker.defaultProps = {
    date: {
        gte: new Date(),
        lte: new Date(),
    },
    size: 'medium',
    onChange: null,
    range: null,
    contextOptions: {},
    analyticsID: null,
};

export default DateRangePicker;
