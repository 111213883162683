import React from 'react';

import { WORKSPACE_ROLE } from "@quidlo/common/constants/main";
import useWorkspace from "@quidlo/common/hooks/useWorkspace";
import { Space } from '@quidlo/ui';

import Tags from "./Tags";
import WorkspaceDelete from "./WorkspaceDelete";
import WorkspaceLeave from "./WorkspaceLeave";
import WorkspaceSettings from "./WorkspaceSettings";

const
    Workspace = () => {
        const { workspace } = useWorkspace();

        return (
            <Space pv="m">
                {
                    [WORKSPACE_ROLE.OWNER, WORKSPACE_ROLE.ADMIN].includes(workspace?.organizationRole) && (
                        <Space mb="l">
                            <WorkspaceSettings />
                        </Space>
                    )
                }
                <Space mb="l">
                    <Tags />
                </Space>
                <Space mb="l">
                    <WorkspaceLeave />
                </Space>
                {
                    WORKSPACE_ROLE.OWNER === workspace?.organizationRole && (
                        <Space>
                            <WorkspaceDelete />
                        </Space>
                    )
                }
            </Space>
        );
    };

export default Workspace;
