import { useIntl } from "react-intl";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import TasksService from "@quidlo/common/api/TasksService";
import useAuthentication from "@quidlo/common/hooks/useAuthentication";
import useUI from "@quidlo/common/hooks/useUI";
import useWorkspace from "@quidlo/common/hooks/useWorkspace";

import { messagesToasts } from "../messages";

export const getTaskQuery = id => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    return useQuery(
        [
            { workspace: workspace.code, user: user.id },
            'tasks', 'task', id
        ],
        async () => (await TasksService.get(workspace.code, user.id, id))?.data,
        {
            initialData: null,
            // enabled: !!workspace?.code && !!user?.id && !!id,
            enabled: !!id,
        })
};

export const addTaskMutation = () => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    const { toastPush, displayEgg } = useUI();
    const queryClient = useQueryClient();
    const intl = useIntl();

    return useMutation(async task => (await TasksService.post(workspace.code, user.id, task)), {
        onSuccess: ({ data }) => {
            if (data.title.includes('TSH')) {
                if (user.id === 62) {
                    displayEgg('aga');
                } else {
                    displayEgg('rad');
                }
            }
            toastPush({
                text: intl.formatMessage(messagesToasts.taskCreated)
            });

            queryClient.invalidateQueries([
                { workspace: workspace.code, user: user.id },
                'reports'
            ])
            queryClient.invalidateQueries([
                { workspace: workspace.code, user: user.id },
                'tracker'
            ])
        },
        onError: e => {
            toastPush({
                text: e?.response?.data?.message || intl.formatMessage(messagesToasts.unexpectedError),
                color: 'red'
            });
        }
    })};

export const editTaskMutation = () => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    const queryClient = useQueryClient();
    const { toastPush } = useUI();
    const intl = useIntl();

    return useMutation(async ( { id, delta }) => (await TasksService.patch(workspace.code, user.id, id, delta)), {
        onSuccess: () => {
            toastPush({
                text: intl.formatMessage(messagesToasts.taskEdited)
            });
            queryClient.invalidateQueries([
                { workspace: workspace.code, user: user.id },
                'reports'
            ])
            queryClient.invalidateQueries([
                { workspace: workspace.code, user: user.id },
                'tracker'
            ])
        },
        onError: e => {
            toastPush({
                text: e?.response?.data?.message || intl.formatMessage(messagesToasts.unexpectedError),
                color: 'red'
            });
        }
    })};

export const deleteTaskMutation = () => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    const queryClient = useQueryClient();
    const { toastPush } = useUI();
    const intl = useIntl();

    return useMutation(async id => (await TasksService.delete(workspace.code, user.id, id)), {
        onSuccess: () => {
            toastPush({
                text: intl.formatMessage(messagesToasts.taskDeleted)
            });
            queryClient.invalidateQueries([
                { workspace: workspace.code, user: user.id },
                'reports'
            ])
            queryClient.invalidateQueries([
                { workspace: workspace.code, user: user.id },
                'tracker'
            ])
        },
        onError: e => {
            toastPush({
                text: e?.response?.data?.message || intl.formatMessage(messagesToasts.unexpectedError),
                color: 'red'
            });
        }
    })};