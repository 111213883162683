import { defineMessages } from 'react-intl';

export default defineMessages({
    createProject: {
        id: 'createProject.createProject',
        defaultMessage: 'Create Project'
    },
    typeProjectsName: {
        id: 'createProject.typeProjectsName',
        defaultMessage: 'Project name'
    },
    projectManagers: {
        id: 'createProject.projectManagers',
        defaultMessage: 'At least one project manager'
    },
    cancel: {
        id: 'createProject.cancel',
        defaultMessage: 'Cancel'
    },
    save: {
        id: 'createProject.save',
        defaultMessage: 'Save'
    },
    projectColor: {
        id: 'createProject.projectColor',
        defaultMessage: "Project's color"
    },
    billable: {
        id: 'createProject.billable',
        defaultMessage: 'Billable'
    },
});