import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';

import Portal from '../Portal';

import style from './Context.module.scss';

const Context = ({ children, context, onOpen, onClose, zIndex, fixed, position, dismiss, disabled }) => {
    const [isActive, setIsActive] = useState(false),
        elementRef = useRef(null),
        dropdownRef = useRef(null),
        [coordinates, setCoordinates] = useState({  }),
        clickHandler = useCallback(e => {
            switch (dismiss) {
                case 'blur':
                    if (e.type === 'click' && dropdownRef.current && !dropdownRef.current.contains(e.target)) {
                        onBlur()
                    }
                    break;
                case 'click':
                    onBlur()
            }

        }, []),
        keydownHandler = useCallback(e => {
            if (e?.key === 'Tab') {
                console.log('blur');
                onBlur()
            }
        }, []);

    const onFocus = () => {
        setIsActive(isActive => {
            if (!isActive) {
                if (onOpen) {
                    onOpen();
                }
            }
            return true
        });
    }

    const onBlur = () => {
        setIsActive(isActive => {
            if (isActive) {
                if (onClose) {
                    onClose();
                }
            }
            return false
        });
    }

    const dropdownRefFunc = useCallback(node => {
        if (elementRef.current && node) {
            const el = elementRef.current.getBoundingClientRect();
            const dr = node.getBoundingClientRect();
            setCoordinates({
                el,
                dr,
            })
            dropdownRef.current = node;
        }
    }, []);

    useEffect(() => {
        if (elementRef.current && dropdownRef.current) {
            const el = elementRef.current.getBoundingClientRect();
            const dr = dropdownRef.current.getBoundingClientRect();
            setCoordinates({
                el,
                dr,
            })
        }
    }, [dropdownRef]);

    useEffect(() => {
        document.removeEventListener('click', clickHandler);
        document.removeEventListener('keydown', keydownHandler);
        if (isActive) {
            setTimeout(() => {
                document.addEventListener('click', clickHandler);
                document.addEventListener('keydown', keydownHandler);
            }, 100)
        }

        return (() => {
            document.removeEventListener('click', clickHandler);
            document.removeEventListener('keydown', keydownHandler);
        });
    }, [isActive]);

    const contextStyle = useMemo(() => {
        const positionStyles = {};

        if (coordinates.el && coordinates.dr) {
            if (position === 'bottom-center') {
                positionStyles.top = coordinates.el.y + coordinates.el.height + (fixed ? 0 : window.scrollY) + 5;
                positionStyles.left = coordinates.el.x + (coordinates.el.width / 2) - (coordinates.dr.width / 2);
            } else if (position === 'bottom-right') {
                positionStyles.top = coordinates.el.y + coordinates.el.height + (fixed ? 0 : window.scrollY) + 5;
                positionStyles.left = coordinates.el.x  + (coordinates.el.width / 2) - 50;
            }
        }

        return { ...positionStyles, zIndex, position: fixed ? 'fixed' : 'absolute' }
    }, [coordinates, zIndex, fixed, position])

    return (
        <div ref={elementRef} >
            <div
                className={style.pointer}
                onFocus={!disabled ? onFocus : undefined}
                onClick={!disabled ? onFocus : undefined}
            >
                {children}
            </div>
            <Portal>
                <AnimatePresence>
                    {!!isActive && (
                        <motion.div
                            ref={dropdownRefFunc}
                            className={style.context}
                            style={contextStyle}
                            initial={{ scale: 1, opacity: 0, y: -10 }}
                            animate={{ scale: 1, opacity: 1, y: 0 }}
                            exit={{ scale: 1, opacity: 0, y: -10 }}
                        >
                            {
                                context
                            }
                        </motion.div>
                    )}
                </AnimatePresence>
            </Portal>
        </div>
    );
};

Context.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
    context: PropTypes.element,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    zIndex: PropTypes.number,
    fixed: PropTypes.bool,
    position: PropTypes.oneOf(['bottom-center', 'bottom-right']),
    dismiss: PropTypes.oneOf(['click', 'blur']),
    disabled: PropTypes.bool,
};

Context.defaultProps = {
    onOpen: undefined,
    onClose: undefined,
    zIndex: 100,
    fixed: false,
    disabled: false,
    dismiss: 'blur',
    position: 'bottom-center'
};

export default Context;
