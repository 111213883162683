import { defineMessages } from 'react-intl';

export default defineMessages({
    all: {
        id: 'autocomplete.all',
        defaultMessage: 'All'
    },
    selectedNumber: {
        id: 'autocomplete.selectedNumber',
        defaultMessage: '{number} selected'
    },
    showAll: {
        id: 'autocomplete.showAll',
        defaultMessage: 'Show all ({length})'
    },
    isRequired: {
        id: 'autocomplete.error.isRequired',
        defaultMessage: 'Field is required!'
    },
    min: {
        id: 'autocomplete.error.min',
        defaultMessage: 'Value should contain at least {min} elements!'
    },
    max: {
        id: 'autocomplete.error.max',
        defaultMessage: 'Value should contain at most {max} elements!'
    },
    noOptions: {
        id: 'autocomplete.noOptions',
        defaultMessage: 'No options'
    },
    only: {
        id: 'autocomplete.only',
        defaultMessage: 'Only'
    },
});