import { useEffect, useState } from 'react';

import useAuthentication from "@quidlo/common/hooks/useAuthentication";
import useWorkspace from "@quidlo/common/hooks/useWorkspace";

const useSuggestions = () => {
    const { user } = useAuthentication();
    const { workspace } = useWorkspace();
    const id = (workspace && user) ? `${workspace.code}-${user.id}` : '-';

    const [titles, setTitles] = useState(['Meeting']);
    const [tags, setTags] = useState([]);
    const [projects, setProjects] = useState([]);
    const [durations, setDurations] = useState(['1h 30m']);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const data = JSON.parse(localStorage.getItem('suggestions') || "{}");
        if (data[id]) {
            setTitles(data[id].titles);
            setTags(data[id].tags);
            setProjects(data[id].projects);
            setDurations(data[id].durations);
            setUsers(data[id].users);
        } else {
            localStorage.setItem('suggestions', JSON.stringify({
                ...data,
                [id]: {
                    titles,
                    tags,
                    projects,
                    durations,
                    users,
                }
            }));
        }


    }, []);

    useEffect(() => {
        const data = JSON.parse(localStorage.getItem('suggestions') || "{}");
        localStorage.setItem('suggestions', JSON.stringify({
            ...data,
            [id]: {
                titles,
                tags,
                projects,
                durations,
                users,
            }
        }))
    }, [titles, tags, projects, durations, users])

    const feedSuggestions = (key, value) => {
        if (!value) return;

        const arrValue = Array.isArray(value) ? value : [value];

        switch (key) {
            case 'titles': {
                setTitles(titles => [
                    ...arrValue,
                    ...(titles || []).filter(t => !(arrValue.includes(t))),
                ].splice(0,10))
                return;
            }
            case 'tags': {
                setTags(tags => [
                    ...arrValue,
                    ...(tags || []).filter(t => !arrValue.includes(t)),
                ].splice(0,10))
                return;
            }
            case 'projects': {
                setProjects(projects => [
                    ...arrValue,
                    ...(projects || []).filter(t => !arrValue.includes(t)),
                ].splice(0,10))
                return;
            }
            case 'durations': {
                setDurations(durations => [
                    ...arrValue,
                    ...(durations || []).filter(t => !arrValue.includes(t)),
                ].splice(0,10))
                return;
            }
            case 'users': {
                setUsers(users => [
                    ...arrValue,
                    ...(users || []).filter(t => !arrValue.includes(t)),
                ].splice(0,10))
                return;
            }
            default: {
                return;
            }
        }
    }

    return {
        titles,
        tags,
        projects,
        durations,
        users,
        feedSuggestions
    };
};

export default useSuggestions;
