import { defineMessages } from 'react-intl';

export default defineMessages({
    cookiesError: {
        id: 'authProvider.cookiesError',
        defaultMessage: 'Cookies are not enabled in this environment',
    },
    workspaceSwitched: {
        id: 'authProvider.workspaceSwitched',
        defaultMessage: 'Workspace switched',
    },
});
