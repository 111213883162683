import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'dataValidationDidntSucceed.title',
        defaultMessage: 'Data validation didn’t succeed',
    },
    description1: {
        id: 'dataValidationDidntSucceed.description1',
        defaultMessage: 'Unfortunately your invoice information validation failed. Please make sure you provided correct input and try again.',
    },
    alt: {
        id: 'dataValidationDidntSucceed.alt',
        defaultMessage: 'A man with a phone',
    },
    cancel: {
        id: 'dataValidationDidntSucceed.cancel',
        defaultMessage: 'Cancel',
    },
    tryAgain: {
        id: 'dataValidationDidntSucceed.tryAgain',
        defaultMessage: 'Try again',
    },
});
