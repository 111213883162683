import React from 'react';
import { useIntl } from 'react-intl';

import useUI from "@quidlo/common/hooks/useUI";
import { Button, Space } from "@quidlo/ui";

import illustration from "../../../assets/modals/trash.png";
import { deleteUserMutation } from "../../../queries/users.queries";
import ModalHOC from "../../../ui/Modal";
import { ModalActions, ModalContent, ModalHeader } from "../../../ui/Modal/Modal";

import style from "./DeleteUser.module.scss";

import messages from "./DeleteUser.i18n";

const DeleteUser = () => {
    const
        intl = useIntl(),
        { modal, modalClose } = useUI(),
        { userId, name } = modal.data || {},
        { mutate } = deleteUserMutation(),

        deleteUser = async () => {
            mutate(userId);
            modalClose();
        };

    return (
        <>
            <ModalHeader>
                {intl.formatMessage(messages.title)}
            </ModalHeader>
            <ModalContent>
                <>
                    <span>
                        <span className={style.description}>{intl.formatMessage(messages.subtitle1)}</span>
                        <span className={style.name}>{` ${name}`}</span>
                        <span className={style.description}>{intl.formatMessage(messages.subtitle2)}</span>
                    </span>
                    <div className={style.disclaimer}>{intl.formatMessage(messages.disclaimer)}</div>
                </>
            </ModalContent>
            <ModalActions>
                <Space mr="m">
                    <Button
                        color="white"
                        text={intl.formatMessage(messages.cancel)}
                        onClick={modalClose}
                    />
                </Space>
                <Button
                    color="blue"
                    text={intl.formatMessage(messages.delete)}
                    onClick={deleteUser}
                    shadow
                />
            </ModalActions>
        </>
    );
};

export default ModalHOC(
    DeleteUser,
    {
        id: 'deleteUser',
        size: 'small',
        illustration,
    }
);

