import React from 'react';
import { FormattedDate } from 'react-intl';
import cx from 'classnames';
import PropTypes from 'prop-types';

import analytics from "@quidlo/common/utils/analytics";
import { Context, Icon } from '@quidlo/ui';

import DatePickerContext from '../DatePickerContext';

import style from './DatePicker.module.scss';

const DatePicker = ({
    date,
    dateRange,
    type,
    size,
    onNext,
    onPrev,
    onChange,
    dateBoundary,
    analyticsID
}) => {
    const change = val => {
        onChange(val);
        if (analyticsID) {
            analytics.logTimesheetsDatePickerSelect(val);
        }
    }

    const contextProps = {
        date: date || dateRange.gte,
        dateRange,
        type,
        onChange: change,
        dateBoundary,
        analyticsID
    };

    return (

        <div className={cx(style.datePicker, style[`size-${size}`])}>
            {onNext && onPrev && (
                <div className={style.left}>
                    <a
                        onClick={onPrev}
                    >
                        <Icon icon="caret-left" />
                    </a>
                    <a
                        onClick={onNext}
                    >
                        <Icon icon="caret-right" />
                    </a>
                </div>
            )}
            <div className={style.center}>
                <span>
                    <FormattedDate value={dateRange.gte} month="short" />{' '}
                    <FormattedDate value={dateRange.gte} day="2-digit" /> -{' '}
                    <FormattedDate value={dateRange.lte} month="short" />{' '}
                    <FormattedDate value={dateRange.lte} day="2-digit" />
                </span>
            </div>
            <div className={style.right}>
                <Context
                    context={<DatePickerContext {...contextProps} />}
                    onOpen={analyticsID ? () => {
                        analytics.logTimesheetsDatePickerOpen({ id: analyticsID })
                    } : null}
                    onClose={analyticsID ? () => {
                        analytics.logTimesheetsDatePickerClose({ id: analyticsID })
                    } : null}
                >
                    <div
                        className={style.button}
                        role="button"
                        tabIndex="0">
                        <Icon color="blue" icon="calendar" />
                    </div>
                </Context>
            </div>
        </div>
    )
};

DatePicker.propTypes = {
    date: PropTypes.instanceOf(Date),
    dateRange: PropTypes.shape({
        gte: PropTypes.instanceOf(Date).isRequired,
        lte: PropTypes.instanceOf(Date).isRequired
    }).isRequired,
    type: PropTypes.oneOf(['week', 'range']),
    size: PropTypes.oneOf(['small', 'medium']),
    onNext: PropTypes.func,
    onPrev: PropTypes.func,
    onChange: PropTypes.func,
    dateBoundary: PropTypes.object,
    analyticsID: PropTypes.string
};

DatePicker.defaultProps = {
    date: undefined,
    type: 'week',
    size: 'medium',
    onNext: undefined,
    onPrev: undefined,
    onChange: undefined,
    dateBoundary: undefined,
    analyticsID: undefined
};

export default DatePicker;
