import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import useWorkspace from "@quidlo/common/hooks/useWorkspace";

import style from "./Marker.module.scss";

const Marker = ({ date }) => {
    const
        { workspace } = useWorkspace(),
        { weekStart } = workspace;

    const markerPosition = useMemo(() => {
        if (weekStart === 'sunday') {
            return date.getDay();
        } else {
            return (date.getDay() ? date.getDay() - 1 : 6);
        }
    }, [date]);

    return (
        <div
            className={style.marker}
            style={{
                transform: `translateX(${100 * markerPosition}%)`,
            }}>
            <div />
        </div>
    );
}

Marker.propTypes = {
    date: PropTypes.instanceOf(Date).isRequired,
}

export default Marker;