import { defineMessages } from 'react-intl';

export default defineMessages({
    editTask: {
        id: 'editTaskDialog.editTask',
        defaultMessage: 'Edit task',
    },
    date: {
        id: 'editTaskDialog.date',
        defaultMessage: 'Date',
    },
    project: {
        id: 'editTaskDialog.project',
        defaultMessage: 'Project',
    },
    duration: {
        id: 'timer.duration',
        defaultMessage: 'Duration',
    },
    start: {
        id: 'timer.start',
        defaultMessage: 'Start',
    },
    stop: {
        id: 'timer.stop',
        defaultMessage: 'Stop',
    },
    task: {
        id: 'editTaskDialog.task',
        defaultMessage: 'Task description',
    },
    tags: {
        id: 'editTaskDialog.tags',
        defaultMessage: 'Tags',
    },
    wrongTimeFormat: {
        id: 'editTaskDialog.wrongTimeFormat',
        defaultMessage: 'Incorrect time format',
    },
    descriptionMin: {
        id: 'editTaskDialog.descriptionMin',
        defaultMessage: 'Description should be at least {min} characters long',
    },
    descriptionMax: {
        id: 'editTaskDialog.descriptionMax',
        defaultMessage: 'Description should be at most {min} characters long',
    },
    edit: {
        id: 'editTaskDialog.edit',
        defaultMessage: 'Edit',
    },
    cancel: {
        id: 'editTaskDialog.cancel',
        defaultMessage: 'Cancel',
    },
    save: {
        id: 'editTaskDialog.save',
        defaultMessage: 'Save',
    },
    pleaseSelect: {
        id: 'editTaskDialog.pleaseSelect',
        defaultMessage: 'Please select project',
    },
    timeStartStopError: {
        id: 'editTaskDialog.timeStartStopError',
        defaultMessage: 'Start time must be equal or greater than Stop time'
    },
});