import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'confirmDurationExceeded.title',
        defaultMessage: 'Task duration exceeded',
    },
    text: {
        id: 'confirmDurationExceeded.text',
        defaultMessage: 'The duration of this task exceeded 24 hours.',
    },
    ok: {
        id: 'confirmDurationExceeded.ok',
        defaultMessage: 'OK',
    },
});