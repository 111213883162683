import React, { useMemo } from 'react';
import { useIntl } from "react-intl";
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Icon, Tooltip } from '..';

import style from './Autocomplete.module.scss';

import messages from './Autocomplete.i18n';

const AutocompleteView =({
    fieldId,
    label,
    placeholder,
    error,
    inputValue,
    selectedOptions,
    onInput,
    onFocus,
    isInputFocused,
    isMulti,
    allSelected,
    type,
    isRequired,
}) => {
    const intl = useIntl();

    const valueContent = useMemo(() => {
        if (type === 'text') {
            let text = ''
            if (isMulti) {
                if (allSelected) {
                    if (selectedOptions.length > 0) {
                        text = intl.formatMessage(messages.all);
                    }
                } else if (selectedOptions.length === 1) {
                    text = selectedOptions[0].labelText;
                } else if (selectedOptions.length > 1) {
                    text = intl.formatMessage(messages.selectedNumber, {
                        number: selectedOptions.length,
                    });
                }
            } else if (selectedOptions[0]) {
                text = selectedOptions[0].labelText;
            }
            return (
                <div className={style.text}>
                    <Tooltip
                        text={
                            selectedOptions.length > 1
                                ? selectedOptions
                                    .map(opt => opt.labelText)
                                    .join(', ')
                                : ''
                        }>
                        <div>{text}</div>
                    </Tooltip>
                </div>
            );
        }

        if (type === 'chips') {
            if (!selectedOptions?.length) return [];

            if (
                selectedOptions.length === 1 ||
                (selectedOptions.length === 2 &&
                    selectedOptions[0].labelText.length <= 5 &&
                    selectedOptions[1].labelText.length <= 5)
            ) {
                return selectedOptions.map(o => (
                    <Tooltip text={o.labelText} key={o.labelText}>
                        <div className={style.chip}>
                            {o.labelText}
                        </div>
                    </Tooltip>
                ));
            }

            if (
                selectedOptions[0].labelText.length <= 4 &&
                selectedOptions[1].labelText.length <= 4
            ) {
                const temp = selectedOptions
                    .map(o => (
                        <Tooltip text={o.labelText} key={o.labelText}>
                            <div className={style.chip}>
                                {o.labelText}
                            </div>
                        </Tooltip>
                    ));
                const chips = temp.slice(0, 2);
                const tooltipText = selectedOptions.map(o => o.labelText);
                chips.push(
                    <Tooltip
                        text={tooltipText
                            .slice(2, tooltipText.length)
                            .join(', ')}
                    >
                        <div
                            className={`${style.chip} ${style.count}`}>{`+${
                                selectedOptions.length - 2
                            }`}</div>
                    </Tooltip>,
                );
                return chips;
            }

            const temp = selectedOptions
                .map(o => (
                    <Tooltip text={o.labelText} key={o.labelText}>
                        <div className={style.chip}>
                            {o.labelText}
                        </div>
                    </Tooltip>
                ));
            const chips = temp.slice(0, 1);
            const tooltipText = selectedOptions.map(o => o.labelText);
            chips.push(
                <Tooltip
                    text={tooltipText
                        .slice(1, tooltipText.length)
                        .join(', ')}
                >
                    <div className={`${style.chip} ${style.count}`}>{`+${
                        selectedOptions.length - 1
                    }`}</div>
                </Tooltip>,
            );
            return chips;
        }

        return null

    }, [allSelected, selectedOptions, type])

    return (
        <div
            className={cx(
                style.input,
                !!(selectedOptions.length || inputValue) && style.filled,
                error && style.error,
                isInputFocused && style.inputActive,
            )}>
            <input
                onFocus={onFocus}
                type="text"
                value={inputValue}
                onChange={e => {onInput(e.target.value)}}
                autoComplete="off"
            />
            <Icon onClick={onFocus} icon="caret-down" color="flint" size="small" />
            {!isInputFocused ?
                <div className={style.value}>{valueContent}</div>
                : null}
            <span className={style.bar} />
            {label ? (
                <label htmlFor={fieldId}>
                    {label}
                    {isRequired && <span>*</span>}
                </label>
            ) : null}
            {placeholder ? (
                <span className={style.hint}>{placeholder}</span>
            ) : null}
            {error && (
                <span className={style.errormessage}>
                    {error}
                </span>
            )}
        </div>
    );
};

AutocompleteView.propTypes = {
    fieldId: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    error: PropTypes.string,
    inputValue: PropTypes.string,
    selectedOptions: PropTypes.array,
    onInput: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    isInputFocused: PropTypes.bool,
    isMulti: PropTypes.bool,
    allSelected: PropTypes.bool,
    type: PropTypes.string.isRequired,
    isRequired: PropTypes.bool,
};

AutocompleteView.defaultProps = {
    label: '',
    placeholder: '',
    error: '',
    inputValue: '',
    selectedOptions: [],
    isInputFocused: false,
    isMulti: false,
    allSelected: false,
    isRequired: false,
};

export default AutocompleteView;
