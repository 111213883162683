import { defineMessages } from 'react-intl';

export default defineMessages({
    addTeamMember: {
        id: 'addTeamMembers.addTeamMember',
        defaultMessage: 'Add team members'
    },
    youCanEdit: {
        id: 'addTeamMembers.youCanEdit',
        defaultMessage: 'You can add multiple members at once and edit their roles later in the Project Team section.'
    },
    addTeamMemberEmail: {
        id: 'addTeamMembers.addTeamMemberEmail',
        defaultMessage: 'Add team member (e-mail)'
    },
    pressEnterToAdd: {
        id: 'addTeamMembers.pressEnterToAdd',
        defaultMessage: 'Press enter to add project member'
    },
    cancel: {
        id: 'addTeamMembers.cancel',
        defaultMessage: 'Cancel'
    },
    members: {
        id: 'addTeamMembers.members',
        defaultMessage: 'Members'
    },
    addMembers: {
        id: 'addTeamMembers.addMembers',
        defaultMessage: 'Add members'
    },
});