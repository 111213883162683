import { defineMessages } from 'react-intl';

export default defineMessages({
    trialExpiredTitle: {
        id: 'subscriptionExpired.trialExpiredTitle',
        defaultMessage: 'Your 30-day free trial just expired!',
    },
    subscriptionExpiredTitle: {
        id: 'subscriptionExpired.subscriptionExpiredTitle',
        defaultMessage: 'Your subscription plan just expired',
    },
    subtitleBold: {
        id: 'subscriptionExpired.subtitleBold',
        defaultMessage: 'To continue, please ask the workspace’s owner to upgrade the plan.',
    },
    subtitleRegular: {
        id: 'subscriptionExpired.subtitleRegular',
        defaultMessage: 'Once upgraded, you will regain the access to Quidlo Timesheets automatically!',
    },
    alt: {
        id: 'subscriptionExpired.alt',
        defaultMessage: 'A man with calendar and clock in his hands',
    },
    workspaceOwner: {
        id: 'subscriptionExpired.workspaceOwner',
        defaultMessage: 'Workspace Owner: {email}',
    },
});