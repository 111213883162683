import React from 'react';
import { FormattedDate } from 'react-intl';
import PropTypes from "prop-types";

import { durationFormatter } from '@quidlo/common/utils/duration';
import { Pill, Space } from '@quidlo/ui';

import style from "./SummaryTable.module.scss";

const SummaryTable = ({ dateRange, list }) => {
    const totalTime = list.reduce((acc, { duration_mins }) => acc + duration_mins, 0)

    return (
        <Space ph="m" pv="xs" className={style.table}>
            <Space pv="xxs" direction="row" className={style.total}>
                <div>
                    {dateRange?.gte &&
                        <>
                            <FormattedDate value={dateRange.gte} month="short" />{' '}
                            <FormattedDate value={dateRange.gte} day="2-digit" /> -{' '}
                            <FormattedDate value={dateRange.lte} month="short" />{' '}
                            <FormattedDate value={dateRange.lte} day="2-digit" />
                        </>
                    }
                </div>
                <Pill text={durationFormatter(totalTime)} size={'small'} />
            </Space>
            <div className={style.projects}>
                {list.map(({ user, project, duration_mins }) => (
                    <Space direction="row" pv="xxs" className={style.project} key={project.id}>
                        <div className={style.left}>
                            {
                                user ?
                                    `${user.first_name} ${user.last_name} - ${project.name}` :
                                    project.name
                            }
                        </div>
                        <div className={style.right}>
                            <Pill size={'small'} text={durationFormatter(duration_mins)} />
                        </div>
                    </Space>
                ))}
            </div>
        </Space>
    );
}

SummaryTable.propTypes = {
    dateRange: PropTypes.shape({
        gte: PropTypes.instanceOf(Date),
        lte: PropTypes.instanceOf(Date)
    }),
    list: PropTypes.array
}

SummaryTable.defaultProps = {
    list: []
}

export default SummaryTable;