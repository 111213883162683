import { defineMessages } from 'react-intl';

export default defineMessages({
    tagAddedToast: {
        id: 'basicParams.tagAddedToast',
        defaultMessage: 'Project tag added'
    },
    tagRemovedToast: {
        id: 'basicParams.tagRemovedToast',
        defaultMessage: 'Project tag removed'
    },
    tags: {
        id: 'basicParams.tags',
        defaultMessage: 'Tags'
    },
    addNewTag: {
        id: 'basicParams.addNewTag',
        defaultMessage: 'Add new tag'
    },
    tagsInUse: {
        id: 'basicParams.tagsInUse',
        defaultMessage: 'Tags in use'
    },
    newTagAdded: {
        id: 'basicParams.newTagAdded',
        defaultMessage: 'New tag added'
    },
    tagRemoved: {
        id: 'basicParams.tagRemoved',
        defaultMessage: 'Tag removed'
    },
    projectTagsTitle: {
        id: 'basicParams.projectTagsTitle',
        defaultMessage: 'Project tags'
    },
    addProjectTag: {
        id: 'basicParams.addProjectTag',
        defaultMessage: 'Press enter to add custom project tag'
    }
});