import { defineMessages } from 'react-intl';

export default defineMessages({
    all: {
        id: 'potentialApprovalDetails.all',
        defaultMessage: 'All',
    },
    project: {
        id: 'potentialApprovalDetails.project',
        defaultMessage: 'Project',
    },
    logDate: {
        id: 'potentialApprovalDetails.logDate',
        defaultMessage: 'Log date',
    },
    person: {
        id: 'potentialApprovalDetails.person',
        defaultMessage: 'Person',
    },
    duration: {
        id: 'potentialApprovalDetails.duration',
        defaultMessage: 'Duration',
    },
    period: {
        id: 'potentialApprovalDetails.period',
        defaultMessage: 'Period',
    },
    tags: {
        id: 'potentialApprovalDetails.tags',
        defaultMessage: 'Tags',
    },
    description: {
        id: 'potentialApprovalDetails.description',
        defaultMessage: 'Description',
    },
    total: {
        id: 'potentialApprovalDetails.total',
        defaultMessage: 'Total:',
    },
    details: {
        id: 'potentialApprovalDetails.details',
        defaultMessage: 'Details',
    },
    loggedFrom: {
        id: 'potentialApprovalDetails.loggedFrom',
        defaultMessage: 'Logged from',
    },
    startStop: {
        id: 'defaultMessage.startStop',
        defaultMessage: 'Start - Stop',
    },
});