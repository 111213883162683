import React, { useMemo } from 'react';
import { useIntl } from "react-intl";

import { PROJECT_ROLE, WORKSPACE_ROLE } from "@quidlo/common/constants/main";
import useForm from "@quidlo/common/hooks/useForm2";
import useWorkspace from "@quidlo/common/hooks/useWorkspace";
import analytics from "@quidlo/common/utils/analytics";
import { Button, Header, PillBar, Space } from "@quidlo/ui";

import { Container, DatePicker } from "../../ui";
import ViewHOC from '../../ui/View/View';
import { addDays, isToday, setWeekday } from '../../utils/dateHelpers';
import DialogDeleteTask from "../modals/DeleteTask";
import DialogEditTask from "../modals/EditTask";

import DialogConfirmDurationExceeded from "./modals/ConfirmDurationExceeded";
import ProjectsList from "./ProjectsList";
import TimeBar from "./TimeBar";
import TrackerForm from "./TrackerForm";

import style from "./Tracker.module.scss";

import messages from "./Tracker.i18n";

const Tracker = () => {
    const
        intl = useIntl(),
        { workspace } = useWorkspace(),
        { weekStart } = workspace,
        [form] = useForm(useMemo(() => ({
            date: {
                value: new Date()
            },
            dateRange: {
                value: {
                    gte: setWeekday(new Date(), 0, weekStart),
                    lte: setWeekday(new Date(), 6, weekStart),
                }
            },
            mode: {
                value: 'my'
            }
        }), [])),
        setDate = ({ date, range }) => {
            if (date) {
                form.date.onChange(date)
            }

            if (range) {
                form.dateRange.onChange(range)
            }
        },
        setToday = () => {
            analytics.logTimesheetsTrackerTodayButtonClick();
            form.date.onChange(new Date())
            form.dateRange.onChange({
                gte: setWeekday(new Date(), 0, weekStart),
                lte: setWeekday(new Date(), 6, weekStart),
            });
        },
        nextWeek = () => {
            form.date.onChange(addDays(form.date.value, 7));
            form.dateRange.onChange({
                gte: addDays(form.dateRange.value.gte, 7),
                lte: addDays(form.dateRange.value.lte, 7),
            });
        },
        prevWeek = () => {
            form.date.onChange(addDays(form.date.value, -7));
            form.dateRange.onChange({
                gte: addDays(form.dateRange.value.gte, -7),
                lte: addDays(form.dateRange.value.lte, -7),
            });
        };

    const isTeam = useMemo(() =>
        [WORKSPACE_ROLE.OWNER, WORKSPACE_ROLE.ADMIN].includes(workspace.organizationRole) ||
        PROJECT_ROLE.MANAGER === workspace.projectsRole
    , [workspace])

    return (
        <>
            <Container>
                <Space mb="l">
                    <Header
                        title="Tracker"
                        icon="timer"
                        size="large"
                        underline
                        leftChildren={
                            <>
                                <Space mr="s">
                                    <Button
                                        text={intl.formatMessage(messages.today)}
                                        type="bordered"
                                        size="small"
                                        color="white"
                                        onClick={setToday}
                                    />
                                </Space>
                                <DatePicker
                                    size="small"
                                    date={form.date.value}
                                    dateRange={form.dateRange.value}
                                    onChange={v => {
                                        setDate(v);
                                        analytics.logTimesheetsTrackerCalendarSelect();
                                    }}
                                    onNext={v => {
                                        nextWeek(v)
                                        analytics.logTimesheetsTrackerArrowButtonClick('Next')
                                    }}
                                    onPrev={v => {
                                        prevWeek(v)
                                        analytics.logTimesheetsTrackerArrowButtonClick('Prev')
                                    }}
                                    analyticsID="Tracker"
                                />
                            </>
                        }
                        rightChildren={
                            <div>
                                {isTeam &&
                                    <PillBar
                                        {...form.mode}
                                        options={[
                                            {
                                                value: 'my',
                                                label: intl.formatMessage(messages.me)
                                            },
                                            {
                                                value: 'all',
                                                label: intl.formatMessage(messages.all)
                                            }
                                        ]}
                                        fieldId="manager-content-switch"
                                    />
                                }
                            </div>
                        }
                    />
                    <div className={style.top}>
                        <div>
                            <TimeBar
                                dateStart={form.dateRange.value.gte}
                                dateEnd={form.dateRange.value.lte}
                                date={form.date.value}
                                setDate={setDate}
                                nextWeek={nextWeek}
                                prevWeek={prevWeek}
                                mode={form.mode.value}
                            />
                        </div>
                        <div>
                            <TrackerForm
                                scope={form.mode.value}
                                date={form.date.value}
                            />
                        </div>
                    </div>
                </Space>
                <Space>
                    <Header
                        title={(
                            isToday(form.date.value) ?
                                intl.formatMessage(messages.today)
                                :
                                intl.formatDate(form.date.value, {
                                    weekday: 'long',
                                    day: 'numeric',
                                    month: 'long'
                                })
                        )}
                    />
                    <ProjectsList date={form.date.value} mode={form.mode.value}/>
                </Space>
            </Container>
            <DialogDeleteTask />
            <DialogEditTask />
            <DialogConfirmDurationExceeded />
        </>
    );
};

export default ViewHOC(
    Tracker,
    {
        title: 'Tracker',
        menu: true,
        topbar: true,
        auth: {
            roles: ['employee', 'manager', 'admin', 'owner'],
        },
        subscription: true
    },
);
