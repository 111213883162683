import React from 'react';
import PropTypes from 'prop-types';

import style from './Explanation.module.scss';

const Explanation = ({
    imageSrc,
    imageAlt,
    title,
    children
}) => (
    <div className={style.root}>
        {
            imageSrc && (
                <img src={imageSrc} alt={imageAlt} className={style.icon} />
            )
        }
        <div className={style.content}>
            {
                title && (
                    <div className={style.title}>
                        {title}
                    </div>
                )
            }
            <div className={style.description}>
                {children}
            </div>
        </div>
    </div>
);

Explanation.propTypes = {
    imageSrc: PropTypes.string,
    imageAlt: PropTypes.string,
    title: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
};

Explanation.defaultProps = {
    imageSrc: null,
    imageAlt: null,
    title: null,
}

export default Explanation;